import React from 'react'
import LineSetting from '../chatSettings/LineSetting'
import FacebookSetting from '../chatSettings/FacebookSetting'
import StagSetting from '../chatSettings/StagSetting'
import AgentSetting from '../chatSettings/AgentSetting'

const BoundSetting = ({ room, token, clientId, client, settings }) => {
  return (
    <div className="bg-bgLight h-full min-w-72 max-w-72">
      {client.channel === 'line' ? (
        <LineSetting room={room} token={token} showWebhook={false} clientId={clientId} client={client} />
      ) : 
      client.channel === 'messenger' ? (
        <FacebookSetting room={room} token={token} showWebhook={false} clientId={clientId} client={client} />
      ) :
      client.channel === 'agoda' ? (
        <StagSetting room={room} token={token} showWebhook={false} clientId={clientId} client={client} settings={settings} />
      ) :
      client.channel === 'airbnb' ? 
      (
        <StagSetting room={room} token={token} showWebhook={false} clientId={clientId} client={client} settings={settings} />
      ) :
      client.channel === 'agent' ? (
        <AgentSetting room={room} token={token} showWebhook={false} clientId={clientId} client={client} settings={settings} />
      ) : null
    }
    </div>
  )
}

export default BoundSetting