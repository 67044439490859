
import React, { useState, useEffect } from "react";
import { Button, message, Switch, Radio, Input, Modal, Tooltip, Tabs, Divider } from "antd";
import axios from "axios";

import TutorialModal from "./TutorialModal";

import { LoadingOutlined, ReloadOutlined, SearchOutlined } from "@ant-design/icons";
import { Spin } from "antd";

import { ReactComponent as EditIcon } from "../../assets/edit-icon.svg";
import { ReactComponent as PlusIcon } from "../../assets/plus-icon.svg";
import { ReactComponent as TagIcon } from "../../assets/tag-icon.svg";
import { ReactComponent as DownIcon } from "../../assets/down-icon.svg";
import { ReactComponent as PlusWhiteIcon } from "../../assets/plus-white-icon.svg";
import { ReactComponent as PlusBlackIcon } from "../../assets/plus-black-icon.svg";
import { ReactComponent as TrashIcon } from "../../assets/trash-icon.svg";
import { ReactComponent as EditIcon2 } from "../../assets/edit-icon2.svg";

import { LineChannelFacade } from "../../../../application/facades/LineChannelFacade";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const WEBHOOK_PATH = "/public/line/callback/";

const propertyMap = (item) => {
  if (item === "useWebhook") return "use_webhook";
  if (item === "temperature") return "temperature";
  if (item === "webSearch") return "web_search";
  if (item === "responseMode") return "response_mode";
  if (item === "queryOnly") return "query_only";
  if (item === "agentType") return "agent_type";
  return item;
};

const Footer = ({ disabled, ctaWord = "完成", onClick, tutorial }) => {
  return (
    <div className="flex flex-row justify-end items-center gap-2 mt-4">
      <Button onClick={() => tutorial(true)}>說明</Button>
      <Button type="primary" onClick={onClick} disabled={disabled}>
        {ctaWord}
      </Button>
    </div>
  );
};

const BaseModal = ({
  id,
  token,
  open,
  close,
  webhook,
  tutorial,
  refresh,
  oldChannelId,
  oldChannelSecret,
  oldChannelAccessToken,
}) => {
  const [channelId, setChannelId] = useState(oldChannelId);
  const [channelSecret, setChannelSecret] = useState(oldChannelSecret);
  const [channelAccessToken, setChannelAccessToken] = useState(
    oldChannelAccessToken
  );

  const handleUpdateBase = async () => {
    try {
      await axios.patch(
        `${SERVER_URL}/private/property/chatroom/line_channel/settings/${id}/base`,
        {
          channel_id: channelId,
          channel_secret: channelSecret,
          channel_access_token: channelAccessToken,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      message.success("Line@ 串接變更成功");
      close();
    } catch (error) {
      message.error(JSON.stringify(error.response?.data?.detail));
    }
  };

  const handleComplete = () => {
    handleUpdateBase();
    refresh();
    close();
    webhook(true);
  };

  return (
    <Modal
      open={open}
      onCancel={close}
      width={485}
      footer={
        <Footer
          disabled={false}
          ctaWord="變更"
          onClick={handleComplete}
          tutorial={tutorial}
        />
      }
      title="Line@ 變更串接"
    >
      <div className="text-xs text-textLight mb-4">
        請確認三項資料皆正確，以免變更後串接失敗。變更串接後可能導致聊天資料於本系統中遺失。
      </div>
      <div className="flex flex-col border border-borderLight rounded bg-bgMedium w-full">
        <div className="flex flex-col items-start w-full h-22 p-4 gap-2 border-t border-t-borderLight">
          <div className="font-bold">Channel ID</div>
          <Input
            value={channelId}
            onChange={(e) => setChannelId(e.target.value)}
          />
        </div>
        <div className="flex flex-col items-start w-full h-22 p-4 gap-2 border-t border-t-borderLight">
          <div className="font-bold">Channel Secret</div>
          <Input
            value={channelSecret}
            onChange={(e) => setChannelSecret(e.target.value)}
          />
        </div>
        <div className="flex flex-col items-start w-full h-22 p-4 gap-2 border-t border-t-borderLight">
          <div className="font-bold">Access Token</div>
          <Input
            value={channelAccessToken}
            onChange={(e) => setChannelAccessToken(e.target.value)}
          />
        </div>
      </div>
    </Modal>
  );
};

const WebhookModal = ({ id, open, close, handleCopy, copied, tutorial }) => {
  const [copiedOnce, setCopiedOnce] = useState(false);

  useEffect(() => {
    if (copied) {
      setCopiedOnce(true);
    }
  }, [copied]);

  return (
    <Modal
      open={open}
      maskClosable={false}
      closeIcon={null}
      width={400}
      footer={
        <Footer disabled={!copiedOnce} onClick={close} tutorial={tutorial} />
      }
      title="Webhook 網址"
    >
      <div className="flex flex-col items-center min-h-20">
        <div className="items-start">
          <div className="text-textDark mb-2">
            前往 Line Developer 後台，在您 Provider 的中 Messaging API
            的頁面內，於 Webhook settings 的 Webhook URL 區塊貼上以下網址。
          </div>
          <Tooltip title={copied ? "已複製" : "點擊以複製"}>
            <div
              className="flex flex-col p-2 rounded bg-bgLight border w-full"
              onClick={handleCopy}
            >
              {`${SERVER_URL}${WEBHOOK_PATH}${id}`}
            </div>
          </Tooltip>
        </div>
      </div>
    </Modal>
  );
};

const TagModal = ({ open, onClose, clientId, room, updateClientData, client }) => {
  const [newTag, setNewTag] = useState("");
  const [searchTag, setSearchTag] = useState("");
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [localTags, setLocalTags] = useState([]);

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const response = await LineChannelFacade.fetchTagGroup(room.id);
        setTags(response.tags);
        // 初始化 localTags
        if (client?.tags) {
          setLocalTags(client.tags);
        }
      } catch (error) {
        console.error('Error fetching tags:', error);
      }
    };
    fetchTags();
  }, [room.id, client?.tags]);

  // 處理新增標籤
  const handleAddNewTag = async (tag) => {
    if (!tag && !newTag.trim()) {
      message.error('請輸入標籤名稱');
      return;
    }

    const tagName = tag ? tag.name : newTag.trim();
    
    setLoading(true);
    try {
      await LineChannelFacade.addTag(room.id, clientId, tagName);
      message.success('標籤新增成功');
      // 更新本地狀態
      const newTagObj = { id: Date.now(), name: tagName }; // 臨時 ID
      setLocalTags(prev => [...prev, newTagObj]);
      // 清空輸入
      setNewTag("");
      // 更新父組件數據
      await updateClientData();
    } catch (error) {
      console.error('Error adding new tag:', error);
      message.error(error.message || '新增標籤失敗');
    } finally {
      setLoading(false);
    }
  };

  // 處理移除已選擇的標籤
  const handleRemoveSelectedTag = async (tag) => {
    Modal.confirm({
      title: '確認刪除標籤',
      content: (
        <div>
          <p>確定要刪除此標籤嗎？</p>
          <div className="mt-2 p-2 bg-gray-100 rounded max-h-[100px] overflow-y-auto">
            {tag?.name}
          </div>
        </div>
      ),
      okText: '刪除',
      cancelText: '取消',
      okButtonProps: {
        danger: true,
      },
      onOk: async () => {
        if (!tag?.id) {
          console.error('Tag ID is missing:', tag);
          message.error('標籤資訊不完整');
          return;
        }
    
        try {
          await LineChannelFacade.deleteTag(room.id, clientId, tag.id);
          message.success('標籤刪除成功');
          // 更新本地狀態
          setLocalTags(prev => prev.filter(t => t.id !== tag.id));
          await updateClientData();
        } catch (error) {
          console.error('Error removing tag:', error);
          message.error(error.message || '刪除標籤失敗');
        }
      },
    });
  };

  // 過濾搜尋結果
  const filteredTags = tags.filter(tag =>
    tag.name.toLowerCase().includes(searchTag.toLowerCase())
  );

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.nativeEvent.isComposing) {
      handleAddNewTag();
    }
  };

  return (
    <Modal
      title={<div className="text-center">編輯標籤</div>}
      open={open}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose} className="bg-[#F5F5F5] border-none">
          取消
        </Button>,
        <Button key="submit" type="primary" className="bg-[#67BE5F] hover:bg-[#67BE5F] border-none" onClick={onClose}>
          完成
        </Button>
      ]}
      width={600}
    >
      <div className="flex flex-col gap-4">
        <div className="text-[16px] font-bold">
          新增標籤
        </div>
        <div className="flex items-center gap-2">
          <Input
            placeholder="輸入標籤"
            value={newTag}
            onChange={(e) => setNewTag(e.target.value)}
            maxLength={20}
            suffix={<span className="text-gray-400">{newTag.length}/20</span>}
            allowClear
            className="flex-grow"
            onKeyDown={(e) => handleKeyDown(e)}
          />
          <button
            onClick={() => handleAddNewTag()}
            disabled={!newTag || loading}
            className={`flex items-center justify-center w-[32px] h-[32px] rounded-lg transition-colors cursor-pointer active:scale-90 ${loading ? 'opacity-50' : ''}`}
          >
            {loading ? (
              <LoadingOutlined className="w-4 h-4" />
            ) : (
              <PlusWhiteIcon className="w-4 h-4 text-white" />
            )}
          </button>
        </div>

        {/* 已選擇的標籤 */}
        <div className="h-[120px] overflow-y-auto">
          {localTags?.length > 0 ? (
            <div className="flex flex-wrap gap-2">
              {localTags.map((tag, index) => (
                <div
                  key={index}
                  className='h-7 w-fit flex items-center gap-2 px-3 py-1 cursor-pointer rounded-full bg-primaryMedium text-sm text-textDark border-[#67BE5F] text-textDark bg-[#67BE5F] hover:bg-[#67BE5F]'
                >
                  {tag?.name}
                  <PlusBlackIcon
                    className="w-[10px] h-[10px] transform cursor-pointer active:scale-90 hover:scale-110"
                    onClick={() => handleRemoveSelectedTag(tag)}
                  />
                </div>
              ))}
            </div>
          ) : (
            <div className="flex flex-col gap-2 items-center justify-center h-full">
              <TagIcon className="w-[31px] h-[39px] text-gray-400" />
              <div className="text-[14px] font-bold text-gray-400">尚無標籤</div>
              <div className="text-[14px] text-gray-400">您可手動輸入標籤或是從現有標籤中點選加入</div>
            </div>
          )}
        </div>

        <div className="text-[16px] font-bold mt-2">
          現有標籤
        </div>
        <Input
          placeholder="搜尋標籤"
          value={searchTag}
          onChange={(e) => setSearchTag(e.target.value)}
          prefix={<SearchOutlined className="w-4 h-4 text-gray-400" />}
        />
        <div className="flex flex-wrap gap-2 mt-2">
          {filteredTags.map((tag, index) => (
            <div
              key={index}
              className={`h-7 flex items-center gap-2 px-3 py-1 cursor-pointer rounded-full bg-primaryMedium text-sm text-textDark hover:bg-[#67BE5F] 
                ${localTags?.find(t => t.name === tag.name)
                  ? 'border-[#67BE5F] text-textDark bg-[#67BE5F]'
                  : 'border-primary border !text-primary bg-white hover:!text-white'
                }`}
              onClick={() => {
                if (!localTags?.find(t => t.name === tag.name)) {
                  handleAddNewTag(tag);
                }
              }}
            >
              {tag.name}
              {!localTags?.find(t => t.name === tag.name) ? (
                <PlusBlackIcon className="w-[10px] h-[10px] text-[#67BE5F] rotate-45 hover:text-black transform cursor-pointer" />
              ) : (
                ''
              )}
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};


const NoteModal = ({ open, onClose, room, clientId, updateClientData }) => {
  const [newNote, setNewNote] = useState("");
  const [loading, setLoading] = useState(false);

  // 重置輸入內容
  useEffect(() => {
    if (!open) {
      setNewNote("");
    }
  }, [open]);

  const handleSave = async () => {
    if (!newNote.trim()) {
      message.error('請輸入備註內容');
      return;
    }

    setLoading(true);
    try {
      await LineChannelFacade.addNote(
        room.id,
        clientId,
        '備註', // 固定使用 "備註" 作為 note_name
        newNote.trim()
      );

      message.success('備註新增成功');
      // 更新客戶資料
      await updateClientData();
      onClose();
    } catch (error) {
      console.error('Error adding note:', error);
      message.error(error.message || '新增備註失敗');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={<div className="text-center">編輯備註</div>}
      open={open}
      onCancel={onClose}
      maskClosable={false}
      destroyOnClose
      footer={[
        <Button key="cancel" onClick={onClose} className="bg-[#F5F5F5] border-none">
          取消
        </Button>,
        <Button
          key="submit"
          type="primary"
          className="bg-[#67BE5F] hover:bg-[#67BE5F] border-none"
          onClick={handleSave}
          loading={loading}
          disabled={!newNote.trim()}
        >
          儲存
        </Button>
      ]}
      width={600}
    >
      <div className="flex flex-col gap-4 h-[300px] overflow-y-auto">
        <div className="text-[16px] font-bold">
          新增備註
        </div>
        <div className="flex items-center gap-2">
          <Input.TextArea
            placeholder="請在此輸入用戶的相關資訊，這些內容將記錄於記事本中，不對外公開"
            value={newNote}
            onChange={(e) => setNewNote(e.target.value)}
            maxLength={300}
            autoSize={{ minRows: 8, maxRows: 8 }}
            showCount
            className="flex-grow"
          />
        </div>
        <div className="text-xs text-textLight">由 XXX 在 2024/12/26 10:10 編輯</div>
      </div>
    </Modal>
  );
};

const LineSetting = ({ room, token, showWebhook = true, clientId = null, client = null, updateClientData }) => {
  const [loading, setLoading] = useState(false);
  const [bound, setBound] = useState(false);
  const [openWebhookModal, setOpenWebhookModal] = useState(false);
  const [openTutorialModal, setOpenTutorialModal] = useState(false);
  const [copied, setCopied] = useState(false);
  const [channelId, setChannelId] = useState("");
  const [channelSecret, setChannelSecret] = useState("");
  const [channelAccessToken, setChannelAccessToken] = useState("");
  const [temperature, setTemperature] = useState(null);
  const [webSearch, setWebSearch] = useState(null);
  const [agentType, setAgentType] = useState(null);
  const [responseMode, setResponseMode] = useState(null);
  const [queryOnly, setQueryOnly] = useState(null);
  const [useWebhook, setUseWebhook] = useState(null);
  const [openUnbind, setOpenUnbind] = useState(false);
  const [openBaseModal, setOpenBaseModal] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [tagModalOpen, setTagModalOpen] = useState(false);
  const [noteModalOpen, setNoteModalOpen] = useState(false);
  const [tagCollapsed, setTagCollapsed] = useState(true);
  const [noteCollapsed, setNoteCollapsed] = useState(true);

  useEffect(() => {
    if (copied) {
      setTimeout(() => setCopied(false), 2000);
    }
    console.log('agentType', agentType);

  }, [copied]);

  useEffect(() => {
    fetchLineProperty();
    fetchUseWebhook();
    setActiveTab(0);
  }, [room, clientId]);

  const fetchUseWebhook = async () => {
    if (clientId) {
      setLoading(true);
      try {
        const response = await axios.get(
          `${SERVER_URL}/private/property/chatroom/line_channel/settings/${room.id}/use_webhook/${clientId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setUseWebhook(response.data);
      } catch (error) {
        // if 404 not show
        if (error.response?.status !== 404) {
          message.error(JSON.stringify(error.response?.data?.detail));
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const fetchLineProperty = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${SERVER_URL}/private/property/chatroom/line_channel/${room.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setChannelId(response.data.body.channel_id);
      setChannelSecret(response.data.body.channel_secret);
      setChannelAccessToken(response.data.body.channel_access_token);

      // console.log(response.data.body)

      setTemperature(response.data.body.temperature);
      setWebSearch(response.data.body.web_search);
      setAgentType(response.data.body.agent_type);
      setResponseMode(response.data.body.response_mode);
      setQueryOnly(response.data.body.query_only);

      setBound(true);
    } catch (error) {
      // message.error(JSON.stringify(error.response?.data?.detail));
      setBound(false);
    } finally {
      setLoading(false);
    }
  };

  const handleBind = async () => {
    setLoading(true);
    try {
      await axios.post(
        `${SERVER_URL}/private/property/chatroom/line_channel/${room.id}`,
        {
          channel_id: channelId,
          channel_secret: channelSecret,
          channel_access_token: channelAccessToken,
          temperature: 0.7,
          web_search: false,
          agent_type: "image_reader",
          response_mode: "text_only",
          query_only: false,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      message.success("Line@ 已串接成功");
      fetchLineProperty();
      setOpenWebhookModal(true);
    } catch (error) {
      // message.error(JSON.stringify(error.response?.data?.detail));
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateProperty = async (item, value) => {
    setLoading(true);
    const mappedItem = propertyMap(item);

    const path =
      mappedItem === "use_webhook"
        ? `${SERVER_URL}/private/property/chatroom/line_channel/settings/${room.id}/${mappedItem}/${clientId}`
        : `${SERVER_URL}/private/property/chatroom/line_channel/settings/${room.id}/${mappedItem}`;

    try {
      await axios.patch(
        path,
        {},
        {
          params: {
            [mappedItem]: value,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      message.success("已更新 AI 狀態設定");
      fetchLineProperty();
      fetchUseWebhook();
    } catch (error) {
      message.error(JSON.stringify(error.response?.data?.detail));
    } finally {
      setLoading(false);
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(`${SERVER_URL}${WEBHOOK_PATH}${room.id}`);
    setCopied(true);
  };

  const handleUnbind = async () => {
    setLoading(true);
    try {
      await axios.delete(
        `${SERVER_URL}/private/property/chatroom/line_channel/${room.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      message.success("Line@ 已解除串接");
      setBound(false);
      setOpenUnbind(false);
    } catch (error) {
      message.error(JSON.stringify(error.response?.data?.detail));
    } finally {
      setLoading(false);
    }
  };

  const onChangeTab = (key) => {
    setActiveTab(key);
    console.log('key', key);
  };

  const tabs = [
    { label: 'AI 設定', key: 0 },
    { label: '用戶設定', key: 1 },
  ];

  const TabContent = ({ activeTab, setTagModalOpen, setNoteModalOpen }) => {
    const toggleTag = () => setTagCollapsed(!tagCollapsed);
    const toggleNote = () => setNoteCollapsed(!noteCollapsed);

    const handleOpenNoteModal = () => {
      setNoteModalOpen(true);
    };

    // 共用的容器樣式
    const containerStyle = "flex flex-col justify-start items-start w-full px-4 py-6 gap-4 overflow-y-auto overflow-x-hidden";

    switch (activeTab) {
      case 0:
        return (
          <div className={containerStyle}>
            {bound ? (
              <>
                {clientId && (
                  <>
                    <div className="text-lg text-textDark font-bold w-full">
                      AI 個別狀態設定
                    </div>
                    <div className="flex flex-col border border-borderLight rounded bg-bgMedium w-full">
                      <div className="flex flex-col items-start w-full h-22 p-4 gap-2">
                        <div className="font-bold">AI 狀態</div>
                        <div className="flex flex-row justify-between w-full items-center">
                          <div>針對此客戶啟用 AI</div>
                          <Tooltip title="將自動傳送狀態變更訊息給客戶">
                            <Switch
                              value={useWebhook}
                              onChange={(e) =>
                                handleUpdateProperty("useWebhook", e)
                              }
                            />
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div className="flex justify-between items-center text-lg text-textDark font-bold w-full">
                  <span>AI 全域狀態設定</span>
                  <ReloadOutlined
                    className="flex items-center hover:text-primary ml-2"
                    style={{ width: "12px", height: "12px" }}
                    onClick={fetchLineProperty}
                  />
                </div>
                <div className="flex flex-col border border-borderLight rounded bg-bgMedium w-full">
                  <div className="flex flex-col items-start w-full h-22 p-4 gap-2">
                    <div className="font-bold">Web 模式</div>
                    <div className="flex flex-row justify-between w-full items-center">
                      <div>AI 上網</div>
                      <Switch
                        value={webSearch}
                        onChange={(e) => handleUpdateProperty("webSearch", e)}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col items-start w-full h-22 p-4 gap-2 border-t border-t-borderLight">
                    <div className="font-bold">連續對話模式</div>
                    <div className="flex flex-row justify-between w-full items-center">
                      <div>AI 會延續之前的對話</div>
                      <Switch
                        value={!queryOnly}
                        onChange={(e) => handleUpdateProperty("queryOnly", !e)}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col items-start w-full h-22 p-4 gap-2 border-t border-t-borderLight">
                    <div className="font-bold">AI 回覆模式</div>
                    <div className="flex flex-row justify-between w-full items-center">
                      <Radio.Group value={temperature} buttonStyle="solid">
                        <Radio.Button
                          value={0.3}
                          onClick={() => handleUpdateProperty("temperature", 0.3)}
                        >
                          精確
                        </Radio.Button>
                        <Radio.Button
                          value={0.5}
                          onClick={() => handleUpdateProperty("temperature", 0.5)}
                        >
                          平衡
                        </Radio.Button>
                        <Radio.Button
                          value={0.7}
                          onClick={() => handleUpdateProperty("temperature", 0.7)}
                        >
                          創造
                        </Radio.Button>
                      </Radio.Group>
                    </div>
                  </div>
                  <div className="flex flex-col items-start w-full h-22 p-4 gap-2 border-t border-t-borderLight">
                    <div className="font-bold">AI 回覆方式</div>
                    <div className="flex flex-row justify-between w-full items-center">
                      <Radio.Group value={responseMode} buttonStyle="solid" className="w-full">
                        <div className="flex flex-wrap gap-2">
                          <Radio.Button
                            value={"voice_only"}
                            onClick={() =>
                              handleUpdateProperty("responseMode", "voice_only")
                            }
                          >
                            語音
                          </Radio.Button>
                          <Radio.Button
                            value={"text_only"}
                            onClick={() =>
                              handleUpdateProperty("responseMode", "text_only")
                            }
                          >
                            文字
                          </Radio.Button>
                          <Radio.Button
                            value={"text_and_voice"}
                            onClick={() =>
                              handleUpdateProperty("responseMode", "text_and_voice")
                            }
                          >
                            語音+文字
                          </Radio.Button>
                        </div>
                      </Radio.Group>
                    </div>
                  </div>
                </div>
                {showWebhook && (
                  <>
                    <div className="text-lg text-textDark font-bold w-full">
                      串接管理
                    </div>
                    <div className="flex flex-col border border-borderLight rounded bg-bgMedium w-full">
                      <div className="flex flex-col items-start w-full h-22 p-4 gap-2 border-t border-t-borderLight">
                        <div className="font-bold">Channel ID</div>
                        <div className="flex flex-1 p-2 rounded bg-bgLight border w-full break-all">
                          {channelId}
                        </div>
                      </div>
                      <div className="flex flex-col items-start w-full h-22 p-4 gap-2 border-t border-t-borderLight">
                        <div className="font-bold">Channel Secret</div>
                        <div className="flex flex-1 p-2 rounded bg-bgLight border w-full break-all">
                          {channelSecret}
                        </div>
                      </div>
                      <div className="flex flex-col items-start w-full h-22 p-4 gap-2 border-t border-t-borderLight">
                        <div className="font-bold">Access Token</div>
                        <div className="flex flex-1 p-2 rounded bg-bgLight border w-full break-all">
                          {channelAccessToken}
                        </div>
                      </div>
                      <div className="flex flex-col items-start w-full h-22 p-4 gap-2 border-t border-t-borderLight">
                        <div className="font-bold">Webhook 網址</div>
                        <Tooltip title={copied ? "已複製" : "點擊以複製"}>
                          <div
                            className="flex flex-1 p-2 rounded bg-bgLight border w-full break-all cursor-pointer"
                            onClick={handleCopy}
                          >
                            {`${SERVER_URL}${WEBHOOK_PATH}${room.id}`}
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                    <div className="flex flex-row justify-space w-full gap-1">
                      <div className="flex gap-1">
                        <Button onClick={() => setOpenTutorialModal(true)}>
                          說明
                        </Button>
                        <Button onClick={() => setOpenBaseModal(true)}>
                          變更串接
                        </Button>
                      </div>
                      {openUnbind ? (
                        <div className="flex gap-1">
                          <Button danger onClick={() => handleUnbind()}>
                            解除串接
                          </Button>
                          <Button onClick={() => setOpenUnbind(false)}>取消</Button>
                        </div>
                      ) : (
                        <Button danger onClick={() => setOpenUnbind(true)}>
                          解除串接
                        </Button>
                      )}
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                <div className="text-lg text-textDark font-bold w-full">
                  Line@ 串接
                </div>
                <div className="text-xs text-textLight">
                  透過串接 Line@ 官方帳號，您的顧客可以透過 Line 跟您的 Teamsync AI
                  機器人聊天，以接觸更多潛在顧客及粉絲。
                </div>
                <div className="flex flex-col border border-borderLight rounded bg-bgMedium w-full">

                  <div className="flex flex-col items-start w-full h-22 p-4 gap-2 border-t border-t-borderLight">
                    <div className="font-bold">Channel ID</div>
                    <Input
                      value={channelId}
                      onChange={(e) => setChannelId(e.target.value)}
                    />
                  </div>
                  <div className="flex flex-col items-start w-full h-22 p-4 gap-2 border-t border-t-borderLight">
                    <div className="font-bold">Channel Secret</div>
                    <Input
                      value={channelSecret}
                      onChange={(e) => setChannelSecret(e.target.value)}
                    />
                  </div>
                  <div className="flex flex-col items-start w-full h-22 p-4 gap-2 border-t border-t-borderLight">
                    <div className="font-bold">Access Token</div>
                    <Input
                      value={channelAccessToken}
                      onChange={(e) => setChannelAccessToken(e.target.value)}
                    />
                  </div>
                </div>
                <div className="flex flex-row w-full justify-end gap-2">
                  <Button onClick={() => setOpenTutorialModal(true)}>說明</Button>
                  <Button
                    type="primary"
                    disabled={!channelId || !channelSecret || !channelAccessToken}
                    onClick={() => handleBind(true)}
                  >
                    串接
                  </Button>
                </div>
              </>
            )}
          </div>
        );
      case 1:
        return (
          <div className={containerStyle}>
            {/* Line Profile Section */}
            <div className="flex items-center space-x-4 w-full">
              <div className="w-[70px] h-[70px] rounded-full flex items-center justify-center">
                <img src={client?.avatar || '/images/user.png'} alt="Line Profile" className="w-full h-full object-cover rounded-full" />
              </div>

              <div className="flex flex-col flex-grow">
                <div className="flex items-center justify-between w-full">
                  <span className="text-md font-medium text-gray-700">{client?.displayName || '請設定顯示名稱'}</span>
                </div>

                <div className="flex items-center justify-between w-full">
                  <span className="text-[16px] font-medium text-gray-700">{client?.nickname || '請設定暱名'}</span>
                  <button className="p-2 hover:bg-gray-100 rounded-lg transition-colors">
                    <EditIcon />
                  </button>
                </div>
              </div>
            </div>

            <Divider className="my-1" />

            <div className="flex flex-row justify-between items-center text-lg text-textDark font-bold w-full">
              <div>負責人員</div>
              <button className="p-2 hover:bg-gray-100 rounded-lg transition-colors">
                <EditIcon />
              </button>
            </div>

            <Divider className="my-1" />

            <div className="w-full">
              <div
                className="flex flex-row justify-between items-center text-lg text-textDark font-bold w-full cursor-pointer"
                onClick={toggleTag}
              >
                <div>標籤 <span className="text-lg font-medium text-gray-400 ml-2">{client?.tags?.length || 0}</span></div>
                <button className="p-2 hover:bg-gray-100 rounded-lg transition-colors">
                  <DownIcon
                    className={`w-4 h-4 transform transition-transform ${tagCollapsed ? '' : 'rotate-180'}`}
                    fill="none"
                  />
                </button>
              </div>

              {/* 標籤 Collapse Content */}
              {!tagCollapsed && (
                <div className="flex gap-4 flex-col justify-start mt-4">

                  <button
                    onClick={() => setTagModalOpen(true)}
                    className="flex items-center gap-2 px-0 border-none text-[#67BE5F] text-[14px] shadow-none hover:text-[#67BE5F] active:scale-95 transition-transform"
                  >
                    <PlusIcon className="w-[18px] h-[18px]" />
                    新增標籤
                  </button>
                  {client?.tags?.length > 0 && (
                    <div className="flex flex-col gap-2">
                      {client.tags.map((tag, index) => (
                        <div
                          key={index}
                          className='h-7 w-fit flex items-center gap-2 px-3 py-1 cursor-pointer rounded-full bg-primaryMedium text-sm text-textDark border-[#67BE5F] text-textDark bg-[#67BE5F]'
                        >
                          {tag?.name}
                          <PlusBlackIcon
                            className="w-[10px] h-[10px] transform cursor-pointer"
                            onClick={() => handleDeleteTag(tag)}
                          />
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>

            <Divider className="my-1 border-borderLight" />
            <div className="w-full">
              <div
                className="flex flex-row justify-between items-center text-lg text-textDark font-bold w-full cursor-pointer"
                onClick={toggleNote}
              >
                <div>備註欄 <span className="text-lg font-medium text-gray-400 ml-2">{client?.notes?.length || 0}</span></div>
                <button className="p-2 hover:bg-gray-100 rounded-lg transition-colors">
                  <DownIcon
                    className={`w-4 h-4 transform transition-transform ${noteCollapsed ? '' : 'rotate-180'}`}
                    fill="none"
                  />
                </button>
              </div>



              {/* 備註欄 Collapse Content */}
              {!noteCollapsed && (
                <div className="flex gap-4 flex-col justify-start mt-4">

                  <button
                    onClick={handleOpenNoteModal}
                    className="flex items-center gap-2 px-0 border-none text-[#67BE5F] text-[14px] shadow-none hover:text-[#67BE5F] active:scale-95 transition-transform"
                  >
                    <PlusIcon className="w-[18px] h-[18px]" />
                    新增備註欄
                  </button>
                  {client?.notes?.length > 0 && (
                    <div className="flex flex-col gap-2">
                      {client.notes.map((note, index) => (
                        <div key={index} className="flex flex-col justify-between border border-borderLight rounded bg-bgMedium p-2 min-h-[100px] w-full">
                          <div className="flex-grow overflow-y-auto whitespace-pre-wrap break-words mb-2 w-full">
                            {note.content}
                          </div>
                          <div className="flex flex-row justify-between items-center mt-auto">
                            <span className="text-[12px] font-medium text-gray-700 flex items-center justify-center">
                              {note?.created_at || '未設定更新時間'}
                            </span>
                            <div className="flex flex-row gap-2">
                              <button className="p-2 hover:bg-gray-100 rounded-lg transition-colors">
                                <EditIcon2 />
                              </button>
                              <button
                                onClick={() => handleDeleteNote(note.id, note.content)}
                                className="p-2 hover:bg-gray-100 rounded-lg transition-colors">
                                <TrashIcon />
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  const handleDeleteNote = async (noteId, noteContent) => {
    Modal.confirm({
      title: '確認刪除備註',
      content: (
        <div>
          <p>確定要刪除此備註嗎？</p>
          <div className="mt-2 p-2 bg-gray-100 rounded max-h-[100px] overflow-y-auto">
            {noteContent}
          </div>
        </div>
      ),
      okText: '刪除',
      cancelText: '取消',
      okButtonProps: {
        danger: true,
      },
      onOk: async () => {
        try {
          await LineChannelFacade.deleteNote(room.id, clientId, noteId);
          message.success('備註刪除成功');
          // 更新客戶資料
          await updateClientData();
        } catch (error) {
          console.error('Error deleting note:', error);
          message.error(error.message || '刪除失敗');
        }
      },
    });
  };

  const handleDeleteTag = async (tag) => {
    Modal.confirm({
      title: '確認刪除標籤',
      content: (
        <div>
          <p>確定要刪除此標籤嗎？</p>
          <div className="mt-2 p-2 bg-gray-100 rounded max-h-[100px] overflow-y-auto">
            {tag?.name}
          </div>
        </div>
      ),
      okText: '刪除',
      cancelText: '取消',
      okButtonProps: {
        danger: true,
      },
      onOk: async () => {
        if (!tag?.id) {
          console.error('Tag ID is missing:', tag);
          message.error('標籤資訊不完整');
          return;
        }

        try {
          await LineChannelFacade.deleteTag(room.id, clientId, tag.id);
          message.success('標籤刪除成功');
          await updateClientData();
        } catch (error) {
          console.error('Error removing tag:', error);
          message.error(error.message || '刪除標籤失敗');
        }
      },
    });
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center w-full h-full">
        <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
      </div>
    );
  }

  return (
    <>
      <BaseModal
        id={room.id}
        token={token}
        open={openBaseModal}
        close={() => setOpenBaseModal(false)}
        webhook={setOpenWebhookModal}
        tutorial={setOpenTutorialModal}
        refresh={fetchLineProperty}
        oldChannelId={channelId}
        oldChannelSecret={channelSecret}
        oldChannelAccessToken={channelAccessToken}
      />
      <WebhookModal
        open={openWebhookModal}
        close={() => setOpenWebhookModal(false)}
        id={room.id}
        handleCopy={handleCopy}
        copied={copied}
        tutorial={setOpenTutorialModal}
      />
      <TutorialModal
        open={openTutorialModal}
        close={() => setOpenTutorialModal(false)}
      />
      <TagModal
        open={tagModalOpen}
        onClose={() => setTagModalOpen(false)}
        room={room}
        clientId={clientId}
        updateClientData={updateClientData}
        client={client}
      />
      <NoteModal
        open={noteModalOpen}
        onClose={() => setNoteModalOpen(false)}
        room={room}
        clientId={clientId}
        updateClientData={updateClientData}
      />
      <div className="flex flex-col w-full">
        {clientId ? (
          <>
            <Tabs
              defaultActiveKey={0}
              items={tabs}
              onChange={onChangeTab}
              className="px-2 w-full"
            />
            <TabContent activeTab={activeTab} setTagModalOpen={setTagModalOpen} setNoteModalOpen={setNoteModalOpen} />
          </>
        ) : (
          <div className="flex flex-col w-full">
            <TabContent activeTab={0} setTagModalOpen={setTagModalOpen} setNoteModalOpen={setNoteModalOpen} />
          </div>
        )}
      </div>
    </>
  );
};

export default LineSetting;
