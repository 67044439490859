// Airbnb Profile Entity
export class AirbnbProfile {
  constructor(data) {
    this.id = data.id;
    this.chatroomId = data.chatroom_id;
    this.profileId = "kuhedr4";
    // this.profileId = data.body?.id;
    this.serialNumber = data.body?.serial_number;
  }
}

// Airbnb User Entity
export class AirbnbUser {
  constructor(data) {
    this.userId = data.airbnb_user_id;
    this.username = data.airbnb_username;
    this.email = data.airbnb_email;
    this.profileId = data.profile_id;
  }
}

// Airbnb Source Entity
export class AirbnbSource {
  constructor(data) {
    this.id = data.id;
    this.sourceId = data.airbnb_source_id;
    this.sourceName = data.airbnb_source_name;
    this.userId = data.airbnb_user_id;
    this.name = data.airbnb_conversation_guest_name;
  }
}

// Airbnb Booking Entity
export class AirbnbBooking {
  constructor(data) {
    this.id = data.id;
    this.bookingId = data.airbnb_booking_id;
    this.status = data.airbnb_booking_status;
    this.checkInDate = data.airbnb_check_in_date;
    this.checkOutDate = data.airbnb_check_out_date;
    this.bookingDate = data.airbnb_booking_date;
    this.conversationId = data.airbnb_conversation_id;
  }
}

// Airbnb Message Entity
export class AirbnbMessage {
  constructor(data) {
    this.id = data.id;
    this.owner = data.airbnb_message_owner;
    this.content = data.airbnb_content;
    this.image = data.airbnb_image;
    this.sentDate = data.airbnb_sent_date;
    this.sentTime = data.airbnb_sent_time;
    this.sentDateTime = data.airbnb_sent_datetime;
    this.conversationId = data.airbnb_conversation_id;
  }
} 