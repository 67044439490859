import React, { useState, useEffect } from 'react'
import mqtt from 'mqtt'

import { useChat } from '../../../../hooks/use-chat'
import { useAuth } from '../../../../contexts/AuthProvider'

import BoundList from './BoundList'
import BoundChatRoom from './BoundChatRoom'
import BoundSetting from './BoundSetting'

const MQTT_URL = "wss://api-broker.scfg.io" // process.env.REACT_APP_MQTT_URL;
const MQTT_PORT = process.env.REACT_APP_MQTT_PORT;

const platformOptions = [
  // {
  //   value: 'all',
  //   label: '所有平台',
  // },
  {
    value: 'line',
    label: 'Line',
  },
  {
    value: 'facebook',
    label: 'Facebook',
  },
  {
    value: 'agoda',
    label: 'Agoda',
  },
  {
    value: 'airbnb',
    label: 'Airbnb',
  },
  {
    value: 'teamsync',
    label: 'TeamSync',
  },
];

const BoundInterface = ({ room, switchTo }) => {
  const { userId, token } = useAuth()
  const { 
    lineClients, 
    facebookClients, 
    selectedPlatform, 
    setSelectedPlatform, 
    clients, 
    setClients, 
    loading, 
    agoda, 
    airbnb,
    agodaSourceInfo,
    airbnbSourceInfo,
  } = useChat(room.id);
  const [activeId, setActiveId] = useState('')
  const [mqttConnection, setMqttConnection] = useState(false)
  const [activeClient, setActiveClient] = useState(null);
  const [newMessageClientList, setNewMessageClientList] = useState([])

  useEffect(() => {
    setActiveId('')
    setActiveClient(null)
  }, [selectedPlatform])

  useEffect(() => {
    setMqttConnection(false)
    setActiveId('')
    setActiveClient(null)
    setNewMessageClientList([])
  }, [room])

  useEffect(() => {
    setActiveClient(clients.find(client => client.userId === activeId))
    setNewMessageClientList(newMessageClientList.filter(clientId => clientId !== activeId))
  }, [activeId])

  // MQTT connect
  useEffect(() => {
    // Establish connection with the MQTT broker
    const mqttClient = mqtt.connect(MQTT_URL, {
      clientId: 'mqttx_' + Math.random().toString(16).substring(2, 10),
      port: MQTT_PORT,
      path: '/',
      connectTimeout: 10000,
      keepAlive: 30000,
      autoReconnect: true,
      reconnectPeriod: 1000,
      cleanStart: false,
    });

    mqttClient.on('error', (error) => {
      setMqttConnection(false)
    });

    mqttClient.on('connect', () => {
      mqttClient.subscribe(`chatroom/${room.id}/line/#`, (error) => {
        if (error) {
          setMqttConnection(false)
        } else {
          setMqttConnection(true)
        }
      });
      
      mqttClient.subscribe(`chatroom/${room.id}/messenger/#`, (error) => {
        if (error) {
          setMqttConnection(false)
        } else {
          setMqttConnection(true)
        }
      });
    });

    mqttClient.on('message', (topic, payload) => {
      console.log('收到 MQTT 消息:', {
        主題: topic,
        消息內容: payload.toString()
      });
      
      try {
        const topicParts = topic.split('/');
        // const topicChannel = topicParts[2]; // 主題中的頻道類型 (line 或 messenger)
        const receivedClientId = topicParts[3].toString();
        
        // 解析消息內容
        // const messageContent = JSON.parse(payload.toString());
        // const messageChannelType = messageContent.channel_type?.split(':')[0] || topicChannel;
        
        // unfocused client got message
        if (receivedClientId !== activeId) {
          setNewMessageClientList(prevList => {
            // 避免重複添加相同的 clientId
            if (!prevList.includes(receivedClientId)) {
              return [...prevList, receivedClientId];
            }
            return prevList;
          });
        }
      
      } catch (error) {

      }
    });

    // Clean up the connection when the component unmounts
    return () => {
      mqttClient.end();
    };
  }, [clients, activeId]);

  const handlePlatformChange = (value) => {
    setSelectedPlatform(value);    
    // 直接根據選擇的平台更新 clients
    if (value === 'facebook' && facebookClients?.length > 0) {
      setClients(facebookClients);
    } else if ((value === 'line' || value === 'all') && lineClients?.length > 0) {
      setClients(lineClients);
    } else {
      // 切換到沒有數據的平台時，清空客戶端列表
      setClients([]);
    }
  };


  return (
    <div className="w-full flex flex-row">
      <BoundList
        clients={clients}
        // clients={clients}
        activeId={activeId}
        setActiveId={setActiveId}
        platformOptions={platformOptions}
        selectedPlatform={selectedPlatform}
        onPlatformChange={handlePlatformChange}
        switchTo={switchTo}
        mqttConnection={mqttConnection}
        newMessageClientList={newMessageClientList}
        loading={loading}
        agodaSourceInfo={agodaSourceInfo}
        airbnbSourceInfo={airbnbSourceInfo}
      />
      {
        activeClient ?
          <>
            <BoundChatRoom userId={userId} token={token} room={room} client={activeClient} agoda={agoda} airbnb={airbnb}/>
            <BoundSetting room={room} token={token} clientId={activeClient.userId} client={activeClient} />
          </>
          :
          <div className="flex flex-row items-center justify-center w-full h-full text-2xl font-bold text-textLight">
            請選擇第三方紀錄
          </div>
      }
    </div>
  )
}

export default BoundInterface