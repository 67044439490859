import { request } from '../../utils';

export const BoundApi = {
  // Line
  getLineClients: async (roomId) => {
    return await request.get(`/private/module/line_channel/${roomId}/clients`);
  },
  getLineMessages: async (roomId, socialMediaClientId, limit = 10, fromId = null) => {
    return await request.get(`/private/module/line_channel/${roomId}/chats/${socialMediaClientId}`, {
      params: {
        limit: limit,
        from_id: fromId
      }
    });
  },
  sendLineMessage: async (roomId, socialMediaClientId, message) => {
    return await request.post(`/private/module/line_channel/${roomId}/p2p/${socialMediaClientId}`, {
      text: message
    });
  },

  // Messenger
  getMessengerClients: async (roomId) => {
    return await request.get(`/private/module/messenger/${roomId}/clients`);
  },
  getMessengerMessages: async (roomId, socialMediaClientId, limit = 10, fromId = null) => {
    return await request.get(`/private/module/messenger/${roomId}/chats/${socialMediaClientId}`, {
      params: {
        limit: limit,
        from_id: fromId
      }
    });
  },
  sendFacebookMessage: async (roomId, socialMediaClientId, message) => {
    return await request.post(`/private/module/messenger/${roomId}/p2p/${socialMediaClientId}`, {
      text: message
    });
  },
  // Agent
  getAgentClients: async (roomId) => {
    return await request.get(`/private/module/agent/${roomId}/clients`);
  },
  getAgentMessages: async (roomId, socialMediaClientId, limit = 10, fromId = null) => {
    return await request.get(`/private/module/agent/${roomId}/chats/${socialMediaClientId}`, {
      params: {
        limit: limit,
        from_id: fromId
      }
    });
  },
  sendAgentMessage: async (roomId, socialMediaClientId, message) => {
    return await request.post(`/private/module/agent/${roomId}/p2p/${socialMediaClientId}`, {
      text: message
    });
  },

};