import { ChatHistoriesApi } from "../../infrastructure/api/ChatHistoriesApi";
import { TaskWatchdogApi } from "../../infrastructure/api/TaskWatchdogApi";

/**
 * 匯出聊天室歷史記錄
 * @param {string} type - 匯出類型 (chatroom, department, company)
 * @param {string} id - 實體ID (聊天室ID、部門ID，公司匯出時可為null)
 * @param {string} since - 開始日期 (YYYY-MM-DD格式)
 * @param {string} until - 結束日期 (YYYY-MM-DD格式)
 * @param {string} [apiKey=null] - API 密鑰，可選參數
 * @param {Function} onSuccess - 成功回調函數
 * @param {Function} onError - 錯誤回調函數
 * @param {Function} onProgress - 進度回調函數
 */
export const exportChatHistories = async (
  type,
  id,
  since,
  until,
  apiKey,
  onSuccess,
  onError,
  onProgress
) => {
  try {
    // 開始匯出操作
    onProgress && onProgress("開始匯出聊天記錄...");

    // 根據類型選擇不同的API調用
    let response;
    switch (type) {
      case "chatroom":
        response = await ChatHistoriesApi.getChatroomHistories(
          id,
          since,
          until
        );
        break;
      case "department":
        if (id) {
          response = await ChatHistoriesApi.getDepartmentChatHistories(
            id,
            since,
            until,
            apiKey
          );
        } else {
          response = await ChatHistoriesApi.getDepartmentsChatHistories(
            since,
            until,
            apiKey
          );
        }
        break;
      case "company":
        response = await ChatHistoriesApi.getCompanyChatHistories(
          since,
          until,
          apiKey
        );
        break;
      default:
        throw new Error("不支援的匯出類型");
    }

    if (!response?.ticket_id) {
      throw new Error("無法獲取匯出任務");
    }

    // 使用輪詢方式檢查任務狀態
    let attempts = 0;
    const maxAttempts = 30; // 最多嘗試次數
    const pollInterval = 1000; // 輪詢間隔（毫秒）

    const checkTaskStatus = async () => {
      try {
        if (attempts >= maxAttempts) {
          throw new Error("匯出操作超時，請稍後再試");
        }

        attempts++;
        onProgress &&
          onProgress(`檢查匯出進度... (${attempts}/${maxAttempts})`);

        const taskStatus = await TaskWatchdogApi.getTask(response.ticket_id);

        if (taskStatus?.body?.url) {
          // 匯出成功，返回下載URL
          onSuccess && onSuccess(taskStatus.body.url);
        } else if (taskStatus?.ttl === -1 || attempts < maxAttempts) {
          // 任務仍在處理中，繼續輪詢
          setTimeout(checkTaskStatus, pollInterval);
        } else {
          throw new Error("聊天記錄匯出失敗");
        }
      } catch (error) {
        onError && onError(error.message || "檢查任務狀態失敗");
      }
    };

    // 開始輪詢檢查任務狀態
    checkTaskStatus();
  } catch (error) {
    onError && onError(error.message || "匯出聊天記錄失敗，請稍後再試");
  }
};
