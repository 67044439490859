export const AirbnbUseCases = {
  // Get complete Airbnb chat flow
  getAirbnbChatFlow: async (repository, chatroomId) => {
      // Step 1: Get profile
      // const profile = await repository.getProfile(chatroomId);
      // console.log('profile', profile);
      // if (!profile.profileId) {
      //   throw new Error('無法獲取 Profile ID');
      // }

      let profile = {
        profileId: 'kuhedr4'
      }


      // Step 2: Get user info
      const users = await repository.getUserInfo(profile.profileId);
      
      // if (!users.length) {
      //   throw new Error('無法獲取使用者資訊');
      // }
      const user = users;

      // Step 3: Get source info
      const sources = await repository.getSourceInfo(users.id);
      
      if (!sources.length) {
        throw new Error('無法獲取房源資訊');
      }

      // Step 4: Get conversation IDs for each source
      const conversationPromises = sources.map(source => 
        repository.getConversationId(source.id)
      );
      const airbnbClients = await Promise.all(conversationPromises);
      
      // 將二維數組扁平化為一維數組
      const flattenedAirbnbClients = airbnbClients.flat(); 
      // 過濾掉空值
      const validConversations = flattenedAirbnbClients.filter(conversation => conversation && conversation.id);
      
      // Step 5: Get booking info and messages for each conversation
      const chatData = await Promise.all(
        validConversations.map(async (conversation) => {
          const [bookings, messages] = await Promise.all([
            repository.getBookingInfo(conversation.id),
            repository.getConversationMessages(conversation.id)
          ]);
          
          return {
            conversationId: conversation.id,
            airbnbConversationId: conversation.airbnb_conversation_id,
            airbnbSourceId: conversation.airbnb_source_id,
            airbnbGuestName: conversation.airbnb_conversation_guest_name,
            bookings,
            messages
          };
        })
      );

      return {
        airbnbClients: flattenedAirbnbClients,
        profile,
        user,
        sources,
        chatData
      };
  },

  getProfile: async (repository, chatroomId) => {
    return await repository.getProfile(chatroomId);
  },

  getUserInfo: async (repository, profileId) => {
    return await repository.getUserInfo(profileId);
  },

  getSourceInfo: async (repository, sourceId) => {
    const sourceInfo = await repository.getSourceInfo(sourceId); 
    console.log('sourceInfo', sourceInfo); 
    return sourceInfo;
  },

  getConversationId: async (repository, sourceId) => {
    return await repository.getConversationId(sourceId);
  },

  getBookingInfo: async (repository, conversationId) => {
    return await repository.getBookingInfo(conversationId);
  },

  getConversationMessages: async (repository, conversationId, displayName) => {
    return await repository.getConversationMessages(conversationId, displayName);
  },
  
  
  // Get messages for specific conversation
  getConversationData: async (repository, conversationId) => {
      const [bookings, messages] = await Promise.all([
        repository.getBookingInfo(conversationId),
        repository.getConversationMessages(conversationId)
      ]);

      // Reverse the messages array to display in descending order
      const reversedMessages = messages ? [...messages].reverse() : [];

      return {
        bookings,
        messages: reversedMessages
      };
  },

  sendMessage: async (repository, profileId, sourceId, conversationId, messageType, message, file) => {
    return await repository.sendMessage(profileId, sourceId, conversationId, messageType, message, file);
  }
}; 