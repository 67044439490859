import { FacebookRepository } from "../../infrastructure/repositories/FacebookRepository";

export const FacebookUseCase = {
  fetchFacebookClients: async (roomId) => {
    return await FacebookRepository.fetchFacebookClients(roomId);
  },
  fetchFacebookMessages: async (roomId, socialMediaClientId, limit = 10, oldestMessageId = null) => {
    return await FacebookRepository.fetchFacebookMessages(roomId, socialMediaClientId, limit, oldestMessageId);
  },
  sendFacebookMessage: async (roomId, socialMediaClientId, message) => {
    return await FacebookRepository.sendFacebookMessage(roomId, socialMediaClientId, message);
  },
  // get
  getFacebookChannel: async (roomId) => {
    return await FacebookRepository.getFacebookChannel(roomId);
  },

  // create
  createFacebookProperty: async (roomId, facebookProperty) => {
    return await FacebookRepository.createFacebookProperty(roomId, facebookProperty);
  },

  // update
  updateFacebookWebhookStatus: async (roomId, socialMediaClientId, useWebhook) => {
    return await FacebookRepository.updateFacebookWebhookStatus(roomId, socialMediaClientId, useWebhook);
  },
  updateFacebookChatSettings: async (roomId, chatSettings) => {
    return await FacebookRepository.updateFacebookChatSettings(roomId, chatSettings);
  },
  updateFacebookBaseSettings: async (roomId, baseSettings) => {
    return await FacebookRepository.updateFacebookBaseSettings(roomId, baseSettings);
  },
  updateFacebookNickname: async (roomId, socialMediaClientId, nickname) => {
    return await FacebookRepository.updateFacebookNickname(roomId, socialMediaClientId, nickname);
  },

  // delete
  deleteFacebookProperty: async (roomId) => {
    return await FacebookRepository.deleteFacebookProperty(roomId);
  },
  addFacebookNote: async (roomId, clientId, noteName, noteContent) => {
    return await FacebookRepository.addFacebookNote(roomId, clientId, noteName, noteContent);
  },
  updateFacebookNote: async (roomId, clientId, noteId, noteName, noteContent) => {
    return await FacebookRepository.updateFacebookNote(roomId, clientId, noteId, noteName, noteContent);
  },
  deleteFacebookNote: async (roomId, clientId, noteId) => {
    return await FacebookRepository.deleteFacebookNote(roomId, clientId, noteId);
  },
  addFacebookTag: async (roomId, clientId, tagName) => {
    return await FacebookRepository.addFacebookTag(roomId, clientId, tagName);
  },
  deleteFacebookTag: async (roomId, clientId, tagId) => {
    return await FacebookRepository.deleteFacebookTag(roomId, clientId, tagId);
  },
  fetchFacebookTagGroup: async (roomId) => {
    return await FacebookRepository.fetchFacebookTagGroup(roomId);
  }
};