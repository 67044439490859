import React, { createContext, useState } from 'react';
import { fetchServicesAPI, getAccessToken, getServerUrl } from '../services/api';

export const ServiceContext = createContext();

export const ServiceProvider = ({ children }) => {
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [showOrderManagement, setShowOrderManagement] = useState(false);
  const [filteredServices, setFilteredServices] = useState([]);

  console.log('services', services);
  

  const fetchServices = async () => {
    setLoading(true);
    try {
      const token = await getAccessToken();
      const url = await getServerUrl();
      const fetchedServices = await fetchServicesAPI(token, url);
      setServices(fetchedServices);
    } catch (error) {
      console.error('Error fetching services:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleShowForm = () => setShowForm(!showForm);

  const filterServices = (status) => {
    if (!status || status === "all") {
      setFilteredServices(services);
    } else {
      const filtered = services.filter(service => service.status === status);
      setFilteredServices(filtered);
    }
  };

  const value = {
    services,
    selectedService,
    loading,
    showForm,
    showOrderManagement,
    fetchServices,
    setSelectedService,
    setShowForm,
    handleShowForm,
    setShowOrderManagement,
    filteredServices,
    filterServices,
  };

  return (
    <ServiceContext.Provider value={value}>
      {children}
    </ServiceContext.Provider>
  );
};
