// 聊天客戶實體定義
export const BoundClient = {
  id: '',
  userId: '',
  roomId: '',
  pictureUrl: '',
  channel: '',
  settings: {
    webhook: true
  },
  notes: [],
  tags: [],
  displayName: '',
  nickname: '',
  last_two_messages: [],
  num_of_unseen_messages: 0,
  created_at: null,
};

// 聊天消息實體定義
export const BoundMessage = {
  id: '',                     // 用於唯一識別和作為 key
  message: '',                // 文本訊息內容
  created_at: null,           // 訊息創建時間 (用於排序和顯示)
  blobs: [                    // 附件陣列
    {
      id: '',                 // 附件唯一識別碼，用於 key
      url: '',                // 附件 URL，用於顯示/下載
      content_type: ''        // 附件類型，用於 blobType 參數
    }
  ],
  response: {                 // AI 自動回覆
    id: '',                   // 回覆唯一識別碼
    message: '',              // 回覆文本內容
    created_at: null,         // 回覆時間，用於顯示
    blobs: [                  // 回覆附件
      {
        id: '',               // 附件唯一識別碼，用於 key
        url: '',              // 附件 URL
        content_type: ''      // 附件類型
      }
    ]
  },
  sender: {
    userId: '',               // 發送者 ID
    displayName: '',          // 發送者顯示名稱
    pictureUrl: ''            // 發送者頭像 URL
  }
};

// 將DTO轉換為實體的函數
export const createBoundClientEntity = (dto) => {
  if (!dto) return null;

  console.log('dto', dto);
  

  if (dto.platform === 'line') {
    return {
      id: dto.id || '',
      userId: dto?.external_id || dto?.external_profile?.userId || '',
      roomId: dto?.roomId || dto?.room_id || '',
      pictureUrl: dto?.external_profile?.pictureUrl || '',
      channel: dto?.platform || '',
      settings: {
        webhook: dto?.settings?.webhook || false
      },
      notes: dto?.notes || [],
      tags: dto?.tags || [],
      displayName: dto?.external_profile?.displayName || '',
      nickname: dto?.nickname || '',
      last_two_messages: Array.isArray(dto?.last_two_messages) ? dto?.last_two_messages : [],
      num_of_unseen_messages: dto?.num_of_unseen_messages || 0,
      created_at: dto?.created_at ? new Date(dto?.created_at) : null,
    };
  } else if (dto.platform === 'messenger') {
    return {
      id: dto.id || '',
      userId: dto?.external_id || dto?.external_profile?.userId || '',
      roomId: dto?.roomId || dto?.room_id || '',
      pictureUrl: dto?.external_profile?.profile_pic || '',
      channel: dto?.platform || '',
      settings: {
        webhook: dto?.settings?.webhook || false
      },
      notes: dto?.notes || [],
      tags: dto?.tags || [],
      displayName: dto?.external_profile?.first_name ? dto?.external_profile?.first_name + ' ' + dto?.external_profile?.last_name : dto?.nickname || '',
      nickname: dto?.nickname || '',
      last_two_messages: Array.isArray(dto?.last_two_messages) ? dto?.last_two_messages : [],
      num_of_unseen_messages: dto?.num_of_unseen_messages || 0,
      created_at: dto?.created_at ? new Date(dto?.created_at) : null,
    };
  } else if (dto.platform === 'agent') {
    return {
      id: dto?.id || '',
      userId: dto?.id || '',
      roomId: dto?.chatroom_id || dto?.room_id || '',
      pictureUrl: dto?.external_profile?.photo_url || '',
      channel: dto?.platform || '',
      settings: {
        webhook: dto?.settings?.webhook || false
      },
      notes: dto?.notes || [],
      tags: dto?.tags || [],
      displayName: dto?.external_profile?.nickname || '',
      nickname: dto?.nickname || '',
      last_two_messages: Array.isArray(dto?.last_two_messages) ? dto?.last_two_messages : [],
      num_of_unseen_messages: dto?.num_of_unseen_messages || 0,
      created_at: dto?.created_at ? new Date(dto?.created_at) : null,
    };
  }
  
  else if (dto.platform === 'agoda') {
    
    return {
      id: dto.id || '',
      userId: dto?.agoda_conversation_id || '',
      roomId: dto?.agoda_source_id || '',
      pictureUrl: '',
      channel: dto?.platform || '',
      settings: {
        webhook: false
      },
      notes: [],
      tags: [],
      displayName: dto?.agoda_conversation_guest_name || '',
      nickname: '',
      last_two_messages: [],
      num_of_unseen_messages: 0,
      created_at: null,
    }
  } else if (dto.platform === 'airbnb') {
    return {
      id: dto.id || '',
      userId: dto?.airbnb_conversation_id || '',
      roomId: dto?.source_id || '',
      pictureUrl: '',
      channel: dto?.platform || '',
      settings: {
        webhook: false
      },
      notes: [],
      tags: [],
      displayName: dto?.airbnb_conversation_name || '',
      nickname: '',
      last_two_messages: [],
      num_of_unseen_messages: 0,
      created_at: null,
    }
  }
};

// 將DTO轉換為消息實體
export const createBoundMessageEntity = (dto) => {
  if (!dto) return null;
   
  if (dto.channel_type === 'line:ai') {
    return {
      id: dto.id || '',
      message: dto.message || '',
      created_at: dto.created_at ? new Date(dto.created_at) : null,
      blobs: Array.isArray(dto.blobs) ? dto.blobs.map(blob => ({
        id: blob.id || '',
        url: blob.url || '',
        content_type: blob.content_type || ''
      })) : [],
      response: dto.response ? {
        id: dto.response.id || '',
        message: dto.response.message || '',
        created_at: dto.response.created_at ? new Date(dto.response.created_at) : null,
        blobs: Array.isArray(dto.response.blobs) ? dto.response.blobs.map(blob => ({
          id: blob.id || '',
          url: blob.url || '',
          content_type: blob.content_type || ''
        })) : []
      } : null,
      sender: {
        userId: dto.sender?.userId || dto.external_id || '',
        displayName: dto.sender?.displayName || '',
        pictureUrl: dto.sender?.pictureUrl || ''
      }
    };
  } else if (dto.channel_type === 'messenger:ai') {
    return {
      id: dto.id || '',
      message: dto.message || '',
      created_at: dto.created_at ? new Date(dto.created_at) : null,
      blobs: Array.isArray(dto.blobs) ? dto.blobs.map(blob => ({
        id: blob.id || '',
        url: blob.url || '',
        content_type: blob.content_type || ''
      })) : [],
      response: dto.response ? {
        id: dto.response.id || '',
        message: dto.response.message || '',
        created_at: dto.response.created_at ? new Date(dto.response.created_at) : null,
        blobs: Array.isArray(dto.response.blobs) ? dto.response.blobs.map(blob => ({
          id: blob.id || '',
          url: blob.url || '',
          content_type: blob.content_type || ''
        })) : []
      } : null,
      sender: {
        userId: dto.sender?.userId || dto.external_id || '',
        displayName: dto.sender?.displayName || '',
        pictureUrl: dto.sender?.pictureUrl || ''
      }
    };
  } 
  
  
  else if (dto.platform === 'agoda') {
    const isUser = dto.agoda_message_owner === 'User';
    
    return {
      id: dto.id || '',
      message: !isUser ? dto.agoda_content || '' : '#.aFaKeMeSsAgE',
      created_at: dto.agoda_sent_datetime ? new Date(dto.agoda_sent_datetime) : null,
      blobs: [],
      response: isUser ? {
        id: dto.id || '',
        message: dto.agoda_content || '',
        created_at: dto.agoda_sent_datetime ? new Date(dto.agoda_sent_datetime) : null,
        blobs: []
      } : null,
      sender: {
        userId: dto.agoda_conversation_id || '',
        displayName: dto.agoda_message_owner || '',
        pictureUrl: ''
      }
    };
  } else if (dto.platform === 'airbnb') {
    const isUser = dto.user !== dto.airbnb_message_owner;
    
    return {
      id: dto.id || '',
      message: !isUser ? dto.airbnb_content || '' : "#.aFaKeMeSsAgE",
      created_at: dto.airbnb_sent_datetime ? new Date(dto.airbnb_sent_datetime) : null,
      blobs: Array.isArray(dto.blobs) ? dto.blobs.map(blob => ({
        id: blob.id || '',
        url: blob.url || '',
        content_type: blob.content_type || ''
      })) : [],
      response: isUser ? {
        id: dto.id || '',
        message: dto.airbnb_content || '',
        created_at: dto.airbnb_sent_datetime ? new Date(dto.airbnb_sent_datetime) : null,
        blobs: Array.isArray(dto.blobs) ? dto.blobs.map(blob => ({
          id: blob.id || '',
          url: blob.url || '',
          content_type: blob.content_type || ''
        })) : []
      } : null,
      sender: {
        userId: dto.sender?.userId || dto.external_id || '',
        displayName: dto.sender?.displayName || dto.airbnb_message_owner || '',
        pictureUrl: dto.sender?.pictureUrl || ''
      }
    };
  }
};

export const createBoundSourceEntity = (dto) => {
  if (!dto) return null;

  if (dto.platform === 'agoda') {
    return {
      id: dto.id || '',
      source_id: dto.agoda_source_id || '',
      source_name: dto.agoda_source_name || '',
      user_id: dto.user_id || ''
    } 
  } else if (dto.platform === 'airbnb') {
    return {
      id: dto.id || '',
      source_id: dto.airbnb_source_id || '',
      source_name: dto.airbnb_source_name || '',
      user_id: dto.user_id || ''
    }
  }
};