import React, { useContext, useState } from 'react';
import { Table, Input, Button, Select } from 'antd';
import { SearchOutlined, PlusOutlined } from '@ant-design/icons';
import { ServiceContext } from '../../context/ServiceContext';
import { serviceColumns } from '../../data/serviceColumns';

const ServiceTable = () => {
  const { services, setShowOrderManagement, setSelectedService, selectedService } = useContext(ServiceContext);
  const [searchText, setSearchText] = useState('');
  const [selectedProvider, setSelectedProvider] = useState('all');
  const [selectedStatus, setSelectedStatus] = useState('all');
  const [tableLoading, setTableLoading] = useState(false);

  const filteredServices = services
    .filter((service) => {
      const serviceStatus = service.status || 'On Sale';     
      return (
        (searchText === '' || service.name.toLowerCase().includes(searchText.toLowerCase())) &&
      (selectedProvider === 'all' || service.provider === selectedProvider) &&
      (selectedStatus === 'all' || serviceStatus === selectedStatus)
    );
  })

  const providerOptions = [
    { value: 'all', label: '全部商家' },
    ...Array.from(new Set(services.map((service) => service.provider)))
      .filter(Boolean)
      .map((provider) => ({ value: provider, label: provider })),
  ];

  const statusOptions = [
    { value: 'all', label: 'Status' },
    { value: 'onSale', label: 'On Sale' },
    { value: 'offSale', label: 'Off Sale' },
  ];

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handleProviderChange = (value) => {
    setSelectedProvider(value);
  };

  const handleStatusChange = (value) => {
    setSelectedStatus(value);
  };

  const handleRowClick = (record) => {
    if (selectedService?.key !== record.key) {
      const serviceWithImage = {
        ...record,
        imageUrl: record?.blobs?.[0]?.url || '/images/default-service.jpg',
        specs: record.specs || [],
        description: record.description || '',
        notification: record.notification || '',
        status: record.status || 'On Sale',
        provider: record.provider || '',
        category: record.category || {},
        tags: record.tags || [],
        recommendation: record.recommendation || null,
        publishDate: record.publishDate || null
      };
      setSelectedService(serviceWithImage);
    }
  };

  return (
    <div className="flex flex-col w-full h-full">

      <div className="flex space-x-4 mb-4">
      <Select
          className="w-40"
          placeholder="狀態"
          options={statusOptions}
          onChange={handleStatusChange}
          allowClear
          value={selectedStatus}
        />
        <Input
          className="w-80"
          placeholder="Search"
          prefix={<SearchOutlined />}
          onChange={handleSearch}
        />
        {/* <Select
          className="w-40"
          placeholder="上架商家"
          options={providerOptions}
          onChange={handleProviderChange}
          allowClear
          value={selectedProvider}
        /> */}
        
      </div>
      <div className="flex-1 overflow-auto">
        <Table
          loading={tableLoading}
          columns={serviceColumns({
            setShowOrderManagement,
            setSelectedService,
            services,
          })}
          dataSource={filteredServices}
          pagination={{
            pageSize: 10,
            className: "sticky bottom-0 pb-2",
          }}
          onRow={(record) => ({
            onClick: () => handleRowClick(record),
            className: record.key === (selectedService?.key ?? '') ? "bg-gray-100" : "",
          })}
          rowClassName={(record) => `cursor-pointer ${record.key === (selectedService?.key ?? '') ? "bg-gray-100" : ""}`}
          scroll={{ x: 2000 }}
          size="small"
          className="[&_.ant-table-thead>tr>th]:bg-[#E0F0E0]"
        />
      </div>
    </div>
  );
};

export default ServiceTable;
