import { LineUseCase } from "../useCases/LineUseCase";
export const LineFacade = {
  fetchLineClients: async (roomId) => {
    return await LineUseCase.fetchLineClients(roomId);
  },
  fetchLineMessages: async (roomId, socialMediaClientId, limit = 10, fromId = null) => {
    return await LineUseCase.fetchLineMessages(roomId, socialMediaClientId, limit, fromId);
  },
  sendLineMessage: async (roomId, socialMediaClientId, message) => {
    return await LineUseCase.sendLineMessage(roomId, socialMediaClientId, message);
  },

  // get
  getLineChannel: async (roomId) => {
    return await LineUseCase.getLineChannel(roomId);
  },

  // create
  createLineProperty: async (roomId, lineProperty) => {
    return await LineUseCase.createLineProperty(roomId, lineProperty);
  },

  // update
  updateLineWebhookStatus: async (roomId, socialMediaClientId, useWebhook) => {
    return await LineUseCase.updateLineWebhookStatus(roomId, socialMediaClientId, useWebhook);
  },
  updateLineChatSettings: async (roomId, chatSettings) => {
    return await LineUseCase.updateLineChatSettings(roomId, chatSettings);
  },
  updateLineBaseSettings: async (roomId, baseSettings) => {
    return await LineUseCase.updateLineBaseSettings(roomId, baseSettings);
  },
  updateLineNickname: async (roomId, socialMediaClientId, nickname) => {
    return await LineUseCase.updateLineNickname(roomId, socialMediaClientId, nickname);
  },

  // delete
  deleteLineProperty: async (roomId) => {
    return await LineUseCase.deleteLineProperty(roomId);
  },
  addLineNote: async (roomId, clientId, noteName, noteContent) => {
    return await LineUseCase.addLineNote(roomId, clientId, noteName, noteContent);
  },
  updateLineNote: async (roomId, clientId, noteId, noteName, noteContent) => {
    return await LineUseCase.updateLineNote(roomId, clientId, noteId, noteName, noteContent);
  },
  updateLineAdmin: async (roomId, clientId, noteId, noteName, noteContent) => {
    return await LineUseCase.updateLineAdmin(roomId, clientId, noteId, noteName, noteContent);
  },
  deleteLineNote: async (roomId, clientId, noteId) => {
    return await LineUseCase.deleteLineNote(roomId, clientId, noteId);
  },
  addLineTag: async (roomId, clientId, tagName) => {
    return await LineUseCase.addLineTag(roomId, clientId, tagName);
  },
  deleteLineTag: async (roomId, clientId, tagId) => {
    return await LineUseCase.deleteLineTag(roomId, clientId, tagId);
  },
  fetchLineTagGroup: async (roomId) => {
    return await LineUseCase.fetchLineTagGroup(roomId);
  }
}; 