import { LineApi } from '../api/LineApi';
import { createBoundClientEntity, createBoundMessageEntity } from '../../domain/entities/bound/boundEntity';

export const LineRepository = {
  _transformChatData: (rawData) => {
    if (!rawData) return null;
    return createBoundMessageEntity(rawData);
  },

  _transformClientData: (rawData) => {
    if (!rawData) return null;
    return createBoundClientEntity(rawData);
  },

  fetchLineClients: async (roomId) => {
      const response = await LineApi.getLineClients(roomId);
      
      // Handle array of clients
      if (Array.isArray(response)) {
        return response.map(client => LineRepository._transformClientData(client));
      }
      // Handle single client
      return LineRepository._transformClientData(response);
  },

  fetchLineMessages: async (roomId, socialMediaClientId, limit = 10, fromId = null) => {
    const response = await LineApi.getLineMessages(roomId, socialMediaClientId, limit, fromId);
    return response.map(chat => LineRepository._transformChatData(chat));
  },

  setLastSeenMessageCache: async (client) => {
    try {
      const response = await LineApi.setLastSeenMessageCache(client.roomId, client.messageId);
      return response;
    } catch (error) {
      console.error('Error setting last seen message in LineRepository:', error);
      throw error;
    }
  },

  sendLineMessage: async (roomId, socialMediaClientId, message) => {
    return await LineApi.sendLineMessage(roomId, socialMediaClientId, message);
  },

  // get
  getLineChannel: async (roomId) => {
    return await LineApi.getLineChannel(roomId);
  },

  // create
  createLineProperty: async (roomId, lineProperty) => {
    return await LineApi.createLineProperty(roomId, lineProperty);
  },
  
  // update
  updateLineWebhookStatus: async (roomId, socialMediaClientId, useWebhook) => {
    return await LineApi.updateLineWebhookStatus(roomId, socialMediaClientId, useWebhook);
  },

  updateLineChatSettings: async (roomId, chatSettings) => {
    return await LineApi.updateLineChatSettings(roomId, chatSettings);
  },

  updateLineBaseSettings: async (roomId, baseSettings) => {
    return await LineApi.updateLineBaseSettings(roomId, baseSettings);
  },

  updateLineNickname: async (roomId, socialMediaClientId, nickname) => {
    return await LineApi.updateLineNickname(roomId, socialMediaClientId, nickname);
  },

  // delete
  deleteLineProperty: async (roomId) => {
    return await LineApi.deleteLineProperty(roomId);
  },

  addLineNote: async (roomId, clientId, noteName, noteContent) => {
    return await LineApi.addLineNote(roomId, clientId, noteName, noteContent);
  },

  updateLineNote: async (roomId, clientId, noteId, noteName, noteContent) => {
    return await LineApi.updateLineNote(roomId, clientId, noteId, noteName, noteContent);
  },

  updateLineAdmin: async (roomId, clientId, noteId, noteName, noteContent) => {
    return await LineApi.updateLineAdmin(roomId, clientId, noteId, noteName, noteContent);
  },

  deleteLineNote: async (roomId, clientId, noteId) => {
    return await LineApi.deleteLineNote(roomId, clientId, noteId);
  },

  addLineTag: async (roomId, clientId, tagName) => {
    return await LineApi.addLineTag(roomId, clientId, tagName);
  },

  deleteLineTag: async (roomId, clientId, tagId) => {
    return await LineApi.deleteLineTag(roomId, clientId, tagId);
  },

  fetchLineTagGroup: async (roomId) => {
    return await LineApi.fetchLineTagGroup(roomId);
  }

}; 