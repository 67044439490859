import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AddServiceForm from './AddServiceForm';
import { request } from "@/utils";
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const EditServiceForm = () => {
  const { id } = useParams();
  const [serviceData, setServiceData] = useState(null);
  const [loading, setLoading] = useState(true);
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  useEffect(() => {
    const fetchServiceData = async () => {
      try {
        const profileResponse = await request.get(`${SERVER_URL}/private/property/ecommerce/profile`);
        const serverUrl = profileResponse.body.server_url;

        const tokenResponse = await request.get(`${SERVER_URL}/private/property/ecommerce/jwt`);
        const accessToken = tokenResponse.access_token;
        
        const response = await request.get(
          `${serverUrl}/public/sp/${id}`,
          null,
          {
            headers: {
              'Authorization': `Bearer ${accessToken}`
            }
          }
        );

        // 處理規格數據和圖片
        const serviceDataWithSpecs = {
          ...response,
          specs: response.specs || [],
          organization: response.organization || {},
          identity_ids: response.identity_ids || [],
          imageUrl: response.blobs?.length > 0 ? response.blobs[0].url : '/images/default-service.jpg'
        };

        console.log('獲取到的服務數據:', serviceDataWithSpecs);
        setServiceData(serviceDataWithSpecs);
      } catch (error) {
        console.error('獲取服務數據失敗:', error);
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchServiceData();
    }
  }, [id, SERVER_URL]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
      </div>
    );
  }

  if (!serviceData) {
    return <div>找不到該服務</div>;
  }

  return (
    <AddServiceForm
      editMode={true}
      serviceData={serviceData}
      onSave={() => {
        window.history.back();
      }}
    />
  );
};

export default EditServiceForm;