import React, { useState, useEffect, useRef } from "react";
import { Button, message, Switch, Input, Modal, Tooltip, Tabs, Divider } from "antd";

import { useChat } from "../../../../hooks/use-chat";

import TutorialModal from "./TutorialModal";

import { LoadingOutlined, SearchOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";

import { ReactComponent as EditIcon } from "../../assets/edit-icon.svg";
import { ReactComponent as PlusIcon } from "../../assets/plus-icon.svg";
import { ReactComponent as TagIcon } from "../../assets/tag-icon.svg";
import { ReactComponent as DownIcon } from "../../assets/down-icon.svg";
import { ReactComponent as PlusWhiteIcon } from "../../assets/plus-white-icon.svg";
import { ReactComponent as PlusBlackIcon } from "../../assets/plus-black-icon.svg";
import { ReactComponent as TrashIcon } from "../../assets/trash-icon.svg";
import { ReactComponent as EditIcon2 } from "../../assets/edit-icon2.svg";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const WEBHOOK_PATH = "/public/line/callback/";

const Footer = ({ disabled, ctaWord = "完成", onClick, tutorial }) => {
  return (
    <div className="flex flex-row justify-end items-center gap-2 mt-4">
      <Button onClick={() => tutorial(true)}>說明</Button>
      <Button type="primary" onClick={onClick} disabled={disabled}>
        {ctaWord}
      </Button>
    </div>
  );
};

const BaseModal = ({
  id,
  token,
  open,
  close,
  webhook,
  tutorial,
  refresh,
  oldChannelId,
  oldChannelSecret,
  oldChannelAccessToken,
  updateAgentBaseSettings,
}) => {
  const [channelId, setChannelId] = useState(oldChannelId);
  const [channelSecret, setChannelSecret] = useState(oldChannelSecret);
  const [channelAccessToken, setChannelAccessToken] = useState(
    oldChannelAccessToken
  );


  const handleUpdateBase = async () => {
    try {
      await updateAgentBaseSettings(id, {
        channel_id: channelId,
        channel_secret: channelSecret,
        channel_access_token: channelAccessToken,
      });
      message.success("Line@ 串接變更成功");
      close();
    } catch (error) {
      message.error(JSON.stringify(error.response?.data?.detail));
    }
  };

  const handleComplete = () => {
    handleUpdateBase();
    refresh();
    close();
    webhook(true);
  };

  return (
    <Modal
      open={open}
      onCancel={close}
      width={485}
      footer={
        <Footer
          disabled={false}
          ctaWord="變更"
          onClick={handleComplete}
          tutorial={tutorial}
        />
      }
      title="Line@ 變更串接"
    >
      <div className="text-xs text-textLight mb-4">
        請確認三項資料皆正確，以免變更後串接失敗。變更串接後可能導致聊天資料於本系統中遺失。
      </div>
      <div className="flex flex-col border border-borderLight rounded bg-bgMedium w-full">
        <div className="flex flex-col items-start w-full h-22 p-4 gap-2 border-t border-t-borderLight">
          <div className="font-bold">Channel ID</div>
          <Input
            value={channelId}
            onChange={(e) => setChannelId(e.target.value)}
          />
        </div>
        <div className="flex flex-col items-start w-full h-22 p-4 gap-2 border-t border-t-borderLight">
          <div className="font-bold">Channel Secret</div>
          <Input
            value={channelSecret}
            onChange={(e) => setChannelSecret(e.target.value)}
          />
        </div>
        <div className="flex flex-col items-start w-full h-22 p-4 gap-2 border-t border-t-borderLight">
          <div className="font-bold">Access Token</div>
          <Input
            value={channelAccessToken}
            onChange={(e) => setChannelAccessToken(e.target.value)}
          />
        </div>
      </div>
    </Modal>
  );
};

const WebhookModal = ({ id, open, close, handleCopy, copied, tutorial }) => {
  const [copiedOnce, setCopiedOnce] = useState(false);

  useEffect(() => {
    if (copied) {
      setCopiedOnce(true);
    }
  }, [copied]);

  return (
    <Modal
      open={open}
      maskClosable={false}
      closeIcon={null}
      width={400}
      footer={
        <Footer disabled={!copiedOnce} onClick={close} tutorial={tutorial} />
      }
      title="Webhook 網址"
    >
      <div className="flex flex-col items-center min-h-20">
        <div className="items-start">
          <div className="text-textDark mb-2">
            前往 Line Developer 後台，在您 Provider 的中 Messaging API
            的頁面內，於 Webhook settings 的 Webhook URL 區塊貼上以下網址。
          </div>
          <Tooltip title={copied ? "已複製" : "點擊以複製"}>
            <div
              className="flex flex-col p-2 rounded bg-bgLight border w-full"
              onClick={handleCopy}
            >
              {`${SERVER_URL}${WEBHOOK_PATH}${id}`}
            </div>
          </Tooltip>
        </div>
      </div>
    </Modal>
  );
};

const TagModal = ({ open, onClose, room, client, updateClientData }) => {
  const [newTag, setNewTag] = useState("");
  const [searchTag, setSearchTag] = useState("");
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [localTags, setLocalTags] = useState([]);
  const { fetchAgentTagGroup, addAgentTag, deleteAgentTag } = useChat(room.id);

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const response = await fetchAgentTagGroup(room.id);
        setTags(response.tags);
        // 初始化 localTags
        if (client?.tags) {
          setLocalTags(client.tags);
        }
      } catch (error) {
        // console.error('Error fetching tags:', error);
      }
    };
    fetchTags();
  }, [room.id, client?.tags]);

  // 處理新增標籤
  const handleAddNewTag = async (tag) => {
    if (!tag && !newTag.trim()) {
      message.error('請輸入標籤名稱');
      return;
    }

    const tagName = tag ? tag.name : newTag.trim();

    setLoading(true);
    try {
      const newTagObj = await addAgentTag(room.id, client.id, tagName);
      message.success('標籤新增成功');
      // 更新本地狀態
      setLocalTags(newTagObj.tags);
      
      // 手動更新 client 物件的 tags 屬性，確保在 API 重新載入前能看到最新狀態
      if (client && Array.isArray(client.tags)) {
        client.tags = newTagObj.tags;
      }
      
      // 清空輸入
      setNewTag("");
    } catch (error) {
      console.error('Error adding new tag:', error);
      message.error(error.message || '新增標籤失敗');
    } finally {
      setLoading(false);
    }
  };

  // 處理移除已選擇的標籤
  const handleRemoveSelectedTag = async (tag) => {
    Modal.confirm({
      title: '確認刪除標籤',
      content: (
        <div>
          <p>確定要刪除此標籤嗎？</p>
          <div className="mt-2 p-2 bg-gray-100 rounded max-h-[100px] overflow-y-auto">
            {tag?.name}
          </div>
        </div>
      ),
      okText: '刪除',
      cancelText: '取消',
      okButtonProps: {
        danger: true,
      },
      onOk: async () => {
        if (!tag?.id) {
          console.error('Tag ID is missing:', tag);
          message.error('標籤資訊不完整');
          return;
        }

        try {
          await deleteAgentTag(room.id, client.id, tag.id);
          message.success('標籤刪除成功');
          // 更新本地狀態
          setLocalTags(prev => prev.filter(t => t.id !== tag.id));
          
          // 手動更新 client 物件的 tags 屬性
          if (client && Array.isArray(client.tags)) {
            client.tags = client.tags.filter(t => t.id !== tag.id);
          }
        } catch (error) {
          console.error('Error removing tag:', error);
          message.error(error.message || '刪除標籤失敗');
        }
      },
    });
  };

  // 過濾搜尋結果
  const filteredTags = tags.filter(tag =>
    tag.name.toLowerCase().includes(searchTag.toLowerCase())
  );

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.nativeEvent.isComposing) {
      handleAddNewTag();
    }
  };

  return (
    <Modal
      title={<div className="text-center">編輯標籤</div>}
      open={open}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose} className="bg-[#F5F5F5] border-none">
          取消
        </Button>,
        <Button key="submit" type="primary" className="bg-[#67BE5F] hover:bg-[#67BE5F] border-none" onClick={onClose}>
          完成
        </Button>
      ]}
      width={600}
    >
      <div className="flex flex-col gap-4">
        <div className="text-[16px] font-bold">
          新增標籤
        </div>
        <div className="flex items-center gap-2">
          <Input
            placeholder="輸入標籤"
            value={newTag}
            onChange={(e) => setNewTag(e.target.value)}
            maxLength={20}
            suffix={<span className="text-gray-400">{newTag.length}/20</span>}
            allowClear
            className="flex-grow"
            onKeyDown={(e) => handleKeyDown(e)}
          />
          <button
            onClick={() => handleAddNewTag()}
            disabled={!newTag || loading}
            className={`flex items-center justify-center w-[32px] h-[32px] rounded-lg transition-colors cursor-pointer active:scale-90 ${loading ? 'opacity-50' : ''}`}
          >
            {loading ? (
              <LoadingOutlined className="w-4 h-4" />
            ) : (
              <PlusWhiteIcon className="w-4 h-4 text-white" />
            )}
          </button>
        </div>

        {/* 已選擇的標籤 */}
        <div className="h-[120px] overflow-y-auto">
          {localTags?.length > 0 ? (
            <div className="flex flex-wrap gap-2">
              {localTags.map((tag, index) => (
                <div
                  key={index}
                  className='h-7 w-fit flex items-center gap-2 px-3 py-1 cursor-pointer rounded-full bg-primary text-sm border-[#67BE5F] text-textDark hover:bg-[#67BE5F]'
                >
                  {tag?.name}
                  <PlusBlackIcon
                    className="w-[10px] h-[10px] transform cursor-pointer active:scale-90 hover:scale-110"
                    onClick={() => handleRemoveSelectedTag(tag)}
                  />
                </div>
              ))}
            </div>
          ) : (
            <div className="flex flex-col gap-2 items-center justify-center h-full">
              <TagIcon className="w-[31px] h-[39px] text-gray-400" />
              <div className="text-[14px] font-bold text-gray-400">尚無標籤</div>
              <div className="text-[14px] text-gray-400">您可手動輸入標籤或是從現有標籤中點選加入</div>
            </div>
          )}
        </div>

        <div className="text-[16px] font-bold mt-2">
          現有標籤
        </div>
        <Input
          placeholder="搜尋標籤"
          value={searchTag}
          onChange={(e) => setSearchTag(e.target.value)}
          prefix={<SearchOutlined className="w-4 h-4 text-gray-400" />}
        />
        <div className="flex flex-wrap gap-2 mt-2">
          {filteredTags.map((tag, index) => (
            <div
              key={index}
              className={`h-7 flex items-center gap-2 px-3 py-1 cursor-pointer rounded-full bg-primaryMedium text-sm text-textDark hover:bg-[#67BE5F] 
                ${localTags?.find(t => t.name === tag.name)
                  ? 'border-[#67BE5F] text-textDark bg-[#67BE5F]'
                  : 'border-primary border !text-primary bg-white hover:!text-white'
                }`}
              onClick={() => {
                if (!localTags?.find(t => t.name === tag.name)) {
                  handleAddNewTag(tag);
                }
              }}
            >
              {tag.name}
              {!localTags?.find(t => t.name === tag.name) ? (
                <PlusBlackIcon className="w-[10px] h-[10px] text-[#67BE5F] rotate-45 hover:text-black transform cursor-pointer" />
              ) : (
                ''
              )}
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};


const NoteModal = ({ open, onClose, room, client, editNote = null }) => {
  const [newNote, setNewNote] = useState("");
  const [loading, setLoading] = useState(false);
  const { addAgentNote, updateAgentNote } = useChat(room.id);
  
  // 當對話框打開時或接收到要編輯的筆記時，重置或加載輸入內容
  useEffect(() => {
    if (!open) {
      setNewNote("");
    } else if (editNote) {
      setNewNote(editNote.content || "");
    } else {
      setNewNote("");
    }
  }, [open, editNote]);

  const handleSave = async () => {
    if (!newNote.trim()) {
      message.error('請輸入備註內容');
      return;
    }

    setLoading(true);
    try {
      if (editNote) {
        // 編輯現有備註
        await updateAgentNote(
          room.id,
          client.id,
          editNote.id,
          '備註', // 固定使用 "備註" 作為 note_name
          newNote.trim()
        );

        message.success('備註更新成功');
        
        // 手動更新 client 物件的 notes 屬性
        if (client && Array.isArray(client.notes)) {
          const updatedNotes = client.notes.map(note => 
            note.id === editNote.id 
              ? { ...note, content: newNote.trim(), updated_at: new Date().toLocaleString() }
              : note
          );
          client.notes = updatedNotes;
        }
      } else {
        // 新增備註
        const newNoteObj = await addAgentNote(
          room.id,
          client.id,
          '備註', // 固定使用 "備註" 作為 note_name
          newNote.trim()
        );

        message.success('備註新增成功');
        
        // 手動更新 client 物件的 notes 屬性
        if (client && Array.isArray(client.notes)) {
          client.notes = newNoteObj.notes;
        }
      }
      
      onClose();
    } catch (error) {
      console.error('Error saving note:', error);
      message.error(error.message || (editNote ? '更新備註失敗' : '新增備註失敗'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={<div className="text-center">{editNote ? '編輯備註' : '新增備註'}</div>}
      open={open}
      onCancel={onClose}
      maskClosable={false}
      destroyOnClose
      footer={[
        <Button key="cancel" onClick={onClose} className="bg-[#F5F5F5] border-none">
          取消
        </Button>,
        <Button
          key="submit"
          type="primary"
          className="bg-[#67BE5F] hover:bg-[#67BE5F] border-none"
          onClick={handleSave}
          loading={loading}
          disabled={!newNote.trim()}
        >
          儲存
        </Button>
      ]}
      width={600}
    >
      <div className="flex flex-col gap-4 h-[300px] overflow-y-auto">
        <div className="text-[16px] font-bold">
          {editNote ? '編輯備註' : '新增備註'}
        </div>
        <div className="flex items-center gap-2">
          <Input.TextArea
            placeholder="請在此輸入用戶的相關資訊，這些內容將記錄於記事本中，不對外公開"
            value={newNote}
            onChange={(e) => setNewNote(e.target.value)}
            maxLength={300}
            autoSize={{ minRows: 8, maxRows: 8 }}
            showCount
            className="flex-grow"
          />
        </div>
        <div className="text-xs text-textLight">由 XXX 在 {new Date().toLocaleString()} 編輯</div>
      </div>
    </Modal>
  );
};

const AgentSetting = ({ room, token, showWebhook = true, clientId = null, client = null, updateClientData }) => {
  const [loading, setLoading] = useState(false);
  const [bound, setBound] = useState(false);
  const [openWebhookModal, setOpenWebhookModal] = useState(false);
  const [openTutorialModal, setOpenTutorialModal] = useState(false);
  const [copied, setCopied] = useState(false);
  const [channelId, setChannelId] = useState("");
  const [channelSecret, setChannelSecret] = useState("");
  const [channelAccessToken, setChannelAccessToken] = useState("");
  const [agentType, setAgentType] = useState(null);
  const [responseMode, setResponseMode] = useState(null);
  const [queryOnly, setQueryOnly] = useState(null);
  const [useWebhook, setUseWebhook] = useState(null);
  const [openUnbind, setOpenUnbind] = useState(false);
  const [openBaseModal, setOpenBaseModal] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [tagModalOpen, setTagModalOpen] = useState(false);
  const [noteModalOpen, setNoteModalOpen] = useState(false);
  const [tagCollapsed, setTagCollapsed] = useState(true);
  const [noteCollapsed, setNoteCollapsed] = useState(true);
  const [isEditingNickname, setIsEditingNickname] = useState(false);
  const [editedNickname, setEditedNickname] = useState("");
  const [nicknameLoading, setNicknameLoading] = useState(false);
  const [currentEditNote, setCurrentEditNote] = useState(null);
  const [isEditingAdmin, setIsEditingAdmin] = useState(false);
  const [adminLoading, setAdminLoading] = useState(false);
  
  // 添加refs來直接訪問輸入框DOM
  const nicknameInputRef = useRef(null);
  const adminInputRef = useRef(null);

  const { updateAgentWebhookStatus, getAgentChannel, updateAgentChatSettings, createAgentProperty, deleteAgentProperty, updateAgentBaseSettings, updateAgentNickname, deleteAgentNote, deleteAgentTag, updateAgentNote, addAgentNote } = useChat(room.id);

  useEffect(() => {
    if (copied) {
      setTimeout(() => setCopied(false), 2000);
    }

  }, [copied]);

  useEffect(() => {
    setUseWebhook((client?.settings?.webhook) || false);
    fetchLineProperty();
    setActiveTab(0);
  }, [client]);


  const fetchLineProperty = async () => {
    setLoading(true);
    try {
      const response = await getAgentChannel(room.id);
      setChannelId(response?.body?.channel_id);
      setChannelSecret(response?.body?.channel_secret);
      setChannelAccessToken(response?.body?.channel_access_token);

      // console.log(response.data.body)

      setAgentType(response?.body?.agent_type);
      setResponseMode(response?.body?.response_mode);
      setQueryOnly(response?.body?.query_only);

      setBound(true);
      
    } catch (error) {
      // message.error(JSON.stringify(error.response?.data?.detail));
      console.log('false', error);
      
      setBound(false);
    } finally {
      setLoading(false);
    }
  };

  const handleBind = async () => {
    setLoading(true);
    try {
      const lineProperty = {
        channel_id: channelId,
        channel_secret: channelSecret,
        channel_access_token: channelAccessToken,
        agent_type: "image_reader",
        response_mode: "text_only",
        query_only: false,
      }

      console.log('lineProperty', lineProperty);

      await createAgentProperty(room.id, lineProperty);


      message.success("Line@ 已串接成功");
      fetchLineProperty();
      setOpenWebhookModal(true);
    } catch (error) {
      console.log('123');

      // message.error(JSON.stringify(error.response?.data?.detail));
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateChatSettings = async (updatedSettings) => {
    setLoading(true);
    try {
      await updateAgentChatSettings(room.id, updatedSettings);
      message.success("已更新 AI 回覆設定");
      // Fetch the updated settings
      fetchLineProperty();
    } catch (error) {
      message.error(JSON.stringify(error.response?.data?.detail));
    } finally {
      setLoading(false);
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(`${SERVER_URL}${WEBHOOK_PATH}${room.id}`);
    setCopied(true);
  };

  const handleUnbind = async () => {
    setLoading(true);
    try {
      await deleteAgentProperty(room.id);
      message.success("Line@ 已解除串接");
      setBound(false);
      setOpenUnbind(false);
    } catch (error) {
      message.error(JSON.stringify(error.response?.data?.detail));
    } finally {
      setLoading(false);
    }
  };

  const onChangeTab = (key) => {
    setActiveTab(key);
  };

  const tabs = [
    { label: 'AI 設定', key: 0 },
    { label: '用戶設定', key: 1 },
  ];

  const TabContent = ({ activeTab, setTagModalOpen, setNoteModalOpen, setUseWebhook }) => {
    const toggleTag = () => setTagCollapsed(!tagCollapsed);
    const toggleNote = () => setNoteCollapsed(!noteCollapsed);
    // 為 Switch 添加獨立的 loading 狀態
    const [switchLoading, setSwitchLoading] = useState(false);

    const handleOpenNoteModal = (note = null) => {
      setCurrentEditNote(note);
      setNoteModalOpen(true);
    };

    const startEditingNickname = () => {
      setIsEditingNickname(true);
    };

    const cancelEditingNickname = () => {
      setIsEditingNickname(false);
    };

    const saveNickname = async () => {
      // 從DOM獲取值而不是React狀態
      const value = nicknameInputRef.current.input.value;
      
      if (!value.trim()) {
        message.error("暱稱不能為空");
        return;
      }

      setNicknameLoading(true);
      
      // 先將值暫存起來，在API返回前先顯示新值
      const newValue = value.trim();
      const originalNickname = client?.nickname || '';
      
      // 先更新客戶端顯示
      if (client) {
        client.nickname = newValue;
      }
      
      // 先退出編輯模式，顯示新值
      setIsEditingNickname(false);
      
      try {
        await updateAgentNickname(room.id, client.id, newValue);
        message.success("暱稱更新成功");
      } catch (error) {
        console.error('Error updating nickname:', error);
        message.error(error.message || '更新暱稱失敗');
        
        // 如果API請求失敗，恢復原始數據
        if (client) {
          client.nickname = originalNickname;
        }
        
        // 恢復編輯模式
        setIsEditingNickname(true);
      } finally {
        setNicknameLoading(false);
      }
    };

    const handleWebhook = async (e) => {
      // 開始切換時設置 Switch 為 loading 狀態
      setSwitchLoading(true);
      try {
        console.log('client', client);
        if (client.channel === 'line') {
          const res = await updateAgentWebhookStatus(room.id, client.id, e);
          console.log('res', res);
        } else if (client.channel === 'messenger') {
          await updateAgentWebhookStatus(room.id, client.id, e);
        }
        message.success("已更新 AI 狀態設定");

        // 直接更新本地狀態，不需要重新獲取
        setUseWebhook(e);

        // 移除對 fetchLineProperty 的調用，避免頁面重新加載
        // fetchLineProperty();
      } catch (error) {
        message.error(JSON.stringify(error.response?.data?.detail));

        // 如果 API 請求失敗，還原開關狀態
        setUseWebhook(!e);
      } finally {
        // 完成後結束 Switch 的 loading 狀態
        setSwitchLoading(false);
      }
    };

    // 負責人員編輯功能
    const startEditingAdmin = () => {
      setIsEditingAdmin(true);
    };

    // 取消編輯負責人員
    const cancelEditingAdmin = () => {
      setIsEditingAdmin(false);
    };

    // 儲存負責人員 - 直接從DOM獲取值
    const saveAdmin = async () => {
      // 確保adminInputRef.current存在
      if (!adminInputRef.current || !adminInputRef.current.input) {
        message.error("輸入控件未初始化");
        return;
      }
      
      const value = adminInputRef.current.input.value;
      
      if (!value || !value.trim()) {
        message.error("負責人員不能為空");
        return;
      }

      setAdminLoading(true);
      
      // 先將值暫存起來，在API返回前先顯示新值
      const newValue = value.trim();
      const adminNote = client.notes?.find(note => note.name === '負責人員');
      
      // 先更新客戶端顯示
      if (client) {
        if (adminNote) {
          // 更新現有的負責人員記錄
          client.notes = client.notes.map(note => 
            note.name === '負責人員' 
              ? { ...note, content: newValue, updated_at: new Date().toLocaleString() } 
              : note
          );
        } else {
          // 添加新的負責人員記錄
          client.notes = [...(client.notes || []), { 
            id: Date.now(), // 臨時ID
            name: '負責人員', 
            content: newValue,
            created_at: new Date().toLocaleString(),
            updated_at: new Date().toLocaleString()
          }];
        }
      }
      
      // 先退出編輯模式，顯示新值
      setIsEditingAdmin(false);
      
      try {
        if (adminNote) {
          await updateAgentNote(room.id, client.id, adminNote.id, '負責人員', newValue);
        } else {
          await addAgentNote(room.id, client.id, '負責人員', newValue);
        }
        
        message.success("負責人員更新成功");
      } catch (error) {
        console.error('Error updating admin:', error);
        message.error(error.message || '更新負責人員失敗');
        
        // 如果API請求失敗，恢復原始數據
        if (client && adminNote) {
          client.notes = client.notes.map(note => 
            note.name === '負責人員' 
              ? { ...note, content: adminNote.content, updated_at: adminNote.updated_at } 
              : note
          );
        } else if (client) {
          // 如果是新增記錄失敗，則移除臨時記錄
          client.notes = client.notes.filter(note => note.name !== '負責人員');
        }
        
        // 恢復編輯模式
        setIsEditingAdmin(true);
      } finally {
        setAdminLoading(false);
      }
    };

    // 共用的容器樣式
    const containerStyle = "flex flex-col justify-start items-start w-full px-4 py-6 gap-4 overflow-y-auto overflow-x-hidden";

    switch (activeTab) {
      case 0:
        return (
          <div className={containerStyle}>
            {clientId && (
                  <>
                    <div className="text-lg text-textDark font-bold w-full">
                      AI 個別狀態設定
                    </div>
                    <div className="flex flex-col border border-borderLight rounded bg-bgMedium w-full">
                      <div className="flex flex-col items-start w-full h-22 p-4 gap-2">
                        <div className="font-bold">AI 狀態</div>
                        <div className="flex flex-row justify-between w-full items-center">
                          <div>針對此客戶啟用 AI</div>
                          <Tooltip title="將自動傳送狀態變更訊息給客戶">
                            <Switch
                              value={useWebhook}
                              onChange={(e) => handleWebhook(e)}
                              loading={switchLoading}
                            />
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </>
                )}
            {/* {bound ? (
              <>
                {clientId && (
                  <>
                    <div className="text-lg text-textDark font-bold w-full">
                      AI 個別狀態設定
                    </div>
                    <div className="flex flex-col border border-borderLight rounded bg-bgMedium w-full">
                      <div className="flex flex-col items-start w-full h-22 p-4 gap-2">
                        <div className="font-bold">AI 狀態</div>
                        <div className="flex flex-row justify-between w-full items-center">
                          <div>針對此客戶啟用 AI</div>
                          <Tooltip title="將自動傳送狀態變更訊息給客戶">
                            <Switch
                              value={useWebhook}
                              onChange={(e) => handleWebhook(e)}
                              loading={switchLoading}
                            />
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div className="flex justify-between items-center text-lg text-textDark font-bold w-full">
                  <span>AI 全域狀態設定</span>
                  <ReloadOutlined
                    className="flex items-center hover:text-primary ml-2"
                    style={{ width: "12px", height: "12px" }}
                    onClick={fetchLineProperty}
                  />
                </div>
                <div className="flex flex-col border border-borderLight rounded bg-bgMedium w-full">
                  <div className="flex flex-col items-start w-full h-22 p-4 gap-2">
                    <div className="font-bold">連續對話模式</div>
                    <div className="flex flex-row justify-between w-full items-center">
                      <div>AI 會延續之前的對話</div>
                      <Switch
                        value={!queryOnly}
                        onChange={(e) =>
                          handleUpdateChatSettings({
                            response_mode: responseMode,
                            query_only: !e,
                            agent_type: agentType
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="flex flex-col items-start w-full h-22 p-4 gap-2 border-t border-t-borderLight">
                    <div className="font-bold">AI 回覆方式</div>
                    <div className="flex flex-row justify-between w-full items-center">
                      <Radio.Group value={responseMode} buttonStyle="solid" className="w-full">
                        <div className="flex flex-wrap">
                          <Radio.Button
                            value={"voice_only"}
                            onClick={() =>
                              handleUpdateChatSettings({
                                response_mode: "voice_only",
                                query_only: queryOnly,
                                agent_type: agentType
                              })
                            }
                          >
                            語音
                          </Radio.Button>
                          <Radio.Button
                            value={"text_only"}
                            onClick={() =>
                              handleUpdateChatSettings({
                                response_mode: "text_only",
                                query_only: queryOnly,
                                agent_type: agentType
                              })
                            }
                          >
                            文字
                          </Radio.Button>
                          <Radio.Button
                            value={"text_and_voice"}
                            onClick={() =>
                              handleUpdateChatSettings({
                                response_mode: "text_and_voice",
                                query_only: queryOnly,
                                agent_type: agentType
                              })
                            }
                          >
                            語音+文字
                          </Radio.Button>
                        </div>
                      </Radio.Group>
                    </div>
                  </div>
                </div>
                {showWebhook && (
                  <>
                    <div className="text-lg text-textDark font-bold w-full">
                      串接管理
                    </div>
                    <div className="flex flex-col border border-borderLight rounded bg-bgMedium w-full">
                      <div className="flex flex-col items-start w-full h-22 p-4 gap-2 border-t border-t-borderLight">
                        <div className="font-bold">Channel ID</div>
                        <div className="flex flex-1 p-2 rounded bg-bgLight border w-full break-all">
                          {channelId}
                        </div>
                      </div>
                      <div className="flex flex-col items-start w-full h-22 p-4 gap-2 border-t border-t-borderLight">
                        <div className="font-bold">Channel Secret</div>
                        <div className="flex flex-1 p-2 rounded bg-bgLight border w-full break-all">
                          {channelSecret}
                        </div>
                      </div>
                      <div className="flex flex-col items-start w-full h-22 p-4 gap-2 border-t border-t-borderLight">
                        <div className="font-bold">Access Token</div>
                        <div className="flex flex-1 p-2 rounded bg-bgLight border w-full break-all">
                          {channelAccessToken}
                        </div>
                      </div>
                      <div className="flex flex-col items-start w-full h-22 p-4 gap-2 border-t border-t-borderLight">
                        <div className="font-bold">Webhook 網址</div>
                        <Tooltip title={copied ? "已複製" : "點擊以複製"}>
                          <div
                            className="flex flex-1 p-2 rounded bg-bgLight border w-full break-all cursor-pointer"
                            onClick={handleCopy}
                          >
                            {`${SERVER_URL}${WEBHOOK_PATH}${room.id}`}
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                    <div className="flex flex-row justify-space w-full gap-1">
                      <div className="flex gap-1">
                        <Button onClick={() => setOpenTutorialModal(true)}>
                          說明
                        </Button>
                        <Button onClick={() => setOpenBaseModal(true)}>
                          變更串接
                        </Button>
                      </div>
                      {openUnbind ? (
                        <div className="flex gap-1">
                          <Button danger onClick={() => handleUnbind()}>
                            解除串接
                          </Button>
                          <Button onClick={() => setOpenUnbind(false)}>取消</Button>
                        </div>
                      ) : (
                        <Button danger onClick={() => setOpenUnbind(true)}>
                          解除串接
                        </Button>
                      )}
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                <div className="text-lg text-textDark font-bold w-full">
                  Line@ 串接
                </div>
                <div className="text-xs text-textLight">
                  透過串接 Line@ 官方帳號，您的顧客可以透過 Line 跟您的 Teamsync AI
                  機器人聊天，以接觸更多潛在顧客及粉絲。
                </div>
                <div className="flex flex-col border border-borderLight rounded bg-bgMedium w-full">

                  <div className="flex flex-col items-start w-full h-22 p-4 gap-2 border-t border-t-borderLight">
                    <div className="font-bold">Channel ID</div>
                    <Input
                      value={channelId}
                      onChange={(e) => setChannelId(e.target.value)}
                    />
                  </div>
                  <div className="flex flex-col items-start w-full h-22 p-4 gap-2 border-t border-t-borderLight">
                    <div className="font-bold">Channel Secret</div>
                    <Input
                      value={channelSecret}
                      onChange={(e) => setChannelSecret(e.target.value)}
                    />
                  </div>
                  <div className="flex flex-col items-start w-full h-22 p-4 gap-2 border-t border-t-borderLight">
                    <div className="font-bold">Access Token</div>
                    <Input
                      value={channelAccessToken}
                      onChange={(e) => setChannelAccessToken(e.target.value)}
                    />
                  </div>
                </div>
                <div className="flex flex-row w-full justify-end gap-2">
                  <Button onClick={() => setOpenTutorialModal(true)}>說明</Button>
                  <Button
                    type="primary"
                    disabled={!channelId || !channelSecret || !channelAccessToken}
                    onClick={() => handleBind(true)}
                  >
                    串接
                  </Button>
                </div>
              </>
            )} */}
          </div>
        );
      case 1:
        return (
          <div className={containerStyle}>
            {/* Line Profile Section */}
            <div className="flex items-center space-x-4 w-full">
              <div className="w-[70px] h-[70px] min-w-[70px] rounded-full flex items-center justify-center">
                <img src={client?.pictureUrl || '/images/user.png'} alt="Agent Profile" className="w-full h-full object-cover rounded-full" />
              </div>

              <div className="flex flex-col flex-grow overflow-hidden">
                <div className="flex items-center justify-between w-full">
                  <span className="text-md font-medium text-gray-700">{client?.displayName || '請設定顯示名稱'}</span>
                </div>

                <div className="flex items-center justify-between w-full">
                  {isEditingNickname ? (
                    <div className="flex items-center w-full">
                      <Input 
                        ref={nicknameInputRef}
                        defaultValue={client?.nickname || ''}
                        placeholder="請輸入暱稱"
                        className="flex-1 mr-2"
                        maxLength={30}
                        autoFocus
                      />
                      <div className="flex shrink-0">
                      <button 
                          className="p-2 hover:bg-gray-100 rounded-lg transition-colors mr-1"
                          onClick={saveNickname}
                          disabled={nicknameLoading}
                        >
                          {nicknameLoading ? <LoadingOutlined /> : <CheckOutlined style={{ color: "#67BE5F" }} />}
                        </button>
                        <button 
                          className="p-2 hover:bg-gray-100 rounded-lg transition-colors"
                          onClick={cancelEditingNickname}
                          disabled={nicknameLoading}
                        >
                          <CloseOutlined style={{ color: "#FF4D4F" }} />
                        </button>
                      </div>
                    </div>
                  ) : (
                    <>
                      <span className="text-[16px] font-medium text-gray-700 truncate">{client?.nickname || '請設定暱稱'}</span>
                      <button 
                        className="p-2 hover:bg-gray-100 rounded-lg transition-colors ml-2 shrink-0"
                        onClick={startEditingNickname}
                      >
                        <EditIcon />
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>

            <Divider className="my-1" />

            <div className="flex flex-row justify-between items-center text-lg text-textDark font-bold w-full">
              <div>負責人員</div>
              <button className="p-2 hover:bg-gray-100 rounded-lg transition-colors" onClick={startEditingAdmin}>
                <EditIcon />
              </button>
            </div>

            {/* 負責人員編輯區域 */}
            {isEditingAdmin ? (
              <div className="flex items-center w-full mt-2">
                <Input
                  ref={adminInputRef}
                  defaultValue={client?.notes?.find(note => note.name === '負責人員')?.content || ''}
                  placeholder="請輸入負責人員"
                  className="flex-1 mr-2"
                  maxLength={30}
                  autoFocus
                />
                <div className="flex shrink-0">
                  <button
                    className="p-2 hover:bg-gray-100 rounded-lg transition-colors mr-1"
                    onClick={saveAdmin}
                    disabled={adminLoading}
                  >
                    {adminLoading ? <LoadingOutlined /> : <CheckOutlined style={{ color: "#67BE5F" }} />}
                  </button>
                  <button
                    className="p-2 hover:bg-gray-100 rounded-lg transition-colors"
                    onClick={cancelEditingAdmin}
                    disabled={adminLoading}
                  >
                    <CloseOutlined style={{ color: "#FF4D4F" }} />
                  </button>
                </div>
              </div>
            ) : (
              <div className="pl-2 text-base text-gray-700 mt-2">
                {client?.notes?.find(note => note.name === '負責人員')?.content || '未設定負責人員'}
              </div>
            )}

            <Divider className="my-1" />

            <div className="w-full">
              <div
                className="flex flex-row justify-between items-center text-lg text-textDark font-bold w-full cursor-pointer"
                onClick={toggleTag}
              >
                <div>標籤 <span className="text-lg font-medium text-gray-400 ml-2">{client?.tags?.length || 0}</span></div>
                <button className="p-2 hover:bg-gray-100 rounded-lg transition-colors">
                  <DownIcon
                    className={`w-4 h-4 transform transition-transform ${tagCollapsed ? '' : 'rotate-180'}`}
                    fill="none"
                  />
                </button>
              </div>

              {/* 標籤 Collapse Content */}
              {!tagCollapsed && (
                <div className="flex gap-4 flex-col justify-start mt-4">

                  <button
                    onClick={() => setTagModalOpen(true)}
                    className="flex items-center gap-2 px-0 border-none text-[#67BE5F] text-[14px] shadow-none hover:text-[#67BE5F] active:scale-95 transition-transform"
                  >
                    <PlusIcon className="w-[18px] h-[18px]" />
                    新增標籤
                  </button>
                  {client?.tags?.length > 0 && (
                    <div className="flex flex-row flex-wrap gap-2">
                      {client.tags.map((tag, index) => (
                        <div
                          key={index}
                          className='h-7 w-fit flex items-center gap-2 px-3 py-1 cursor-pointer rounded-full bg-primary text-sm border-[#67BE5F] text-textDark'
                        >
                          {tag?.name}
                          <PlusBlackIcon
                            className="w-[10px] h-[10px] transform cursor-pointer"
                            onClick={() => handleDeleteTag(tag)}
                          />
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>

            <Divider className="my-1 border-borderLight" />
            <div className="w-full">
              <div
                className="flex flex-row justify-between items-center text-lg text-textDark font-bold w-full cursor-pointer"
                onClick={toggleNote}
              >
                <div>備註欄 <span className="text-lg font-medium text-gray-400 ml-2">{client?.notes?.filter(note => note.name === '備註').length || 0}</span></div>
                <button className="p-2 hover:bg-gray-100 rounded-lg transition-colors">
                  <DownIcon
                    className={`w-4 h-4 transform transition-transform ${noteCollapsed ? '' : 'rotate-180'}`}
                    fill="none"
                  />
                </button>
              </div>



              {/* 備註欄 Collapse Content */}
              {!noteCollapsed && (
                <div className="flex gap-4 flex-col justify-start mt-4">

                  <button
                    onClick={() => handleOpenNoteModal()}
                    className="flex items-center gap-2 px-0 border-none text-[#67BE5F] text-[14px] shadow-none hover:text-[#67BE5F] active:scale-95 transition-transform"
                  >
                    <PlusIcon className="w-[18px] h-[18px]" />
                    新增備註欄
                  </button>
                  {client?.notes?.filter(note => note.name === '備註').length > 0 && (
                    <div className="flex flex-col gap-2">
                      {client.notes.filter(note => note.name === '備註').map((note, index) => (
                        <div key={index} className="flex flex-col justify-between border border-borderLight rounded bg-bgMedium p-2 min-h-[100px] w-full">
                          <div className="flex-grow overflow-y-auto whitespace-pre-wrap break-words mb-2 w-full">
                            {note.content}
                          </div>
                          <div className="flex flex-row justify-between items-center mt-auto">
                            <span className="text-[12px] font-medium text-gray-700 flex items-center justify-center">
                              {/* {note?.created_at || '未設定更新時間'} */}
                            </span>
                            <div className="flex flex-row gap-2">
                              <button 
                                className="p-2 hover:bg-gray-100 rounded-lg transition-colors"
                                onClick={() => handleOpenNoteModal(note)}>
                                <EditIcon2 />
                              </button>
                              <button
                                onClick={() => handleDeleteNote(note.id, note.content)}
                                className="p-2 hover:bg-gray-100 rounded-lg transition-colors">
                                <TrashIcon />
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  const handleDeleteNote = async (noteId, noteContent) => {
    Modal.confirm({
      title: '確認刪除備註',
      content: (
        <div>
          <p>確定要刪除此備註嗎？</p>
          <div className="mt-2 p-2 bg-gray-100 rounded max-h-[100px] overflow-y-auto">
            {noteContent}
          </div>
        </div>
      ),
      okText: '刪除',
      cancelText: '取消',
      okButtonProps: {
        danger: true,
      },
      onOk: async () => {
        try {
          await deleteAgentNote(room.id, client.id, noteId);
          message.success('備註刪除成功');
          
          // 手動更新 client 物件的 notes 屬性
          if (client && Array.isArray(client.notes)) {
            client.notes = client.notes.filter(note => note.id !== noteId);
          }
        } catch (error) {
          console.error('Error deleting note:', error);
          message.error(error.message || '刪除失敗');
        }
      },
    });
  };

  const handleDeleteTag = async (tag) => {
    Modal.confirm({
      title: '確認刪除標籤',
      content: (
        <div>
          <p>確定要刪除此標籤嗎？</p>
          <div className="mt-2 p-2 bg-gray-100 rounded max-h-[100px] overflow-y-auto">
            {tag?.name}
          </div>
        </div>
      ),
      okText: '刪除',
      cancelText: '取消',
      okButtonProps: {
        danger: true,
      },
      onOk: async () => {
        if (!tag?.id) {
          console.error('Tag ID is missing:', tag);
          message.error('標籤資訊不完整');
          return;
        }

        try {
          await deleteAgentTag(room.id, client.id, tag.id);
          message.success('標籤刪除成功');
          
          // 手動更新 client 物件的 tags 屬性
          if (client && Array.isArray(client.tags)) {
            client.tags = client.tags.filter(t => t.id !== tag.id);
          }
        } catch (error) {
          console.error('Error removing tag:', error);
          message.error(error.message || '刪除標籤失敗');
        }
      },
    });
  };


  return (
    <>
      <BaseModal
        id={room.id}
        token={token}
        open={openBaseModal}
        close={() => setOpenBaseModal(false)}
        webhook={setOpenWebhookModal}
        tutorial={setOpenTutorialModal}
        refresh={fetchLineProperty}
        oldChannelId={channelId}
        oldChannelSecret={channelSecret}
        oldChannelAccessToken={channelAccessToken}
        updateAgentBaseSettings={updateAgentBaseSettings}
      />
      <WebhookModal
        open={openWebhookModal}
        close={() => setOpenWebhookModal(false)}
        id={room.id}
        handleCopy={handleCopy}
        copied={copied}
        tutorial={setOpenTutorialModal}
      />
      <TutorialModal
        open={openTutorialModal}
        close={() => setOpenTutorialModal(false)}
      />
      <TagModal
        open={tagModalOpen}
        onClose={() => setTagModalOpen(false)}
        room={room}
        client={client}
        updateClientData={updateClientData}
      />
      <NoteModal
        open={noteModalOpen}
        onClose={() => {
          setNoteModalOpen(false);
          setCurrentEditNote(null);
        }}
        room={room}
        client={client}
        clientId={clientId}
        updateClientData={updateClientData}
        editNote={currentEditNote}
      />
      <div className="flex flex-col w-full">
        {clientId ? (
          <>
            <Tabs
              defaultActiveKey={0}
              items={tabs}
              onChange={onChangeTab}
              className="px-2 w-full"
            />
            <TabContent activeTab={activeTab} setTagModalOpen={setTagModalOpen} setNoteModalOpen={setNoteModalOpen} setUseWebhook={setUseWebhook} />
          </>
        ) : (
          <div className="flex flex-col w-full">
            <TabContent activeTab={0} setTagModalOpen={setTagModalOpen} setNoteModalOpen={setNoteModalOpen} setUseWebhook={setUseWebhook} />
          </div>
        )}
      </div>
    </>
  );
};

export default AgentSetting;
