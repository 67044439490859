import { AgentApi } from '../api/AgentApi';
import { createBoundClientEntity, createBoundMessageEntity } from '../../domain/entities/bound/boundEntity';

export const AgentRepository = {
  transformChatData: (rawData) => {
    if (!rawData) return null;
    return createBoundMessageEntity(rawData);
  },

  transformClientData: (rawData) => {
    if (!rawData) return null;
    return createBoundClientEntity(rawData);
  },

  fetchAgentClients: async (roomId) => {
      const response = await AgentApi.getAgentClients(roomId);
      
      // Handle array of clients
      if (Array.isArray(response)) {
        return response.map(client => AgentRepository.transformClientData(client));
      }
      
      // Handle single client
      return AgentRepository.transformClientData(response);
  },

  fetchAgentMessages: async (roomId, socialMediaClientId, limit = 10, oldestMessageId = null) => {
    const response = await AgentApi.getAgentMessages(roomId, socialMediaClientId, limit, oldestMessageId);
    return response.map(chat => AgentRepository.transformChatData(chat));
  },

  sendAgentMessage: async (roomId, socialMediaClientId, message) => {
    return await AgentApi.sendAgentMessage(roomId, socialMediaClientId, message);
  },

  // get
  getAgentChannel: async (roomId) => {
    return await AgentApi.getAgentChannel(roomId);
  },

  // create
  createAgentProperty: async (roomId, agentProperty) => {
    return await AgentApi.createAgentProperty(roomId, agentProperty);
  },
  
  // update
  updateAgentWebhookStatus: async (roomId, socialMediaClientId, useWebhook) => {
    return await AgentApi.updateAgentWebhookStatus(roomId, socialMediaClientId, useWebhook);
  },
  updateAgentChatSettings: async (roomId, chatSettings) => {
    return await AgentApi.updateAgentChatSettings(roomId, chatSettings);
  },

  updateAgentBaseSettings: async (roomId, baseSettings) => {
    return await AgentApi.updateAgentBaseSettings(roomId, baseSettings);
  },
  updateAgentNickname: async (roomId, socialMediaClientId, nickname) => {
    return await AgentApi.updateAgentNickname(roomId, socialMediaClientId, nickname);
  },

  // delete
  deleteAgentProperty: async (roomId) => {
    return await AgentApi.deleteAgentProperty(roomId);
  },

  addAgentNote: async (roomId, clientId, noteName, noteContent) => {
    return await AgentApi.addAgentNote(roomId, clientId, noteName, noteContent);
  },
  updateAgentNote: async (roomId, clientId, noteId, noteName, noteContent) => {
    return await AgentApi.updateAgentNote(roomId, clientId, noteId, noteName, noteContent);
  },
  deleteAgentNote: async (roomId, clientId, noteId) => {
    return await AgentApi.deleteAgentNote(roomId, clientId, noteId);
  },
  addAgentTag: async (roomId, clientId, tagName) => {
    return await AgentApi.addAgentTag(roomId, clientId, tagName);
  },
  deleteAgentTag: async (roomId, clientId, tagId) => {
    return await AgentApi.deleteAgentTag(roomId, clientId, tagId);
  },
  fetchAgentTagGroup: async (roomId) => {
    return await AgentApi.fetchAgentTagGroup(roomId);
  }
  
  
  
}; 