import React from 'react'; // dump 
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ConfigProvider, App as AntApp } from 'antd';
import './App.css';
import { useAuth } from './contexts/AuthProvider';
import Redirection from './app/landing/Redirection';
import Login from './app/landing/Login';
import Register from './app/landing/Register';
import Forget from './app/landing/Forget';
import Reset from './app/landing/Reset';
import Explore from './app/explore/Explore';
import Chat from './app/chat/Chat';
import Privacy from "./app/privacy/Privacy";
import Settings from './app/systemSettings/Settings';
import NavBar from './components/navBar/NavBar';
import NotFound from './components/NotFound';
import { Provider } from 'react-redux';
import store from './store/index';
import { routes } from './app/ams/config/routeConfig';

const teamsyncTheme = {
  token: {
    colorPrimary: "#67be5f",
    colorSecondary: "#e0e0e0",
    colorBgContainer: "#f7f7f7",
  },
};
function App() {
  const { token, loading } = useAuth();
  if (loading) {
    return null;
  }
  return (
    <ConfigProvider theme={teamsyncTheme}>
      <AntApp>
        <Router>
          <React.Suspense fallback={<div></div>}>
            <div className="flex flex-row overflow-x-hidden w-screen h-screen">
              {token && <NavBar />}
              <Provider store={store}>
                <Routes>
                  <Route
                    path="/login"
                    element={
                      token ? <Redirection target={"/explore"} /> : <Login />
                    }
                  />
                  <Route
                    path="/register"
                    element={
                      token ? <Redirection target={"/explore"} /> : <Register />
                    }
                  />
                  <Route
                    path="/forget"
                    element={
                      token ? <Redirection target={"/explore"} /> : <Forget />
                    }
                  />
                  <Route
                    path="/reset/:ticketId"
                    element={
                      token ? <Redirection target={"/explore"} /> : <Reset />
                    }
                  />

                  <Route
                    path="/"
                    element={
                      token ? <Redirection target={"/explore"} /> : <Login />
                    }
                  />
                  <Route
                    path="/explore"
                    element={token ? <Explore /> : <Login />}
                  />
                  <Route
                    path="/chat/:roomId?"
                    element={token ? <Chat /> : <Login />}
                  />
                  <Route
                    path="/settings"
                    element={token ? <Settings /> : <Login />}
                  />

                  {/* e-commerce */}
                  {routes(token).map((route) => (
                    <Route
                      key={route.path}
                      path={route.path}
                      element={
                        route.component === "Login" ? (
                          <Login />
                        ) : (
                          React.createElement(
                            React.lazy(() =>
                              import(`./app/ams/pages${route.component}`)
                            )
                          )
                        )
                      }
                    />
                  ))}
                  <Route path="privacy" element={<Privacy />} />
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </Provider>
            </div>
          </React.Suspense>
        </Router>
      </AntApp>
    </ConfigProvider>
  );
}

export default App;
