import { useEffect, useState } from 'react';
import { FacebookFacade } from '../application/facades/FacebookFacade';
import { LineFacade } from '../application/facades/LineFacade';
import { AgentFacade } from '../application/facades/AgentFacade';
import { AgodaFacade } from '../application/facades/AgodaFacade';
import { AirbnbFacade } from '../application/facades/AirbnbFacade';
import { BoundFacade } from '../application/facades/BoundFacade';
import { useBound } from '../contexts/BoundContext';
import { useUser } from '../contexts/UserProvider';
/**
 * Hook for chat-related operations
 * @returns {Object} Chat operations
 */
export function useChat(roomId) {
    const { facebookClients, setFacebookClients, lineClients, setLineClients, agodaClients, setAgodaClients, airbnbClients, setAirbnbClients, setAgentClients } = useBound();
    const { user } = useUser();
    const [selectedPlatform, setSelectedPlatform] = useState('line')
    const [clients, setClients] = useState([])
    const [agodaSourceInfo, setAgodaSourceInfo] = useState(null)
    const [airbnbSourceInfo, setAirbnbSourceInfo] = useState(null)
    const [settings, setSettings] = useState({})
    const [loading, setLoading] = useState(false)

    const [agoda, setAgoda] = useState(null)
    const [airbnb, setAirbnb] = useState(null)

    // fetch clients
    const fetchFacebookClients = async (roomId) => {
        try {
            setLoading(true)
            const res = await BoundFacade.fetchMessengerClients(roomId);
            setFacebookClients(res);
            return res;
        } catch (error) {
            console.error('Error fetching Facebook clients:', error);
            return [];
        } finally {
            setLoading(false)
        }
    }
    const fetchLineClients = async (roomId) => {
        try {
            setLoading(true)
            const res = await BoundFacade.fetchLineClients(roomId);
            setLineClients(res);
            return res;
        } catch (error) {
            // console.error('Error fetching Line clients:', error);
            return [];
        } finally {
            setLoading(false)
        }
    }
    const fetchAgentClients = async (roomId) => {
        try {            
            setLoading(true);
            const res = await BoundFacade.fetchAgentClients(roomId);
            setAgentClients(res);
            return res;
        } catch (error) {
            console.error('Error fetching Agent clients:', error);
            return [];
        } finally {
            setLoading(false);
        }
    }

    // fetch messages
    const fetchLineMessages = async (roomId, socialMediaClientId, limit = 10, fromId = null) => {
        try {
            const res = await BoundFacade.fetchLineMessages(roomId, socialMediaClientId, limit, fromId);
            return res;
        } catch (error) {
            console.error('Error fetching new messages:', error);
            return [];
        }
    }
    const fetchFacebookMessages = async (roomId, socialMediaClientId, limit = 10, fromId = null) => {
        try {
            const res = await BoundFacade.fetchMessengerMessages(roomId, socialMediaClientId, limit, fromId);
            return res;
        } catch (error) {
            console.error('Error fetching Facebook messages:', error);
            return [];
        }
    }
    const fetchAgentMessages = async (roomId, socialMediaClientId, limit = 10, fromId = null) => {
        try {
            const res = await BoundFacade.fetchAgentMessages(roomId, socialMediaClientId, limit, fromId);
            return res;
        } catch (error) {
            console.error('Error fetching Agent messages:', error);
            return [];
        }
    }

    // send messages
    const sendLineMessage = async (roomId, socialMediaClientId, message) => {
        try {
            const res = await LineFacade.sendLineMessage(roomId, socialMediaClientId, message);
            return res;
        } catch (error) {
            console.error('Error sending Line message:', error);
            return [];
        }
    }
    const sendFacebookMessage = async (roomId, socialMediaClientId, message) => {
        try {
            const res = await FacebookFacade.sendFacebookMessage(roomId, socialMediaClientId, message);
            return res;
        } catch (error) {
            console.error('Error sending Facebook message:', error);
            return [];
        }
    }
    const sendAgentMessage = async (roomId, socialMediaClientId, message) => {
        try {
            const res = await BoundFacade.sendAgentMessage(roomId, socialMediaClientId, message);
            return res;
        } catch (error) {
            console.error('Error sending Agent message:', error);
            return [];
        }
    }






    // update webhook status
    const updateLineWebhookStatus = async (roomId, socialMediaClientId, useWebhook) => {
        try {
            const res = await LineFacade.updateLineWebhookStatus(roomId, socialMediaClientId, useWebhook);
            return res;
        } catch (error) {
            console.error('Error updating webhook status:', error);
            return [];
        }
    }
    const updateFacebookWebhookStatus = async (roomId, socialMediaClientId, useWebhook) => {
        try {
            const res = await FacebookFacade.updateFacebookWebhookStatus(roomId, socialMediaClientId, useWebhook);
            return res;
        } catch (error) {
            console.error('Error updating webhook status:', error);
            return [];
        }
    }
    const updateAgentWebhookStatus = async (roomId, socialMediaClientId, useWebhook) => {
        try {
            const res = await AgentFacade.updateAgentWebhookStatus(roomId, socialMediaClientId, useWebhook);
            return res;
        } catch (error) {
            console.error('Error updating webhook status:', error);
            return [];
        }
    }

    // get channel
    const getLineChannel = async (roomId) => {
        try {
            const res = await LineFacade.getLineChannel(roomId);
            return res;
        } catch (error) {
            console.error('Error getting Line channel information:', error);
            throw error;
        }
    }
    const getFacebookChannel = async (roomId) => {
        try {
            const res = await FacebookFacade.getFacebookChannel(roomId);
            return res;
        } catch (error) {
            console.error('Error getting Facebook channel information:', error);
            throw error;
        }
    }
    const getAgentChannel = async (roomId) => {
        try {
            const res = await AgentFacade.getAgentChannel(roomId);
            return res;
        } catch (error) {
            console.error('Error getting Agent channel information:', error);
            throw error;
        }
    }

    // create property
    const createLineProperty = async (roomId, lineProperty) => {
        try {
            const res = await LineFacade.createLineProperty(roomId, lineProperty);
            return res;
        } catch (error) {
            console.error('Error creating Line property:', error);
            throw error;
            // return [];
        }
    }
    const createFacebookProperty = async (roomId, facebookProperty) => {
        try {
            const res = await FacebookFacade.createFacebookProperty(roomId, facebookProperty);
            return res;
        } catch (error) {
            console.error('Error creating Facebook property:', error);
            throw error;
        }
    }
    const createAgentProperty = async (roomId, agentProperty) => {
        try {
            const res = await AgentFacade.createAgentProperty(roomId, agentProperty);
            return res;
        } catch (error) {
            console.error('Error creating Agent property:', error);
            throw error;
        }
    }

    // update chat settings
    const updateLineChatSettings = async (roomId, chatSettings) => {
        try {
            const res = await LineFacade.updateLineChatSettings(roomId, chatSettings);
            return res;
        } catch (error) {
            console.error('Error updating Line chat settings:', error);
            return [];
        }
    }
    const updateFacebookChatSettings = async (roomId, chatSettings) => {
        try {
            const res = await FacebookFacade.updateFacebookChatSettings(roomId, chatSettings);
            return res;
        } catch (error) {
            console.error('Error updating Facebook chat settings:', error);
            return [];
        }
    }
    const updateAgentChatSettings = async (roomId, chatSettings) => {
        try {
            const res = await AgentFacade.updateAgentChatSettings(roomId, chatSettings);
            return res;
        } catch (error) {
            console.error('Error updating Agent chat settings:', error);
            return [];
        }
    }

    // update base settings
    const updateLineBaseSettings = async (roomId, baseSettings) => {
        try {
            const res = await LineFacade.updateLineBaseSettings(roomId, baseSettings);
            return res;
        } catch (error) {
            console.error('Error updating Line base settings:', error);
            return [];
        }
    }
    const updateFacebookBaseSettings = async (roomId, baseSettings) => {
        try {
            const res = await FacebookFacade.updateFacebookBaseSettings(roomId, baseSettings);
            return res;
        } catch (error) {
            console.error('Error updating Facebook base settings:', error);
            return [];
        }
    }
    const updateAgentBaseSettings = async (roomId, baseSettings) => {
        try {
            const res = await AgentFacade.updateAgentBaseSettings(roomId, baseSettings);
            return res;
        } catch (error) {
            console.error('Error updating Agent base settings:', error);
            return [];
        }
    }

    // update nickname
    const updateLineNickname = async (roomId, socialMediaClientId, nickname) => {
        try {
            const res = await LineFacade.updateLineNickname(roomId, socialMediaClientId, nickname);
            await fetchLineClients(roomId);

            return res;
        } catch (error) {
            console.error('Error updating Line nickname:', error);
            return [];
        }
    }
    const updateFacebookNickname = async (roomId, socialMediaClientId, nickname) => {
        try {
            const res = await FacebookFacade.updateFacebookNickname(roomId, socialMediaClientId, nickname);
            await fetchFacebookClients(roomId);
            return res;
        } catch (error) {
            console.error('Error updating Facebook nickname:', error);
            return [];
        }
    }
    const updateAgentNickname = async (roomId, socialMediaClientId, nickname) => {
        try {
            const res = await AgentFacade.updateAgentNickname(roomId, socialMediaClientId, nickname);
            await fetchAgentClients(roomId);
            return res;
        } catch (error) {
            console.error('Error updating Agent nickname:', error);
            return [];
        }
    }

    // delete property
    const deleteLineProperty = async (roomId) => {
        try {
            const res = await LineFacade.deleteLineProperty(roomId);
            return res;
        } catch (error) {
            console.error('Error deleting Line property:', error);
            throw error;
        }
    }
    const deleteFacebookProperty = async (roomId) => {
        try {
            const res = await FacebookFacade.deleteFacebookProperty(roomId);
            return res;
        } catch (error) {
            console.error('Error deleting Facebook property:', error);
            throw error;
        }
    }
    const deleteAgentProperty = async (roomId) => {
        try {
            const res = await AgentFacade.deleteAgentProperty(roomId);
            return res;
        } catch (error) {
            console.error('Error deleting Agent property:', error);
            throw error;
        }
    }

    // add note
    const addLineNote = async (roomId, clientId, noteName, noteContent) => {
        try {
            const res = await LineFacade.addLineNote(roomId, clientId, noteName, noteContent);
            await fetchLineClients(roomId);
            return res;
        } catch (error) {
            console.error('Error adding note:', error);
            throw error;
        }
    }
    const addFacebookNote = async (roomId, clientId, noteName, noteContent) => {
        try {
            const res = await FacebookFacade.addFacebookNote(roomId, clientId, noteName, noteContent);
            await fetchFacebookClients(roomId);
            return res;
        } catch (error) {
            console.error('Error adding Facebook note:', error);
            throw error;
        }
    }
    const addAgentNote = async (roomId, clientId, noteName, noteContent) => {
        try {
            const res = await AgentFacade.addAgentNote(roomId, clientId, noteName, noteContent);
            await fetchAgentClients(roomId);
            return res;
        } catch (error) {
            console.error('Error adding Agent note:', error);
            throw error;
        }
    }

    // delete note
    const deleteLineNote = async (roomId, clientId, noteId) => {
        try {
            const res = await LineFacade.deleteLineNote(roomId, clientId, noteId);
            await fetchLineClients(roomId);
            return res;
        } catch (error) {
            console.error('Error deleting note:', error);
            throw error;
        }
    }
    const deleteFacebookNote = async (roomId, clientId, noteId) => {
        try {
            const res = await FacebookFacade.deleteFacebookNote(roomId, clientId, noteId);
            await fetchFacebookClients(roomId);
            return res;
        } catch (error) {
            console.error('Error deleting Facebook note:', error);
            throw error;
        }
    }
    const deleteAgentNote = async (roomId, clientId, noteId) => {
        try {
            const res = await AgentFacade.deleteAgentNote(roomId, clientId, noteId);
            await fetchAgentClients(roomId);
            return res;
        } catch (error) {
            console.error('Error deleting Agent note:', error);
            throw error;
        }
    }

    // update admin
    const updateLineAdmin = async (roomId, clientId, noteId, noteName, noteContent) => {
        try {
            const res = await LineFacade.updateLineAdmin(roomId, clientId, noteId, noteName, noteContent);
            return res;
        } catch (error) {
            console.error('Error updating admin note:', error);
            throw error;
        }
    }
    const updateFacebookAdmin = async (roomId, clientId, noteId, noteName, noteContent) => {
        try {
            const res = await FacebookFacade.updateFacebookAdmin(roomId, clientId, noteId, noteName, noteContent);
            return res;
        } catch (error) {
            console.error('Error updating Facebook admin:', error);
            throw error;
        }
    }
    const updateAgentAdmin = async (roomId, clientId, noteId, noteName, noteContent) => {
        try {
            const res = await AgentFacade.updateAgentAdmin(roomId, clientId, noteId, noteName, noteContent);
            return res;
        } catch (error) {
            console.error('Error updating Agent admin:', error);
            throw error;
        }
    }

    // add tag
    const addLineTag = async (roomId, clientId, tagName) => {
        try {
            const res = await LineFacade.addLineTag(roomId, clientId, tagName);
            await fetchLineClients(roomId);
            return res;
        } catch (error) {
            console.error('Error adding tag:', error);
            throw error;
        }
    }
    const addFacebookTag = async (roomId, clientId, tagName) => {
        try {
            const res = await FacebookFacade.addFacebookTag(roomId, clientId, tagName);
            await fetchFacebookClients(roomId);
            return res;
        } catch (error) {
            console.error('Error adding Facebook tag:', error);
            throw error;
        }
    }
    const addAgentTag = async (roomId, clientId, tagName) => {
        try {
            const res = await AgentFacade.addAgentTag(roomId, clientId, tagName);
            await fetchAgentClients(roomId);
            return res;
        } catch (error) {
            console.error('Error adding Agent tag:', error);
            throw error;
        }
    }

    // update note
    const updateLineNote = async (roomId, clientId, noteId, noteName, noteContent) => {
        try {
            const res = await LineFacade.updateLineNote(roomId, clientId, noteId, noteName, noteContent);
            return res;
        } catch (error) {
            console.error('Error updating note:', error);
            throw error;
        }
    }
    const updateFacebookNote = async (roomId, clientId, noteId, noteName, noteContent) => {
        try {
            const res = await FacebookFacade.updateFacebookNote(roomId, clientId, noteId, noteName, noteContent);
            await fetchFacebookClients(roomId);
            return res;
        } catch (error) {
            console.error('Error updating Facebook note:', error);
            throw error;
        }
    }
    const updateAgentNote = async (roomId, clientId, noteId, noteName, noteContent) => {
        try {
            const res = await AgentFacade.updateAgentNote(roomId, clientId, noteId, noteName, noteContent);
            await fetchAgentClients(roomId);
            return res;
        }
        catch (error) {
            console.error('Error updating Agent note:', error);
            throw error;
        }
    }

    // delete tag
    const deleteLineTag = async (roomId, clientId, tagId) => {
        try {
            const res = await LineFacade.deleteLineTag(roomId, clientId, tagId);
            await fetchLineClients(roomId);
            return res;
        } catch (error) {
            console.error('Error deleting tag:', error);
            throw error;
        }
    }
    const deleteFacebookTag = async (roomId, clientId, tagId) => {
        try {
            const res = await FacebookFacade.deleteFacebookTag(roomId, clientId, tagId);
            await fetchFacebookClients(roomId);
            return res;
        } catch (error) {
            console.error('Error deleting Facebook tag:', error);
            throw error;
        }
    }
    const deleteAgentTag = async (roomId, clientId, tagId) => {
        try {
            const res = await AgentFacade.deleteAgentTag(roomId, clientId, tagId);
            await fetchAgentClients(roomId);
            return res;
        } catch (error) {
            console.error('Error deleting Agent tag:', error);
            throw error;
        }
    }

    // fetch tag group
    const fetchLineTagGroup = async (roomId) => {
        try {
            const res = await LineFacade.fetchLineTagGroup(roomId);
            await fetchLineClients(roomId);
            return res;
        } catch (error) {
            console.error('Error fetching tag group:', error);
            throw error;
        }
    }
    const fetchFacebookTagGroup = async (roomId) => {
        try {
            const res = await FacebookFacade.fetchFacebookTagGroup(roomId);
            setFacebookClients(res);
            return res;
        } catch (error) {
            console.error('Error fetching Facebook tag group:', error);
            throw error;
        }
    }

    const fetchAgentTagGroup = async (roomId) => {
        try {
            const res = await AgentFacade.fetchAgentTagGroup(roomId);
            await fetchAgentClients(roomId);
            return res;
        } catch (error) {
            console.error('Error fetching Agent tag group:', error);
            throw error;
        }
    }



    






    const getAgodaChatFlow = async (roomId) => {
        try {
            setLoading(true);
            const res = await AgodaFacade.getAgodaChatFlow(roomId);


            if (res && res.agodaClients && res.agodaClients.length > 0) {
                console.log('res', res);
                setAgodaClients(res.agodaClients);
                setAgodaSourceInfo(res.sources);
                setAgoda(res.agoda);
            }

            return res;
        } catch (error) {
            console.error('Error fetching Agoda chat flow:', error);
            throw error;
        } finally {
            setLoading(false);
        }
    }

    const getAgodaProfile = async (roomId) => {
        try {
            const res = await AgodaFacade.getProfile(roomId);
            return res;
        } catch (error) {
            console.error('Error fetching Agoda profile:', error);
            throw error;
        }
    }

    const getAgodaSourceInfo = async (roomId) => {
        try {
            if (!roomId) {
                console.error('Error fetching Agoda source info: roomId is undefined');
                return null;
            }
            const res = await AgodaFacade.getSourceInfo(roomId);
            if (res) {
                setAgodaSourceInfo(res);
                return res;
            } else {
                console.error('Error fetching Agoda source info: Empty response');
                return null;
            }
        } catch (error) {
            console.error('Error fetching Agoda source info:', error);
            return null;
        }
    }

    const getAgodaConversationId = async (roomId) => {
        try {
            const res = await AgodaFacade.getAgodaConversationId(roomId);
            return res;
        } catch (error) {
            console.error('Error fetching Agoda conversation id:', error);
            throw error;
        }
    }

    const getAgodaBookingInfo = async (conversationId) => {
        try {
            const res = await AgodaFacade.getAgodaBookingInfo(conversationId);
            return res;
        } catch (error) {
            console.error('Error fetching Agoda booking info:', error);
            throw error;
        }
    }

    const getAgodaConversationMessages = async (conversationId) => {
        try {
            const res = await AgodaFacade.getAgodaConversationMessages(conversationId);
            console.log('getAgodaConversationMessages', res);
            return res || [];
        } catch (error) {
            console.error('Error fetching Agoda conversation messages:', error);
            return [];
        }
    }

    const getAirbnbChatFlow = async (roomId) => {
        try {
            setLoading(true);
            const res = await AirbnbFacade.getAirbnbChatFlow(roomId);


            if (res && res.airbnbClients && res.airbnbClients.length > 0) {
                console.log('res', res);
                setAirbnbClients(res.airbnbClients);
                setAirbnbSourceInfo(res.sources);
                setAirbnb(res.airbnb);
            }

            return res;
        } catch (error) {
            console.error('Error fetching Airbnb chat flow:', error);
            throw error;
        } finally {
            setLoading(false);
        }
    }

    const getAirbnbProfile = async (roomId) => {
        try {
            const res = await AirbnbFacade.getProfile(roomId);
            return res;
        } catch (error) {
            console.error('Error fetching Airbnb profile:', error);
            throw error;
        }
    }

    const getAirbnbUserInfo = async (roomId) => {
        try {
            const res = await AirbnbFacade.getUserInfo(roomId);
            return res;
        } catch (error) {
            console.error('Error fetching Airbnb user info:', error);
            throw error;
        }
    }

    const getAirbnbSourceInfo = async (roomId) => {
        try {
            if (!roomId) {
                console.error('Error fetching Airbnb source info: roomId is undefined');
                return null;
            }
            const res = await AirbnbFacade.getSourceInfo(roomId);
            if (res) {
                setAirbnbSourceInfo(res);
                return res;
            } else {
                console.error('Error fetching Airbnb source info: Empty response');
                return null;
            }
        } catch (error) {
            console.error('Error fetching Airbnb source info:', error);
            return null;
        }
    }

    const getAirbnbConversationId = async (roomId) => {
        try {
            const res = await AirbnbFacade.getConversationId(roomId);
            return res;
        } catch (error) {
            console.error('Error fetching Airbnb conversation id:', error);
            throw error;
        }
    }

    const getAirbnbBookingInfo = async (conversationId) => {
        try {
            const res = await AirbnbFacade.getAirbnbBookingInfo(conversationId);
            console.log('999999999999999', res);
            return res;
        } catch (error) {
            console.error('Error fetching Airbnb booking info:', error);
            throw error;
        }
    }

    const getAirbnbConversationMessages = async (conversationId, displayName) => {
        try {
            const res = await AirbnbFacade.getAirbnbConversationMessages(conversationId, displayName);
            return res || [];
        } catch (error) {
            console.error('Error fetching Airbnb conversation messages:', error);
            return [];
        }
    }

    const sendAgodaMessage = async (profileId, sourceId, conversationId, messageType, message, file) => {
        try {
            const res = await AgodaFacade.sendAgodaMessage(profileId, sourceId, conversationId, messageType, message, file);
            return res;
        } catch (error) {
            console.error('Error sending Agoda message:', error);
            throw error;
        }
    }

    const sendAirbnbMessage = async (profileId, sourceId, conversationId, messageType, message, file) => {
        try {
            const res = await AirbnbFacade.sendAirbnbMessage(profileId, sourceId, conversationId, messageType, message, file);
            return res;
        } catch (error) {
            console.error('Error sending Airbnb message:', error);
            throw error;
        }
    }

    useEffect(() => {
        const fetchChats = async () => {
            try {
                if (selectedPlatform === 'facebook') {
                    const res = await fetchFacebookClients(roomId);
                    setClients(res);
                } else if (selectedPlatform === 'line') {
                    const res = await fetchLineClients(roomId);
                    setClients(res);
                } else if (selectedPlatform === 'teamsync') {
                    const res = await fetchAgentClients(roomId);
                    setClients(res);
                } else if (selectedPlatform === 'agoda') {
                    const res = await getAgodaChatFlow(roomId);
                    setClients(res.agodaClients);
                } else if (selectedPlatform === 'airbnb') {
                    if (selectedPlatform === 'airbnb' && user.username !== 'rpa-user.stag') return;
                    const res = await getAirbnbChatFlow(roomId);
                    setClients(res.airbnbClients);
                }
            } catch (err) {
                console.error('Failed to load chats');
            }
        };
        fetchChats();
    }, [roomId, selectedPlatform]);

    const hookReturn = {
        // common
        selectedPlatform,
        setSelectedPlatform,
        clients,
        setClients,
        agodaSourceInfo,
        setAgodaSourceInfo,
        loading,
        settings,
        setSettings,

        // fetch clients
        fetchFacebookClients,
        fetchLineClients,

        // fetch messages
        fetchLineMessages,
        fetchFacebookMessages,

        // send messages
        sendLineMessage,
        sendFacebookMessage,
        sendAgentMessage,

        // settings

        // facebook
        getFacebookChannel,
        updateFacebookChatSettings,
        createFacebookProperty,
        deleteFacebookProperty,
        updateFacebookBaseSettings,
        updateFacebookWebhookStatus,
        updateFacebookNickname,
        addFacebookNote,
        updateFacebookNote,
        deleteFacebookNote,
        addFacebookTag,
        deleteFacebookTag,
        fetchFacebookTagGroup,
        updateFacebookAdmin,

        // line
        getLineChannel,
        updateLineChatSettings,
        createLineProperty,
        deleteLineProperty,
        updateLineBaseSettings,
        updateLineNickname,
        updateLineWebhookStatus,
        addLineNote,
        updateLineNote,
        deleteLineNote,
        addLineTag,
        deleteLineTag,
        fetchLineTagGroup,
        updateLineAdmin,

        // agent
        fetchAgentClients,
        fetchAgentMessages,
        updateAgentChatSettings,
        updateAgentBaseSettings,
        updateAgentNickname,
        updateAgentWebhookStatus,
        addAgentNote,
        updateAgentNote,
        deleteAgentNote,
        addAgentTag,
        deleteAgentTag,
        fetchAgentTagGroup,
        updateAgentAdmin,
        deleteAgentProperty,
        createAgentProperty,
        updateAgentChatSettings,
        updateAgentBaseSettings,
        updateAgentNickname,
        updateAgentWebhookStatus,
        getAgentChannel,
        updateAgentChatSettings,
        updateAgentBaseSettings,
        updateAgentNickname,
        updateAgentWebhookStatus,

        // agoda
        getAgodaChatFlow,
        getAgodaProfile,
        getAgodaSourceInfo,
        getAgodaConversationId,
        getAgodaBookingInfo,
        getAgodaConversationMessages,
        sendAgodaMessage,
        // airbnb
        getAirbnbChatFlow,
        getAirbnbProfile,
        getAirbnbUserInfo,
        getAirbnbSourceInfo,
        getAirbnbConversationId,
        getAirbnbBookingInfo,
        getAirbnbConversationMessages,
        sendAirbnbMessage,
        airbnbSourceInfo,
        // state
        facebookClients,
        lineClients,
        agodaClients,
        airbnbClients,
    };

    return hookReturn;
}