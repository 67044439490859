import React, { useState, useEffect } from "react";
import mqtt from "mqtt";
import { useAuth } from "../../../../contexts/AuthProvider";
import { useBound } from "../../../../contexts/BoundContext";
import BoundList from "./BoundList";
import BoundChatRoom from "./BoundChatRoom";
import BoundSetting from "./BoundSetting";
// import { fetchLineClients } from "./platforms/line";
import { LineChannelFacade } from "../../../../application/facades/LineChannelFacade";
// import { fetchAgodaHistory } from "./platforms/agoda";
import { MQTT_URL, MQTT_PORT } from "../../../../constants/constants";

const BoundInterface = ({ room, switchTo }) => {
  const { userId, token } = useAuth();
  const {
    setListLoading,
    fetchAgodaHistory,
    setAgodaMessage,
  } = useBound();

  const [clients, setClients] = useState([]);
  // const [clientsAirbnb, setClientsAirbnb] = useState([]);
  const [clientsAgoda, setClientsAgoda] = useState([]);

  // const [channelType, setChannelType] = useState('agoda');
  // const [chatType] = useState('ai');
  const [activeId, setActiveId] = useState('');
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [mqttConnection, setMqttConnection] = useState(false);
  const [activeClient, setActiveClient] = useState(null);
  const [newMessageClientList, setNewMessageClientList] = useState([]);
  const roomId = room.id;

  const tabList = [
    {
      key: 0,
      label: "Agoda",
    },
    {
      key: 1,
      label: "Line",
    },
  ];

  useEffect(() => {
    setMqttConnection(false);
    setActiveId("");
    setActiveClient(null);
    setNewMessageClientList([]);
  }, [room]);

  useEffect(() => {
    setActiveId("");
    setActiveClient(null);
    setClientsAgoda([]);

    if (activeTab === 0) {
      // 初始加載
      setAgodaMessage([]);
      fetchAgodaHistory();

      // 設置30秒定時器
      const intervalId = setInterval(() => {
        setAgodaMessage([]);
        fetchAgodaHistory();
      }, 30000);

      // 清理定時器
      return () => clearInterval(intervalId);
    } else if (activeTab === 1) {
      const FetchLine = async () => {
        try {
          setListLoading(true);
          const res = await LineChannelFacade.fetchLineClients(room.id);
          setClients(res);
        } catch (error) {
          console.error('Error fetching Line channel:', error);
        } finally {
          setListLoading(false);
        }
      }
      FetchLine();
    }
  }, [activeTab]);

  // Set active client when activeId changes
  useEffect(() => {
    const sourceClients =
      activeTab === 0
        ? clientsAgoda
        : activeTab === 1
          ? clients
          : '';
    setActiveClient(sourceClients.find((client) => client.userId === activeId));
    setNewMessageClientList((prevList) =>
      prevList.filter((clientId) => clientId !== activeId)
    );
  }, [activeId, activeTab, clients, clientsAgoda]);

  // MQTT connect
  useEffect(() => {
    // Establish connection with the MQTT broker
    const mqttClient = mqtt.connect(MQTT_URL, {
      clientId: 'mqttx_' + Math.random().toString(16).substring(2, 10),
      port: MQTT_PORT,
      path: '/',
      connectTimeout: 10000,
      keepAlive: 30000,
      autoReconnect: true,
      reconnectPeriod: 1000,
      cleanStart: false,
    });

    mqttClient.on('error', (error) => {
      // console.error('Connection error:', error);
      setMqttConnection(false);
    });

    mqttClient.on('connect', () => {
      mqttClient.subscribe(`chatroom/${room.id}/line/#`, (error) => {
        if (error) {
          // console.error('Subscription error:', error);
          setMqttConnection(false);
        } else {
          // console.log('Subscription successful');
          setMqttConnection(true);
        }
      });
    });

    mqttClient.on('message', (topic, payload) => {
      const receivedClientId = topic.split('/')[3].toString();
      // console.log('INTERFACE | Message received:', receivedClientId, "content", payload.toString());
      // console.log('INTERFACE | Active clients:', clients);

      // filter new client
      // const newClient = clients.find(client => client.userId === receivedClientId);
      // console.log('the new client', newClient)

      // unfocused client got message
      if (receivedClientId !== activeId) {
        setNewMessageClientList((prevList) => [...prevList, receivedClientId]);
      }
      // new client or message
      const FetchLine = async () => {
        try {
          const res = await LineChannelFacade.fetchLineClients(room.id);
          console.log('res', res);
          setClients(res);
        } catch (error) {
          console.error('Error fetching Line channel:', error);
        }
      }
      FetchLine();
      
    });
    // Clean up the connection when the component unmounts
    return () => mqttClient.end();
  }, [clients, activeId]);

  // Change tab
  const onChangeTab = (key) => {
    setActiveTab(key);
    // setChannelType(key === 0 ? 'agoda' : key === 1 ? 'line' : '');
  };

  // 新增更新客戶資料的函數
  const updateClientData = async () => {
    if (activeTab === 1) { // Line
      try {
        const res = await LineChannelFacade.fetchLineClients(room.id);
        
        // 找到當前活動的客戶
        const updatedActiveClient = res.find(client => client.userId === activeId);
        
        // 只更新當前活動客戶的資料
        if (updatedActiveClient) {
          setActiveClient(prevClient => ({
            ...prevClient,
            notes: updatedActiveClient.notes,
            tags: updatedActiveClient.tags
          }));
          
          // 更新 clients 列表中的對應客戶資料
          setClients(prevClients => 
            prevClients.map(client => 
              client.userId === activeId 
                ? { ...client, notes: updatedActiveClient.notes, tags: updatedActiveClient.tags }
                : client
            )
          );
        }
      } catch (error) {
        console.error('Error updating Line client data:', error);
      }
    }
  };

  return (
    <div className="w-full flex flex-row">
      <BoundList
        activeId={activeId}
        setActiveId={setActiveId}
        tabs={tabList}
        activeTab={activeTab}
        onChangeTab={onChangeTab}
        switchTo={switchTo}
        mqttConnection={mqttConnection}
        newMessageClientList={newMessageClientList}
        setLoading={setLoading}
        clients={activeTab === 0 ? clientsAgoda : activeTab === 1 ? clients : ''}
        // setClientsAirbnb={setClientsAirbnb}
        setClientsAgoda={setClientsAgoda}
        setClients={setClients}
        loading={loading}
        roomId={roomId}
        clientsAgoda={clientsAgoda}
        room={room}
      />
      {activeClient ? (
        <>
          <BoundChatRoom
            userId={userId}
            token={token}
            room={room}
            client={activeClient}
            tabs={activeTab}
            activeId={activeId}
          />
          <BoundSetting
            room={room}
            token={token}
            clientId={activeClient.userId}
            tabs={activeTab}
            client={activeClient}
            updateClientData={updateClientData}
          />
        </>
      ) : (
        <div className="flex flex-row items-center justify-center w-full h-full text-2xl font-bold text-textLight">
          請選擇第三方紀錄
        </div>
      )}
    </div>
  );
};

export default BoundInterface;
