import React, { useState, useEffect } from "react";
import { Select, Input, DatePicker, Checkbox, Form, TimePicker } from "antd";
import "tailwindcss/tailwind.css";

const { Option } = Select;
const { RangePicker } = DatePicker;

// Define ProductPublish component
const ProductPublish = ({ form }) => {
  const [isUnlimited, setIsUnlimited] = useState(false);

  return (
    <>
      {/* recommended product section */}
      <div className="bg-gray-50 rounded-lg mb-6">
        <div className="grid grid-cols-2 gap-8">
          <Form.Item
            label="推薦商品"
            name="recommendedProduct"
            className="mb-0"
          >
            <Select placeholder="Select Category">
              <Option value="熱門">熱門</Option>
              <Option value="新品">新品</Option>
              <Option value="促銷">促銷</Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Display Text"
            name="displayText"
            className="mb-0"
          >
            <Input placeholder="Popular" disabled/>
          </Form.Item>
        </div>

        {/* expiration section */}
        <div className="mt-6">
          <div className="flex items-center mb-4">
            <span className="mr-4">期限</span>
            <Form.Item name="isUnlimited" className="mb-0">
              <Checkbox
                checked={isUnlimited}
                onChange={(e) => setIsUnlimited(e.target.checked)}
                disabled
              >
                No expiration
              </Checkbox>
            </Form.Item>
          </div>

          {!isUnlimited && (
            <Form.Item name="dateRange" className="mb-0">
              <RangePicker
                placeholder={['Start Date', 'End Date']}
                separator="至"
                disabled
              />
            </Form.Item>
          )}
        </div>

        {/* schedule section */}
        <div className="mt-6">
          <div className="mb-4">排程上架</div>
          <div className="grid grid-cols-2 gap-8">
            <Form.Item
              label="日期"
              name="scheduleDate"
              className="mb-0"
            >
              <DatePicker disabled/>
            </Form.Item>

            <Form.Item
              label="時間"
              name="scheduleTime"
              className="mb-0"
            >
              <TimePicker disabled/>
            </Form.Item>
          </div>
        </div>
      </div>

    </>
  );
};

export default ProductPublish;
