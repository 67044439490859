import React, { useContext } from 'react';
import { ProductContext } from '../../context/ProductContext';

const ProductStatistics = () => {
  const { products } = useContext(ProductContext);

  const stats = products.reduce(
    (acc, product) => {
      if (product.status === 'On Sale') {
        acc.availableProducts++;
      }
      if (parseInt(product.remaining) < 20 && product.remaining !== '0') {
        acc.lowStockWarnings++;
      }
      if (parseFloat(product.memberDiscount) > 0) {
        acc.onPromotion++;
      }
      if (product.status === '草稿夾') {
        acc.drafts++;
      }
      return acc;
    },
    {
      availableProducts: 0,
      lowStockWarnings: 0,
      onPromotion: 0,
      drafts: 0,
    }
  );

  const totalProducts = products.length;
  const { availableProducts, lowStockWarnings, onPromotion, drafts } = stats;

  return (
    <div className="grid grid-cols-5 gap-4 mb-4">
      <div className="col-span-2 bg-[#F3F3F3] rounded-lg shadow">
        <div className="h-[36px] pt-2 pl-2">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M16.8492 12.11C16.8492 15.0917 15.0917 16.8492 12.11 16.8492H5.625C2.63583 16.8492 0.875 15.0917 0.875 12.11V5.61C0.875 2.6325 1.97 0.875 4.9525 0.875H6.61917C7.2175 0.875833 7.78083 1.15667 8.13917 1.63583L8.9 2.6475C9.26 3.12583 9.82333 3.4075 10.4217 3.40833H12.78C15.7692 3.40833 16.8725 4.93 16.8725 7.9725L16.8492 12.11Z"
              stroke="#3E3D3D"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path d="M5.23438 11.0524H12.5135" stroke="#3E3D3D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </div>
        <div className="grid grid-cols-2 gap-2">
          <div className="p-2 text-start rounded-lg">
            <div className="text-sm">總商品數</div>
            <div className="text-2xl font-bold">{totalProducts}</div>
          </div>
          <div className="p-2 text-start rounded-lg">
            <div className="text-sm">可用商品數</div>
            <div className="text-2xl font-bold">{availableProducts}</div>
          </div>
        </div>
      </div>

      <div className="col-span-3 p-1 rounded-lg">
        <div className="bg-[#FFCC9129] rounded-lg w-[36px] h-[36px] inline-flex items-center justify-center">
          <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.49268 11.6724C9.56685 11.6724 12.1943 12.1382 12.1943 13.999C12.1943 15.8599 9.58435 16.339 6.49268 16.339C3.41768 16.339 0.791016 15.8774 0.791016 14.0157C0.791016 12.154 3.40018 11.6724 6.49268 11.6724Z"
              stroke="rgb(19, 15, 38)"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.49289 9.01634C4.47456 9.01634 2.83789 7.38051 2.83789 5.36217C2.83789 3.34384 4.47456 1.70801 6.49289 1.70801C8.51039 1.70801 10.1471 3.34384 10.1471 5.36217C10.1546 7.37301 8.52956 9.00884 6.51872 9.01634H6.49289Z"
              stroke="rgb(19, 15, 38)"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12.2358 8.0679C13.57 7.8804 14.5975 6.7354 14.6 5.34956C14.6 3.98373 13.6042 2.8504 12.2983 2.63623"
              stroke="rgb(19, 15, 38)"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13.9961 11.2769C15.2886 11.4694 16.1911 11.9227 16.1911 12.856C16.1911 13.4985 15.7661 13.9152 15.0794 14.176"
              stroke="rgb(19, 15, 38)"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div className="grid grid-cols-3 gap-2">
          <div className="p-2 text-start rounded-lg">
            <div className="text-red-500 text-sm">低庫存警告</div>
            <div className="text-2xl font-bold">{lowStockWarnings}</div>
          </div>
          <div className="p-2 text-start rounded-lg">
            <div className="text-sm">優惠中</div>
            <div className="text-2xl font-bold">{onPromotion}</div>
          </div>
          <div className="p-2 text-start rounded-lg">
            <div className="text-sm">草稿數量</div>
            <div className="text-2xl font-bold">{drafts}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductStatistics;
