import { LineRepository } from "../../infrastructure/repositories/LineRepository";

export const LineUseCase = {
  fetchLineClients: async (roomId) => {
    return await LineRepository.fetchLineClients(roomId);
  },
  fetchLineMessages: async (roomId, socialMediaClientId, limit = 10, fromId = null) => {
    return await LineRepository.fetchLineMessages(roomId, socialMediaClientId, limit, fromId);
  },
  sendLineMessage: async (roomId, socialMediaClientId, message) => {
    return await LineRepository.sendLineMessage(roomId, socialMediaClientId, message);
  },

  // get
  getLineChannel: async (roomId) => {
    return await LineRepository.getLineChannel(roomId);
  },

  // create
  createLineProperty: async (roomId, lineProperty) => {
    return await LineRepository.createLineProperty(roomId, lineProperty);
  },

  // update
  updateLineWebhookStatus: async (roomId, socialMediaClientId, useWebhook) => {
    return await LineRepository.updateLineWebhookStatus(roomId, socialMediaClientId, useWebhook);
  },
  updateLineChatSettings: async (roomId, chatSettings) => {
    return await LineRepository.updateLineChatSettings(roomId, chatSettings);
  },
  updateLineBaseSettings: async (roomId, baseSettings) => {
    return await LineRepository.updateLineBaseSettings(roomId, baseSettings);
  },
  updateLineNickname: async (roomId, socialMediaClientId, nickname) => {
    return await LineRepository.updateLineNickname(roomId, socialMediaClientId, nickname);
  },

  // delete
  deleteLineProperty: async (roomId) => {
    return await LineRepository.deleteLineProperty(roomId);
  },
  addLineNote: async (roomId, clientId, noteName, noteContent) => {
    return await LineRepository.addLineNote(roomId, clientId, noteName, noteContent);
  },
  updateLineNote: async (roomId, clientId, noteId, noteName, noteContent) => {
    return await LineRepository.updateLineNote(roomId, clientId, noteId, noteName, noteContent);
  },
  updateLineAdmin: async (roomId, clientId, noteId, noteName, noteContent) => {
    return await LineRepository.updateLineAdmin(roomId, clientId, noteId, noteName, noteContent);
  },
  deleteLineNote: async (roomId, clientId, noteId) => {
    return await LineRepository.deleteLineNote(roomId, clientId, noteId);
  },
  addLineTag: async (roomId, clientId, tagName) => {
    return await LineRepository.addLineTag(roomId, clientId, tagName);
  },
  deleteLineTag: async (roomId, clientId, tagId) => {
    return await LineRepository.deleteLineTag(roomId, clientId, tagId);
  },
  fetchLineTagGroup: async (roomId) => {
    return await LineRepository.fetchLineTagGroup(roomId);
  }
};