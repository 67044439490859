import React, { useState, useEffect, useCallback } from 'react'
import { Select, Tooltip } from 'antd'
import BoundListItem from './BoundListItem'
import { LoadingOutlined, SwapOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const BoundList = ({ clients, activeId, setActiveId, loading, platformOptions, selectedPlatform, onPlatformChange, fetchClients, switchTo, mqttConnection, newMessageClientList, agodaSourceInfo, airbnbSourceInfo }) => {
  const [chatRoomType, setChatRoomType] = useState('all');
  const [roomListCollapsed, setRoomListCollapsed] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [sources, setSources] = useState([]);

  useEffect(() => {
    console.log('selectedPlatform', selectedPlatform);
    console.log('clients', clients);
  }, [selectedPlatform, clients]);

  // 當平台為 agoda 時，將 agodaSourceInfo 加入 sources 狀態
  useEffect(() => {
    setSources([]);
    if (selectedPlatform === 'agoda' && agodaSourceInfo && agodaSourceInfo.length > 0) {
      setSources(agodaSourceInfo);
    } else if (selectedPlatform === 'airbnb' && airbnbSourceInfo && airbnbSourceInfo.length > 0) {
      setSources(airbnbSourceInfo);
    } else {
      setSources([]);
    }
  }, [selectedPlatform, agodaSourceInfo, airbnbSourceInfo]);

  const handleChatRoomTypeChange = (value) => {
    setChatRoomType(value);
    // 這裡可以加入過濾聊天室的邏輯
  };

  const handleRoomSelect = useCallback((room) => {
    setSelectedRoom(room);
    // 如果這個房源有客戶ID，可以直接設置為活躍ID
    if (room && room.userId) {
      setActiveId(room.userId);
    }
  }, [setActiveId]);

  // 當平台切換時重置並選中第一個房源
  useEffect(() => {
    if ((selectedPlatform === 'agoda' || selectedPlatform === 'airbnb') && sources && sources.length > 0) {
      handleRoomSelect(sources[0]);
    } else {
      setSelectedRoom(null);
    }
  }, [selectedPlatform, sources, handleRoomSelect]);

  const toggleRoomList = () => {
    setRoomListCollapsed(!roomListCollapsed);
  };

  return (
    <div className="flex flex-col min-w-72 max-w-72 bg-bgLight px-2 pt-6">
      <div className="flex flex-row items-center justify-between text-2xl font-bold mb-2 text-textDark ml-1">
        <div>
          第三方紀錄
          <Tooltip title="切換至語音模組">
            <SwapOutlined
              className="hover:text-primary ml-2"
              style={{ width: "12px", height: "12px" }}
              onClick={() => switchTo('asr')}
            />
          </Tooltip>
        </div>
        {/* <ReloadOutlined
          className="hover:text-primary ml-2"
          style={{ width: "12px", height: "12px" }}
          onClick={fetchClients}
        /> */}
        {
          mqttConnection ?
            <Tooltip title="連線穩定">
              <div className="flex flex-row gap-1 ml-2 bg-green-600 w-2 h-2 rounded-full" />
            </Tooltip>
            :
            <Tooltip title="重新連線中...點擊以重整">
              <div className="flex flex-row gap-1 ml-2 bg-yellow-600 w-2 h-2 rounded-full animate-ping" onClick={fetchClients} />
            </Tooltip>
        }
      </div>

      <div className="flex flex-row gap-2">
        <div className="px-2 mb-4 w-full">
          <Select
            className="w-[110px]"
            value={selectedPlatform}
            onChange={onPlatformChange}
            options={platformOptions}
            placeholder="選擇平台"
            dropdownStyle={{ zIndex: 1001 }}
            bordered={false}
          />
        </div>
        <div className="px-2 mb-4">
          <Select
            className="w-[140px]"
            placeholder="所有聊天室"
            value={chatRoomType}
            onChange={handleChatRoomTypeChange}
            options={[
              { label: '全部聊天室', value: 'all' },
              { label: '轉真人聊天室', value: 'service' },
            ]}
            dropdownStyle={{ zIndex: 1001 }}
            bordered={false}
          />
        </div>
      </div>

      {
        (selectedPlatform === 'agoda' || selectedPlatform === 'airbnb') &&
        <div className="flex flex-col overflow-hidden mb-6 border-b border-gray-200">
          <div 
            className="bg-gray-100 py-2 px-4 text-sm font-semibold text-gray-700 flex justify-between items-center cursor-pointer"
            onClick={toggleRoomList}
          >
            房源
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className={`bi ${roomListCollapsed ? 'bi-chevron-down' : 'bi-chevron-up'}`} viewBox="0 0 16 16">
              {roomListCollapsed ? (
                <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
              ) : (
                <path fillRule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/>
              )}
            </svg>
          </div>
          {!roomListCollapsed ? (
            <div className="flex flex-col overflow-y-auto h-[300px] overflow-x-hidden pb-4">
              {sources?.map((room, index) => (
                <div
                  key={room.id}
                  className={`flex flex-row items-center gap-2 px-4 py-3 cursor-pointer transition-all duration-200 ${selectedRoom?.source_id === room.source_id ? 'bg-primary/20 border-l-4 border-primary' : 'border-l-4 border-transparent'} hover:bg-gray-100`}
                  onClick={() => handleRoomSelect(room)}
                >
                  <div className="flex-shrink-0">
                    <div className={`w-10 h-10 rounded-lg ${selectedRoom?.source_id === room.source_id ? 'bg-primary/30' : 'bg-primary/10'} flex items-center justify-center relative`}>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-6 h-6 ${selectedRoom?.source_id === room.source_id ? 'text-primary' : 'text-primary'}`}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                      </svg>
                      {selectedRoom?.source_id === room.source_id && (
                        <div className="absolute -top-1 -right-1 w-3 h-3 bg-primary rounded-full"></div>
                      )}
                    </div>
                  </div>
                  <div className="flex-1 min-w-0">
                    <div className="flex items-center justify-between">
                      <p className={`text-sm ${selectedRoom?.source_id === room.source_id ? 'font-semibold text-primary' : 'font-medium text-gray-900'} truncate`}>
                        { room.source_name || ""}
                      </p>
                      {newMessageClientList.includes(room.userId) && (
                        <span className="inline-flex items-center rounded-full bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
                          新訊息
                        </span>
                      )}
                    </div>
                    <div className="flex items-center gap-2">
                      <p className="text-sm text-gray-500 truncate">
                        { room.lastMessage || ""}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="h-4"></div>
          )}
        </div>
      }

      {
        loading ?
          <div className="flex justify-center items-center w-full h-screen">
            <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
          </div>
          :
          
          (clients.length === 0 ?
            <div className="w-full flex flex-col gap-4 items-center justify-center pt-12">
              這裡沒有任何第三方紀錄。
            </div>
            :
            <div className="flex flex-col overflow-hidden mb-6 border-b border-gray-200">
          { selectedPlatform === 'airbnb' && (
            <div 
              className="bg-gray-100 py-2 px-4 text-sm font-semibold text-gray-700 flex justify-between items-center cursor-pointer"
            >
              房客
            </div>
          )}
          { selectedPlatform === 'agoda' && (
            <div 
              className="bg-gray-100 py-2 px-4 text-sm font-semibold text-gray-700 flex justify-between items-center cursor-pointer"
            >
              房客
            </div>
          )}
            <div className={`flex flex-col overflow-y-auto flex-grow h-full ${selectedPlatform === 'agoda' || selectedPlatform === 'airbnb' ? 'max-h-[calc(70vh-180px)]' : ''} overflow-x-hidden`}>
              {Array.isArray(clients) ? clients
                .sort((a, b) => {
                  // 確保 last_two_messages 存在且有元素
                  if (!a.last_two_messages?.[0]?.created_at) return 1;
                  if (!b.last_two_messages?.[0]?.created_at) return -1;
                  return new Date(b.last_two_messages[0].created_at) - new Date(a.last_two_messages[0].created_at);
                })
                .filter(client => {
                  // 根據選擇的聊天室類型過濾客戶列表
                  if (chatRoomType === 'all') return true;
                  if (chatRoomType === 'service') return client.is_service_chat === true;
                  return true;
                })
                .filter(client => {
                  // console.log('client', client);

                  
                  // 如果是 agoda 模式且有選中房源，則只顯示對應房源的客戶
                  if (selectedPlatform === 'agoda' && selectedRoom) {
                    return client.roomId === selectedRoom.id;
                  }
                  if (selectedPlatform === 'airbnb' && selectedRoom) {
                    // console.log('client.roomId', client.roomId);
                    // console.log('selectedRoom.id', selectedRoom.id);
                    return client.roomId === selectedRoom.id;
                  }
                  return true;
                })
                .map((client) => (
                  <BoundListItem
                    key={client.id}
                    client={client}
                    activeId={activeId}
                    setActiveId={setActiveId}
                    newMessage={newMessageClientList.includes(client.userId)}
                  />
                )) : <div className="text-center py-4">無法載入資料</div>}
            </div>
          </div>
          )
      }
    </div>
  )
}

export default BoundList