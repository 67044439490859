import React, { useContext, useState } from 'react';
import { Table, Input, Button, Select } from 'antd';
import { SearchOutlined, PlusOutlined } from '@ant-design/icons';
import { ProductContext } from '../../context/ProductContext';
import { productColumns } from '@/app/cms/data/productColumns';

const ProductTable = () => {
  const { products, setShowOrderManagement, setSelectedProduct, selectedProduct } = useContext(ProductContext);
  const [searchText, setSearchText] = useState('');
  const [selectedProvider, setSelectedProvider] = useState('all');
  const [selectedStatus, setSelectedStatus] = useState('all');
  const [tableLoading, setTableLoading] = useState(false);

  const filteredProducts = products
    .filter((product) => {
      return (
        (searchText === '' || product.name.toLowerCase().includes(searchText.toLowerCase())) &&
        (selectedProvider === 'all' || product.provider === selectedProvider) &&
        (selectedStatus === 'all' || product.status === selectedStatus)
      );
    })
    .sort((a, b) => {
      if (a.recommendation && b.recommendation) {
        return a.recommendation - b.recommendation;
      }
      if (a.recommendation) return -1;
      if (b.recommendation) return 1;
      return 0;
    });

  const providerOptions = [
    { value: 'all', label: '全部商家' },
    ...Array.from(new Set(products.map((product) => product.provider)))
      .filter(Boolean)
      .map((provider) => ({ value: provider, label: provider })),
  ];

  const statusOptions = [
    { value: 'all', label: '全部狀態' },
    { value: 'On Sale', label: 'On Sale' },
    { value: '下架中', label: '下架中' },
  ];

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handleProviderChange = (value) => {
    setSelectedProvider(value);
  };

  const handleStatusChange = (value) => {
    setSelectedStatus(value);
  };



  return (
    <div className="flex flex-col w-full h-full">
      
      <div className="flex space-x-4 mb-4">
        <Input
          className="w-80"
          placeholder="搜尋"
          prefix={<SearchOutlined />}
          onChange={handleSearch}
        />
        <Select
          className="w-40"
          placeholder="上架商家"
          options={providerOptions}
          onChange={handleProviderChange}
          allowClear
          value={selectedProvider}
        />
        <Select
          className="w-40"
          placeholder="狀態"
          options={statusOptions}
          onChange={handleStatusChange}
          allowClear
          value={selectedStatus}
        />
      </div>
      <div className="flex-1 overflow-auto">
        <Table
          loading={tableLoading}
          columns={productColumns({
            setShowOrderManagement,
            setSelectedProduct,
            products,
          })}
          dataSource={filteredProducts}
          pagination={{
            pageSize: 10,
            className: "sticky bottom-0 pb-2",
          }}
          onRow={(record) => ({
            onClick: () => setSelectedProduct(record),
            className: record.key === (selectedProduct?.key ?? '') ? "bg-gray-100" : "",
          })}
          rowClassName={(record) => `cursor-pointer ${record.key === (selectedProduct?.key ?? '') ? "bg-gray-100" : ""}`}
          scroll={{ x: 1200 }}
          size="small"
          className="[&_.ant-table-thead>tr>th]:bg-[#E0F0E0]"
        />
      </div>
    </div>
  );
};

export default ProductTable;
