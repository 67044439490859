import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios'
import { useAuth } from '../../contexts/AuthProvider'
import ChatList from './components/list/ChatList'
import ChatInterface from './components/ChatInterface'
import CreateChat from './components/list/CreateChat'
import JoinChat from './components/list/JoinChat'
import { message, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import ToolBar from './components/ToolBar';
import ChatRoomFacade from '../../application/facades/ChatRoomFacade';
const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const Chat = () => {
  let { roomId } = useParams();
  const { userId, token } = useAuth();
  const navigate = useNavigate()
  const [chatList, setChatList] = useState([])
  const [activeRoomId, setActiveRoomId] = useState(null)
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  const [isJoinModalOpen, setIsJoinModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [currentRoom, setCurrentRoom] = useState(null)
  const [isRoomLoading, setIsRoomLoading] = useState(false)
  useEffect(() => {
    fetchRooms()
  }, [userId])
  const fetchRooms = async () => {
    setLoading(true)
    try {
      const userResponse = await axios.get(SERVER_URL + '/private/user/me', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const idList = userResponse.data.joined_rooms;
      let allChatRooms = [];

      // 獲取部門列表
      const departmentsResponse = await axios.get(`${SERVER_URL}/private/company/departments/`, {
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      // 並行獲取所有部門的聊天室
      const departments = departmentsResponse.data;
      const roomPromises = departments.map(dept => 
        axios.get(`${SERVER_URL}/private/chatrooms/list/department/${dept.id}`, {
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        })
        .then(response => response.data)
        .catch(error => {
          console.error(`Error fetching rooms for department ${dept.id}:`, error);
          return [];
        })
      );
      
      // 同時獲取群組聊天室
      const groupChatPromise = axios.get(SERVER_URL + '/private/chatrooms/list/group/my', {
        headers: { 
          Accept: 'application/json', 
          Authorization: `Bearer ${token}` 
        }
      })
      .then(response => response.data.flatMap(group => group.chatrooms))
      .catch(error => {
        console.error(`Error fetching group chatrooms:`, error);
        return [];
      });
      
      // 等待所有請求完成
      const [roomsResponses, groupChatrooms] = await Promise.all([
        Promise.all(roomPromises),
        groupChatPromise
      ]);
      
      // 合併部門聊天室和群組聊天室
      allChatRooms = [...roomsResponses.flat(), ...groupChatrooms];
      
      // 移除重複的聊天室（如果有的話）
      const uniqueRooms = Array.from(new Map(allChatRooms.map(room => [room.id, room])).values());
      // Filter out the department chatrooms if exist in idList
      let tempChatList = uniqueRooms.filter(chatroom => 
        idList.includes(chatroom.id) || userResponse.data.role_id === 4
      );
      // check if roomid is in tempchatlist
      if (roomId && (tempChatList.filter(x => x.id === roomId).length > 0 || userResponse.data.role_id === 4)) {
        setActiveRoomId(roomId)
        navigate(`/chat/${roomId}`)
      } else if (tempChatList.length > 0) {
        setActiveRoomId(tempChatList[0].id)
        navigate(`/chat/${tempChatList[0].id}`)
      } else {
        setActiveRoomId(null)
        navigate(`/chat`)
      }
      if (tempChatList.length === 0) {
        message.info("尚未加入任何聊天室")
      }
      setChatList(tempChatList)
    } catch (error) {
      // message.error(JSON.stringify(error.response?.data?.detail));
    }
    setLoading(false)
  }
  const changeActiveRoomId = (id) => {
    setActiveRoomId(id)
    navigate(`/chat/${id}`)
  }
  const openCreateModal = () => {
    setIsCreateModalOpen(true)
  }
  const closeCreateModal = () => {
    setIsCreateModalOpen(false)
  }
  const openJoinModal = () => {
    setIsJoinModalOpen(true)
  }
  const closeJoinModal = () => {
    setIsJoinModalOpen(false)
  }
  const fetchSingleRoom = async (roomId) => {
    try {
      setIsRoomLoading(true);
      const chatRoomFacade = new ChatRoomFacade(token);
      const result = await chatRoomFacade.getChatRoom(roomId);
      if (result.success) {
        setCurrentRoom(result.data);
      } else {
        // message.error(result.error || '獲取聊天室資訊失敗');
        setCurrentRoom(null);
      }
    } catch (error) {
      // message.error('獲取聊天室資訊失敗');
      console.error('Error fetching room:', error);
      setCurrentRoom(null);
    } finally {
      setIsRoomLoading(false);
    }
  };
  useEffect(() => {
    if (activeRoomId) {
      const roomInList = chatList.find(x => x.id === activeRoomId);
      if (roomInList) {
        setCurrentRoom(roomInList);
      } else {
        setCurrentRoom(null); // 重置 currentRoom
        fetchSingleRoom(activeRoomId);
      }
    } else {
      setCurrentRoom(null);
    }
  }, [activeRoomId, chatList]);

  const renderChatInterface = () => {
    if (!activeRoomId) {
      return (
        <div className="flex flex-col items-center justify-start w-full h-screen">
          <ToolBar pure={true} />
          <div className="text-center my-auto text-2xl font-bold text-textLight">
            請選擇聊天室
          </div>
        </div>
      );
    }

    if (isRoomLoading) {
      return (
        <div className="flex flex-col items-center justify-center w-full h-screen">
          <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
          <div className="mt-4 text-textLight">載入聊天室中...</div>
        </div>
      );
    }

    if (!currentRoom) {
      return (
        <div className="flex flex-col items-center justify-center w-full h-screen">
          <div className="text-center text-2xl font-bold text-textLight">
            無法載入聊天室
          </div>
        </div>
      );
    }
    return (
      <ChatInterface 
        id={activeRoomId} 
        room={currentRoom} 
        refresh={fetchRooms} 
      />
    );
  };
  return (
    <div className="flex flex-row w-full h-full">
      <CreateChat token={token} isModalOpen={isCreateModalOpen} closeModal={closeCreateModal} />
      <JoinChat token={token} isModalOpen={isJoinModalOpen} closeModal={closeJoinModal} />
      <ChatList
        userId={userId}
        activeRoomId={activeRoomId}
        chatList={chatList}
        changeActiveRoom={changeActiveRoomId}
        openCreateModal={openCreateModal}
        openJoinModal={openJoinModal}
        loading={loading}
      />
      {renderChatInterface()}
    </div>
  )
}
export default Chat
