import React, { useState, useEffect } from 'react';
import { useBound } from '../../../../contexts/BoundContext';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin, Tooltip, Switch } from 'antd';

const AgodaSetting = (client) => {
  const { orderAgodaMessage } = useBound(); 
  const [orderData, setOrderData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setOrderData(null);
    if (client?.client?.channel === "agoda" && Array.isArray(orderAgodaMessage)) {
      // Filter order data by messageId
      const filteredOrders = orderAgodaMessage.filter(
        (item) => item.message_id === client.client.messageId
      );
        const ordersObject = filteredOrders.reduce((acc, order) => {
        return { ...acc, ...order };
      }, {});
      setOrderData(ordersObject);
      setLoading(false);
    }
  }, [client, orderAgodaMessage]);
  
  return (
    <>
      <div className="p-3 mr-3 overflow-y-auto h-full">
      {true && (
          <>
            <div className="flex justify-between font-inter text-[17px] font-semibold leading-[22.1px] text-left text-[#3E3D3D] w-full my-3">
              AI 個別狀態設定
            </div>
            <div className="flex flex-col border border-borderLight rounded bg-bgMedium w-full">
              <div className="flex flex-col items-start w-full h-22 p-4 gap-2">
                <div className="font-bold">AI 狀態</div>
                <div className="flex flex-row justify-between w-full items-center">
                  <div>針對此客戶啟用 AI</div>
                  <Tooltip title="將自動傳送狀態變更訊息給客戶">
                    <Switch
                    // value={useWebhook}
                    // onChange={(e) =>
                    //   handleUpdateProperty("useWebhook", e)
                    // }
                    />
                  </Tooltip>
                </div>
              </div>
            </div>
          </>
        )}
        <div className="flex justify-between items-center font-inter text-[17px] font-semibold leading-[22.1px] text-left text-[#3E3D3D] w-full my-3">
          <span>預訂詳情</span>
        </div>
        
        <div className="flex flex-col border border-borderLight mb-3 rounded bg-bgMedium w-full rounded-[11px]">
          {
            loading ?
              <div className="flex justify-center items-center h-[600px]">
                <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
              </div>
              :
              <>
              <div className="flex flex-col items-start w-full h-10 p-4 gap-2">
                <div className="font-bold">預訂人訊息</div>
                  </div>
                  {orderData?.result?.guest_details?.map((detail, index) => (
                  <div key={index} className={`flex flex-col items-start w-full h-22 p-4 gap-2 ${index !== orderData.result.guest_details.length - 1 ? 'border-b border-b-borderLight' : ''}`}>
                    <div className="font-[400]">{detail[0] || 'data not found'}
                      {/* <span className="text-xs text-bgMedium">{" "}{orderData.last_update?.split('.')[0]}</span> */}
                    </div>
                    <div className="text-[14px] font-[400]">{detail[1] || 'data not found'}</div>
                  </div>
                ))}
              </>
          }
        </div>

        <div className="flex flex-col border border-borderLight mb-3 rounded bg-bgMedium w-full rounded-[11px]">
          {
            loading ?
              <div className="flex justify-center items-center h-[600px]">
                <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
              </div>
              :
              <>
              <div className="flex flex-col items-start w-full h-10 p-4 gap-2">
                <div className="font-bold">房源訊息</div>
                  </div>
                {orderData?.result?.property_info?.map((detail, index) => (
                  <div key={index} className={`flex flex-col items-start w-full h-22 p-4 gap-2 ${index !== orderData.result.property_info.length - 1 ? 'border-b border-b-borderLight' : ''}`}>
                    <div className="font-[400]">{detail[0] || 'data not found'}
                      {/* <span className="text-xs text-bgMedium">{" "}{orderData.last_update?.split('.')[0]}</span> */}
                    </div>
                    <div className="text-[14px] font-[400]">{detail[1] || 'data not found'}</div>
                  </div>
                ))}
              </>
          }
        </div>

        

        <div className="flex flex-col border border-borderLight mb-3 rounded bg-bgMedium w-full rounded-[11px]">
          {
            loading ?
              <div className="flex justify-center items-center h-[600px]">
                <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
              </div>
              :
              <>
              <div className="flex flex-col items-start w-full h-10 p-4 gap-2">
                <div className="font-bold">預訂資訊</div>
                  </div>
                {orderData?.result?.booking_details?.map((detail, index) => (
                  <div key={index} className={`flex flex-col items-start w-full h-22 p-4 gap-2 ${index !== orderData.result.booking_details.length - 1 ? 'border-b border-b-borderLight' : ''}`}>
                    <div className="font-[400]">{detail[0] || 'data not found'}
                      {/* <span className="text-xs text-bgMedium">{" "}{orderData.last_update?.split('.')[0]}</span> */}
                    </div>
                    <div className="text-[14px] font-[400]">{detail[1] || 'data not found'}</div>
                  </div>
                ))}
              </>
          }
        </div>
      </div>
    </>
  );


}

export default AgodaSetting