import React, { useState, useEffect, useRef } from 'react';
import { Switch, Button, message } from 'antd';
import axios from 'axios';
import { Editor, EditorState, RichUtils, ContentState, Modifier, CompositeDecorator, SelectionState } from 'draft-js';
import 'draft-js/dist/Draft.css';
import { useChat } from '../../../../hooks/use-chat';

// 自定義標籤樣式組件
const TagSpan = (props) => {
  const { children, blockKey, start, end } = props;

  // 根據Figma設計設置樣式
  const style = {
    backgroundColor: '#ADDEAC',
    color: '#3E3D3D',
    borderRadius: '12px',
    padding: '3px 16px',
    display: 'inline-block',
    fontSize: '14px',
    fontFamily: 'Inter, sans-serif',
    marginRight: '4px',
    lineHeight: '1.3em',
    cursor: 'grab',
    userSelect: 'all', // 讓整個標籤可一次選取
  };

  // 處理標籤點擊，用於一鍵選取整個標籤
  const handleTagClick = (e) => {
    e.stopPropagation();

    // 這裡可以添加自定義邏輯，例如觸發全選標籤
    // 由於我們使用了 userSelect: 'all'，點擊時應該會自動選中整個標籤
  };

  return (
    <span
      style={style}
      onClick={handleTagClick}
      data-offset-key={`${blockKey}-${start}-${end}`}
    >
      {children}
    </span>
  );
};

// 定義裝飾器
const createDecorator = () => {
  return new CompositeDecorator([
    {
      strategy: (contentBlock, callback, contentState) => {
        contentBlock.findEntityRanges(
          (character) => {
            const entityKey = character.getEntity();
            return (
              entityKey !== null &&
              contentState.getEntity(entityKey).getType() === 'TAG'
            );
          },
          callback
        );
      },
      component: TagSpan,
    },
  ]);
};

const WelcomeSetting = ({ room, token }) => {
  const { updateLineChatSettings } = useChat(room.id);
  const [enabled, setEnabled] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty(createDecorator()));
  const [isLoading, setIsLoading] = useState(false);
  const [originalSettings, setOriginalSettings] = useState(null); // 儲存原始設定
  const editorRef = useRef(null);
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  // 將 EditorState 內容轉換為純文本
  const getPlainText = () => {
    const contentState = editorState.getCurrentContent();
    return contentState.getPlainText();
  };

  // 將編輯器文本轉換為API格式（替換標籤為API格式）
  const convertToApiFormat = (text) => {
    return text
      .replace(/好友的顯示名稱/g, '<USER>')
      .replace(/本帳號名稱/g, '<SELF>');
  };

  // 將API格式的文本轉換為帶有實體標籤的EditorState
  const createEditorStateWithTags = (text) => {
    if (!text) return EditorState.createEmpty(createDecorator());

    // 創建初始內容
    let contentState = ContentState.createFromText('');
    let selectionState = SelectionState.createEmpty(contentState.getBlockMap().first().getKey());

    // 使用正則表達式分解文本，保留標籤和普通文本
    const parts = text.split(/(<USER>|<SELF>)/g).filter(part => part);

    // 處理每個部分
    for (const part of parts) {
      // 確定當前選擇位置
      selectionState = selectionState.merge({
        anchorOffset: selectionState.getFocusOffset(),
        focusOffset: selectionState.getFocusOffset(),
      });

      if (part === '<USER>') {
        // 創建並插入好友標籤實體
        contentState = contentState.createEntity('TAG', 'IMMUTABLE', { tagType: 'friend' });
        const entityKey = contentState.getLastCreatedEntityKey();
        contentState = Modifier.replaceText(
          contentState,
          selectionState,
          '好友的顯示名稱',
          null,
          entityKey
        );

        // 更新選擇位置
        selectionState = selectionState.merge({
          anchorOffset: selectionState.getFocusOffset() + '好友的顯示名稱'.length,
          focusOffset: selectionState.getFocusOffset() + '好友的顯示名稱'.length,
        });
      } else if (part === '<SELF>') {
        // 創建並插入本帳號標籤實體
        contentState = contentState.createEntity('TAG', 'IMMUTABLE', { tagType: 'self' });
        const entityKey = contentState.getLastCreatedEntityKey();
        contentState = Modifier.replaceText(
          contentState,
          selectionState,
          '本帳號名稱',
          null,
          entityKey
        );

        // 更新選擇位置
        selectionState = selectionState.merge({
          anchorOffset: selectionState.getFocusOffset() + '本帳號名稱'.length,
          focusOffset: selectionState.getFocusOffset() + '本帳號名稱'.length,
        });
      } else {
        // 插入普通文本
        contentState = Modifier.insertText(
          contentState,
          selectionState,
          part
        );

        // 更新選擇位置
        selectionState = selectionState.merge({
          anchorOffset: selectionState.getFocusOffset() + part.length,
          focusOffset: selectionState.getFocusOffset() + part.length,
        });
      }
    }

    // 創建最終的EditorState
    return EditorState.createWithContent(contentState, createDecorator());
  };

  useEffect(() => {
    if (room?.id) {
      fetchWelcomeSettings();
    }
  }, [room]);

  const fetchWelcomeSettings = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${SERVER_URL}/private/module/line_channel/${room.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data) {
        // 保存原始設定
        setOriginalSettings(response.data);
        // 設置歡迎詞開關
        setEnabled(response.data.body?.use_welcome_message || false);
        // 將純文本轉換為 EditorState
        const welcomeMessage = response.data.body?.welcome_message || '';
        setEditorState(createEditorStateWithTags(welcomeMessage));
      }
    } catch (error) {
      console.error('獲取歡迎詞設定失敗:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const saveWelcomeSettings = async () => {
    try {
      setIsLoading(true);

      // 只發送必要的幾個欄位
      const updatedSettings = {
        response_mode: originalSettings.body.response_mode || "text_only",
        query_only: originalSettings.body.query_only || false,
        loading_animation: originalSettings.body.loading_animation || true,
        welcome_message: convertToApiFormat(getPlainText()),
        use_welcome_message: enabled,
        agent_type: originalSettings.body.agent_type || "image_reader",
        additional_args: originalSettings.body.additional_args || {}
      };

      await updateLineChatSettings(room.id, updatedSettings);
       
      message.success('歡迎詞設定已儲存');
    } catch (error) {
      console.error('儲存歡迎詞設定失敗:', error);
      message.error('儲存歡迎詞設定失敗');
    } finally {
      setIsLoading(false);
    }
  };

  // 插入標籤的函數
  const insertTag = (tagType, tagText) => {
    // 創建Entity
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      'TAG',
      'IMMUTABLE',
      { tagType }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    // 插入Entity到當前選擇位置
    const selection = editorState.getSelection();
    const textWithEntity = Modifier.replaceText(
      contentStateWithEntity,
      selection,
      tagText,
      null,
      entityKey
    );

    // 更新EditorState
    const newEditorState = EditorState.push(
      editorState,
      textWithEntity,
      'insert-characters'
    );

    setEditorState(EditorState.forceSelection(newEditorState,
      newEditorState.getSelection().merge({
        anchorOffset: selection.getAnchorOffset() + tagText.length,
        focusOffset: selection.getFocusOffset() + tagText.length,
      })
    ));
  };

  // 插入"好友的顯示名稱"標籤
  const insertFriendNameTag = () => {
    insertTag('friend', '好友的顯示名稱');
  };

  // 插入"本帳號名稱"標籤
  const insertMyNameTag = () => {
    insertTag('self', '本帳號名稱');
  };

  // 添加兩個標籤按鈕到界面
  const renderTagButtons = () => {
    return (
      <div className="flex gap-2 my-2">
        <Button
          className='rounded-full'
          style={{
            backgroundColor: '#ADDEAC',
            borderColor: '#ADDEAC'
          }}
          onClick={insertFriendNameTag}
          disabled={!enabled}
        >
          好友的顯示名稱
        </Button>
        <Button
          className='rounded-full'
          style={{
            backgroundColor: '#ADDEAC',
            borderColor: '#ADDEAC'
          }}
          onClick={insertMyNameTag}
          disabled={!enabled}
        >
          本帳號名稱
        </Button>
      </div>
    );
  };

  // 處理鍵盤命令
  const handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  // 處理編輯器點擊事件
  const handleEditorClick = () => {
    editorRef.current?.focus();
  };

  // 編輯器包裝器樣式
  const editorWrapperStyle = {
    border: '1px solid #d9d9d9',
    borderRadius: '4px',
    padding: '8px 11px',
    minHeight: '120px',
    cursor: enabled ? 'text' : 'not-allowed',
    backgroundColor: enabled ? '#fff' : '#f5f5f5',
  };

  // 編輯器樣式
  const editorStyle = {
    minHeight: '100px',
    fontSize: '14px',
    lineHeight: '1.5',
  };

  return (
    <div className="flex flex-col w-full p-6">
      <div className="text-lg text-textDark font-bold mb-4">歡迎詞設定</div>

      <div className="bg-bgLight rounded-lg w-full">
        <div className="flex justify-between items-center p-4">
          <div className="font-semibold text-textDark">歡迎詞設定</div>
          <Switch
            checked={enabled}
            onChange={setEnabled}
            className={enabled ? 'bg-primaryDark' : ''}
          />
        </div>

        <div className="p-4">
          <div
            style={editorWrapperStyle}
            onClick={handleEditorClick}
            className="border border-borderLight rounded-md"
          >
            <Editor
              ref={editorRef}
              editorState={editorState}
              onChange={setEditorState}
              handleKeyCommand={handleKeyCommand}
              placeholder="請輸入進入聊天室時的歡迎詞"
              readOnly={!enabled}
              editorStyle={editorStyle}
            />
          </div>
          {renderTagButtons()}
          <div className="text-textLight text-xs mt-2">
            若於訊息中加入「好友的顯示名稱」，則只會傳送給已允許您存取其個人檔案的用戶。
          </div>

          <div className="flex justify-end mt-4">
            <Button
              type="primary"
              onClick={saveWelcomeSettings}
              loading={isLoading}
              className="bg-primaryDark"
            >
              儲存
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeSetting;