import React, { useState, useEffect, useRef } from 'react';
import { Button, Divider, Tabs, Tag } from 'antd';
import moment from 'moment';

const ProductDetail = ({ product }) => {
  const [currentPrice, setCurrentPrice] = useState(product?.price ?? 0);
  const [selectedDuration, setSelectedDuration] = useState(null);
  const [selectedDate, setSelectedDate] = useState(moment().format("YYYY-MM-DD"));
  const [selectedTime, setSelectedTime] = useState("15:00");
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [slideDirection, setSlideDirection] = useState('next');
  const timer = useRef(null);

  useEffect(() => {
    if (!product) return;

    if (product.pricingMode === "tiered" && Array.isArray(product.pricingTable)) {
      const validPricing = product.pricingTable.filter(item => item.price && item.price.trim() !== "");
      if (validPricing.length > 0) {
        const defaultSelected = validPricing[0];
        setSelectedDuration(defaultSelected.actualDuration);
        setCurrentPrice(defaultSelected.price);
      }
    } else {
      setCurrentPrice(product.price);
    }
  }, [product]);

  useEffect(() => {
    if (!product?.imageUrls?.length) return;

    if (product.imageUrls.length > 1) {
      timer.current = setInterval(() => {
        setSlideDirection('next');
        setCurrentImageIndex(prevIndex => (prevIndex === product.imageUrls.length - 1 ? 0 : prevIndex + 1));
      }, 3000);

      return () => {
        if (timer.current) {
          clearInterval(timer.current);
        }
      };
    }
  }, [product?.imageUrls]);

  if (!product) {
    return (
      <div className="w-full flex items-center justify-center h-[calc(100vh-64px-32px)] bg-gray-50">
        <div className="flex flex-col items-center justify-center">
          <p className="text-gray-500 text-center">請選擇一個商品以查看詳情</p>
        </div>
      </div>
    );
  }

  const handleDotClick = index => {
    setSlideDirection(index > currentImageIndex ? 'next' : 'prev');
    setCurrentImageIndex(index);
  };

  const handleMouseEnter = () => {
    if (timer.current) {
      clearInterval(timer.current);
    }
  };

  const handleMouseLeave = () => {
    if (product.imageUrls?.length > 1) {
      timer.current = setInterval(() => {
        setSlideDirection('next');
        setCurrentImageIndex(prevIndex => (prevIndex === product.imageUrls.length - 1 ? 0 : prevIndex + 1));
      }, 3000);
    }
  };

  const renderDurationSelection = () => {
    if (!Array.isArray(product.pricingTable) || product.pricingTable.length === 0) return null;

    return (
      <>
        <div className="bg-white p-4 rounded-lg sticky top-4">
          <h4 className="font-inter text-base font-black leading-[26px] tracking-[0.05em] text-left mb-3">Select Specification</h4>
          <h6 className="text-sm text-gray-600 mb-2">Model</h6>
          <div className="flex flex-wrap gap-2">
            {product.pricingTable.map(item => (
              <Button
                key={item.actualDuration}
                className={`min-w-[87px] h-[26px] px-4 mb-2 rounded-md ${selectedDuration === item.actualDuration ? 'bg-primary' : 'bg-white'}`}
                type={selectedDuration === item.actualDuration ? "primary" : "default"}
                onClick={() => {
                  setSelectedDuration(item.actualDuration);
                  setCurrentPrice(item.price);
                }}
              >
                {item.actualDuration}
              </Button>
            ))}
          </div>
        </div>
        <Divider className="border-b-[5px] border-[#F0F0F0] mx-4 my-2" />
      </>
    );
  };

  const items = [
    {
      key: "1",
      label: "商品資訊",
      children: (
        <div className="flex-1 pb-20">
          <div className="flex flex-col bg-white shadow gap-1">
            <div className="p-4">
              <div className="relative mb-2">
                {product.recommendation && product.recommendation >= 1 && product.recommendation <= 5 && (
                  <div className="absolute top-2 left-2 z-10">
                    <span className="text-[11px] bg-[#FF7171] text-white rounded-md h-[20px] w-[40px] inline-flex items-center justify-center">熱門</span>
                  </div>
                )}
                <div className="relative overflow-hidden" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                  <div className="relative w-full h-48 transition-transform duration-300 ease-in-out"
                    style={{ transform: `translateX(${currentImageIndex * -100}%)` }}>
                    {product.imageUrls?.length > 0 ? (
                      <div className="absolute flex w-full h-full">
                        {product.imageUrls.map((url, index) => (
                          <img key={index} src={url} alt={`${product.name}-${index + 1}`} className="w-full h-full object-cover flex-shrink-0" />
                        ))}
                      </div>
                    ) : (
                      <img src={product.imageUrl} alt={product.name} className="w-full h-full object-cover" />
                    )}
                  </div>
                  {product.imageUrls?.length > 1 && (
                    <div className="absolute bottom-2 left-1/2 transform -translate-x-1/2 flex gap-2">
                      {product.imageUrls.map((_, index) => (
                        <div key={index} onClick={() => handleDotClick(index)} className={`w-2 h-2 rounded-full cursor-pointer transition-all ${currentImageIndex === index ? 'bg-[#D7AE71]' : 'bg-white'}`} />
                      ))}
                    </div>
                  )}
                  {product.imageUrls?.length > 1 && (
                    <div className="absolute bottom-2 right-2 bg-black/50 text-white px-2 py-1 rounded text-xs">
                      {currentImageIndex + 1}/{product.imageUrls.length}
                    </div>
                  )}
                </div>
                <h3 className="text-lg font-bold mb-1">{product.name}</h3>
                <p className="text-sm text-gray-600 mb-2">{product.provider}</p>
                {product.tags && product.tags.length > 0 && (
                  <div className="flex flex-wrap gap-1 mb-2">
                    {product.tags.map(tag => (
                      <Tag key={tag} className="m-0 bg-[#F3E5D4] text-[#DFB782] border-[#DFB782]">
                        {tag}
                      </Tag>
                    ))}
                  </div>
                )}
                {product.description && (
                  <div>
                    <p className="text-sm text-gray-700">
                      {product.description}
                    </p>
                  </div>
                )}
                <div className="text-[24px] mb-2 text-primary flex items-baseline justify-end pb-2">
                  <span className="font-roboto text-2xl font-medium leading-[26px] tracking-[-0.9px] text-right">
                    $ {Number(currentPrice).toLocaleString()}
                  </span>
                  <span className="text-xl text-black ml-1 align-baseline"></span>
                </div>
              </div>
              <Divider className="border-b-[5px] border-[#F0F0F0] mx-4 my-2" />
              {renderDurationSelection()}
              <div className="p-2">
                <h4 className="text-base font-black mb-3">Select Time</h4>
                <div className="grid grid-cols-2 gap-4 mb-4">
                  <div>
                    <div className="text-sm mb-2">Date</div>
                    <div className="w-full h-[32px] border rounded px-3 py-1 bg-gray-50">
                      {selectedDate}
                    </div>
                  </div>
                  <div>
                    <div className="text-sm mb-2">Time</div>
                    <div className="w-full h-[32px] border rounded px-3 py-1 bg-gray-50">
                      {selectedTime}
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-3 gap-2">
                  {["09:00", "10:00", "11:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00"].map(time => (
                    <div key={time} className={`text-center py-2 rounded ${time === selectedTime ? 'bg-primary' : 'bg-gray-200'}`}>
                      {time}
                    </div>
                  ))}
                </div>
              </div>
              {product.productNotice && (
                <div className="py-4">
                  <h4 className="text-base font-bold mb-2">注意事項</h4>
                  <p className="text-sm text-gray-700">
                    {product.productNotice}
                  </p>
                </div>
              )}

            </div>
            <div className="fixed w-[440px] bottom-0 bg-white border shadow-3xl p-4">
              <Button
                type="primary"
                className="w-full h-10 bg-[#ADDEAC] hover:bg-[#45a049] text-black hover:!text-black"
              >
                編輯商品
              </Button>
            </div>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="w-full flex flex-col h-full bg-white overflow-auto">
      <Tabs defaultActiveKey="1" items={items} className="flex-1 [&_.ant-tabs-content-holder]:overflow-auto [&_.ant-tabs-nav]:px-4 [&_.ant-tabs-nav]:pt-4" />
    </div>
  );
};

export default ProductDetail;
