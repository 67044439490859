import { Tag, Button, Input } from 'antd';
import moment from 'moment';

export const productColumns = ({ setShowOrderManagement, setSelectedProduct, products, setProducts }) => [
  {
    title: "推薦",
    width: "5%",
    fixed: "left",
    dataIndex: "recommendation",
    align: "center",
    sorter: (a, b) => {
      if (a.recommendation === null && b.recommendation === null) return 0;
      if (a.recommendation === null) return 1;
      if (b.recommendation === null) return -1;
      return a.recommendation - b.recommendation;
    },
    render: (value, record) => (
      <Input
        type="text"
        style={{ width: '60px' }}
        value={value || ''}
        onChange={(e) => {
          const inputValue = e.target.value;

          if (inputValue === '') {
            const updatedProducts = products.map(product => {
              if (product.key === record.key) {
                return { ...product, recommendation: null };
              }
              return product;
            });
            setProducts(updatedProducts);
            return;
          }

          let newValue = parseInt(inputValue);

          if (isNaN(newValue) || newValue < 1 || newValue > 5) return;

          const isNumberUsed = products.some(
            product => product.key !== record.key && product.recommendation === newValue
          );

          if (isNumberUsed) return;

          const updatedProducts = products.map(product => {
            if (product.key === record.key) {
              return { ...product, recommendation: newValue };
            }
            return product;
          });
          setProducts(updatedProducts);
        }}
        className="[&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none [&]:MozAppearance-textfield"
      />
    ),
  },
  {
    title: "上架商家",
    dataIndex: ["category", "name"],
    width: "10%",
    fixed: "left",
    sorter: (a, b) => a.category.name.localeCompare(b.category.name),
  },
  {
    title: "前台狀態",
    dataIndex: "status",
    width: "6%",
    fixed: "left",
    align: "center",
    sorter: (a, b) => (a.status || "On Sale").localeCompare(b.status || "On Sale"),
    render: (status = "On Sale") => (
      <span
        className={`badge rounded-full px-2 py-1 text-white ${
          status === "On Sale" ? "bg-[#67BE5F]" : "bg-[#B6B3A7]"
        }`}
      >
        {status}
      </span>
    ),
  },
  {
    title: "商品序號",
    dataIndex: "id",
    width: "6%",
    align: "center",
    sorter: (a, b) => a.id.localeCompare(b.id),
    render: (id) => id.substring(0, 5),
  },
  {
    title: "商品名稱",
    dataIndex: "name",
    width: "15%",
    sorter: (a, b) => a.name.localeCompare(b.name),
    render: (text, record) => (
      <div className="flex items-center">
        <img
          src={record.imageUrls?.length > 0 ? record.imageUrls[0] : record.imageUrl}
          alt="商品縮圖"
          className="w-6 h-6 object-cover rounded mr-2"
        />
        <span>{text}</span>
      </div>
    ),
  },
  {
    title: "價格",
    dataIndex: "price",
    width: "8%",
    align: "center",
    sorter: (a, b) => (a.price || 0) - (b.price || 0),
    render: (price) => price ? `${price}` : 'Unset',
  },
  {
    title: "訂單數",
    dataIndex: "order",
    width: "7%",
    sorter: (a, b) => a.order - b.order,
    render: (order, record) => (
      <Button
        type="link"
        onClick={(e) => {
          e.stopPropagation();
          setShowOrderManagement(true);
          setSelectedProduct(record);
        }}
      >
        {order}
        <img
          src="/images/order.png"
          className="w-[70px]"
          alt="arrow"
        />
      </Button>
    ),
  },
  {
    title: "庫存數",
    dataIndex: "remaining",
    width: "7%",
    align: "center",
    sorter: (a, b) => {
      if (a.remaining === "0" && b.remaining === "0") return 0;
      if (a.remaining === "0") return 1;
      if (b.remaining === "0") return -1;
      return parseInt(a.remaining) - parseInt(b.remaining);
    },
    render: (remaining) => (remaining === "0" ? "無限" : remaining),
  },
  {
    title: "優惠",
    dataIndex: "memberDiscount",
    width: "6%",
    render: (memberDiscount, record) => {
      const discounts = [];
      if (memberDiscount && memberDiscount !== "無折扣") {
        discounts.push(`會員${memberDiscount}`);
      }
      if (record.earlyBirdDiscount && record.earlyBirdDiscount !== "無折扣") {
        discounts.push(`晚鳥${record.earlyBirdDiscount}`);
      }
      return discounts.length > 0 ? discounts.join("、") : "無折扣";
    },
  },
  {
    title: "標籤",
    dataIndex: "tags",
    width: "10%",
    render: (tags) => (
      <div className="flex flex-wrap gap-1">
        {(tags || []).map((tag) => (
          <Tag 
            key={tag} 
            className="m-0 bg-[#F3E5D4] text-[#DFB782] border-[#DFB782]"
          >
            {tag}
          </Tag>
        ))}
      </div>
    ),
  },
  {
    title: "上架日期",
    dataIndex: "publishDate",
    width: "8%",
    align: "center",
    render: (date) => moment(date).format("YYYY-MM-DD"),
  },
];