import { request } from '../../utils';

/**
 * Facebook Channel API
 */
export const FacebookApi = {
  getFacebookClients: async (roomId) => { 
    return await request.get(`/private/module/messenger/${roomId}/clients`);
  },
  getFacebookMessages: async (roomId, socialMediaClientId, limit = 10, fromId = null) => {
    return await request.get(`/private/module/messenger/${roomId}/chats/${socialMediaClientId}`, {
      params: {
        limit: limit,
        from_id: fromId
      }
    });
  },
  sendFacebookMessage: async (roomId, socialMediaClientId, message) => {
    return await request.post(`/private/module/messenger/${roomId}/p2p/${socialMediaClientId}`, {
      text: message
    });
  },
  // get
  getFacebookChannel: async (roomId) => {
    return await request.get(`/private/module/messenger/${roomId}`);
  },

  // create
  createFacebookProperty: async (roomId, facebookProperty) => {
    return await request.post(`/private/module/messenger/${roomId}`, facebookProperty);
  },
  
  // update
  updateFacebookWebhookStatus: async (roomId, socialMediaClientId, useWebhook) => {
    return await request.patch(
      `/private/module/messenger/${roomId}/use_webhook/${socialMediaClientId}`,
      null,
      {
        params: {
          use_webhook: useWebhook
        }
      }
    );
  },

  updateFacebookChatSettings: async (roomId, chatSettings) => {
    return await request.put(`/private/module/messenger/settings/${roomId}/chat_settings`, chatSettings);
  },
  updateFacebookBaseSettings: async (roomId, baseSettings) => {
    return await request.put(`/private/module/messenger/settings/${roomId}/base`, baseSettings);
  },
  updateFacebookNickname: async (roomId, socialMediaClientId, nickname) => {
    return await request.patch(`/private/module/messenger/${roomId}/nickname/${socialMediaClientId}?nickname=${nickname}`);
  },



  // delete
  deleteFacebookProperty: async (roomId) => {
    return await request.delete(`/private/module/messenger/${roomId}`);
  },


  addFacebookNote: async (roomId, socialMediaClientId, noteName, noteContent) => {
    return await request.post(
      `/private/module/messenger/${roomId}/note/${socialMediaClientId}`,
      null,
      {
        params: {
          note_name: noteName,
          note_content: noteContent
        }
      }
    );
},

updateFacebookNote: async (roomId, socialMediaClientId, noteId, noteName, noteContent) => {
  return await request.patch(
      `/private/module/messenger/${roomId}/note/${socialMediaClientId}`,
      null,
      {
        params: {
          note_id: noteId,
          note_name: noteName,
          note_content: noteContent
        }
      }
    );
},

deleteFacebookNote: async (roomId, socialMediaClientId, noteId) => {
  return await request.delete(
      `/private/module/messenger/${roomId}/note/${socialMediaClientId}`,
      {
        params: {
          note_id: noteId
        }
      }
    );
},
addFacebookTag: async (roomId, socialMediaClientId, tagName) => {
  return await request.post(
      `/private/module/messenger/${roomId}/tag/${socialMediaClientId}`,
      null,
      {
        params: {
          name: tagName
        }
      }
    );
},
deleteFacebookTag: async (roomId, socialMediaClientId, tagId) => {
  return await request.delete(
      `/private/module/messenger/${roomId}/tag/${socialMediaClientId}`,
      {
        params: {
          tag_id: tagId
        }
      }
    );
},
fetchFacebookTagGroup: async (roomId) => {
  return await request.get(`/private/module/social_media_clients/${roomId}/tag_group`);
}
}; 