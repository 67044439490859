import { AgentRepository } from "../../infrastructure/repositories/AgentRepository";

export const AgentUseCase = {
  fetchAgentClients: async (roomId) => {
    return await AgentRepository.fetchAgentClients(roomId);
  },
  fetchAgentMessages: async (roomId, socialMediaClientId, limit = 10, oldestMessageId = null) => {
    return await AgentRepository.fetchAgentMessages(roomId, socialMediaClientId, limit, oldestMessageId);
  },
  sendAgentMessage: async (roomId, socialMediaClientId, message) => {
    return await AgentRepository.sendAgentMessage(roomId, socialMediaClientId, message);
  },
  // get
  getAgentChannel: async (roomId) => {
    return await AgentRepository.getAgentChannel(roomId);
  },

  // create
  createAgentProperty: async (roomId, agentProperty) => {
    return await AgentRepository.createAgentProperty(roomId, agentProperty);
  },

  // update
  updateAgentWebhookStatus: async (roomId, socialMediaClientId, useWebhook) => {
    return await AgentRepository.updateAgentWebhookStatus(roomId, socialMediaClientId, useWebhook);
  },
  updateAgentChatSettings: async (roomId, chatSettings) => {
    return await AgentRepository.updateAgentChatSettings(roomId, chatSettings);
  },
  updateAgentBaseSettings: async (roomId, baseSettings) => {
    return await AgentRepository.updateAgentBaseSettings(roomId, baseSettings);
  },
  updateAgentNickname: async (roomId, socialMediaClientId, nickname) => {
    return await AgentRepository.updateAgentNickname(roomId, socialMediaClientId, nickname);
  },

  // delete
  deleteAgentProperty: async (roomId) => {
    return await AgentRepository.deleteAgentProperty(roomId);
  },
  addAgentNote: async (roomId, clientId, noteName, noteContent) => {
    return await AgentRepository.addAgentNote(roomId, clientId, noteName, noteContent);
  },
  updateAgentNote: async (roomId, clientId, noteId, noteName, noteContent) => {
    return await AgentRepository.updateAgentNote(roomId, clientId, noteId, noteName, noteContent);
  },
  deleteAgentNote: async (roomId, clientId, noteId) => {
    return await AgentRepository.deleteAgentNote(roomId, clientId, noteId);
  },
  addAgentTag: async (roomId, clientId, tagName) => {
    return await AgentRepository.addAgentTag(roomId, clientId, tagName);
  },
  deleteAgentTag: async (roomId, clientId, tagId) => {
    return await AgentRepository.deleteAgentTag(roomId, clientId, tagId);
  },
  fetchAgentTagGroup: async (roomId) => {
    return await AgentRepository.fetchAgentTagGroup(roomId);
  }
};