import React, { useState, useEffect } from "react";
import { Select, Input, DatePicker, Checkbox, Form } from "antd";
import "tailwindcss/tailwind.css";

const { Option } = Select;
const { RangePicker } = DatePicker;

const DiscountManagement = ({ form }) => {
  const [isMemberUnlimited, setIsMemberUnlimited] = useState(false);
  const [isEarlyBirdUnlimited, setIsEarlyBirdUnlimited] = useState(false);
  useEffect(() => {
    const formValues = form.getFieldsValue();
    if (formValues.memberDiscountValue) {
      setIsMemberUnlimited(true);
    }
    if (formValues.earlyBirdDiscountValue) {
      setIsEarlyBirdUnlimited(true);
    }
  }, [form]);

  // calculate discount percentage
  const calculatePercentOff = (discount) => {
    if (!discount) return '';
    const percentOff = (100 - parseFloat(discount) * 10).toFixed(0);
    return percentOff >= 0 ? percentOff.toString() : '';
  };

  useEffect(() => {
    const memberDiscount = form.getFieldValue('memberDiscountValue');
    const earlyBirdDiscount = form.getFieldValue('earlyBirdDiscountValue');
    
    form.setFieldsValue({
      memberDiscountPercentOff: calculatePercentOff(memberDiscount),
      earlyBirdDiscountPercentOff: calculatePercentOff(earlyBirdDiscount)
    });
  }, [form.getFieldValue('memberDiscountValue'), form.getFieldValue('earlyBirdDiscountValue')]);

  return (
    <>
      <div className="flex flex-col bg-white rounded-md shadow-sm">
        {/* Member discount area */}
        <div className="grid grid-cols-3 gap-4 mb-6">
          <Form.Item 
            name="memberDiscountType" 
            label="Select Discount"
            rules={[{ required: true, message: '請選擇折扣類型' }]}
          >
            <Select placeholder="Tiered Discount">
              <Option value="Member Discount">Member Discount</Option>
              <Option value="無">None</Option>
            </Select>
          </Form.Item>

          <Form.Item 
            name="memberDiscountValue" 
            label="Discount Method" 
            className="flex items-center"
            rules={[{ required: true, message: '請輸入折扣數值' }]}
          >
            <div className="flex items-center w-full">
              <Select
                placeholder="Please enter a number"
                className="w-24"
                onChange={(value) => {
                  form.setFieldsValue({
                    memberDiscountPercentOff: calculatePercentOff(value)
                  });
                }}
              >
                {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(num => (
                  <Option key={num} value={String(num)}>{num}</Option>
                ))}
              </Select>
              <span className="mx-2">Discount =</span><span className="mx-2">=</span>
              <Form.Item name="memberDiscountPercentOff" noStyle>
                <Input
                  disabled
                  className="w-[110px]"
                  suffix="%"
                />
              </Form.Item>
              <span className="ml-1">off</span>
            </div>
          </Form.Item>
        </div>

        <div className="mb-6">
          <Form.Item label="Discount Period" className="mb-2">
            <div className="flex items-center">
              <Form.Item name="memberDiscountUnlimited" valuePropName="checked" noStyle>
                <Checkbox onChange={(e) => setIsMemberUnlimited(e.target.checked)} disabled>
                  No expiration
                </Checkbox>
              </Form.Item>
            </div>
            {!isMemberUnlimited && (
              <div className="flex items-center mt-2">
                <Form.Item name="memberDiscountPeriod" noStyle>
                  <RangePicker placeholder={['Start Date', 'End Date']} disabled/>
                </Form.Item>
              </div>
            )}
          </Form.Item>
        </div>

        {/* Late bird discount area */}
        <div className="grid grid-cols-3 gap-4 mb-6">
          <Form.Item name="earlyBirdDiscountType" label="Night Owl Discount">
            <Select disabled>
              <Option value="無">無</Option>
              <Option value="Night Owl Discount">Night Owl Discount</Option>
            </Select>
          </Form.Item>

          <Form.Item name="earlyBirdDiscount" label="Discount Method" className="flex items-center">
            <div className="flex items-center w-full">
              <Select
                placeholder="Please enter a number"
                className="w-24"
                disabled
              >
                {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(num => (
                  <Option key={num} value={String(num)}>{num}</Option>
                ))}
              </Select>
              <span className="mx-2">Discount =</span><span className="mx-2">=</span>
              <Input
                value={calculatePercentOff(form.getFieldValue('earlyBirdDiscountValue'))}
                disabled
                className="w-[110px]"
                suffix="%"
              />
              <span className="ml-1">off</span>
            </div>
          </Form.Item>
        </div>

        <div className="mb-6">
          <Form.Item label="Promotion Period" className="mb-2">
            <div className="flex items-center">
              <Form.Item name="earlyBirdDiscountUnlimited" valuePropName="checked" noStyle>
                <Checkbox onChange={(e) => setIsEarlyBirdUnlimited(e.target.checked)} disabled>
                  No expiration
                </Checkbox>
              </Form.Item>
            </div>
            {!isEarlyBirdUnlimited && (
              <div className="flex items-center mt-2">
                <Form.Item name="earlyBirdDiscountPeriod" noStyle>
                  <RangePicker placeholder={['Start Date', 'End Date']} disabled/>
                </Form.Item>
              </div>
            )}
          </Form.Item>
        </div>

        {/* Profit sharing and bonus points */}
        <div className="grid grid-cols-2 gap-4">
          <Form.Item name="profitSharing" label="Revenue Share Ratio (in %)">
            <Input placeholder="Please enter a ratio" disabled/>
          </Form.Item>

          <Form.Item name="bonusPoints" label="Reward Points (in points)">
            <Input placeholder="Please enter points" disabled/>
          </Form.Item>
        </div>
      </div>
    </>
  );
};

export default DiscountManagement;
