import request from "../../utils/request";

export const ChatHistoriesApi = {
  /**
   * 獲取聊天室歷史記錄
   * @param {string} chatroomId - 聊天室ID
   * @param {string} since - 開始日期 (YYYY-MM-DD格式)
   * @param {string} until - 結束日期 (YYYY-MM-DD格式)
   * @returns {Promise<Array>} - 返回聊天室歷史記錄
   */
  getChatroomHistories: async (chatroomId, since, until) => {
    try {
      const response = await request.get(
        `/private/stat/chat_histories/chatroom/${chatroomId}`,
        {
          params: {
            since,
            until,
          },
        }
      );
      return response;
    } catch (error) {
      console.error("ChatHistoriesApi.getChatroomHistories Error:", error);
      throw error;
    }
  },
  /**
   * 獲取部門聊天歷史
   * @param {string} since - 開始日期，格式為 YYYY-MM-DD
   * @param {string} until - 結束日期，格式為 YYYY-MM-DD
   * @param {string} [apiKey=null] - API 密鑰，可選參數
   * @returns {Promise<Object>} - 返回部門聊天歷史數據
   */
  getDepartmentsChatHistories: async (since, until, apiKey = null) => {
    try {
      const headers = {};
      if (apiKey) {
        headers["x-api-key"] = apiKey;
      }

      const response = await request.get(
        "/private/stat/chat_histories/department",
        {
          params: { since, until },
          headers,
        }
      );
      return response;
    } catch (error) {
      console.error(
        "ChatHistoriesApi.getDepartmentsChatHistories Error:",
        error
      );
      throw error;
    }
  },
  /**
   * 獲取特定部門的聊天歷史記錄
   * @param {string} departmentId - 部門ID
   * @param {string} since - 開始日期，格式為 YYYY-MM-DD
   * @param {string} until - 結束日期，格式為 YYYY-MM-DD
   * @param {string} [apiKey=null] - API 密鑰，可選參數
   * @returns {Promise<Object>} - 返回特定部門的聊天歷史數據
   */
  getDepartmentChatHistories: async (
    departmentId,
    since,
    until,
    apiKey = null
  ) => {
    try {
      const headers = {};
      if (apiKey) {
        headers["x-api-key"] = apiKey;
      }

      const response = await request.get(
        `/private/stat/chat_histories/department/${departmentId}`,
        {
          params: { since, until },
          headers,
        }
      );
      return response;
    } catch (error) {
      console.error(
        "ChatHistoriesApi.getDepartmentChatHistories Error:",
        error
      );
      throw error;
    }
  },
  /**
   * 獲取公司聊天歷史記錄
   * @param {string} since - 開始日期，格式為 YYYY-MM-DD
   * @param {string} until - 結束日期，格式為 YYYY-MM-DD
   * @param {string} [apiKey=null] - API 密鑰，可選參數
   * @returns {Promise<Object>} - 返回公司聊天歷史數據
   */
  getCompanyChatHistories: async (since, until, apiKey = null) => {
    try {
      const headers = {};
      if (apiKey) {
        headers["x-api-key"] = apiKey;
      }

      const response = await request.get(
        "/private/stat/chat_histories/company",
        {
          params: { since, until },
          headers,
        }
      );
      return response;
    } catch (error) {
      console.error("ChatHistoriesApi.getCompanyChatHistories Error:", error);
      throw error;
    }
  },
};
