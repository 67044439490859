import { Tag } from 'antd';
import moment from 'moment';

export const userColumns = [
  {
    title: "Member ID",
    dataIndex: "id",
    width: "150px",
    fixed: "left",
    render: (id) => id.slice(0, 8)
  },
  {
    title: "Member Name",
    dataIndex: "nickname",
    width: "180px",
    fixed: "left",
    render: (text, record) => (
      <div className="flex items-center">
        <img
          src={record.picture || "/images/user.png"}
          alt="會員頭像"
          className="w-6 h-6 object-cover rounded-full mr-2"
        />
        <span className={!text && !record.first_name && !record.last_name ? "text-gray-400" : ""}>{text || `${record.first_name || ''} ${record.last_name || ''}`.trim() || 'Unset'}</span>          </div>
    ),
  },
  {
    title: "Phone",
    dataIndex: "phone",
    width: "120px",
    render: (phone) => <span className={!phone ? "text-gray-400" : ""}>{phone || 'Unset'}</span>  },
  {
    title: "Email",
    dataIndex: "email",
    width: "180px",
    render: (email) => <span className={!email ? "text-gray-400" : ""}>{email || 'Unset'}</span>  },
  {
    title: "Level",
    dataIndex: "level",
    width: "100px",
    render: (level) => (
      <Tag color={level > 0 ? "gold" : "blue"}>Level {level}</Tag>
    ),
  },
  {
    title: "Registration Time",
    dataIndex: "created_at",
    width: "130px",
    render: (date) => moment(date).format("YYYY-MM-DD"),
  },
  {
    title: "Social Platform",
    dataIndex: "sso_info",
    width: "120px",
    render: (sso) => {
      if (!sso || sso.length === 0) return <span className="text-gray-400">Unset</span>;
      return sso[0].provider;
    }
  },
  {
    title: "Verification Status",
    width: "200px",
    render: (_, record) => {
      const hasVerification = record.phone_is_verified || record.email_is_verified;

      if (!hasVerification) {
        return <Tag color="default">Unset</Tag>;
      }

      return (
        <div className="flex flex-wrap gap-1">
          {record.phone_is_verified && <Tag color="green">手機已驗證</Tag>}
          {record.email_is_verified && <Tag color="green">郵箱已驗證</Tag>}
        </div>
      );
    },
  },
  {
    title: "Open Status",
    dataIndex: "enabled",
    width: "90px",
    align: "center",
    fixed: "right",
    render: (enabled) => (
      <span
        className={`badge rounded-full px-2 py-1 text-white ${enabled ? "bg-[#67BE5F]" : "bg-[#B6B3A7]"
          }`}
      >
        {enabled ? '開放' : '關閉'}
      </span>
    ),
  },
];

