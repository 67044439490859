import React, { useState, useEffect } from "react";
import { Select, Input, Form, Tag, Upload, Modal } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import "tailwindcss/tailwind.css";
import ImgCrop from 'antd-img-crop';

const { TextArea } = Input;

const ShopInfo = ({ form }) => {
  const [tags, setTags] = useState(['專攻美白', '長期合作', '免費取消']);
  const [selectedTags, setSelectedTags] = useState(['專攻美白', '長期合作', '免費取消']);
  const [fileList, setFileList] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newTagInput, setNewTagInput] = useState('');
  const [platformRows, setPlatformRows] = useState([
    { id: 1, platform: 'facebook', url: '' }
  ]);

  // Get base64
  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  // Handle image change
  const onChange = async ({ fileList: newFileList }) => {
    try {
      const processedFileList = await Promise.all(
        (newFileList || []).map(async (file) => {
          if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
          }
          return file;
        })
      );
      setFileList(processedFileList);
      if (processedFileList.length > 0) {
        const imageUrl = processedFileList[0].preview || processedFileList[0].url;
        form.setFieldsValue({ serviceImage: imageUrl });
      } else {
        form.setFieldsValue({ serviceImage: '' });
      }
    } catch (error) {
      console.error('處理圖片時發生錯誤:', error);
    }
  };

  // Handle image preview
  const onPreview = async (file) => {
    let src = file.url || file.preview;
    if (!src) {
      src = await getBase64(file.originFileObj);
      file.preview = src;
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  // Handle tag close
  const handleTagClose = (tag) => {
    const newSelectedTags = selectedTags.filter((t) => t !== tag);
    setSelectedTags(newSelectedTags);
    form.setFieldsValue({ tags: newSelectedTags }); // 更新表單值
  };

  // Handle tag add
  const addTag = () => {
    if (newTagInput && !tags.includes(newTagInput)) {
      const newTags = [...tags, newTagInput];
      const newSelectedTags = [...selectedTags, newTagInput];
      setTags(newTags);
      setSelectedTags(newSelectedTags);
      form.setFieldsValue({ tags: newSelectedTags }); // 更新表單值
      setNewTagInput('');
    }
    setIsModalVisible(false);
  };

  // 添加新平台行
  const handleAddPlatform = () => {
    setPlatformRows([
      ...platformRows,
      { id: Date.now(), platform: 'facebook', url: '' }
    ]);
  };

  // 删除平台行
  const handleRemovePlatform = (id) => {
    setPlatformRows(platformRows.filter(row => row.id !== id));
  };

  // 更新平台数据
  const handlePlatformChange = (id, field, value) => {
    setPlatformRows(platformRows.map(row => 
      row.id === id ? { ...row, [field]: value } : row
    ));
  };

  // 渲染平台綁定表單
  const renderPlatformRows = () => (
    <div className="space-y-4">
      {platformRows.map((row) => (
        <div key={row.id} className="flex bg-[#F3F3F3] p-4 rounded-lg">
          <div className="flex flex-1 gap-4">
            <div className="flex items-center gap-4 w-1/4">
              <div className="w-24 flex-shrink-0">平台</div>
              <Form.Item name={`platform-${row.id}`} className="flex-1 mb-0">
                <select 
                  className="w-full border rounded-md p-2"
                  value={row.platform}
                  onChange={(e) => handlePlatformChange(row.id, 'platform', e.target.value)}
                >
                  <option value="facebook">臉書</option>
                  <option value="instagram">Instagram</option>
                  <option value="threads">Threads</option>
                  <option value="tiktok">TikTok</option>
                </select>
              </Form.Item>
            </div>
            
            <div className="flex items-center gap-4 w-3/4">
              <div className="w-24 flex-shrink-0">連結網址</div>
              <Form.Item name={`platformUrl-${row.id}`} className="flex-1 mb-0">
                <div className="flex items-center gap-2">
                  <input 
                    type="text" 
                    placeholder="http://xxxxxxxx" 
                    className="flex-1 border rounded-md p-2"
                    value={row.url}
                    onChange={(e) => handlePlatformChange(row.id, 'url', e.target.value)}
                  />
                  <button 
                    type="button"
                    onClick={() => handleRemovePlatform(row.id)}
                    className="p-2"
                  >
                    <img src="/images/close.png" alt="remove" className="w-4 h-4" />
                  </button>
                </div>
              </Form.Item>
            </div>
          </div>
        </div>
      ))}
      
      <div className="bg-[#F3F3F3] p-4 rounded-lg">
        <div className="w-24 flex-shrink-0 mb-2">平台</div>
        <button 
          type="button"
          onClick={handleAddPlatform}
          className="text-[#67BE5F] border border-dashed border-[#67BE5F] rounded-md px-4 py-2 flex items-center gap-2"
        >
          <span>新增官方平台</span>
          <span>+</span>
        </button>
      </div>
    </div>
  );

  useEffect(() => {
    form.setFieldsValue({
      serviceImage: '',
      fileList: [],
    });
  }, [form]);

  return (
    <>
      <div className="space-y-4 px-4">
        {/* shop name and number */}
        <div className="flex space-x-6">
          <Form.Item 
            label="商家名稱" 
            name="shopName" 
            className="w-1/2"
            rules={[{ required: true, message: '請輸入商家名稱' }]}
          >
            <Input placeholder="請輸入商家名稱" />
          </Form.Item>
          <Form.Item label="商家序號" name="shopNumber" className="w-1/2">
            <Input disabled placeholder="Automatically Generated by System" />
          </Form.Item>
        </div>

        {/* shop image upload area */}
        <div>
          <div className="font-medium mb-2">商家主要圖片</div>
          <div className="mb-2 text-gray-500 text-sm">
            建議格式: jpeg, png 建議尺寸 800×600 (1:1)
          </div>
          <Form.Item
            name="serviceImage"
            rules={[{ required: true, message: '請上傳商家圖片' }]}
          >
            <div className="flex space-x-4">
              <ImgCrop rotationSlider>
                <Upload
                  action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                  listType="picture-card"
                  fileList={fileList}
                  onChange={onChange}
                  onPreview={onPreview}
                  maxCount={1}
                  beforeUpload={() => false}
                >
                  {fileList.length < 1 && '+ Upload'}
                </Upload>
              </ImgCrop>
            </div>
          </Form.Item>
        </div>

        {/* contact information */}
        <div className="flex space-x-6">
          <Form.Item 
            label="商家電話" 
            name="shopPhone" 
            className="w-1/2"
            rules={[{ required: true, message: '請輸入聯絡電話' }]}
          >
            <Input placeholder="請輸入聯絡電話" />
          </Form.Item>
          <Form.Item 
            label="商家 Email" 
            name="shopEmail" 
            className="w-1/2"
            rules={[
              { required: true, message: '請輸入 Email' },
              { type: 'email', message: '請輸入有效的 Email 格式' }
            ]}
          >
            <Input placeholder="請輸入 Email" />
          </Form.Item>
        </div>

        {/* owner information */}
        <div className="flex space-x-6">
          <Form.Item 
            label="負責人" 
            name="ownerName" 
            className="w-1/2"
            rules={[{ required: true, message: '請輸入負責人姓名' }]}
          >
            <Input placeholder="請輸入負責人姓名" />
          </Form.Item>
          <Form.Item 
            label="電話" 
            name="ownerPhone" 
            className="w-1/2"
            rules={[{ required: true, message: '請輸入負責人電話' }]}
          >
            <Input placeholder="請輸入商家負責人電話" />
          </Form.Item>
        </div>

        {/* establish date */}
        <Form.Item 
          label="創立日期" 
          name="establishDate"
          rules={[{ required: true, message: '請選擇創立日期' }]}
        >
          <Input type="date" style={{ width: '200px' }} />
        </Form.Item>

        {/* address */}
        <div className="flex space-x-4">
          <Form.Item 
            label="商家地址" 
            name="city" 
            className="w-1/4"
            rules={[{ required: true, message: '請輸入城市' }]}
          >
            <Input placeholder="請輸入城市" />
          </Form.Item>
          <Form.Item 
            label=" "
            name="district" 
            className="w-1/4"
            rules={[{ required: true, message: '請輸入區域' }]}
          >
            <Input placeholder="請輸入區域" />
          </Form.Item>
          <Form.Item 
            label=" "
            name="address" 
            className="w-1/2"
            rules={[{ required: true, message: '請輸入詳細地址' }]}
          >
            <Input placeholder="請輸入詳細地址" />
          </Form.Item>
        </div>

        {/* service description */}
        <Form.Item 
          label="服務描述" 
          name="serviceDescription"
          rules={[{ required: true, message: 'Please Enter Service Description' }]}
        >
          <TextArea rows={4} placeholder="Please Enter Service Description" />
        </Form.Item>

        {/* tags */}
        <Form.Item 
          label={`標籤 (${selectedTags.length}/5)`}
          name="tags"
          rules={[{ required: true, message: '請至少增加一個標籤' }]}
        >
          <div className="flex flex-wrap gap-2 border border-gray-300 rounded-lg p-2 min-w-[400px] w-fit">
            {selectedTags.map((tag) => (
              <Tag
                key={tag}
                closable
                onClose={() => handleTagClose(tag)}
                className="bg-green-50 text-green-600 border-green-200"
              >
                {tag}
              </Tag>
            ))}
            {selectedTags.length < 5 && (
              <Tag
                className="border-dashed border-gray-300 cursor-pointer hover:border-green-500"
                onClick={() => setIsModalVisible(true)}
              >
                <PlusOutlined /> 新增標籤
              </Tag>
            )}
          </div>
        </Form.Item>

        {/* add Modal component */}
        <Modal
          title="新增標籤"
          open={isModalVisible}
          onOk={addTag}
          onCancel={() => {
            setIsModalVisible(false);
            setNewTagInput('');
          }}
          okText="確定"
          cancelText="取消"
        >
          <Input
            placeholder="請輸入標籤名稱"
            value={newTagInput}
            onChange={(e) => setNewTagInput(e.target.value)}
            onPressEnter={addTag}
          />
        </Modal>

        {/* 在標籤區塊後添加社交平台綁定表單 */}
        <div className="mt-8">
          <div className="text-[20px] font-semibold mb-4">綁定官方平台</div>
          {renderPlatformRows()}
        </div>
      </div>
    </>
  );
};

export default ShopInfo;
