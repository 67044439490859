export const AgodaUseCases = {
  // Get complete Agoda chat flow
  getAgodaChatFlow: async (repository, chatroomId) => {
      // Step 1: Get profile
      const profile = await repository.getProfile(chatroomId);
      if (!profile.profileId) {
        throw new Error('無法獲取 Profile ID');
      }

      // Step 2: Get user info
      const users = await repository.getUserInfo(profile.profileId);
      if (!users.length) {
        throw new Error('無法獲取使用者資訊');
      }
      const user = users[0];

      // Step 3: Get source info
      const sources = await repository.getSourceInfo(user.userId);
      
      if (!sources.length) {
        throw new Error('無法獲取房源資訊');
      }

      // Step 4: Get conversation IDs for each source
      const conversationPromises = sources.map(source => 
        repository.getConversationId(source.id)
      );
      const agodaClients = await Promise.all(conversationPromises);
      
      // 將二維數組扁平化為一維數組
      const flattenedAgodaClients = agodaClients.flat(); 
      // 過濾掉空值
      const validConversations = flattenedAgodaClients.filter(conversation => conversation && conversation.id);
      
      // Step 5: Get booking info and messages for each conversation
      const chatData = await Promise.all(
        validConversations.map(async (conversation) => {
          const [bookings, messages] = await Promise.all([
            repository.getBookingInfo(conversation.id),
            repository.getConversationMessages(conversation.id)
          ]);
          
          return {
            conversationId: conversation.id,
            agodaConversationId: conversation.agoda_conversation_id,
            agodaSourceId: conversation.agoda_source_id,
            agodaGuestName: conversation.agoda_conversation_guest_name,
            bookings,
            messages
          };
        })
      );

      return {
        agodaClients: flattenedAgodaClients,
        profile,
        user,
        sources,
        chatData
      };
  },

  getProfile: async (repository, chatroomId) => {
    return await repository.getProfile(chatroomId);
  },

  getUserInfo: async (repository, profileId) => {
    return await repository.getUserInfo(profileId);
  },

  getSourceInfo: async (repository, sourceId) => {
    const sourceInfo = await repository.getSourceInfo(sourceId); 
    return sourceInfo;
  },

  getConversationId: async (repository, sourceId) => {
    const conversationId = await repository.getConversationId(sourceId);
    return conversationId;
  },

  getBookingInfo: async (repository, conversationId) => {
    return await repository.getBookingInfo(conversationId);
  },

  getConversationMessages: async (repository, conversationId) => {
    return await repository.getConversationMessages(conversationId);
  },
  
  
  // Get messages for specific conversation
  getConversationData: async (repository, conversationId) => {
      const [bookings, messages] = await Promise.all([
        repository.getBookingInfo(conversationId),
        repository.getConversationMessages(conversationId)
      ]);

      // Reverse the messages array to display in descending order
      const reversedMessages = messages ? [...messages].reverse() : [];

      return {
        bookings,
        messages: reversedMessages
      };
  },

  sendMessage: async (repository, profileId, sourceId, conversationId, messageType, message, file) => {
    return await repository.sendMessage(profileId, sourceId, conversationId, messageType, message, file);
  }
}; 