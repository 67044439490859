const Privacy = () => {
  return (
    <div className="h-screen overflow-y-auto w-full">
      <div className="flex flex-col items-center justify-center gap-4 max-w-5xl mx-auto h-fit  px-4 py-8">
        <h1 className="text-2xl font-bold">Privacy Policy</h1>
        <div className="w-full mx-auto  font-sans leading-relaxed h-fit">
          <section className="mb-8">
            <h2 className="text-lg mb-4 font-medium">
              1. Scope of Privacy Policy
            </h2>
            <p className="mb-4">
              This Privacy Policy outlines how we handle the collection,
              processing, and utilization of personal identifiable information
              when you use our website's services. This Privacy Policy does not
              apply to other related websites not operated by us or to
              individuals not managed or commissioned by us.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-lg mb-4 font-medium">
              2. Collection, Processing, and Utilization of Personal Data
            </h2>
            <p className="mb-4">
              When you visit our website or use specific services provided by
              our website, we may request necessary personal data based on the
              nature of the service. Your personal data will be processed and
              used within the scope of the specific purpose, and we will not use
              your personal data for other purposes without your written
              consent.
            </p>
            <p className="mb-4">
              When you use interactive features such as service mailboxes and
              surveys, we may retain the information you provide, including your
              name, email address, contact information, and usage time.
            </p>
            <p className="mb-4">
              During general browsing, our server automatically records relevant
              data, including your IP address, usage time, browser used,
              browsing history, and click data. This information is for internal
              use only and will not be disclosed to external parties.
            </p>
            <p className="mb-4">
              To provide accurate services, we may statistically analyze the
              content of surveys and use the analysis results to present
              statistical data or explanatory text. In addition to internal
              research purposes, we may also publish statistical data and
              explanatory text as needed, but this will not involve specific
              personal information.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-lg mb-4 font-medium">3. Data Protection</h2>
            <p className="mb-4">
              Our website's servers are equipped with firewalls, antivirus
              systems, and other relevant information security devices and
              necessary security protection measures to protect the website and
              your personal data. Only authorized personnel can access your
              personal data, and all relevant personnel have signed
              confidentiality agreements. Violations of confidentiality
              obligations will be subject to legal sanctions.
            </p>
            <p className="mb-4">
              When it is necessary to entrust other units to provide services
              due to business needs, we will strictly require them to comply
              with confidentiality obligations and implement necessary
              inspection procedures to ensure their compliance.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-lg mb-4 font-medium">
              4. Related External Links
            </h2>
            <p className="mb-4">
              Our website provides web links to other websites. You can also
              access other websites through the links provided on our website.
              However, the privacy policy of these linked websites does not
              apply to our website. You must refer to the privacy policy of the
              linked website.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-lg mb-4 font-medium">
              5. Policy on Sharing Personal Data with Third Parties
            </h2>
            <p className="mb-4">
              We will never provide, exchange, rent, or sell any of your
              personal data to other individuals, groups, private enterprises,
              or government agencies unless there are legal grounds or
              contractual obligations that require us to do so.
            </p>
            <p className="mb-4">
              The circumstances under which we may disclose your personal data
              include but are not limited to:
            </p>
            <ul className="list-disc pl-8 mb-4">
              <li>With your written consent.</li>
              <li>As required by law.</li>
              <li>
                To prevent risks to your life, body, freedom, or property.
              </li>
              <li>
                In cooperation with government agencies or academic research
                institutions, when it is necessary for statistics or academic
                research for the public interest, and the data is processed or
                collected by the provider in a way that cannot identify specific
                individuals.
              </li>
              <li>
                When your behavior on the website violates the terms of service
                or may harm or interfere with the rights of the website and
                other users, and the website management unit deems it necessary
                to disclose your personal data for identification, contact, or
                legal action.
              </li>
              <li>To protect your interests.</li>
              <li>
                When our website commissions vendors to assist in the
                collection, processing, or use of your personal data, we will
                fulfill our supervisory and management responsibilities for the
                outsourced vendors or individuals.
              </li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-lg mb-4 font-medium">6. Use of Cookies</h2>
            <p className="mb-4">
              To provide you with the best service, our website may place and
              access cookies on your computer. If you do not wish to accept
              cookies, you can set the privacy level to high in your browser's
              settings, which will reject the writing of cookies. However, this
              may affect the normal operation of certain website features.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-lg mb-4 font-medium">
              7. Amendment of Privacy Policy
            </h2>
            <p className="mb-4">
              This website's Privacy Policy may be revised as needed, and the
              revised terms will be posted on the website.
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
