import { BoundApi } from '../api/BoundApi';
import { createBoundClientEntity, createBoundMessageEntity } from '../../domain/entities/bound/boundEntity';
export const BoundRepository = {
  // Line
  fetchLineClients: async (roomId) => {
    const response = await BoundApi.getLineClients(roomId);
      
      // Handle array of clients
      if (Array.isArray(response)) {
        return response.map(client => BoundRepository.transformClientData(client));
      }
      // Handle single client
      return BoundRepository.transformClientData(response);
  },
  fetchLineMessages: async (roomId, socialMediaClientId, limit = 10, fromId = null) => {
    const response = await BoundApi.getLineMessages(roomId, socialMediaClientId, limit, fromId);
    return response.map(chat => BoundRepository.transformChatData(chat));
  },
  sendLineMessage: async (roomId, socialMediaClientId, message) => {
    return await BoundApi.sendLineMessage(roomId, socialMediaClientId, message);

  },
  // Messenger
  fetchMessengerClients: async (roomId) => {
    const response = await BoundApi.getMessengerClients(roomId);
      
      // Handle array of clients
      if (Array.isArray(response)) {
        return response.map(client => BoundRepository.transformClientData(client));
      }
      
      // Handle single client
      return BoundRepository.transformClientData(response);
  },
  fetchMessengerMessages: async (roomId, socialMediaClientId, limit = 10, fromId = null) => {
    const response = await BoundApi.getMessengerMessages(roomId, socialMediaClientId, limit, fromId);
    return response.map(chat => BoundRepository.transformChatData(chat));
  },
  sendFacebookMessage: async (roomId, socialMediaClientId, message) => {
    return await BoundApi.sendFacebookMessage(roomId, socialMediaClientId, message);
  },
  // Agent
  fetchAgentClients: async (roomId) => {
    const response = await BoundApi.getAgentClients(roomId);
      
      // Handle array of clients
      if (Array.isArray(response)) {
        return response.map(client => BoundRepository.transformClientData(client));
      }
      
      // Handle single client
      return BoundRepository.transformClientData(response);
  },
  fetchAgentMessages: async (roomId, socialMediaClientId, limit = 10, fromId = null) => {
    return await BoundApi.getAgentMessages(roomId, socialMediaClientId, limit, fromId);
  },
  sendAgentMessage: async (roomId, socialMediaClientId, message) => {
    return await BoundApi.sendAgentMessage(roomId, socialMediaClientId, message);
  },

  // transform
  transformClientData: (rawData) => {
    if (!rawData) return null;
    return createBoundClientEntity(rawData);
  },
  transformChatData: (rawData) => {
    if (!rawData) return null;
    return createBoundMessageEntity(rawData);
  },
};