import { request } from '../../utils';
import axios from 'axios';
export const AirbnbApi = {
  // Get profile information
  getProfile: async (chatroomId) => {
      return await request.get(`/private/module/rpa/profile/${chatroomId}`, {});
  },

  // Get user information
  getUserInfo: async (profileId) => {
      return await request.get(`/airbnb/user_info_from_db/${profileId}`, {}, true);
  },

  // Get source information
  getSourceInfo: async (userId) => {
    const res = await request.get(`/airbnb/source_info_from_db/${userId}`, {}, true);    
    return res;
},


  // Get conversation ID
  getConversationId: async (sourceId) => {
      return await request.get(`/airbnb/conversation_info_from_db/${sourceId}`, {}, true);
  },

  // Get booking information
  getBookingInfo: async (conversationId) => {
      return await request.get(`/airbnb/booking_info_from_db/${conversationId}`, {}, true);
  },

  // Get conversation messages
  getConversationMessages: async (conversationId) => {
      return await request.get(`/airbnb/message_info_from_db/${conversationId}`, {}, true);
  },
  // Send message
  sendMessage: async (profileId, sourceId, conversationId, messageType, message, file) => {
    
    // 發送文字訊息
    if (messageType === 'text') {
      return await axios.post(`https://rpa.scfg.io/airbnb/send_message/kuhedr4?source_id=${sourceId}&conversation_id=${conversationId}&operation=${messageType}&message=${message}`, {}, 
        {
          headers: {
            'X-API-KEY': 'sc90699098',
            'Content-Type': 'application/json'
          }
        }
      );
    }
    
    // 發送圖片訊息
    if (messageType === 'image' && file) {
      const formData = new FormData();
      // 添加所有參數到 FormData
      formData.append('file', file);
      return await axios.post(`https://rpa.scfg.io/airbnb/send_message/kuhedr4?source_id=${sourceId}&conversation_id=${conversationId}&operation=${messageType}&message=${message}`, formData, {
        headers: {
          'X-API-KEY': 'sc90699098',
          'Content-Type': 'multipart/form-data'
        }
      });
    }

    throw new Error('Invalid message type or missing required parameters');
  },

  // Post airbnb ai message
  postAirbnbAiMessage: async (conversationId, chatroomId, language) => {
    return await axios.post(`https://rpa.scfg.io/airbnb/ai_response/kuhedr4?conversation_id=${conversationId}&chatroom_id=${chatroomId}&language=${language}&x_api_key=sc90699098`, {}, 
      {
        headers: {
          'X-API-KEY': 'sc90699098',
        }
      
    });
  }
}; 