import React, { useState, useEffect, useCallback } from "react";
import { Spin, Button, message, Input, Select, List, Popconfirm, Tag, Divider, ConfigProvider, Popover, Tooltip, Upload } from "antd";
import {
  LoadingOutlined,
  CloseOutlined,
  CheckOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import editIcon from "../assets/edit-icon.png";
import { request } from "../../../../../utils";

const { Option } = Select;
const { TextArea } = Input;

const popconfirmTheme = {
  components: {
    Button: {
      colorPrimary: '#DEACAC',
      colorPrimaryHover: '#C99A9A',
      colorPrimaryActive: '#C99A9A',
      primaryColor: '#000000',  
    },
    Popconfirm: {
      contentPadding: 16,
      textAlign: 'center'
    },
  },
};

export const Qa = ({
  chatroomId,
}) => {
  const [fetching, setFetching] = useState(false);
  const [availableTags, setAvailableTags] = useState([]);
  const [qaPairs, setQaPairs] = useState([]);
  const [editingItemId, setEditingItemId] = useState(null);
  const [editingItemOriginalTags, setEditingItemOriginalTags] = useState([]);
  const [tagInputValue, setTagInputValue] = useState('');
  const [expandedItems, setExpandedItems] = useState({});
  const [showNewQaInput, setShowNewQaInput] = useState(false);
  const [newQaPair, setNewQaPair] = useState({ question: "", answer: "", tag_ids: [] });
  const [isEditing, setIsEditing] = useState(false);
  const [showAddButton, setShowAddButton] = useState(true);
  const fetchTags = useCallback(async () => {
    try {
      const response = await request.get(`/private/module/tags/${chatroomId}`);
      if (response.length > 0) {
        const allTags = response.flatMap(group => group.tags);
        setAvailableTags(allTags);
      } else {
        message.log("獲取標籤失敗");
      }
    } catch (error) {
      console.error("獲取標籤時發生錯誤:", error);
      message.error("獲取標籤時發生錯誤");
    }
  }, [chatroomId]);

  const fetchQaPairs = useCallback(async () => {
    if (isEditing) return; 
    setFetching(true);
    try {
      const response = await request.get(`/private/chatrooms/vector_store/qa/${chatroomId}`);
      if (Array.isArray(response)) {
        setQaPairs(response);
      } else {
        message.error("獲取 QA 失敗");
      }
    } catch (error) {
      console.error("獲取 QA 時發生錯誤:", error);
      message.error("獲取 QA 時發生錯誤");
    } finally {
      setFetching(false);
    }
  }, [chatroomId, isEditing]);

  useEffect(() => {
    fetchTags();
    fetchQaPairs();
  }, [fetchQaPairs, fetchTags]);

  const handleAddNewQaInput = () => {
    setShowNewQaInput(true);
    setShowAddButton(false);
    setNewQaPair({ question: "", answer: "", tag_ids: [] });
  };

  const handleQaInputChange = (field, value) => {
    setNewQaPair(prev => ({ ...prev, [field]: value }));
  };

  const handleConfirmQa = async () => {
    const { question, answer, tag_ids } = newQaPair;
    if (!question || !answer) {
      message.error("問題和回答不能為空");
      return;
    }

    try {
      const params = new URLSearchParams();
      tag_ids.forEach(id => params.append('tag_ids', id));
      const response = await request.post(
        `/private/chatrooms/vector_store/qa/${chatroomId}`,
        { question, answer },
        {
          params: params
        }
      );

      if (response) {
        message.success("QA增加成功");
        setShowNewQaInput(false);
        setShowAddButton(true);
        fetchQaPairs();
      } else {
        message.error("增加 QA失敗");
      }
    } catch (error) {
      console.error("增加 QA 時發生錯誤:", error);
      message.error("增加 QA 時發生錯誤");
    }
  };

  const handleCancelNewQa = () => {
    setShowNewQaInput(false);
    setShowAddButton(true);
  };

  const handleDeleteQa = async (qaRecordId) => {
    try {
      const response = await request.delete(`/private/chatrooms/vector_store/qa/${chatroomId}/byId/${qaRecordId}`);
      // 檢查 response.data 中的消息
      if (response && response.message === "QA pair deleted") {
        message.success("QA 刪除成功");
        fetchQaPairs();
      } else {
        message.error("刪除 QA 失敗");
      }
    } catch (error) {
      console.error("刪除 QA 時發生錯誤:", error);
      message.error("刪除 QA 時發生錯誤");
    }
  };

  const handleEditQa = (qa) => {
    setIsEditing(true);
    setEditingItemId(qa.id);
    setShowAddButton(false);
    setEditingItemOriginalTags(qa.tags.map(tag => tag.id));
  };

  const handleEditQaSubmit = async (item) => {
    try {
      const params = new URLSearchParams();
      item.tags.forEach(tag => params.append('tag_ids', tag.id));
      const response = await request.patch(
        `/private/chatrooms/vector_store/qa/${chatroomId}/tags/${item.id}`,
        {
          question: item.question,
          answer: item.answer
        },
        {
          params: params
        }
      );      
      if (response) {
        message.success("QA 更新成功");
        setEditingItemId(null);
        setShowAddButton(true); 
        fetchQaPairs();
      } else {        
        message.error("更新 QA 失敗");
      }
    } catch (error) {
      console.error("更新 QA 時發生錯誤:", error);
      message.error("更新 QA 時發生誤");
    }
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setShowAddButton(true);
    setEditingItemId(null);
  };

  const handleAddNewTag = async (qaId, newTagText) => {
    try {
      const response = await request.post(`/private/module/tags/${chatroomId}/QA`, {
        text: newTagText
      });

      if (response && response.id) {
        message.success("新標籤增加成功");
        setAvailableTags([...availableTags, response]);
        const updatedQaPairs = qaPairs.map(qa =>
          qa.id === qaId ? { ...qa, tags: [...qa.tags, response] } : qa
        );
        setQaPairs(updatedQaPairs);
        setTagInputValue('');
        return response;
      } else {
        message.error("增加新標籤失敗");
      }
      
    } catch (error) {
      console.error("增加新標籤時發生錯誤:", error);
      message.error("增加新標籤時發生錯誤");
    }
  };

  const tagRender = (tag) => (
    <Tag
      key={tag.id}
      color="blue"
      style={{
        marginRight: 3,
        marginBottom: 3,
        border: 'none',
        padding: '0 7px',
        borderRadius: '2px',
        backgroundColor: '#e6f7ff',
        color: '#1890ff',
      }}
    >
      {tag.name}
    </Tag>
  );

  const toggleExpand = (itemId) => {
    setExpandedItems(prev => {
      // 如果當前項目已經展開,則關閉它
      if (prev[itemId]) {
        return {};
      }
      // 否則,關閉所有其他項目,只展開當前項目
      return { [itemId]: true };
    });
  };

  const TruncatedText = ({ text, maxLength = 50 }) => {
    if (text.length <= maxLength) return text;
    return `${text.substring(0, maxLength)}...`;
  };

  const PreviewContent = ({ content }) => (
    <div style={{ maxWidth: '300px', maxHeight: '200px', overflow: 'auto' }}>
      <div style={{ whiteSpace: 'pre-wrap' }}>{content}</div>
    </div>
  );

  const handleImportExcel = async (file) => {
    const formData = new FormData();
    formData.append('excel_file', file);

    try {
      const response = await request.post(
        `/private/chatrooms/vector_store/qa/${chatroomId}/fromExcel`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (Array.isArray(response)) {
        message.success('Excel 檔案匯入成功');
        fetchQaPairs(); // 重新獲取 QA 列表
      } else {
        message.error('匯入 Excel 檔案失敗');
      }
    } catch (error) {
      console.error('匯入 Excel 檔案時發生錯誤:', error);
      message.error('匯入 Excel 檔案時發生錯誤');
    }
  };

  return (
    <div className="flex flex-col w-full h-full justify-start p-3 gap-1">
      {/* 表頭 */}
      <div className="flex flex-row justify-start bg-bgDark h-8 items-center w-full gap-3 px-2">
        <div className="flex justify-start text-sm w-1/3">問題內容</div>
        <div className="flex justify-start text-sm w-1/3">最佳回答</div>
        <div className="flex justify-start text-sm w-1/6">標籤</div>
        <div className="flex w-1/6 bg-bgDark h-full ml-2" />
      </div>
      {/* QA 列 */}
      {fetching ? (
        <div className="flex justify-center items-center w-full h-72">
          <Spin
            indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}
          />
        </div>
      ) : (
        <List
          dataSource={qaPairs}
          split={false}
          className="overflow-visible"
          renderItem={(item, index) => (
            <React.Fragment key={item.id}>
              <List.Item className={`transition-colors duration-300 ${expandedItems[item.id] ? 'bg-gray-200 py-4' : ''}`}>
                <div className="flex flex-row w-full items-center px-2 gap-3">
                  {editingItemId === item.id ? (
                    <>
                      <div className="w-1/3 mr-2">
                        {item.question}
                      </div>
                      <div className="w-1/3 mr-2">
                        {item.answer}
                      </div>
                      <Select
                        className="w-1/6"
                        mode="multiple"
                        style={{ width: '26.67%' }}
                        showSearch
                        value={item.tags.map(tag => tag.id)}
                        onChange={(newValue) => {
                          const originalTagIds = editingItemOriginalTags;
                          const updatedTagIds = [...new Set([...originalTagIds, ...newValue])];
                          const updatedTags = availableTags.filter(tag => updatedTagIds.includes(tag.id));
                          const updatedQaPairs = qaPairs.map(qa =>
                            qa.id === item.id ? { ...qa, tags: updatedTags } : qa
                          );
                          setQaPairs(updatedQaPairs);
                        }}
                        tagRender={(props) => {
                          const { label, value, onClose } = props;
                          const isOriginal = editingItemOriginalTags.includes(value);
                          return (
                            <Tag
                              color={isOriginal ? "default" : "blue"}
                              closable={!isOriginal}
                              onClose={onClose}
                              style={{
                                marginRight: 3,
                                border: 'none',
                                padding: '0 7px',
                                borderRadius: '2px',
                                backgroundColor: isOriginal ? '#f0f0f0' : '#e6f7ff',
                                color: isOriginal ? 'rgba(0, 0, 0, 0.65)' : '#1890ff',
                              }}
                            >
                              {label}
                            </Tag>
                          );
                        }}
                        dropdownRender={(menu) => (
                          <>
                            <div>{menu}</div>
                            <div style={{ display: 'flex', flexWrap: 'nowrap',  gap: 8  }}>
                              <Input
                                style={{ flex: 'auto' }}
                                placeholder="標籤名稱"
                                value={tagInputValue}
                                onChange={(e) => setTagInputValue(e.target.value)}
                              />
                              <Button
                                type="text"
                                icon={<PlusOutlined />}
                                onClick={() => {
                                  if (tagInputValue) {
                                    handleAddNewTag(item.id, tagInputValue).then((newTag) => {
                                      if (newTag) {
                                        const updatedQaPairs = qaPairs.map(qa =>
                                          qa.id === item.id ? { ...qa, tags: [...qa.tags, newTag] } : qa
                                        );
                                        setQaPairs(updatedQaPairs);
                                        setTagInputValue(''); 
                                      }
                                    });
                                  }
                                }}
                              >
                                新增
                              </Button>
                            </div>
                          </>
                        )}
                      >
                        {availableTags.map(tag => {
                          const isSelected = item.tags.some(itemTag => itemTag.id === tag.id);
                          const isOriginal = editingItemOriginalTags.includes(tag.id);
                          return (
                            <Option
                              key={tag.id}
                              value={tag.id}
                              style={{
                                backgroundColor: isSelected ? (isOriginal ? '#f0f0f0' : '#e6f7ff') : 'transparent',
                              }}
                            >
                              <Tag
                                color={isOriginal ? "default" : "blue"}
                                style={{
                                  border: 'none',
                                  padding: '0 7px',
                                  borderRadius: '2px'
                                }}
                              >
                                {tag.name}
                              </Tag>
                            </Option>
                          );
                        })}
                      </Select>
                      
                      <div className="w-1/6 flex justify-end gap-4">
                        <Tooltip title="取消">
                          <CloseOutlined
                              className="text-textDark hover:text-red-500 cursor-pointer h-full text-xl"
                              onClick={handleCancelEdit}
                          />
                        </Tooltip>
                        <Tooltip title="保存">
                          <CheckOutlined
                            className="text-textDark hover:text-red-500 cursor-pointer h-full text-xl"
                            onClick={() => handleEditQaSubmit(item)}
                          />
                        </Tooltip>
                      </div>
                    </>
                  ) : (
                    <>
                      <div 
                        className={`flex flex-row w-full items-start cursor-pointer gap-3 ${expandedItems[item.id] ? 'py-2 px-1' : ''}`}
                        onClick={() => toggleExpand(item.id)}
                      >
                        {expandedItems[item.id] ? (
                          <>
                            <div className="w-1/3 whitespace-pre-wrap pr-2 leading-relaxed text-base">{item.question}</div>
                            <div className="w-1/3 whitespace-pre-wrap pr-2 leading-relaxed text-base">{item.answer}</div>
                            <div className="w-1/6 whitespace-pre-wrap pr-2">
                              {item.tags.map(tag => tagRender(tag))}
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="w-1/3 overflow-hidden text-ellipsis whitespace-nowrap">
                              {item.question}
                            </div>
                            <Popover
                              content={<PreviewContent content={item.answer} />}
                              title="最佳回答預覽"
                              trigger="hover"
                              placement="right"
                              mouseEnterDelay={0.1}
                              mouseLeaveDelay={0.1}
                            >
                              <div className="w-1/3 overflow-hidden text-ellipsis whitespace-nowrap">
                                <TruncatedText text={item.answer} />
                              </div>
                            </Popover>
                            <div className="w-1/6 overflow-hidden text-ellipsis whitespace-nowrap">
                              {item.tags.map(tag => tagRender(tag))}
                            </div>
                          </>
                        )}
                        <div className="w-1/6 flex justify-end items-center">
                          <Tooltip title="編輯">
                            <Button
                              icon={<img src={editIcon} alt="edit" className="w-5 h-5" />}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleEditQa(item);
                              }}
                              className="mr-2 bg-transparent border-none shadow-none hover:bg-gray-100 p-2 flex items-center justify-center"
                            />
                          </Tooltip>
                          <ConfigProvider theme={popconfirmTheme}>
                            <Popconfirm
                              title={
                                <div style={{ fontSize: '16px', marginBottom: '10px', textAlign: 'center' }}>
                                  移除問答記憶資料
                                </div>
                              }
                              description={
                                <div style={{ fontSize: '14px', lineHeight: '1.5', marginBottom: '20px', textAlign: 'center' }}>
                                  <br />
                                  請確認是否將這則問答記憶移除？
                                  <br />
                                </div>
                              }
                              onConfirm={(e) => {
                                e.stopPropagation();
                                handleDeleteQa(item.id);
                              }}
                              okText="刪除"
                              cancelText="取消"
                              icon={<DeleteOutlined style={{ color: 'red' }} />}
                              overlayInnerStyle={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center'
                              }}
                              footer={(_, { OkBtn, CancelBtn }) => (
                                <div>
                                  <CancelBtn style={{ width: '80px'}} />
                                  <OkBtn style={{ width: '80px'}} />
                                </div>
                              )}
                            >
                              <Tooltip title="刪除">
                                <CloseOutlined
                                  className="text-textDark hover:text-red-500 cursor-pointer h-full text-xl"
                                  onClick={(e) => e.stopPropagation()}
                                />
                              </Tooltip>
                            </Popconfirm>
                          </ConfigProvider>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </List.Item>
              <Divider 
                style={{ 
                  margin: 0,
                  borderTopColor: '#d9d9d9'
                }} 
              />
            </React.Fragment>
          )}
        />
      )}

      {/* 新增 QA 輸入框 */}
      {showNewQaInput && (
        <div className="flex flex-row items-center w-full gap-0 my-2 gap-3">
          <Input
            placeholder="問題內容"
            value={newQaPair.question}
            onChange={(e) => handleQaInputChange("question", e.target.value)}
            className="w-1/3"
          />
          <TextArea
            placeholder="最佳回答"
            value={newQaPair.answer}
            onChange={(e) => handleQaInputChange("answer", e.target.value)}
            className="w-1/3 mx-1"
            autoSize={{ minRows: 1, maxRows: 6 }}
          />
          <Select
            mode="multiple"
            style={{ width: '26.67%' }}
            className="w-1/6"
            showSearch
            placeholder="標籤"
            value={newQaPair.tag_ids}
            onChange={(value) => handleQaInputChange("tag_ids", value)}
            tagRender={(props) => {
              const { label, onClose } = props;
              return (
                <Tag
                  closable
                  onClose={onClose}
                  style={{
                    marginRight: 3,
                    border: 'none',
                    padding: '0 7px',
                    borderRadius: '2px',
                    backgroundColor: '#e6f7ff',
                    color: '#1890ff',
                  }}
                >
                  {label}
                </Tag>
              );
            }}
            dropdownRender={(menu) => (
              <>
                {menu}
                <div style={{ display: 'flex', flexWrap: 'nowrap', gap: 8 }}>
                  <Input
                    style={{ flex: 'auto' }}
                    placeholder="標籤名稱"
                    value={tagInputValue}
                    onChange={(e) => setTagInputValue(e.target.value)}
                  />
                  <Button
                    type="text"
                    icon={<PlusOutlined />}
                    onClick={() => {
                      if (tagInputValue) {
                        handleAddNewTag(null, tagInputValue).then((newTag) => {
                          if (newTag) {
                            setNewQaPair(prev => ({
                              ...prev,
                              tag_ids: [...prev.tag_ids, newTag.id]
                            }));
                            setTagInputValue('');
                          }
                        });
                      }
                    }}
                  >
                    新增
                  </Button>
                </div>
              </>
            )}
          >
            {availableTags.map(tag => (
              <Option key={tag.id} value={tag.id}>
                <span
                  style={{
                    padding: '0 7px',
                    borderRadius: '2px',
                    backgroundColor: '#e6f7ff',
                    color: '#1890ff',
                  }}
                >
                  {tag.name}
                </span>
              </Option>
            ))}
          </Select>
          <div className="flex justify-end px-1 w-1/6 gap-4">
            <CloseOutlined
              className="text-textDark hover:text-red-500 cursor-pointer h-full text-xl"
              onClick={handleCancelNewQa}
            />
            <CheckOutlined
              className="text-textDark hover:text-red-500 cursor-pointer h-full text-xl"
              onClick={handleConfirmQa}
            />
          </div>
        </div>
      )}
      {/* 按鈕區 */}
      {showAddButton &&(
        <div className="flex justify-end w-full mt-3 gap-3">
          <Upload
            accept=".xlsx,.xls"
            beforeUpload={(file) => {
              handleImportExcel(file);
              return false; 
            }}
            showUploadList={false}
          >
            <Tooltip title="匯入Excel檔案">
              <Button
                className="text-black-900 text-[16px] leading-[1.3] w-[58px] bg-[#ADDEAC] flex items-center justify-center"
                style={{ transition: "background-color 0.3s, color 0.3s" }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.backgroundColor = "rgba(173, 222, 172, 0.8)";
                  e.currentTarget.style.color = "inherit";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.backgroundColor = "#ADDEAC";
                  e.currentTarget.style.color = "inherit";
                }}
              >
                匯入
              </Button>
            </Tooltip>
          </Upload>
          <Tooltip title="新增問答記憶">
            <Button
              className="text-black-900 text-[16px] leading-[1.3] w-[58px] bg-[#ADDEAC] flex items-center justify-center"
              style={{ transition: "background-color 0.3s, color 0.3s" }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = "rgba(173, 222, 172, 0.8)";
                e.currentTarget.style.color = "inherit";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = "#ADDEAC";
                e.currentTarget.style.color = "inherit";
              }}
              onClick={handleAddNewQaInput}
            >
              新增
            </Button>
          </Tooltip>
        </div>
      )}
    </div>
  );
};
