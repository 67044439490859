import React, { useState, useMemo } from 'react';
import { Table, Input, Select, Badge, Button, Tabs, Tag, Divider } from 'antd';
import { SearchOutlined, HomeOutlined } from '@ant-design/icons';
import 'tailwindcss/tailwind.css';
import { useNavigate, Link } from 'react-router-dom';
import ToolBar from '../ToolBar';
import moment from 'moment';

// 將測試數據移到組件外部
const mockOrderData = Array(10).fill(null).map(() => ({
  key: Math.random().toString(),
  orderNumber: '59217',
  buyer: 'Mrs.Wang (0988888888)',
  orderDate: '2024-10-14 7:22PM',
  useDate: '2024-10-14 7:22PM',
  orderStatus: 'Completed',
  paymentStatus: 'Paid',
  total: 1800,
}));

const OrderManagement = ({ service, onBack }) => {
  const [selectedOrder, setSelectedOrder] = useState(mockOrderData[0]);
  

  // 使用 useMemo 來緩存訂單數據
  const orderData = useMemo(() => {
    return mockOrderData;
  }, []);

  // 篩選相關狀態
  const [searchText, setSearchText] = useState('');
  const [selectedStatus, setSelectedStatus] = useState(null);

  // Order Status選項
  const statusOptions = [
    { value: 'all', label: 'All Orders' },
    { value: 'Pending', label: 'Pending' },
    { value: 'Completed', label: 'Completed' },
    { value: 'Confirmed', label: 'Confirmed' },
    { value: 'Cancelled', label: 'Cancelled' }
  ];

  // 添加表格列定义
  const columns = [
    {
      title: 'Order Number',
      dataIndex: 'orderNumber',
      key: 'orderNumber',
    },
    {
      title: 'Buyer',
      dataIndex: 'buyer',
      key: 'buyer',
    },
    {
      title: 'Order Date',
      dataIndex: 'orderDate',
      key: 'orderDate',
    },
    {
      title: 'Usage Date',
      dataIndex: 'useDate',
      key: 'useDate',
    },
    {
      title: 'Order Status',
      dataIndex: 'orderStatus',
      key: 'orderStatus',
      render: (status) => {
        const statusStyles = {
          'Confirmed': { color: '#52c41a', bg: '#f6ffed' },
          'Completed': { color: '#8c8c8c', bg: '#f5f5f5' },
          'Cancelled': { color: '#8c8c8c', bg: '#f5f5f5' },
          '付款失敗': { color: '#8c8c8c', bg: '#f5f5f5' }
        };
        const style = statusStyles[status] || { color: '#8c8c8c', bg: '#f5f5f5' };
        return (
          <span
            className="px-3 py-1 rounded-full text-sm"
            style={{
              color: style.color,
              backgroundColor: style.bg
            }}
          >
            {status}
          </span>
        );
      }
    },
    {
      title: 'Payment Status',
      dataIndex: 'paymentStatus',
      key: 'paymentStatus',
      render: (status) => (
        <Badge
          status={status === 'Paid' ? 'success' : 'default'}
          text={status}
        />
      ),
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      render: (total) => `${total}`,
    },
  ];

  // 訂單詳情組件
  const OrderDetail = ({ order }) => {
    if (!order) return null;

    return (
      <div className="p-4">
        <h3 className="text-lg font-bold mb-4">訂單詳情</h3>
        <div className="space-y-4">
          <div>
            <p className="text-gray-500">Order Number</p>
            <p className="font-medium">{order.orderNumber}</p>
          </div>
          <div>
            <p className="text-gray-500">Buyer</p>
            <p className="font-medium">{order.buyer}</p>
          </div>
          <div>
            <p className="text-gray-500">Order Date</p>
            <p className="font-medium">{order.orderDate}</p>
          </div>
          <div>
            <p className="text-gray-500">Usage Date</p>
            <p className="font-medium">{order.useDate}</p>
          </div>
          <div>
            <p className="text-gray-500">Order Status</p>
            <Badge status={order.orderStatus === 'Completed' ? 'success' : 'default'} text={order.orderStatus} />
          </div>
          <div>
            <p className="text-gray-500">Payment Status</p>
            <Badge status={order.paymentStatus === 'Paid' ? 'success' : 'default'} text={order.paymentStatus} />
          </div>
          <div>
            <p className="text-gray-500">Total</p>
            <p className="font-medium">{order.total}</p>
          </div>
        </div>
      </div>
    );
  };

  // 更新訂單統計區域的渲染
  const StatisticsSection = () => (
    <div className="grid grid-cols-5 gap-4 mb-4">
      <div className="col-span-2 bg-[#F3F3F3] rounded-lg p-4">
        <div className="flex items-center gap-2 mb-2">
          <img src="/images/folder.png" alt="folder" className="w-5 h-5" />
        </div>
        <div className="grid grid-cols-3 gap-2">
          <div>
            <div className="text-sm text-gray-600">All Orders</div>
            <div className="text-2xl font-bold">97</div>
          </div>
          <div>
            <div className="text-sm text-gray-600">Pending</div>
            <div className="text-2xl font-bold">0</div>
          </div>
          <div>
            <div className="text-sm text-gray-600">Completed</div>
            <div className="text-2xl font-bold">1</div>
          </div>
        </div>
      </div>
      <div className="col-span-3 bg-[#F3F3F3] rounded-lg p-4">
        <div className="flex items-center gap-2 mb-2">
          <img src="/images/folder.png" alt="folder" className="w-5 h-5" />
        </div>
        <div className="grid grid-cols-3 gap-2">
          <div>
            <div className="text-sm text-gray-600">Completed</div>
            <div className="text-2xl font-bold">0</div>
          </div>
          <div>
            <div className="text-sm text-gray-600">Confirmed</div>
            <div className="text-2xl font-bold">0</div>
          </div>
          <div>
            <div className="text-sm text-gray-600">Cancelled</div>
            <div className="text-2xl font-bold">0</div>
          </div>
        </div>
      </div>
    </div>
  );

  // 更新返回按鈕和麵包屑導航部分
  const BreadcrumbNav = () => (
    <div className="flex flex-col space-y-2 mb-4">
      <div className="text-xl font-bold">
      Golf Cart Rental-Taichung
      </div>
      <div className="flex items-center text-sm">
        <Link to="/" className="text-green-600">
          <HomeOutlined className="mr-1" />
        </Link>
        <span className="mx-2 text-gray-500">/</span>
        <span
          className="text-green-600 cursor-pointer"
          onClick={() => window.location.href = '/service'}
        >
          Service Management
        </span>
        <span className="mx-2 text-gray-500">/</span>
        <span className="text-gray-500">Order Records</span>
      </div>
    </div>
  );

  const items = [
    {
      key: '1',
      label: 'Service Information',
      children: (
        <div className="flex-1 pb-20">
          <div className="flex flex-col bg-white rounded-lg shadow gap-1">
            {/* Top area */}
            <div className="p-4">
              <div className="relative mb-4">
                {service.recommendation && service.recommendation >= 1 && service.recommendation <= 5 && (
                  <div className="absolute top-2 left-2">
                    <Tag color="red">熱門</Tag>
                  </div>
                )}
                <img
                  src={service.imageUrl || "/images/default-service.jpg"}
                  alt={service.name}
                  className="w-full h-48 object-cover rounded-lg"
                />
              </div>

              <h3 className="text-lg font-bold mb-1">{service.name}</h3>
              <p className="text-sm text-gray-600 mb-2">{service.provider}</p>
              {service.description && (
                <div className="">
                  <p className="text-sm text-gray-700">
                    {service.description}
                  </p>
                </div>
              )}
              <div className="text-[24px] mb-2 text-primary flex items-baseline justify-end pb-2">
              <span className="font-roboto text-2xl font-medium leading-[26px] tracking-[-0.9px] text-right">
                $ {Number(service.price).toLocaleString()}
              </span>
              <span className="text-xl text-black ml-1 align-baseline"></span>
            </div>
            </div>

            {/* Duration selection */}
            {service.pricingMode === "tiered" && service.pricingTable && (
              <>
                <div className="bg-white p-4">
                  <h4 className="text-base font-bold mb-3">Select Specification</h4>
                  <h6 className="text-sm text-gray-600 mb-2">Model</h6>
                  <div className="flex flex-wrap gap-2">
                    {service.pricingTable.map((item) => (
                      <Button
                        key={item.actualDuration}
                        className="min-w-[80px] mb-2"
                      >
                        {item.actualDuration}
                      </Button>
                    ))}
                  </div>
                </div>
                <Divider className="border-b-[5px] border-[#F0F0F0] mx-4 my-2" />
              </>
            )}

            {/* Reservation time selection */}
            <div className="p-2">
              <h4 className="text-base font-bold mb-3">Select Time</h4>
              <div className="grid grid-cols-2 gap-4 mb-4">
                <div>
                  <div className="text-sm mb-2">Date</div>
                  <div className="w-full h-[32px] border rounded px-3 py-1 bg-gray-50">
                    {moment().format('YYYY-MM-DD')}
                  </div>
                </div>
                <div>
                  <div className="text-sm mb-2">Time</div>
                  <div className="w-full h-[32px] border rounded px-3 py-1 bg-gray-50">
                    15:00
                  </div>
                </div>
              </div>

              {/* 9-grid time selection */}
              <div className="grid grid-cols-3 gap-2">
                {[
                  "09:00", "10:00", "11:00",
                  "14:00", "15:00", "16:00",
                  "17:00", "18:00", "19:00"
                ].map((time) => (
                  <div
                    key={time}
                    className={`text-center py-2 ${time === "15:00" ? "bg-primary text-white" : "bg-gray-200"
                      } min-w-[80px] mb-2 rounded`}
                  >
                    {time}
                  </div>
                ))}
              </div>
            </div>

            {/* Notice area */}
            {service.serviceNotice && (
              <div className="py-4">
                <h4 className="text-base font-bold mb-2">注意事項</h4>
                <p className="text-sm text-gray-700">
                  {service.serviceNotice}
                </p>
              </div>
            )}
          </div>
        </div>
      ),
    },
    {
      key: '2',
      label: 'Order Information',
      children: (
        <div className='p-4 bg-white'>
          <div className="flex-1 pb-20">
            {/* 訂單資料 */}
            <div className="bg-white">
              <div className="p-4 border-b bg-gray-100">
                <h3 className="text-xl font-bold text-center">訂單資料</h3>
              </div>

              <div className="p-4 space-y-6  bg-gray-100">
                <div>
                  <p className="text-gray-900 font-bold mb-2">訂單號碼</p>
                  <p>59217</p>
                </div>

                <div>
                  <p className="text-gray-900 font-bold mb-2">Order Date</p>
                  <p>2024-10-14 7:22PM</p>
                </div>

                <div>
                  <p className="text-gray-900 font-bold mb-2">Order Status</p>
                  <span className="inline-block px-4 py-1 bg-[#67BE5F] text-white rounded-full">
                    Confirmed
                  </span>
                </div>

                <div>
                  <p className="text-gray-900 font-bold mb-2">Usage Date</p>
                  <p>2024-10-14 7:22PM</p>
                </div>

                <div>
                  <p className="text-gray-900 font-bold mb-2">訂單備註</p>
                  <p>無</p>
                </div>
              </div>
            </div>

            {/* Buyer資訊 */}
            <div className="bg-white mt-4">
              <div className="p-4 border-b  bg-gray-100">
                <h3 className="text-xl font-bold text-center">Buyer資訊</h3>
              </div>

              <div className="p-4 space-y-6  bg-gray-100">
                <div>
                  <p className="text-gray-900 font-bold mb-2">Buyer</p>
                  <p>Mrs.Wang</p>
                </div>

                <div>
                  <p className="text-gray-900 font-bold mb-2">訂單Email</p>
                  <p>meimei@gmail.com</p>
                </div>

                <div>
                  <p className="text-gray-900 font-bold mb-2">訂單電話</p>
                  <p>0988-888-888</p>
                </div>
              </div>
            </div>

            {/* 付款資料 */}
            <div className="bg-white mt-4">
              <div className="p-4 border-b  bg-gray-100">
                <h3 className="text-xl font-bold text-center">付款資料</h3>
              </div>

              <div className="p-4 space-y-6  bg-gray-100">
                <div>
                  <p className="text-gray-900 font-bold mb-2">Payment Status</p>
                  <span className="inline-block px-4 py-1 bg-[#67BE5F] text-white rounded-full">
                    Paid
                  </span>
                </div>

                <div>
                  <p className="text-gray-900 font-bold mb-2">付款方法</p>
                  <p>現金支付</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ),
    }
  ];

  return (
    <div className="flex flex-col items-center justify-start w-full h-screen overflow-auto">
      <ToolBar pure={true} />
      <div className="flex flex-col w-full h-[calc(100vh-64px)]">
        <div className="flex-1 flex overflow-auto">
          <div className="flex w-full overflow-auto bg-gray-50">
            {/* 左側訂單列表區域 */}
            <div className="flex-1 flex flex-col shadow min-w-0 overflow-auto h-full p-4">
              {/* 替換原有的返回按鈕為新的麵包屑導航 */}
              <BreadcrumbNav />

              {/* 訂單統計資訊 */}
              <StatisticsSection />

              {/* 搜尋和篩選 */}
              <div className="flex space-x-4 mb-4">
                <Input
                  className="w-80"
                  placeholder="Search"
                  prefix={<SearchOutlined />}
                  onChange={(e) => setSearchText(e.target.value)}
                />
                <Select
                  className="w-40"
                  placeholder="Order Status"
                  options={statusOptions}
                  onChange={setSelectedStatus}
                  allowClear
                />
              </div>

              {/* 訂單表格 */}
              <div className="flex-1 overflow-auto">
                <Table
                  columns={columns}
                  dataSource={orderData}
                  pagination={{
                    pageSize: 10,
                    className: "sticky bottom-0 pb-2"
                  }}
                  onRow={(record) => ({
                    onClick: () => setSelectedOrder(record),
                    className: record.key === selectedOrder?.key ? "bg-gray-100" : "",
                  })}
                  rowClassName={(record) =>
                    `cursor-pointer ${record.key === selectedOrder?.key ? "bg-gray-100" : ""}`
                  }
                  scroll={{ x: "max-content" }}
                  className="[&_.ant-table-thead>tr>th]:bg-[#E0F0E0]"
                  size="small"
                />
              </div>
            </div>

            {/* 右側預覽區域 */}
            <div className="w-[440px] flex-shrink-0 shadow overflow-auto">
              {selectedOrder && (
                <div className="flex flex-col h-full">
                  <Tabs
                    defaultActiveKey="1"
                    items={items}
                    className="flex-1 [&_.ant-tabs-content-holder]:overflow-auto [&_.ant-tabs-nav]:px-4 [&_.ant-tabs-nav]:pt-4"
                  />
                  <div className="fixed w-[440px] bottom-0 bg-white border shadow-3xl p-4">
                    <Button
                      type="primary"
                      className="w-full h-10 bg-[#4CAF50] hover:bg-[#45a049]"
                    >
                      Editing Service
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderManagement;
