import React, { useEffect, useState } from "react";
import { request } from "@/utils";
import { Button, Form, Tabs, message, Select, Spin, Divider } from "antd";
import { LoadingOutlined, HomeOutlined } from '@ant-design/icons';
import "tailwindcss/tailwind.css";
import ServicePricing from "./ServicePricing";
import DiscountManagement from "./DiscountManagement";
import ServicePublish from "./ServicePublish";
import Information from "./Information";
import moment from "moment";
import { Link } from "react-router-dom";
import axios from "axios";
import { updateServiceAPI, addServiceSpec } from "../../services/api";

const AddServiceForm = ({ onSave, services, editMode = false, serviceData = null }) => {
  const [loading, setLoading] = useState(true);
  const [tabLoading, setTabLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [serverUrl, setServerUrl] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const [form] = Form.useForm();
  const [previewData, setPreviewData] = useState({
    name: '',
    provider: '',
    price: 0,
    description: '',
    serviceNotice: '',
    reservation: '',
    bookingPeriod: '',
    imageUrl: '/images/default-service.jpg',
  });
  const [tableLoading, setTableLoading] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [categories, setCategories] = useState([]);

  // Get form values
  const formValues = Form.useWatch([], form);

    
  // Update preview data
  useEffect(() => {
    if (formValues) {
      // 找到對應的組織名稱
      const selectedOrganization = organizations.find(org => org.id === formValues.provider);
      const organizationName = selectedOrganization ? selectedOrganization.name : '';

      // 根據價格模式取得價格
      let price = previewData.price; // 保留原有價格作為預設值
      if (formValues.pricingMode === 'fixed') {
        price = formValues.fixedPrice || previewData.price;
      } else if (formValues.pricingTable?.length > 0) {
        const validDurations = formValues.pricingTable.filter(item =>
          item.price && String(item.price).length > 0
        );
        price = validDurations.length > 0 ? validDurations[0].price : previewData.price;
      }

      setPreviewData(prev => ({
        ...prev,
        name: formValues.serviceTitle || '',
        provider: organizationName || '',
        price: Number(price) || 0,
        description: formValues.serviceDescription || '',
        serviceNotice: formValues.serviceNotice || '',
        reservation: formValues.reservation || '',
        bookingPeriod: formValues.bookingPeriod || '',
        imageUrl: formValues.serviceImage || '/images/default-service.jpg',
        pricingTable: formValues.pricingTable?.map(item => ({
          ...item,
          price: Number(item.price) || 0,
          selected: false
        })) || [],
      }));
    }
  }, [formValues, organizations]);

  useEffect(() => {
    const initializeData = async () => {
      try {
        // 1. 獲取 server_url 和 access_token
        const profileResponse = await request.get(`${SERVER_URL}/private/property/ecommerce/profile`);
        const serverUrl = profileResponse.body.server_url;
        setServerUrl(serverUrl);

        const tokenResponse = await request.get(`${SERVER_URL}/private/property/ecommerce/jwt`);
        const accessToken = tokenResponse.access_token;
        setAccessToken(accessToken);

        if (serverUrl) {
          // 2. 獲取分類列表
          const categoriesResponse = await axios.get(
            `${serverUrl}/public/category/`
          );
          setCategories(categoriesResponse.data);

          // 3. 獲取商家���表
          const organizationsResponse = await axios.get(
            `${serverUrl}/public/organization/`
          );
          setOrganizations(organizationsResponse.data);

          // 4. 獲取服務價格資料
          const servicesResponse = await axios.get(
            `${serverUrl}/public/sp/`
          );
          
          // 設置預覽資料的初始價格
          if (servicesResponse.data && servicesResponse.data.length > 0) {
            const lowestPrice = Math.min(...servicesResponse.data.map(service => 
              Math.min(...(service.specs?.map(spec => spec.price) || [0]))
            ));
            
            setPreviewData(prev => ({
              ...prev,
              price: lowestPrice || 0
            }));
          }
        }
        setLoading(false);
      } catch (error) {
        console.error('初始化數據失敗:', error);
        message.error('獲取初始數據失敗');
        setLoading(false);
      }
    };

    initializeData();
  }, []);

  // 如果是編輯模式，設置初始值
  useEffect(() => {
    if (editMode && serviceData && !loading && organizations.length > 0) {
      console.log('設置編輯模式初始值:', serviceData); // 添加日誌
      
      const organization = organizations.find(org => org.id === serviceData.identity_ids?.[0]);
      
      const formData = {
        provider: serviceData.identity_ids?.[0] || "",
        categoryId: serviceData.category.id || "",
        serviceTitle: serviceData.name || "",
        serviceDescription: serviceData.description || "",
        serviceNotice: serviceData.notification || "",
        serviceImage: serviceData.imageUrl || "",
        pricingMode: serviceData.specs?.length > 1 ? 'tiered' : 'fixed',
        fixedPrice: serviceData.specs?.[0]?.price || "",

        pricingTable: serviceData.specs?.map(spec => ({
          actualDuration: spec.name,
          price: spec.price,
          discount: spec.discount_enabled,
          backendDuration: spec.information?.backendDuration || spec.name
        })) || []
      };

      console.log('設置表單數據:', formData); // 添加日誌
      form.setFieldsValue(formData);
      
      setPreviewData({
        name: serviceData.name || "",
        provider: organization?.name || "",
        price: serviceData.specs?.[0]?.price || 0,
        description: serviceData.description || "",
        serviceNotice: serviceData.notification || "",
        imageUrl: serviceData.imageUrl || '/images/default-service.jpg',
        pricingTable: serviceData.specs?.map(spec => ({
          actualDuration: spec.name,
          price: spec.price,
          selected: false
        })) || []
      });
    }
  }, [editMode, serviceData, form, organizations, loading]);

  // 在 form 初始化時設置所有必填欄位的規則
  useEffect(() => {
    form.setFields([
      {
        name: 'serviceTitle',
        rules: [{ required: true, message: '請輸入服務標題' }]
      },
      {
        name: 'provider',
        rules: [{ required: true, message: '請選擇服務提供者' }]
      },
      {
        name: 'categoryId',
        rules: [{ required: true, message: '請選擇服務分類' }]
      },
      {
        name: 'pricingMode',
        rules: [{ required: true, message: '請選擇價格模式' }]
      },
      {
        name: 'fixedPrice',
        rules: [{ 
          required: true, 
          message: '請輸入固定價格',
          validator: (_, value) => {
            const pricingMode = form.getFieldValue('pricingMode');
            if (pricingMode === 'fixed' && (!value || value <= 0)) {
              return Promise.reject('請輸入有效的固定價格');
            }
            return Promise.resolve();
          }
        }]
      },
      {
        name: 'pricingTable',
        rules: [{ 
          validator: async (_, value) => {
            const pricingMode = form.getFieldValue('pricingMode');
            if (pricingMode === 'tiered') {
              if (!value || !value.some(item => item.price)) {
                throw new Error('請至少設定一個規格價格');
              }
            }
          }
        }]
      }
    ]);
  }, [form]);

  // Handle save
  const handleSave = async () => {
    try {
      // 強制觸發所有欄位的驗證
      await form.validateFields();
      setSaveLoading(true);

      

      // 打印完整的表單數據
      console.log('=== 表單數據 ===');
      console.log('基本資訊:', {
        服務標題: formValues.serviceTitle,
        服務提供者: formValues.provider,
        服務分類: formValues.categoryId,
        服務描述: formValues.serviceDescription,
        服務須知: formValues.serviceNotice,
        服務圖片: formValues.serviceImage
      });

      console.log('價格設定:', {
        價格模式: formValues.pricingMode,
        固定價格: formValues.fixedPrice,
        價格表: formValues.pricingTable
      });

      console.log('折扣設定:', {
        會員折扣類型: formValues.memberDiscountType,
        會員折扣值: formValues.memberDiscountValue
      });

      console.log('服務發布:', {
        有效期間: formValues.validPeriod ? {
          開始日期: moment(formValues.validPeriod[0]).format('YYYY-MM-DD'),
          結束日期: moment(formValues.validPeriod[1]).format('YYYY-MM-DD')
        } : '未設定'
      });

      // 重新獲取最新的 token 和 serverUrl
      const profileResponse = await request.get(`${SERVER_URL}/private/property/ecommerce/profile`);
      const serverUrl = profileResponse.body.server_url;
      
      const tokenResponse = await request.get(`${SERVER_URL}/private/property/ecommerce/jwt`);
      const accessToken = tokenResponse.access_token;
    
      
      // 檢查是否為編輯模式且有 serviceData
      if (editMode && serviceData) {
        // 更新服務基本資訊
        await updateServiceAPI(accessToken, serverUrl, serviceData.id, {
          name: formValues.serviceTitle,
          description: formValues.serviceDescription,
          notification: formValues.serviceNotice
        });

        // 更新規格價格
        if (formValues.pricingMode === 'fixed') {
          // 固定價格模式
          const spec = serviceData.specs?.[0];
          if (spec && spec.price !== formValues.fixedPrice) {
            try {
              await axios.patch(
                `${serverUrl}/private/sp/${serviceData.id}/spec/${spec.id}/price`,
                null,
                {
                  params: {
                    price: parseInt(formValues.fixedPrice)
                  },
                  headers: {
                    'Authorization': `Bearer ${accessToken}`
                  }
                }
              );
            } catch (error) {
              console.error('更新規格價格失敗:', error);
              throw error;
            }
          }
        } else if (formValues.pricingTable && formValues.pricingTable.length > 0) {
          // 分級價格模式
          for (const newSpec of formValues.pricingTable) {
            const existingSpec = serviceData.specs?.find(
              spec => spec.name === newSpec.actualDuration
            );
            
            if (existingSpec && existingSpec.price !== parseInt(newSpec.price)) {
              try {
                await axios.patch(
                  `${serverUrl}/private/sp/${serviceData.id}/spec/${existingSpec.id}/price`,
                  null,
                  {
                    params: {
                      price: parseInt(newSpec.price)
                    },
                    headers: {
                      'Authorization': `Bearer ${accessToken}`
                    }
                  }
                );
              } catch (error) {
                console.error('更新規格價格失敗:', error);
                throw error;
              }
            }
          }
        }

        // 檢查有效期是否有變動
        if (formValues.validPeriod && 
            (moment(formValues.validPeriod[0]).format('YYYY-MM-DD') !== moment(serviceData.valid_from).format('YYYY-MM-DD') ||
             moment(formValues.validPeriod[1]).format('YYYY-MM-DD') !== moment(serviceData.valid_until).format('YYYY-MM-DD'))) {
          
          await axios.patch(
            `${serverUrl}/private/sp/${serviceData.id}/validity`,
            null,
            {
              params: {
                valid_from: moment(formValues.validPeriod[0]).format('YYYY-MM-DD'),
                valid_until: moment(formValues.validPeriod[1]).format('YYYY-MM-DD')
              },
              headers: {
                'Authorization': `Bearer ${accessToken}`
              }
            }
          );
        }
        

        // 如果有新的圖片要上傳
        if (formValues.serviceImage && formValues.serviceImage !== serviceData.imageUrl) {
          const formData = new FormData();
          const base64Data = formValues.serviceImage.split(',')[1];
          const blob = await fetch(`data:image/jpeg;base64,${base64Data}`).then(res => res.blob());
          formData.append('images_to_add', blob, 'image.jpg');

          await axios.patch(
            `${serverUrl}/private/sp/${serviceData.id}/images`,
            formData,
            {
              headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'multipart/form-data'
              }
            }
          );
        }
            
           
          
        

        message.success('服務更新成功');
      } else {
        // Add Service的邏輯
        const apiPayload = {
          name: formValues.serviceTitle,
          description: formValues.serviceDescription,
          notification: formValues.serviceNotice,
          category_id: formValues.categoryId,
          identity_ids: [formValues.provider],
          valid_from: formValues.validPeriod ? moment(formValues.validPeriod[0]).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
          valid_until: formValues.validPeriod ? moment(formValues.validPeriod[1]).format('YYYY-MM-DD') : moment().add(1, 'year').format('YYYY-MM-DD')
        };

        const response = await axios.post(
          `${serverUrl}/private/sp/${formValues.provider}`,
          apiPayload,
          {
            headers: {
              'Authorization': `Bearer ${accessToken}`,
              'Content-Type': 'application/json'
            }
          }
        );

        // 如果是固定價格模式，新增固定規格
        if (formValues.pricingMode === 'fixed') {
          try {
            await axios.post(
              `${serverUrl}/private/sp/${response.data.id}/spec`,
              {
                name: "fixed",
                description: '',
                price: formValues.fixedPrice,
                discount_enabled: false,
                stock: 10,
                information: {}
              },
              {
                headers: {
                  'Authorization': `Bearer ${accessToken}`,
                  'Content-Type': 'application/json'
                }
              }
            );
          } catch (error) {
            console.error('新增固定規格失敗:', error);
            throw error;
          }
        }

        // 如果有規格資料，新增規格
        if (formValues.pricingTable && formValues.pricingTable.length > 0) {
          for (const spec of formValues.pricingTable) {
            const specPayload = {
              name: spec.actualDuration,
              description: spec.description || '',
              price: parseInt(spec.price),
              discount_enabled: spec.discount || false,
              stock: 10, // 添加固定庫存數量
              information: {
                backendDuration: spec.backendDuration
              }
            };
            
            await addServiceSpec(accessToken, serverUrl, response.data.id, specPayload);
          }
        }

        // 如果有圖片要上傳
        if (formValues.serviceImage) {
          const formData = new FormData();
          const base64Data = formValues.serviceImage.split(',')[1];
          const blob = await fetch(`data:image/jpeg;base64,${base64Data}`).then(res => res.blob());
          formData.append('images_to_add', blob, 'image.jpg');

          await axios.patch(
            `${serverUrl}/private/sp/${response.data.id}/images`,
            formData,
            {
              headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'multipart/form-data'
              }
            }
          );
        }

        if (response.status === 200) {
          message.success('服務新增成功');
          // 所有操作完成後再重新導向
          window.location.href = '/service';
        }
      }

      if (onSave) {
        onSave();
      }
    } catch (error) {
      console.error('保存失敗:', error);
      
      if (error.errorFields) {
        const errorMessages = error.errorFields.map(field => {
          const fieldLabels = {
            fixedPrice: 'Fixed Amount',
            memberDiscountType: 'Discount Type',
            memberDiscountValue: 'Discount Value',
            validPeriod: 'Service Validity Period',
            serviceTitle: 'Service Title',
            serviceDescription: 'Service Description',
            serviceNotice: 'Service Notice',
            uploadImage: 'Main Service Image',
            pricingTable: 'Specification Amount'
          };

          const fieldName = field.name[0];
          return `${field.errors[0]}`;
        });

        // 顯示所有錯誤訊息
        message.error({
          content: (
            <div style={{ textAlign: 'left' }}>
              {/* <div>請完成以下必填欄位：</div> */}
              <ul className="list-disc pl-5 mt-2">
                {errorMessages.map((msg, index) => (
                  <li key={index}>{msg}</li>
                ))}
              </ul>
            </div>
          ),
          duration: 5,
          style: { width: '100%' }
        });
      } else if (error.response) {
        // API 錯誤
        message.error(`更新服務失敗：${error.response.data?.message || '服務器錯誤'}`);
      } else if (error.request) {
        // 網路錯誤
        message.error('網路連接失敗，請檢查網路連接');
      } else {
        // 其他錯誤
        message.error('更新服務失敗：' + (error.message || '未知錯誤'));
      }
    } finally {
      setSaveLoading(false);
    }
  };

  // handle tab change
  const handleTabChange = (activeKey) => {
    setTabLoading(true);
    setTimeout(() => {
      setTabLoading(false);
    }, 500);
  };

  // breadcrumb navigation
  const BreadcrumbNav = () => (
    <div className="flex flex-col space-y-2 mb-4 p-4">
      <div className="text-lg font-bold">Service Management</div>

      <div className="flex items-center text-sm">
        <Link to="/" className="text-green-600">
          <HomeOutlined className="mr-1" />
        </Link>
        <span className="mx-2 text-gray-500">/</span>
        <span
          className="text-green-600 cursor-pointer"
          onClick={() => window.location.href = '/service'}
        >
          Service Management
        </span>
        <span className="mx-2 text-gray-500">/</span>
        <span className="text-gray-500">Add Service</span>
      </div>
    </div>
  );

  const items = [
    {
      key: "1",
      label: "Basic Information",
      children: (
        <div className="bg-white p-6 rounded-lg">
          {tabLoading ? (
            <div className="flex justify-center items-center h-[400px]">
              <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
            </div>
          ) : (
            <Information form={form} organizations={organizations} categories={categories} serviceData={serviceData} />
          )}
        </div>
      ),
    },
    {
      key: "2",
      label: "Service Specifications",
      children: (
        <div className="bg-white p-6 rounded-lg">
          {tabLoading ? (
            <div className="flex justify-center items-center h-[400px]">
              <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
            </div>
          ) : (
            <ServicePricing form={form} />
          )}
        </div>
      ),
    },
    {
      key: "3",
      label: "Discounts and Offers",
      children: (
        <div className="bg-white p-6 rounded-lg">
          {tabLoading ? (
            <div className="flex justify-center items-center h-[400px]">
              <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
            </div>
          ) : (
            <DiscountManagement form={form} />
          )}
        </div>
      ),
    },
    {
      key: "4",
      label: "Service Deployment",
      children: (
        <div className="bg-white p-6 rounded-lg">
          {tabLoading ? (
            <div className="flex justify-center items-center h-[400px]">
              <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
            </div>
          ) : (
            <ServicePublish form={form} />
          )}
        </div>
      ),
    },
  ];

  // Handle service preview
  const ServicePreview = ({ data }) => {
    return (
      <>
        <div className="bg-[#F3F3F3] p-4" style={{ boxShadow: '0px -4px 8px 0px #00000012 inset' }}>
          <span className="text-lg font-semibold mb-4">Frontdesk Service Preview</span>
        </div>
        <div className="bg-white shadow-lg sticky top-4">
          {/* Top area */}
          <div className="p-4">
            <div className="relative mb-4">
              <img
                src={data.imageUrl}
                alt={data.name}
                className="w-full h-48 object-cover"
              />
            </div>
            <h3 className="text-lg font-bold mb-1">{data.name}</h3>
            <p className="text-lg font-bold mb-2">{data.provider}</p>
            {/* Service description area */}
            <div>
              <p className="text-sm text-gray-700">{data.description}</p>
            </div>
            <div className="text-[24px] mb-2  flex items-baseline justify-end pb-2">
            <span className="text-xl text-black ml-1 mr-2 align-baseline">from </span>
              <span className="font-roboto text-2xl font-medium leading-[26px] tracking-[-0.9px] text-right">
                $ {Number(data.price).toLocaleString()}
              </span>
              <span className="text-xl text-black ml-1 align-baseline">/hr</span>
            </div>
          </div>

          <Divider className="border-b-[5px] border-[#F0F0F0] mx-4 my-2" />

          {/* show duration selection according to pricingMode */}
          {formValues?.pricingMode === 'tiered' && data.pricingTable?.length > 0 && (
            <div className="p-4">
              <h4 className="text-base font-bold mb-3">Select Specification</h4>
              <h6 className="text-sm text-gray-600 mb-2">Model</h6>
              <div className="flex flex-wrap gap-2">
                {data.pricingTable.map((item) => (
                  <Button
                    key={item.actualDuration}
                    className="min-w-[80px] mb-2"
                    type={item.selected ? 'primary' : 'default'}
                    onClick={() => {
                      setPreviewData(prev => ({
                        ...prev,
                        price: item.price || 0,
                        pricingTable: prev.pricingTable.map(row => ({
                          ...row,
                          selected: row.actualDuration === item.actualDuration
                        }))
                      }));
                    }}
                  >
                    {item.actualDuration}
                  </Button>
                ))}
              </div>
            </div>
          )}

          {/* Reservation time selection area */}
          <div className="p-4">
            <h4 className="text-base font-bold mb-3">Select Time</h4>
            <div className="grid grid-cols-2 gap-4 mb-4">
              <div>
                <div className="text-sm mb-2">Date</div>
                <Select
                  className="w-full"
                  placeholder="請選擇日期"
                  disabled
                  value={moment().format('YYYY-MM-DD')}
                >
                  {[...Array(7)].map((_, index) => {
                    const date = moment().add(index, 'days');
                    return (
                      <Select.Option key={date.format('YYYY-MM-DD')} value={date.format('YYYY-MM-DD')}>
                        {date.format('MM/DD (ddd)')}
                      </Select.Option>
                    );
                  })}
                </Select>
              </div>
              <div>
                <div className="text-sm mb-2">Time</div>
                <Select
                  className="w-full"
                  placeholder="請選擇時間"
                  disabled
                  value="15:00"
                >
                  {['09:00', '10:00', '11:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00'].map((time) => (
                    <Select.Option key={time} value={time}>
                      {time}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>

            {/* 9-grid time selection */}
            <div className="grid grid-cols-3 gap-2">
              {[
                "09:00",
                "10:00",
                "11:00",
                "14:00",
                "15:00",
                "16:00",
                "17:00",
                "18:00",
                "19:00",
              ].map((time) => (
                <div
                  key={time}
                  className={`text-center py-2 ${time === "15:00" ? "bg-primary text-white" : "bg-gray-200"
                    } min-w-[80px] mb-2 rounded`}
                >
                  {time}
                </div>
              ))}
            </div>
          </div>
          <Divider className="border-b-[5px] border-[#F0F0F0] mx-4 my-2" />


          {/* Notice area */}
          <div className="p-4 border-b">
            <p className="text-sm text-gray-700">{data.serviceNotice}</p>
          </div>


        </div>
      </>
    );
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center w-full h-screen">
        <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
      </div>
    );
  }

  return (
    <div className="flex w-full overflow-auto">

      {/* left form area */}
      <div className="flex-1 flex flex-col shadow min-w-0 overflow-auto h-full">
        {/* breadcrumb navigation */}
        <BreadcrumbNav />
        <Form
          form={form}
          layout="vertical"
        >
          <div className="bg-white rounded-lg mb-6">
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-6 p-4">
              <h1 className="text-[20px] font-semibold">Add Service</h1>
              <div className="flex gap-3">
                <Button type="default" className="w-full sm:w-auto">Save to Drafts</Button>
                <Button
                  type="primary"
                  onClick={handleSave}
                  className="w-full sm:w-auto"
                  loading={saveLoading} // add loading property
                  disabled={saveLoading} // disable button when loading
                >
                  {saveLoading ? '儲存中...' : 'Save and Publish'}
                </Button>
              </div>
            </div>
            <Tabs
              defaultActiveKey="1"
              size="large"
              items={items}
              onChange={handleTabChange}
              className="service-tabs"
            />
          </div>
        </Form>
      </div>

      {/* right preview area */}
      <div className="w-[440px] flex-shrink-0 border-l border-t border-gray-200 shadow overflow-auto">
        <div>
          {previewData && (
            <ServicePreview data={previewData} />
          )}
        </div>
      </div>
    </div>
  );
};

export default AddServiceForm;
