import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import './App.css';

import { useAuth } from '@/contexts/AuthProvider';
import { ServiceProvider } from '@/app/cms/context/ServiceContext';


import Redirection from '@/app/landing/Redirection';
import Login from '@/app/landing/Login';
import Register from '@/app/landing/Register';
import Forget from '@/app/landing/Forget';
import Reset from '@/app/landing/Reset';
import Explore from '@/app/explore/Explore';
import Chat from '@/app/chat/Chat';
import Service from '@/app/cms/containers/Service';
// import Service1 from '@/app/cms/containers/Service1';
import Org from './app/cms/containers/Org';
import Product from './app/cms/containers/Product';
import User from './app/cms/containers/User';
import Settings from '@/app/systemSettings/Settings';
import NavBar from '@/components/navBar/NavBar';
import { Provider } from 'react-redux';
import store from '@/store/index';
import { Button, Result } from 'antd';
import { ProductProvider } from './app/cms/context/ProductContext';
import AddServiceForm from '@/app/cms/components/Service/AddServiceForm';
import EditServiceForm from '@/app/cms/components/Service/EditServiceForm';


const teamsyncTheme = {
  token: {
    colorPrimary: '#67be5f',
    colorSecondary: '#e0e0e0',
    colorBgContainer: '#f7f7f7',
  },
};
const render_e_commerce = ( process.env.REACT_APP_STAG5_E_COMMERCE || process.env.REACT_APP_STAG5_E_COMMERCE ) === 'true' || false;
const render_official_site = process.env.REACT_APP_E_OFFICIAL_SITE_MODULE === 'true' || false;

function App() {
  const { token, loading } = useAuth();
  if (loading) {
    return null;
  }

  return (
    <ConfigProvider theme={teamsyncTheme}>
      <Router>
        <div className="flex flex-row overflow-x-hidden">
          {token && <NavBar />}
          <Provider store={store}>
            <Routes>
              <Route path="/login" element={token ? <Redirection target={"/explore"} /> : <Login />} />
              <Route path="/register" element={token ? <Redirection target={"/explore"} /> : <Register />} />
              <Route path="/forget" element={token ? <Redirection target={"/explore"} /> : <Forget />} />
              <Route path="/reset/:ticketId" element={token ? <Redirection target={"/explore"} /> : <Reset />} />

              <Route path="/" element={token ? <Redirection target={"/explore"} /> : <Login />} />
              <Route path="/explore" element={token ? <Explore /> : <Login />} />
              <Route path="/chat/:roomId?" element={token ? <Chat /> : <Login />} />
              {render_e_commerce && (
                <>
                <Route
                    path="/user"
                    element={
                        <Routes>
                          <Route path="/" element={<User />} />
                        </Routes>
                    }
                  />
                  <Route
                    path="/service/*"
                    element={
                      <ServiceProvider>
                        <Routes>
                          <Route path="/" element={<Service />} />
                          <Route 
                            path="edit/:id" 
                            element={<EditServiceForm />} 
                          />
                        </Routes>
                      </ServiceProvider>
                    }
                  />
                  <Route
                    path="/product/*"
                    element={
                      <ServiceProvider>
                        <Routes>
                          <Route path="/" element={<Service />} />
                          <Route 
                            path="edit/:id" 
                            element={<EditServiceForm />} 
                          />
                        </Routes>
                      </ServiceProvider>
                    }
                  />
                </>
              )}
              {/* {render_official_site && (
                  <>
                    <Route path="/management" element={<Management />} />
                  </>
                )} */}
              <Route path="/settings" element={token ? <Settings /> : <Login />} />

              <Route path="*" element={
                <div className="flex items-center justify-center min-h-screen w-full">
                  <Result
                    status="404"
                    title="404"
                    subTitle="對不起，您訪問的頁面不存在。"
                    extra={[
                      <Button type="primary" key="console" onClick={() => window.location.href = '/explore'}>
                        返回首頁
                      </Button>,
                      <Button key="buy" onClick={() => window.history.go(-1)}>返回上一頁</Button>
                    ]}
                  />
                </div>
              } />
            </Routes>
          </Provider>
        </div>
      </Router>
    </ConfigProvider>
  );
}

export default App;
