import { request } from '@/utils';
import axios from 'axios';
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

// 獲取 Access Token
export const getAccessToken = async () => {
  try {
    const response = await request.get(`${SERVER_URL}/private/property/ecommerce/jwt`);
    return response.access_token;
  } catch (error) {
    console.error('獲取存取令牌失敗:', error);
    throw new Error('Unable to fetch access token');
  }
};


// 獲取服務數據
export const fetchServicesAPI = async (accessToken, serverUrl) => {
  try {
    const response = await request.get(`${serverUrl}/public/sp/`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    console.log(response);

    // 格式化數據，並返回
    return response.map((service) => ({
      ...service,
      key: service.id,
    }));
  } catch (error) {
    console.error('獲取服務數據失敗:', error);
    throw new Error('Unable to fetch services');
  }
};

// 獲取服務數據
export const fetchProductsAPI = async (accessToken, serverUrl) => {
  try {
    const response = await request.get(`${serverUrl}/public/sp/`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    console.log(response);

    // 格式化數據，並返回
    return response.map((service) => ({
      ...service,
      key: service.id,
    }));
  } catch (error) {
    console.error('獲取服務數據失敗:', error);
    throw new Error('Unable to fetch services');
  }
};

// 獲取伺服器 URL
export const getServerUrl = async () => {
  try {
    const response = await request.get(`${SERVER_URL}/private/property/ecommerce/profile`);
    return response.body.server_url;
  } catch (error) {
    console.error('獲取伺服器 URL 失敗:', error);
    throw new Error('Unable to fetch server URL');
  }
};

// 更新服務數據
export const updateServiceAPI = async (accessToken, serverUrl, serviceId, updateData) => {
  try {
    const { name, description, notification } = updateData;
    
    // 更新名稱
    if (name) {
      await axios.patch(
        `${serverUrl}/private/sp/${serviceId}?fields=name&value=${encodeURIComponent(name)}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    }

    // 更新描述
    if (description) {
      await axios.patch(
        `${serverUrl}/private/sp/${serviceId}?fields=description&value=${encodeURIComponent(description)}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    }

    // 更新注意事項
    if (notification) {
      await axios.patch(
        `${serverUrl}/private/sp/${serviceId}?fields=notification&value=${encodeURIComponent(notification)}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    }

    return true;
  } catch (error) {
    console.error('更新服務數據失敗:', error);
    throw new Error('Unable to update service');
  }
};

// 新增服務規格
export const addServiceSpec = async (accessToken, serverUrl, serviceId, specData) => {
  try {
    const response = await axios.post(
      `${serverUrl}/private/sp/${serviceId}/spec`,
      specData,
      {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error('新增規格失敗:', error);
    throw error;
  }
};
