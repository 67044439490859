import React, { useState, useEffect } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin, Tooltip, Switch } from 'antd';
import { useChat } from '../../../../hooks/use-chat';
// 使用 Clean Architecture 架構
// UI → Hook → Facade → Use Case → Domain → Use Case → Repository → API → Repository → Use Case → Facade → Hook → UI

/**
 * AgodaSetting 組件 - 顯示 Agoda 預訂詳情
 * @param {Object} props - 組件屬性
 * @returns {JSX.Element} - 渲染的組件
 */
const StagSetting = ({ client }) => {
  const [loading, setLoading] = useState(false);
  const [bookingData, setBookingData] = useState(null);
  const { getAgodaBookingInfo, getAirbnbBookingInfo } = useChat();


  // 當 client 變更時，從 conversationData 中提取預訂數據
  useEffect(() => {
    setLoading(true);
    try {
      // 檢查 client 和 conversationData 是否存在
      if (client && client.channel === "agoda") {        
        // 從 conversationData 中獲取預訂數據
        if (client.conversationData && 
            client.conversationData.bookings && 
            Array.isArray(client.conversationData.bookings) && 
            client.conversationData.bookings.length > 0) {
          setBookingData(client.conversationData.bookings[0]);
        } else {
          setBookingData(null);
        }
      } else if (client && client.channel === "airbnb") {        
        // 從 conversationData 中獲取預訂數據
        if (client.conversationData && 
            client.conversationData.bookings && 
            Array.isArray(client.conversationData.bookings) && 
            client.conversationData.bookings.length > 0) {
          setBookingData(client.conversationData.bookings[0]);
        } else {
          setBookingData(null);
        }
      } else {
        console.log('客戶不存在');
        setBookingData(null);
      }
    } catch (error) {
      console.error('處理預訂數據時出錯:', error);
      setBookingData(null);
    } finally {
      setLoading(false);
    }
  }, [client]);

  useEffect(() => {
    const fetchBookingInfo = async () => {
      try {
        console.log('client', client);
        setLoading(true);
        let response;
        if (client.channel === "agoda") {
          response = await getAgodaBookingInfo(client.id);
        } else if (client.channel === "airbnb") {
          response = await getAirbnbBookingInfo(client.id);
          console.log('response', response);
          
        }
        console.log('99999', response?.[0]);
          
  
        if (response.length > 0) {
          setBookingData(response?.[0]);
        }
      } catch (error) {
        console.error(error)
        // message.error(JSON.stringify(error.response??.detail));
      } finally {
        setLoading(false);
      }
    }
    fetchBookingInfo();
  }, [client]);
  
  // 格式化日期顯示
  const formatDate = (dateString) => {
    if (!dateString) return '未提供';
    
    try {
      // 處理 Airbnb 風格日期格式 (如 "2025年3月16日 週日")
      if (typeof dateString === 'string' && dateString.includes('年') && dateString.includes('月') && dateString.includes('日')) {
        // 已經是中文格式，直接返回
        return dateString.split(' ')[0]; // 返回日期部分，去除星期
      }
      
      // 標準日期處理
      const date = new Date(dateString);
      return date.toLocaleDateString('zh-TW', { 
        year: 'numeric', 
        month: '2-digit', 
        day: '2-digit' 
      });
    } catch (error) {
      return dateString;
    }
  };
  
  // 獲取預訂狀態的中文顯示
  const getStatusText = (status) => {
    // Airbnb 已經是中文狀態的情況
    if (status === '預定成功' || status === '已取消' || status === '待確認' || status === '已完成') {
      return status;
    }
    
    const statusMap = {
      'Confirmed': '已確認',
      'Cancelled': '已取消',
      'Pending': '待確認',
      'Completed': '已完成'
    };
    
    return statusMap[status] || status || '未知狀態';
  };
  
  // 獲取預訂狀態的顏色
  const getStatusColor = (status) => {
    // 處理 Airbnb 中文狀態
    if (status === '預定成功') status = 'Confirmed';
    if (status === '已取消') status = 'Cancelled';
    if (status === '待確認') status = 'Pending';
    if (status === '已完成') status = 'Completed';
    
    const colorMap = {
      'Confirmed': 'text-green-600',
      'Cancelled': 'text-red-600',
      'Pending': 'text-yellow-600',
      'Completed': 'text-blue-600'
    };
    
    return colorMap[status] || '';
  };

  useEffect(() => {
    console.log('bookingData', bookingData);
  }, [bookingData]);
  
  return (
    <>
      <div className="p-3 mr-3 overflow-y-auto h-full">
        {true && (
          <>
            <div className="flex justify-between font-inter text-[17px] font-semibold leading-[22.1px] text-left text-[#3E3D3D] w-full my-3">
              AI 個別狀態設定
            </div>
            <div className="flex flex-col border border-borderLight rounded bg-bgMedium w-full">
              <div className="flex flex-col items-start w-full h-22 p-4 gap-2">
                <div className="font-bold">AI 狀態</div>
                <div className="flex flex-row justify-between w-full items-center">
                  <div>針對此客戶啟用 AI</div>
                  <Tooltip title="將自動傳送狀態變更訊息給客戶">
                    <Switch
                    // value={useWebhook}
                    // onChange={(e) =>
                    //   handleUpdateProperty("useWebhook", e)
                    // }
                    />
                  </Tooltip>
                </div>
              </div>
            </div>
          </>
        )}
        
        <div className="flex justify-between items-center font-inter text-[17px] font-semibold leading-[22.1px] text-left text-[#3E3D3D] w-full my-3">
          <span>預訂詳情</span>
        </div>
        
        {loading ? (
          <div className="flex justify-center items-center h-[600px]">
            <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
          </div>
        ) : !bookingData ? (
          <div className="flex flex-col border border-borderLight mb-3 rounded bg-bgMedium w-full rounded-[11px] p-4">
            <div className="text-center text-gray-500">無預訂資料</div>
          </div>
        ) : (
          <>
            {/* 預訂資訊卡片 */}
            <div className="flex flex-col border border-borderLight mb-3 rounded bg-bgMedium w-full rounded-[11px]">
              <div className="flex flex-col items-start w-full h-10 p-4 gap-2">
                <div className="font-bold">預訂資訊</div>
              </div>
              
              <div className="flex flex-col items-start w-full p-4 gap-2 border-b border-b-borderLight">
                <div className="font-[400]">預訂編號</div>
                <div className="text-[14px] font-[400]">{bookingData.bookingId || '未提供'}</div>
              </div>
              
              <div className="flex flex-col items-start w-full p-4 gap-2 border-b border-b-borderLight">
                <div className="font-[400]">預訂狀態</div>
                <div className={`text-[14px] font-[400] ${getStatusColor(bookingData.status)}`}>
                  {getStatusText(bookingData.status)}
                </div>
              </div>
              
              <div className="flex flex-col items-start w-full p-4 gap-2 border-b border-b-borderLight">
                <div className="font-[400]">預訂日期</div>
                <div className="text-[14px] font-[400]">{formatDate(bookingData.bookingDate)}</div>
              </div>
              
              <div className="flex flex-col items-start w-full p-4 gap-2 border-b border-b-borderLight">
                <div className="font-[400]">入住日期</div>
                <div className="text-[14px] font-[400]">{formatDate(bookingData.checkInDate)}</div>
              </div>
              
              <div className="flex flex-col items-start w-full p-4 gap-2">
                <div className="font-[400]">退房日期</div>
                <div className="text-[14px] font-[400]">{formatDate(bookingData.checkOutDate)}</div>
              </div>
            </div>
            
            {/* 住宿時長計算 */}
            {/* {bookingData.checkInDate && bookingData.checkOutDate && (
              <div className="flex flex-col border border-borderLight mb-3 rounded bg-bgMedium w-full rounded-[11px]">
                <div className="flex flex-col items-start w-full h-10 p-4 gap-2">
                  <div className="font-bold">住宿時長</div>
                </div>
                
                <div className="flex flex-col items-start w-full p-4 gap-2">
                  <div className="text-[14px] font-[400]">
                    {(() => {
                      try {
                        const checkIn = new Date(bookingData.checkInDate);
                        const checkOut = new Date(bookingData.checkOutDate);
                        const diffTime = Math.abs(checkOut - checkIn);
                        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                        return `${isNaN(diffDays) ? '無法計算' : diffDays} ${isNaN(diffDays) ? '' : '晚'}`;
                      } catch (error) {
                        return '無法計算';
                      }
                    })()}
                  </div>
                </div>
              </div>
            )} */}
            
            {/* 對話 ID 資訊 */}
            <div className="flex flex-col border border-borderLight mb-3 rounded bg-bgMedium w-full rounded-[11px]">
              <div className="flex flex-col items-start w-full h-10 p-4 gap-2">
                <div className="font-bold">系統資訊</div>
              </div>
              
              <div className="flex flex-col items-start w-full p-4 gap-2">
                <div className="font-[400]">對話 ID</div>
                <div className="text-[14px] font-[400] break-all">{bookingData.conversationId || bookingData.id || '未提供'}</div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default StagSetting;