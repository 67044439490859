import { exportChatHistories } from "../useCases/ExportChatHistoriesUseCase";

/**
 * 匯出聊天室歷史記錄
 * @param {string} chatroomId - 聊天室ID
 * @param {string} since - 開始日期 (YYYY-MM-DD格式)
 * @param {string} until - 結束日期 (YYYY-MM-DD格式)
 * @param {Function} onSuccess - 成功回調函數
 * @param {Function} onError - 錯誤回調函數
 * @param {Function} onProgress - 進度回調函數
 */
export const exportChatroomHistories = (
  chatroomId,
  since,
  until,
  onSuccess,
  onError,
  onProgress
) => {
  return exportChatHistories(
    "chatroom",
    chatroomId,
    since,
    until,
    null,
    onSuccess,
    onError,
    onProgress
  );
};

/**
 * 匯出部門聊天歷史記錄
 * @param {string} [departmentId=null] - 部門ID，如果為null則匯出所有部門
 * @param {string} since - 開始日期 (YYYY-MM-DD格式)
 * @param {string} until - 結束日期 (YYYY-MM-DD格式)
 * @param {string} [apiKey=null] - API 密鑰，可選參數
 * @param {Function} onSuccess - 成功回調函數
 * @param {Function} onError - 錯誤回調函數
 * @param {Function} onProgress - 進度回調函數
 */
export const exportDepartmentHistories = (
  departmentId,
  since,
  until,
  apiKey,
  onSuccess,
  onError,
  onProgress
) => {
  return exportChatHistories(
    "department",
    departmentId,
    since,
    until,
    apiKey,
    onSuccess,
    onError,
    onProgress
  );
};

/**
 * 匯出公司聊天歷史記錄
 * @param {string} since - 開始日期 (YYYY-MM-DD格式)
 * @param {string} until - 結束日期 (YYYY-MM-DD格式)
 * @param {string} [apiKey=null] - API 密鑰，可選參數
 * @param {Function} onSuccess - 成功回調函數
 * @param {Function} onError - 錯誤回調函數
 * @param {Function} onProgress - 進度回調函數
 */
export const exportCompanyHistories = (
  since,
  until,
  apiKey,
  onSuccess,
  onError,
  onProgress
) => {
  return exportChatHistories(
    "company",
    null,
    since,
    until,
    apiKey,
    onSuccess,
    onError,
    onProgress
  );
};

// 匯出所有函數
const exportChatHistoriesFacade = {
  exportChatroomHistories,
  exportDepartmentHistories,
  exportCompanyHistories,
  exportChatHistories,
};

export default exportChatHistoriesFacade;
