import React, { useState } from "react";
import { CloseOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthProvider";

import Theme from "./Theme";
import UserData from "./UserData";

const UserSetting = ({ handlePersonalSettingPop }) => {
  const [activeMenu, setActiveMenu] = useState("UserData");
  const { logout } = useAuth();
  const navigate = useNavigate();
  const handleActiveMenu = (menu) => {
    setActiveMenu(menu);
  };
  const handleLogout = () => {
    logout();
  };

  return (
    <div className="flex w-[724px] h-[95dvh] border-gray border-[1px] rounded-[10px] overflow-hidden">
      <div className="w-1/3 flex flex-col justify-between items-start bg-bgMedium p-[16px]">
        <div className="flex flex-col w-[100%] ">
          <h3 className="text-[17px] font-semibold p-[12px] border-b-[1px] border-[#E0E0E0]">
            我的帳號
          </h3>
          <div className="flex flex-col w-[100%] mt-[10px] font-normal gap-1">
            <button
              className={`flex justify-start items-center w-[100%] h-10 p-2 text-[16px] rounded-[5px] hover:bg-primaryMedium ${
                activeMenu === "UserData" ? "bg-primaryMedium" : ""
              } `}
              onClick={() => {
                handleActiveMenu("UserData");
              }}
            >
              基本資訊
            </button>
            <button
              className={`flex justify-start items-center w-[100%] h-10 p-2 text-[16px] rounded-[5px] hover:bg-primaryMedium ${
                activeMenu === "Theme" ? "bg-primaryMedium" : ""
              } `}
              onClick={() => {
                handleActiveMenu("Theme");
              }}
            >
              主題
            </button>
            <button
              className={`flex justify-start items-center w-[100%] h-10 p-2 text-[16px] rounded-[5px] hover:bg-primaryMedium `}
              onClick={() => {
                navigate("/privacy");
              }}
            >
              隱私權宣告
            </button>
          </div>
        </div>
        <button
          className="p-2 h-10 w-full text-start hover:bg-primaryMedium rounded"
          onClick={handleLogout}
        >
          登出
        </button>
      </div>
      <div className="flex flex-col w-2/3 p-[16px] bg-white ">
        <div className="flex justify-end">
          <button onClick={handlePersonalSettingPop}>
            <CloseOutlined />
          </button>
        </div>
        {activeMenu === "UserData" && <UserData />}
        {activeMenu === "Theme" && <Theme />}
      </div>
    </div>
  );
};

export default UserSetting;
