import { request } from '../../utils';

export const LineApi = {
  getLineClients: async (roomId) => {
    return await request.get(`/private/module/line_channel/${roomId}/clients`);
  },
  getLineMessages: async (roomId, socialMediaClientId, limit = 10, fromId = null) => {
    return await request.get(`/private/module/line_channel/${roomId}/chats/${socialMediaClientId}`, {
      params: {
        limit: limit,
        from_id: fromId
      }
    });
  },

  setLastSeenMessageCache: async (roomId, messageId) => {
    return await request.post(`/private/module/line_channel/last_seen_message`, {
      roomId,
      messageId
    });
  },

  sendLineMessage: async (roomId, socialMediaClientId, message) => {
    return await request.post(`/private/module/line_channel/${roomId}/p2p/${socialMediaClientId}`, {
      text: message
    });
  },

  // get
  getLineChannel: async (roomId) => {
    return await request.get(`/private/module/line_channel/${roomId}`);
  },

  // create
  createLineProperty: async (roomId, lineProperty) => {
    return await request.post(`/private/module/line_channel/${roomId}`, lineProperty);
  },
  
  // update
  updateLineWebhookStatus: async (roomId, socialMediaClientId, useWebhook) => {
    return await request.patch(
      `/private/module/line_channel/${roomId}/use_webhook/${socialMediaClientId}`,
      null,
      {
        params: {
          use_webhook: useWebhook
        }
      }
    );
  },
  updateLineChatSettings: async (roomId, chatSettings) => {
    return await request.put(`/private/module/line_channel/settings/${roomId}/chat_settings`, chatSettings);
  },
  updateLineBaseSettings: async (roomId, baseSettings) => {
    return await request.put(`/private/module/line_channel/settings/${roomId}/base`, baseSettings);
  },
  updateLineNickname: async (roomId, socialMediaClientId, nickname) => {
    return await request.patch(`/private/module/line_channel/${roomId}/nickname/${socialMediaClientId}?nickname=${nickname}`);
  },
  


  // delete
  deleteLineProperty: async (roomId) => {
    return await request.delete(`/private/module/line_channel/${roomId}`);
  },




  


  addLineNote: async (roomId, socialMediaClientId, noteName, noteContent) => {
      return await request.post(
        `/private/module/line_channel/${roomId}/note/${socialMediaClientId}`,
        null,
        {
          params: {
            note_name: noteName,
            note_content: noteContent
          }
        }
      );
  },

  updateLineNote: async (roomId, socialMediaClientId, noteId, noteName, noteContent) => {
    return await request.patch(
        `/private/module/line_channel/${roomId}/note/${socialMediaClientId}`,
        null,
        {
          params: {
            note_id: noteId,
            note_name: noteName,
            note_content: noteContent
          }
        }
      );
  },

  updateLineAdmin: async (roomId, socialMediaClientId, noteId, noteName, noteContent) => {
    return await request.patch(
        `/private/module/line_channel/${roomId}/note/${socialMediaClientId}`,
        null,
        {
          params: {
            note_id: noteId,
            note_name: noteName,
            note_content: noteContent
          }
        }
      );
  },

  deleteLineNote: async (roomId, socialMediaClientId, noteId) => {
    return await request.delete(
        `/private/module/line_channel/${roomId}/note/${socialMediaClientId}`,
        {
          params: {
            note_id: noteId
          }
        }
      );
  },
  addLineTag: async (roomId, socialMediaClientId, tagName) => {
    return await request.post(
        `/private/module/line_channel/${roomId}/tag/${socialMediaClientId}`,
        null,
        {
          params: {
            name: tagName
          }
        }
      );
  },
  deleteLineTag: async (roomId, socialMediaClientId, tagId) => {
    return await request.delete(
        `/private/module/line_channel/${roomId}/tag/${socialMediaClientId}`,
        {
          params: {
            tag_id: tagId
          }
        }
      );
  },
  fetchLineTagGroup: async (roomId) => {
    return await request.get(`/private/module/social_media_clients/${roomId}/tag_group`);
  }
}; 
