import { AgentUseCase } from "../useCases/AgentUseCase";
export const AgentFacade = {
  fetchAgentClients: async (roomId) => {
    return await AgentUseCase.fetchAgentClients(roomId);
  },
  fetchAgentMessages: async (roomId, socialMediaClientId, limit = 10, oldestMessageId = null) => {
    return await AgentUseCase.fetchAgentMessages(roomId, socialMediaClientId, limit, oldestMessageId);
  },
  sendAgentMessage: async (roomId, socialMediaClientId, message) => {
    return await AgentUseCase.sendAgentMessage(roomId, socialMediaClientId, message);
  },
  // get
  getAgentChannel: async (roomId) => {
    return await AgentUseCase.getAgentChannel(roomId);
  },

  // create
  createAgentProperty: async (roomId, agentProperty) => {
    return await AgentUseCase.createAgentProperty(roomId, agentProperty);
  },

  // update
  updateAgentWebhookStatus: async (roomId, socialMediaClientId, useWebhook) => {
    return await AgentUseCase.updateAgentWebhookStatus(roomId, socialMediaClientId, useWebhook);
  },
  updateAgentChatSettings: async (roomId, chatSettings) => {
    return await AgentUseCase.updateAgentChatSettings(roomId, chatSettings);
  },
  updateAgentBaseSettings: async (roomId, baseSettings) => {
    return await AgentUseCase.updateAgentBaseSettings(roomId, baseSettings);
  },
  updateAgentNickname: async (roomId, socialMediaClientId, nickname) => {
    return await AgentUseCase.updateAgentNickname(roomId, socialMediaClientId, nickname);
  },

  // delete
  deleteAgentProperty: async (roomId) => {
    return await AgentUseCase.deleteAgentProperty(roomId);
  },
  addAgentNote: async (roomId, clientId, noteName, noteContent) => {
    return await AgentUseCase.addAgentNote(roomId, clientId, noteName, noteContent);
  },
  updateAgentNote: async (roomId, clientId, noteId, noteName, noteContent) => {
    return await AgentUseCase.updateAgentNote(roomId, clientId, noteId, noteName, noteContent);
  },
  deleteAgentNote: async (roomId, clientId, noteId) => {
    return await AgentUseCase.deleteAgentNote(roomId, clientId, noteId);
  },
  addAgentTag: async (roomId, clientId, tagName) => {
    return await AgentUseCase.addAgentTag(roomId, clientId, tagName);
  },
  deleteAgentTag: async (roomId, clientId, tagId) => {
    return await AgentUseCase.deleteAgentTag(roomId, clientId, tagId);
  },
  fetchAgentTagGroup: async (roomId) => {
    return await AgentUseCase.fetchAgentTagGroup(roomId);
  }
}; 