import { request } from '../../utils';
import axios from 'axios';
export const AgodaApi = {
  // Get profile information
  getProfile: async (chatroomId) => {
      return await request.get(`/private/module/rpa/profile/${chatroomId}`, {});
  },

  // Get user information
  getUserInfo: async (profileId) => {
      return await request.get(`/agoda/get_user_info_db/${profileId}`, {}, true);
  },

  // Get source information
  getSourceInfo: async (userId) => {
      return await request.get(`/agoda/get_source_info_db/${userId}`, {}, true);
  },

  // Get conversation ID
  getConversationId: async (sourceId) => {
      return await request.get(`/agoda/get_conversation_id_db/${sourceId}`, {}, true);
  },

  // Get booking information
  getBookingInfo: async (conversationId) => {
      return await request.get(`/agoda/get_booking_info_db/${conversationId}`, {}, true);
  },

  // Get conversation messages
  getConversationMessages: async (conversationId) => {
      return await request.get(`/agoda/get_conversation_message_db/${conversationId}`, {}, true);
  },

  // Send message
  sendMessage: async (profileId, sourceId, conversationId, messageType, message, file) => {
    
    // 發送文字訊息
    if (messageType === 'text') {
      return await axios.post(`https://rpa.scfg.io/agoda/send_message/kuhd371?source_id=${sourceId}&conversation_id=${conversationId}&message_type=${messageType}&message=${message}`, {}, 
        {
          headers: {
            'X-API-KEY': 'sc90699098',
            'Content-Type': 'application/json'
          }
        }
      );
    }
    
    // 發送圖片訊息
    if (messageType === 'image' && file) {
      const formData = new FormData();
      // 添加所有參數到 FormData
      formData.append('file', file);
      return await axios.post(`https://rpa.scfg.io/agoda/send_message/kuhd371?source_id=${sourceId}&conversation_id=${conversationId}&message_type=${messageType}&message=${message}`, formData, {
        headers: {
          'X-API-KEY': 'sc90699098',
          'Content-Type': 'multipart/form-data'
        }
      });
    }

    throw new Error('Invalid message type or missing required parameters');
  }
}; 