import { AgodaApi } from '../api/AgodaApi';
import { 
  AgodaProfile, 
  AgodaUser, 
  AgodaBooking, 
} from '../../domain/entities/agoda/AgodaEntity';
import { createBoundClientEntity, createBoundMessageEntity, createBoundSourceEntity } from '../../domain/entities/bound/boundEntity';


export const AgodaRepository = {

  _transformClientData: (rawData) => {
    if (!rawData) return null;
    return createBoundClientEntity(rawData);
  },

  _transformMessageData: (rawData) => {
    if (!rawData) return null;
    return createBoundMessageEntity(rawData);
  },

  _transformSourceData: (rawData) => {
    if (!rawData) return null;
    return createBoundSourceEntity(rawData);
  },

  getProfile: async (chatroomId) => {
      const data = await AgodaApi.getProfile(chatroomId);
      return new AgodaProfile(data);
  },


  getUserInfo: async (profileId) => {
    const data = await AgodaApi.getUserInfo(profileId);
    // console.log('data', data);
    
    return data.map(user => new AgodaUser(user));
  },


  getSourceInfo: async (userId) => {
      const data = await AgodaApi.getSourceInfo(userId);
      const dataWithPlatform = data.map(source => ({
        ...source,
        platform: 'agoda'
      }));
      const res = dataWithPlatform.map(source => AgodaRepository._transformSourceData(source));
      return res;
  },

  getConversationId: async (sourceId) => {
      const data = await AgodaApi.getConversationId(sourceId);
      // console.log('da66666', data);
      
      const dataWithPlatform = data.map(conversation => ({
        ...conversation,
        sourceId: conversation.agoda_source_id,
        platform: 'agoda'
      }));
      // console.log('112356', dataWithPlatform);
      
      const res = dataWithPlatform.map(conversation => AgodaRepository._transformClientData(conversation));
      // console.log('888888888', res);
      
      return res;
  },

  getBookingInfo: async (conversationId) => {
      const data = await AgodaApi.getBookingInfo(conversationId);
      return data.map(booking => new AgodaBooking(booking));
    
  },

  getConversationMessages: async (conversationId) => {
      const data = await AgodaApi.getConversationMessages(conversationId);
      // console.log('data', data);
      const dataWithPlatform = data.map(message => ({
        ...message,
        platform: 'agoda'
      }));
      // console.log('dataWithPlatform', dataWithPlatform);
      
      const res = dataWithPlatform.map(message => AgodaRepository._transformMessageData(message));
      // console.log('res', res);
      
      return res
      // return data.map(message => AgodaRepository._transformMessageData(message));
  },

  sendMessage: async (profileId, sourceId, conversationId, messageType, message, file) => {
    const data = await AgodaApi.sendMessage(profileId, sourceId, conversationId, messageType, message, file);
    return data;
  }
}; 