import { FacebookUseCase } from "../useCases/FacebookUseCase";
export const FacebookFacade = {
  fetchFacebookClients: async (roomId) => {
    return await FacebookUseCase.fetchFacebookClients(roomId);
  },
  fetchFacebookMessages: async (roomId, socialMediaClientId, limit = 10, oldestMessageId = null) => {
    return await FacebookUseCase.fetchFacebookMessages(roomId, socialMediaClientId, limit, oldestMessageId);
  },
  sendFacebookMessage: async (roomId, socialMediaClientId, message) => {
    return await FacebookUseCase.sendFacebookMessage(roomId, socialMediaClientId, message);
  },
  // get
  getFacebookChannel: async (roomId) => {
    return await FacebookUseCase.getFacebookChannel(roomId);
  },

  // create
  createFacebookProperty: async (roomId, facebookProperty) => {
    return await FacebookUseCase.createFacebookProperty(roomId, facebookProperty);
  },

  // update
  updateFacebookWebhookStatus: async (roomId, socialMediaClientId, useWebhook) => {
    return await FacebookUseCase.updateFacebookWebhookStatus(roomId, socialMediaClientId, useWebhook);
  },
  updateFacebookChatSettings: async (roomId, chatSettings) => {
    return await FacebookUseCase.updateFacebookChatSettings(roomId, chatSettings);
  },
  updateFacebookBaseSettings: async (roomId, baseSettings) => {
    return await FacebookUseCase.updateFacebookBaseSettings(roomId, baseSettings);
  },
  updateFacebookNickname: async (roomId, socialMediaClientId, nickname) => {
    return await FacebookUseCase.updateFacebookNickname(roomId, socialMediaClientId, nickname);
  },

  // delete
  deleteFacebookProperty: async (roomId) => {
    return await FacebookUseCase.deleteFacebookProperty(roomId);
  },
  addFacebookNote: async (roomId, clientId, noteName, noteContent) => {
    return await FacebookUseCase.addFacebookNote(roomId, clientId, noteName, noteContent);
  },
  updateFacebookNote: async (roomId, clientId, noteId, noteName, noteContent) => {
    return await FacebookUseCase.updateFacebookNote(roomId, clientId, noteId, noteName, noteContent);
  },
  deleteFacebookNote: async (roomId, clientId, noteId) => {
    return await FacebookUseCase.deleteFacebookNote(roomId, clientId, noteId);
  },
  addFacebookTag: async (roomId, clientId, tagName) => {
    return await FacebookUseCase.addFacebookTag(roomId, clientId, tagName);
  },
  deleteFacebookTag: async (roomId, clientId, tagId) => {
    return await FacebookUseCase.deleteFacebookTag(roomId, clientId, tagId);
  },
  fetchFacebookTagGroup: async (roomId) => {
    return await FacebookUseCase.fetchFacebookTagGroup(roomId);
  }
}; 