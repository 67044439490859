import { request } from '../../utils';
import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { useAuth } from '../../contexts/AuthProvider';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const COMPANY_ID = process.env.REACT_APP_COMPANY_ID;
const COM_LOGO_URL = `https://teamsyncblob.s3.ap-southeast-1.amazonaws.com/Image_Company/${COMPANY_ID}`;
const DEFAULT_LOGO_URL = 'logo192.png';

const Logo = ({ type }) => {
  const { token } = useAuth();
  const [imageSrc, setImageSrc] = useState(localStorage.getItem('logoImage') || DEFAULT_LOGO_URL);
  const [lastUpdatedTime, setLastUpdatedTime] = useState(localStorage.getItem('lastUpdatedTime') || '');

  useEffect(() => {
    if (imageSrc === DEFAULT_LOGO_URL && lastUpdatedTime.length === 0) {
      // no local storage data or no last update time, fetch from server and save to local storage
      convertImageToBase64(COM_LOGO_URL, (base64) => {
        updateLocalStorage(base64);
      }, handleError);
    } else {
      // check if the image in local storage is up to date
      checkLogo();
    }
  }, []);

  const checkLogo = async () => {
    if (token) {
      try {
        const response = await request.get(`${SERVER_URL}/private/company/`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
        if (response.photo_url) {
          const newLastUpdatedTime = response.photo_url.substring(response.photo_url.indexOf(COMPANY_ID) + COMPANY_ID?.length + 1);
          if (newLastUpdatedTime !== lastUpdatedTime) {
            setLastUpdatedTime(newLastUpdatedTime);
            convertImageToBase64(response.photo_url, (base64, newLastUpdatedTime) => {
              updateLocalStorage(base64);
            }, handleError);
          }
        }
      } catch (error) {

        console.log(error);

      }
    }
  }

  const updateLocalStorage = (base64, updatedTime) => {
    localStorage.setItem('logoImage', base64);
    setImageSrc(base64);
    localStorage.setItem('lastUpdatedTime', updatedTime);
    setLastUpdatedTime(updatedTime);
    setImageSrc(base64);
  }

  // Function to convert image to Base64 and handle errors
  const convertImageToBase64 = (url, callback, errorCallback) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = function () {
      const reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.onerror = errorCallback;
      reader.readAsDataURL(xhr.response);
    };
    xhr.onerror = errorCallback;
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  };

  const handleError = () => {
    // Set image to default if error occurs
    setImageSrc(DEFAULT_LOGO_URL);
  };

  return (
    <div>
      <img
        src={imageSrc}
        alt="Logo"
        onError={handleError}
        className={classnames("",
          { "w-[45px] h-[45px]": type === "NAVBAR" },
          { "w-48 h-48": type === "LOGIN" })} />
    </div>
  );
};

export default Logo;