import React, { useState, useEffect } from "react";
import { Select, Input, DatePicker, Form, Tag, Upload, Modal, Carousel } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import "tailwindcss/tailwind.css";
import ImgCrop from 'antd-img-crop';
import moment from 'moment';

const { RangePicker } = DatePicker;
const { TextArea } = Input;

const Information = ({ form, organizations, categories }) => {
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newTagInput, setNewTagInput] = useState('');

  // Get base64 of image
  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  // Handle image change
  const onChange = async ({ fileList: newFileList }) => {
    const processedFileList = [];
    for (let i = 0; i < newFileList.length; i++) {
      const file = newFileList[i];
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      processedFileList.push(file);
    }
    
    setFileList(processedFileList);
    if (processedFileList.length > 0) {
      const imageUrl = processedFileList[0].preview || processedFileList[0].url;
      form.setFieldsValue({ productImage: imageUrl });
    } else {
      form.setFieldsValue({ productImage: '' });
    }
  };

  // Handle image preview
  const onPreview = async (file) => {
    const imageStyle = { 
      width: '100%', 
      maxHeight: '500px', 
      objectFit: 'contain' 
    };
    
    let src = file.url || file.preview;
    if (!src) {
      src = await getBase64(file.originFileObj);
      file.preview = src;
    }
    
    Modal.info({
      title: '圖片預覽',
      width: 800,
      content: (
        <Carousel autoplay>
          {fileList.map((item, index) => (
            <div key={index}>
              <img 
                src={item.url || item.preview} 
                alt={`preview-${index}`}
                style={imageStyle}
              />
            </div>
          ))}
        </Carousel>
      ),
      footer: null,
    });
  };

  // Handle tag close
  const handleTagClose = (removedTag) => {
    const newTags = selectedTags.filter(tag => tag !== removedTag);
    setSelectedTags(newTags);
    form.setFieldsValue({ tags: newTags });
  };

  // Handle tag add
  const addTag = (newTag) => {
    if (newTag && !selectedTags.includes(newTag)) {
      const newTags = [...selectedTags, newTag];
      setSelectedTags(newTags);
      setTags(newTags);
      form.setFieldsValue({ tags: newTags });
    }
  };

  // add new tag
  const handleModalOk = () => {
    if (newTagInput) {
      addTag(newTagInput);
      setNewTagInput('');
    }
    setIsModalVisible(false);
  };

  const handleModalCancel = () => {
    setNewTagInput('');
    setIsModalVisible(false);
  };

  return (
    <>
      <Form.Item
        name="provider"
        label="Select Merchant"
        rules={[{ required: true, message: '請選擇商品提供者' }]}
        className="w-1/5"
      >
        <Select placeholder="請選擇商品提供者">
          {organizations?.map((org) => (
            <Select.Option 
              key={org.id}
              value={org.id}
            >
              {org.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="categoryId"
        label="商品類別"
        rules={[{ required: true, message: '請選擇商品類別' }]}
        className="w-1/5"
      >
        <Select placeholder="請選擇商品類別">
          {categories?.map((category) => (
            <Select.Option 
              key={category.id}
              value={category.id}
            >
              {category.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      {/* use different field names to store selected shop information */}
      <Form.Item name="selectedShopName" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="selectedShopId" hidden>
        <Input />
      </Form.Item>

      <div className="flex space-x-6">
        <Form.Item 
          label="商品名稱" 
          name="productTitle" 
          className="w-1/3"
          rules={[
            { required: true, message: '請輸入商品名稱' },
            { max: 128, message: '商品名稱不能超過128個字符' }
          ]}
        >
          <Input placeholder="請輸入商品名稱" />
        </Form.Item>
        <Form.Item 
          label="商品序號" 
          name="productNumber" 
          className="w-1/3"
          rules={[{ required: true, message: '商品序號為必填' }]}
        >
          <Input disabled placeholder="Automatically Generated by System" />
        </Form.Item>
      </div>
      <div className="font-medium mb-2">
        商品主要圖片
        <span className="text-red-500 ml-1">*</span>
      </div>
      <Form.Item
        name="uploadImage"
        className="mb-4"
      >
        <div className="flex space-x-4">
          <ImgCrop rotationSlider>
            <Upload
              action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
              listType="picture-card"
              fileList={fileList}
              onChange={onChange}
              onPreview={onPreview}
              maxCount={5}
              beforeUpload={() => false}
            >
              {fileList.length < 5 && (
                <div>
                  <PlusOutlined />
                  <div className="mt-2">Upload Photo</div>
                </div>
              )}
            </Upload>
          </ImgCrop>
        </div>
      </Form.Item>
      <Form.Item 
        label="商品描述" 
        name="productDescription"
        rules={[
          { required: true, message: '請輸入商品描述' },
          { max: 1024, message: '商品說明不能超過1024個字符' }
        ]}
      >
        <TextArea rows={4} placeholder="請輸入商品介紹" />
      </Form.Item>

      <Form.Item 
        label="注意事項說明" 
        name="productNotice"
        rules={[
          { required: true, message: '請輸入注意事項說明' },
          { max: 1024, message: '注意事項不能超過1024個字符' }
        ]}
      >
        <TextArea rows={4} placeholder="Please Enter Instructions and Rules of Use" />
      </Form.Item>

      {/* Reservation and Tags */}
      <div className="flex space-x-4">
        <Form.Item 
          label="預約" 
          name="reservation"
        >
          <Select style={{ width: 200 }}>
            <Select.Option value="No Reservation Required">No Reservation Required</Select.Option>
            <Select.Option value="Reservation Date Required">Reservation Date Required</Select.Option>
            <Select.Option value="Reservation Date and Time Slot Required">Reservation Date and Time Slot Required</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item label="可預約區間" name="bookingPeriod" >
          <RangePicker 
            allowEmpty={[true, true]}
          />
        </Form.Item>
      </div>

      <Form.Item
        label="商品有效期限"
        name="validPeriod"
      >
        <RangePicker
          allowEmpty={[false, true]}
          format="YYYY-MM-DD"
          disabledDate={(current) => current && current < moment().startOf('day')}
          placeholder={['Start Date', 'End Date']}
        />
      </Form.Item>

      {/* tags */}
      <Form.Item 
          label={`標籤 (${selectedTags.length}/5)`}
          name="tags"
        >
          <div className="flex flex-wrap gap-2 border border-gray-300 rounded-lg p-2 min-w-[400px] w-fit">
            {selectedTags.map((tag) => (
              <Tag
                key={tag}
                closable
                onClose={() => handleTagClose(tag)}
                className="bg-green-50 text-green-600 border-green-200"
              >
                {tag}
              </Tag>
            ))}
            {selectedTags.length < 5 && (
              <Tag
                className="border-dashed border-gray-300 cursor-pointer hover:border-green-500"
                onClick={() => setIsModalVisible(true)}
              >
                <PlusOutlined /> 新增標籤
              </Tag>
            )}
          </div>
        </Form.Item>

      <Form.Item label="商品備註（小標呈現）" name="copyright">
        <Input placeholder="Please Enter Notes (within 25 characters)" maxLength={25} disabled/>
      </Form.Item>

      {/* Image URL */}
      <Form.Item 
        name="productImage" 
        label="圖片 URL" 
        hidden
      >
        <Input disabled />
      </Form.Item>

      {/* add Modal component */}
      <Modal
        title="新增標籤"
        open={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        okText="確定"
        cancelText="取消"
      >
        <Input
          placeholder="請輸入標籤名稱"
          value={newTagInput}
          onChange={(e) => setNewTagInput(e.target.value)}
          onPressEnter={handleModalOk}
        />
      </Modal>
    </>
  );
};

export default Information;
