import React, { useState, useEffect, useRef } from 'react';
import { Button, Divider, Tabs, Tag, Input, Select, Radio } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';

  // Handle shop detail
  export default function OrgDetail({ shop }) {
    const [activeTab, setActiveTab] = useState("1");
    const items = [
      {
        key: "1",
        label: "商家資訊預覽",
        children: (
          <div className="flex-1 pb-20">
            <div className="flex flex-col bg-white rounded-lg shadow gap-1 p-4">
              {/* 商家名稱和標籤 */}
              <div className="flex flex-col items-center mb-4">
                <img
                  src={shop.imageUrl || "/images/default-service.jpg"}
                  alt={shop.name}
                  className="w-24 h-24 object-cover rounded-full mb-2"
                />
                <h3 className="text-2xl font-semibold">{shop.name}</h3>
                <div className="flex gap-2 mt-2">
                  {shop.tags?.map((tag, index) => (
                    <span
                      key={index}
                      className="px-3 py-1 bg-green-100 text-green-800 rounded-full text-sm"
                    >
                      {tag}
                    </span>
                  ))}
                  <Button shape="circle" icon={<PlusOutlined />} />
                </div>
              </div>

              {/* 新增的商家資訊 */}
              <div className="mb-4">
                <h4 className="font-semibold">營業時間</h4>
                <div className="space-y-2 mt-2">
                  <div className="flex items-center gap-2">
                    <img src="/images/clock.png" alt="Clock" className="w-6 h-6" />
                    <span>{shop.businessHours || "Unset"}</span>
                  </div>
                </div>
              </div>

              <div className="mb-4">
                <h4 className="font-semibold">商家評分</h4>
                <div className="space-y-2 mt-2">
                  <div className="flex items-center gap-2">
                    <img src="/images/star.png" alt="Star" className="w-6 h-6" />
                    <span>{shop.rating || "Unset"}</span>
                  </div>
                </div>
              </div>

              {/* 商家平台 */}
              <div className="mb-4">
                <h4 className="font-semibold">商家平台</h4>
                <div className="flex gap-4 mt-2">
                  <div className="flex items-center gap-2">
                    <img src="/images/facebook.png" alt="Facebook" className="w-6 h-6" />
                    <span>Unset</span>
                  </div>
                  <div className="flex items-center gap-2">
                    <img src="/images/instagram.png" alt="Instagram" className="w-6 h-6" />
                    <span>Unset</span>
                  </div>
                  <div className="flex items-center gap-2">
                    <img src="/images/thread.png" alt="thread" className="w-6 h-6" />
                    <span>Unset</span>
                  </div>
                  <div className="flex items-center gap-2">
                    <img src="/images/tiktok.png" alt="TikTok" className="w-6 h-6" />
                    <span>Unset</span>
                  </div>
                </div>
              </div>

              {/* 基本資料 */}
              <div className="mb-4">
                <h4 className="font-semibold">基本資料</h4>
                <div className="space-y-2 mt-2">
                  <div className="flex items-center gap-2">
                    <img src="/images/date.png" alt="Date" className="w-6 h-6" />
                    <span>{shop.establishDate || "Unset"}</span>
                  </div>
                  <div className="flex items-center gap-2">
                    <img src="/images/phone.png" alt="Phone" className="w-6 h-6" />
                    <span>{shop.phone || "Unset"}</span>
                  </div>
                  <div className="flex items-center gap-2">
                    <img src="/images/email.png" alt="Email" className="w-6 h-6" />
                    <span>{shop.email || "Unset"}</span>
                  </div>
                  <div className="flex items-center gap-2">
                    <img src="/images/location.png" alt="Location" className="w-6 h-6" />
                    <span>{`${shop.city || ""}${shop.district || ""}` || "Unset"}</span>
                  </div>
                </div>
              </div>

              {/* 負責人 */}
              <div className="mb-4">
                <h4 className="font-semibold">負責人</h4>
                <div className="space-y-2 mt-2">
                  <div className="flex items-center gap-2">
                    <img src="/images/person.png" alt="User" className="w-6 h-6" />
                    <span>{shop.ownerName || "Unset"}</span>
                  </div>
                  <div className="flex items-center gap-2">
                    <img src="/images/phone.png" alt="Phone" className="w-6 h-6" />
                    <span>{shop.ownerPhone || "Unset"}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ),
      },
      {
        key: "2",
        label: "篩選商家",
        children: (
          <div className="p-4">
            <div className="space-y-6">
              <div>
                <div className="mb-2">篩選特定商家</div>
                <Input
                  placeholder="輸入商家名稱,商家序號,商家電話,商家Email..."
                  className="w-full"
                  disabled
                />
              </div>

              <div>
                <div className="mb-2">美容師數量</div>
                <div className="flex gap-2">
                  <Input
                    placeholder="輸入最低數量"
                    className="flex-1"
                    disabled
                  />
                  <span className="flex items-center">~</span>
                  <Input
                    placeholder="輸入最高數量"
                    className="flex-1"
                    disabled
                  />
                </div>
              </div>

              <div>
                <div className="mb-2">服務數量</div>
                <div className="flex gap-2">
                  <Input
                    placeholder="輸入最低數量"
                    className="flex-1"
                    disabled
                  />
                  <span className="flex items-center">~</span>
                  <Input
                    placeholder="輸入最高數量"
                    className="flex-1"
                    disabled
                  />
                </div>
              </div>

              <div>
                <div className="mb-2">商品數量</div>
                <div className="flex gap-2">
                  <Input
                    placeholder="輸入最低數量"
                    className="flex-1"
                    disabled
                  />
                  <span className="flex items-center">~</span>
                  <Input
                    placeholder="輸入最高數量"
                    className="flex-1"
                    disabled
                  />
                </div>
              </div>

              <div>
                <div className="mb-2">累積營收</div>
                <div className="flex gap-2">
                  <Input
                    placeholder="輸入最低金額"
                    className="flex-1"
                    disabled
                  />
                  <span className="flex items-center">~</span>
                  <Input
                    placeholder="輸入最高金額"
                    className="flex-1"
                    disabled
                  />
                </div>
              </div>

              <div>
                <div className="mb-2">商家標籤</div>
                <Select
                  className="w-full"
                  placeholder="Select Merchant標籤"
                  mode="multiple"
                  options={[
                    { value: "beauty", label: "美容" },
                    { value: "spa", label: "SPA" },
                    { value: "massage", label: "按摩" },
                  ]}
                  disabled
                />
                <div className="flex gap-2 mt-2">
                  <Radio.Group defaultValue="any" className="w-full" disabled>
                    <div className="flex justify-between">
                      <Radio value="all">符合所有選項</Radio>
                      <Radio value="any">符合任一選項</Radio>
                    </div>
                  </Radio.Group>
                </div>
              </div>

              <div>
                <div className="mb-2">商家所在縣市</div>
                <Select
                  className="w-full"
                  placeholder="擇商家所在縣市"
                  mode="multiple"
                  options={[
                    { value: "taipei", label: "北市" },
                    { value: "newtaipei", label: "新北市" },
                  ]}
                  disabled
                />
                <div className="flex gap-2 mt-2">
                  <Radio.Group defaultValue="any" className="w-full" disabled>
                    <div className="flex justify-between">
                      <Radio value="all">符合所有選項</Radio>
                      <Radio value="any">符合任一選項</Radio>
                    </div>
                  </Radio.Group>
                </div>
              </div>
            </div>
          </div>
        ),
      },
    ];

    return (
      <div className="flex flex-col h-full relative">
        <div className="flex-1 p-4">
          <Tabs
            items={items}
            className="flex-1 [&_.ant-tabs-content-holder]:overflow-auto"
            tabBarStyle={{ justifyContent: 'flex-start' }}
            activeKey={activeTab}
            onChange={(key) => setActiveTab(key)}
          />
        </div>
      </div>
    );
  };