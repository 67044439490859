import { request } from '../../utils';

/**
 * Agent Channel API
 */
export const AgentApi = {
  getAgentClients: async (roomId) => { 
    return await request.get(`/private/module/agent/${roomId}/clients`);
  },
  getAgentMessages: async (roomId, socialMediaClientId, limit = 10, fromId = null) => {
    return await request.get(`/private/module/agent/${roomId}/chats/${socialMediaClientId}`, {
      params: {
        limit: limit,
        from_id: fromId
      }
    });
  },
  sendAgentMessage: async (roomId, socialMediaClientId, message) => {
    return await request.post(`/private/module/agent/${roomId}/p2p/${socialMediaClientId}`, {
      text: message
    });
  },
  // get
  getAgentChannel: async (roomId) => {
    return await request.get(`/private/module/agent/${roomId}`);
  },

  // create
  createAgentProperty: async (roomId, agentProperty) => {
    return await request.post(`/private/module/agent/${roomId}`, agentProperty);
  },
  
  // update
  updateAgentWebhookStatus: async (roomId, socialMediaClientId, useWebhook) => {
    return await request.patch(
      `/private/module/agent/${roomId}/use_webhook/${socialMediaClientId}`,
      null,
      {
        params: {
          use_webhook: useWebhook
        }
      }
    );
  },

  updateAgentChatSettings: async (roomId, chatSettings) => {
    return await request.put(`/private/module/agent/settings/${roomId}/chat_settings`, chatSettings);
  },
  updateAgentBaseSettings: async (roomId, baseSettings) => {
    return await request.put(`/private/module/agent/settings/${roomId}/base`, baseSettings);
  },
  updateAgentNickname: async (roomId, socialMediaClientId, nickname) => {
    return await request.patch(`/private/module/agent/${roomId}/nickname/${socialMediaClientId}?nickname=${nickname}`);
  },



  // delete
  deleteAgentProperty: async (roomId) => {
    return await request.delete(`/private/module/agent/${roomId}`);
  },


  addAgentNote: async (roomId, socialMediaClientId, noteName, noteContent) => {
    return await request.post(
      `/private/module/agent/${roomId}/note/${socialMediaClientId}`,
      null,
      {
        params: {
          note_name: noteName,
          note_content: noteContent
        }
      }
    );
},

updateAgentNote: async (roomId, socialMediaClientId, noteId, noteName, noteContent) => {
  return await request.patch(
      `/private/module/agent/${roomId}/note/${socialMediaClientId}`,
      null,
      {
        params: {
          note_id: noteId,
          note_name: noteName,
          note_content: noteContent
        }
      }
    );
},

deleteAgentNote: async (roomId, socialMediaClientId, noteId) => {
  return await request.delete(
      `/private/module/agent/${roomId}/note/${socialMediaClientId}`,
      {
        params: {
          note_id: noteId
        }
      }
    );
},
addAgentTag: async (roomId, socialMediaClientId, tagName) => {
  return await request.post(
      `/private/module/agent/${roomId}/tag/${socialMediaClientId}`,
      null,
      {
        params: {
          name: tagName
        }
      }
    );
},
deleteAgentTag: async (roomId, socialMediaClientId, tagId) => {
  return await request.delete(
      `/private/module/agent/${roomId}/tag/${socialMediaClientId}`,
      {
        params: {
          tag_id: tagId
        }
      }
    );
},
fetchAgentTagGroup: async (roomId) => {
  return await request.get(`/private/module/social_media_clients/${roomId}/tag_group`);
}
}; 