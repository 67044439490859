import React, { useState, useEffect } from "react";
import { Input, Radio, Table, Switch, Button, Form, Checkbox } from "antd";
import "tailwindcss/tailwind.css";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";

const ServicePricing = ({ form }) => {
  const [isFixedPrice, setIsFixedPrice] = useState(true);
  const [pricingData, setPricingData] = useState([]);

  // 監聽表單的 pricingMode 值變化
  useEffect(() => {
    const currentMode = form.getFieldValue('pricingMode');
    setIsFixedPrice(currentMode === 'fixed');
  }, [form.getFieldValue('pricingMode')]);

  // 初始化時同步狀態
  useEffect(() => {
    const currentMode = form.getFieldValue('pricingMode');
    if (currentMode) {
      setIsFixedPrice(currentMode === 'fixed');
    }
  }, []);

  const handlePricingModeChange = (e) => {
    const isFixed = e.target.value === "fixed";
    setIsFixedPrice(isFixed);
    
    // 清除相關欄位
    form.setFieldsValue({
      pricingTable: isFixed ? undefined : initialData,
      fixedPrice: isFixed ? '' : undefined,
      pricingMode: isFixed ? 'fixed' : 'tiered'
    });
  };

  // define initial table data
  const initialData = [
    { 
      key: "1", 
      actualDuration: "2-seater", 
      backendDuration: "60 mins", 
      price: "1800", 
      selected: true,
      discount: false,
      discountPrice: "1800", 
      earlyBird: false,
      earlyBirdPrice: "1800" 
    },
    { key: "2", actualDuration: "3-seater", backendDuration: "90 mins", price: "", discount: false, discountPrice: "", earlyBird: false, earlyBirdPrice: "" },
    { key: "3", actualDuration: "4-seater", backendDuration: "120 mins", price: "", discount: false, discountPrice: "", earlyBird: false, earlyBirdPrice: "" },
    { key: "4", actualDuration: "5-seater", backendDuration: "150 mins", price: "", discount: false, discountPrice: "", earlyBird: false, earlyBirdPrice: "" },
    { key: "5", actualDuration: "6-seater", backendDuration: "180 mins", price: "", discount: false, discountPrice: "", earlyBird: false, earlyBirdPrice: "" },
  ];

  useEffect(() => {
    // set initial table data
    if (!isFixedPrice) {
      form.setFieldsValue({
        pricingTable: initialData
      });
    }
  }, [isFixedPrice]);

  // modify function to handle price change
  const handlePriceChange = (record, value) => {
    const currentTable = form.getFieldValue('pricingTable') || initialData;
    const updatedTable = currentTable.map(item => {
      if (item.key === record.key) {
        return { 
          ...item, 
          price: value,
          // update discount price and early bird price
          discountPrice: value,
          earlyBirdPrice: value
        };
      }
      return item;
    });
    form.setFieldsValue({ pricingTable: updatedTable });
  };

  // 計算折扣價格
  const calculateDiscountPrice = (originalPrice, discountValue) => {
    if (!originalPrice || !discountValue) return '';
    return Math.floor(originalPrice * (discountValue / 10));
  };

  // 監聽會員折扣變化
  useEffect(() => {
    const memberDiscountValue = form.getFieldValue('memberDiscountValue');
    if (memberDiscountValue) {
      const currentPricingData = form.getFieldValue('pricingTable') || [];
      const updatedData = currentPricingData.map(item => ({
        ...item,
        discountPrice: calculateDiscountPrice(item.price, memberDiscountValue)
      }));
      form.setFieldsValue({ pricingTable: updatedData });
    }
  }, [form.getFieldValue('memberDiscountValue')]);

  // modify table column definition
  const columns = [
    {
      title: "Car Model",
      dataIndex: "actualDuration",
      key: "actualDuration",
    },
    {
      title: "Duration",
      dataIndex: "backendDuration",
      key: "backendDuration",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (text, record) => (
        <Form.Item
          name={['pricingTable', record.key - 1, 'price']}
          noStyle
        >
          <Input 
            prefix="$" 
            className="w-24"
            onChange={(e) => {
              const validValue = validateNumberInput(e);
              handlePriceChange(record, validValue);
            }}
          />
        </Form.Item>
      ),
    },
    {
      title: "Discount",
      dataIndex: "discount",
      key: "discount",
      render: (_, record) => (
        <Form.Item
          name={['pricingTable', record.key, 'discount']}
          valuePropName="checked"
          noStyle
        >
          <Checkbox 
            onChange={(e) => {
              const memberDiscountValue = form.getFieldValue('memberDiscountValue');
              if (e.target.checked && memberDiscountValue) {
                const discountPrice = calculateDiscountPrice(record.price, memberDiscountValue);
                const newData = [...pricingData];
                const index = newData.findIndex(item => item.key === record.key);
                if (index > -1) {
                  newData[index].discountPrice = discountPrice;
                  setPricingData(newData);
                }
              }
            }} 
          />
        </Form.Item>
      ),
    },
    {
      title: "Discount Price",
      dataIndex: "discountPrice",
      key: "discountPrice",
      render: (_, record) => (
        <Form.Item
          name={['pricingTable', record.key, 'discountPrice']}
          noStyle
        >
          <Input 
            prefix="$" 
            className="w-24"
            disabled={!record.discount}
            onChange={(e) => {
              const validValue = validateNumberInput(e);
              e.target.value = validValue;
            }}
          />
        </Form.Item>
      ),
    },
    {
      title: "Late Bird Offer",
      dataIndex: "earlyBird",
      key: "earlyBird",
      render: (_, record) => (
        <Switch defaultChecked={false} />
      ),
    },
    {
      title: "Late Bird Offer Price",
      dataIndex: "earlyBirdPrice",
      key: "earlyBirdPrice",
      render: (_, record) => (
        <Input 
          defaultValue={record.earlyBirdPrice} 
          prefix="$" 
          className="w-24"
          onChange={(e) => {
            const validValue = validateNumberInput(e);
            e.target.value = validValue;
          }}
        />
      ),
    },
  ];

  // add number input validation function
  const validateNumberInput = (e) => {
    const value = e.target.value;
    if (value === '' || /^\d+$/.test(value)) {
      return value;
    }
    return e.target.value.replace(/\D/g, '');
  };

  return (
    <div className="bg-white">
      {/* 價格模式選擇 */}
      <Form.Item 
        name="pricingMode" 
        initialValue="fixed"
        rules={[{ required: true, message: '請選擇價格模式' }]}
      >
        <Radio.Group
          onChange={handlePricingModeChange}
          value={isFixedPrice ? "fixed" : "tiered"}
          className="flex gap-8 mb-6"
        >
          <Radio value="fixed" className="flex items-center">
            Fixed Amount
          </Radio>
          <Radio value="tiered" className="flex items-center">
            Specification Amount
          </Radio>
        </Radio.Group>
      </Form.Item>

      {/* 根據選擇顯示對應的表單 */}
      {isFixedPrice ? (
        <Form.Item 
          label="Please Enter Amount" 
          name="fixedPrice"
          rules={[{ 
            required: true, 
            message: 'Please Enter Amount',
            type: 'number',
            transform: (value) => Number(value)
          }]}
        >
          <Input 
            prefix="$" 
            className="w-48"
            onChange={(e) => {
              const validValue = validateNumberInput(e);
              e.target.value = validValue;
            }}
          />
        </Form.Item>
      ) : (
        <Form.Item 
          name="pricingTable"
        >
          <Table
            columns={columns}
            dataSource={form.getFieldValue('pricingTable') || initialData}
            pagination={false}
            className="bg-white"
          />
        </Form.Item>
      )}
    </div>
  );
};

export default ServicePricing;
