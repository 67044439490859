import React, { useState, useEffect } from 'react'
import classNames from 'classnames';
import { Modal } from 'antd'

import { useAuth } from '../../../../contexts/AuthProvider'

import RuleSetting from './RuleSetting';
import PermissionSetting from './PermissionSetting';
import LineSetting from './LineSetting';
import VoiceSetting from './VoiceSetting';
import IntegratingSetting from './IntegratingSetting'
import AutomationSetting from './AutomationSetting'
import ServerSetting from './ServerSetting'

const ChatSettings = ({ open, closeModal, room }) => {
  const { token } = useAuth();
  const [tab, setTab] = useState(0)
  const [integratingExpanded, setIntegratingExpanded] = useState(false);

  useEffect(() => {
    setTab(0);
    setIntegratingExpanded(false);
  }, [room])

  const handleCloseModal = () => {
    setTab(0);
    setIntegratingExpanded(false);
    closeModal();
  }

  const handleIntegratingClick = () => {
    setIntegratingExpanded(!integratingExpanded);
    if (tab < 4 || tab > 6) {
      setTab(4); // select the first child tab
    }
  }

  return (
    <Modal
      width={724}
      open={open}
      onCancel={handleCloseModal}
      footer={null}
      style={{ top: 10 }}
    >
      <div className="flex flex-row min-h-[705px]">
        <div className="flex flex-col justify-start items-start w-60 px-5 mr-2 py-6 gap-2">
          <div className="text-lg text-textDark font-bold pb-4 border-b border-b-borderLight w-full">
            房間設定
          </div>
          <div className={classNames("h-8 text-textDark hover:bg-primaryMedium px-2 py-1 rounded w-full cursor-pointer", {
            "bg-primaryMedium": tab === 0
          })} onClick={() => setTab(0)}>
            權限管理
          </div>
          <div className={classNames("h-8 text-textDark hover:bg-primaryMedium px-2 py-1 rounded w-full cursor-pointer", {
            "bg-primaryMedium": tab === 1
          })} onClick={() => setTab(1)}>
            聊天室規則
          </div>
          <div className={classNames("h-8 text-textDark hover:bg-primaryMedium px-2 py-1 rounded w-full cursor-pointer", {
            "bg-primaryMedium": tab === 2
          })} onClick={() => setTab(2)}>
            Line@ 管理
          </div>
          <div className={classNames("h-8 text-textDark hover:bg-primaryMedium px-2 py-1 rounded w-full cursor-pointer", {
            "bg-primaryMedium": tab === 3
          })} onClick={() => setTab(3)}>
            語音輸出
          </div>
          <div 
            className={classNames("h-8 text-textDark hover:bg-primaryMedium px-2 py-1 rounded w-full cursor-pointer", {
              "bg-primaryMedium": tab >= 4 && tab <= 6 // if any child tab is selected
            })} 
            onClick={handleIntegratingClick}
          >
            串接功能
          </div>
          {integratingExpanded && (
            <div className="flex flex-col pl-4">
              <div className={classNames("h-8 text-textDark hover:bg-primaryMedium px-2 py-1 rounded w-full cursor-pointer", {
                "bg-primaryMedium": tab === 4
              })} onClick={() => setTab(4)}>
                選擇伺服器
              </div>
              <div className={classNames("h-8 text-textDark hover:bg-primaryMedium px-2 py-1 rounded w-full cursor-pointer", {
                "bg-primaryMedium": tab === 5
              })} onClick={() => setTab(5)}>
                選擇串接第三方
              </div>
              <div className={classNames("h-8 text-textDark hover:bg-primaryMedium px-2 py-1 rounded w-full cursor-pointer", {
                "bg-primaryMedium": tab === 6
              })} onClick={() => setTab(6)}>
                設定自動化排程
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-col justify-start items-start w-full">
          {tab === 0 && <PermissionSetting room={room} token={token} />}
          {tab === 1 && <RuleSetting room={room} token={token} />}
          {tab === 2 && <LineSetting room={room} token={token} />}
          {tab === 3 && <VoiceSetting room={room} token={token} />}
          {tab === 4 && <ServerSetting room={room} token={token} />}
          {tab === 5 && <IntegratingSetting room={room} token={token} />}
          {tab === 6 && <AutomationSetting room={room} token={token} />}

        </div>
      </div>
    </Modal>
  )
}

export default ChatSettings;
