import React, { useEffect, useState } from "react";
import { request } from "@/utils";
import { Button, Form, Tabs, message, Select, Spin, Divider } from "antd";
import { LoadingOutlined, HomeOutlined } from '@ant-design/icons';
import "tailwindcss/tailwind.css";
import ProductPricing from "./ProductPricing";
import DiscountManagement from "./DiscountManagement";
import ProductPublish from "./ProductPublish";
import Information from "./Information";
import moment from "moment";
import { Link } from "react-router-dom";
import axios from "axios";

const AddProductForm = ({ onSave, products }) => {
  const [loading, setLoading] = useState(true);
  const [tabLoading, setTabLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [serverUrl, setServerUrl] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const [form] = Form.useForm();
  const [previewData, setPreviewData] = useState({
    name: '預設商品名稱',
    provider: '商家',
    price: 0,
    description: '',
    productNotice: '',
    reservation: '',
    bookingPeriod: '',
    imageUrl: '/images/default-product.jpg',
  });
  const [tableLoading, setTableLoading] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [categories, setCategories] = useState([]);

  // Get form values
  const formValues = Form.useWatch([], form);

  // Update preview data
  useEffect(() => {
    if (formValues) {
      // 找到對應的組織名稱
      const selectedOrganization = organizations.find(org => org.id === formValues.provider);
      const organizationName = selectedOrganization ? selectedOrganization.name : '請選擇 商家';

      const validDurations = formValues.pricingTable?.filter(item =>
        item.price && item.price.trim() !== ''
      ) || [];

      const defaultPrice = validDurations.length > 0 ? validDurations[0].price : 0;

      setPreviewData({
        name: formValues.productTitle || '請輸入 商品名稱',
        provider: organizationName,  // 使用組織名稱而不是 ID
        price: formValues.fixedPrice || defaultPrice || 0,
        description: formValues.productDescription || '請輸入 商品說明',
        productNotice: formValues.productNotice || '請輸入 注意事項說明',
        reservation: formValues.reservation || '請輸入 預約說明',
        bookingPeriod: formValues.bookingPeriod || '請輸入 約時間',
        imageUrl: formValues.productImage || '/images/default-product.jpg',
        pricingTable: validDurations.map(item => ({
          ...item,
          selected: item === validDurations[0] // default select the first option
        })),
      });
    }
  }, [formValues, organizations]);

  // Set default form values
  useEffect(() => {
    form.setFieldsValue({
      provider: "", // change from productTarget to provider
      productTitle: "",
      productNumber: Date.now().toString(),
      productImage: '',
    });
  }, [form]);

  useEffect(() => {
    const initializeData = async () => {
      try {
        // 1. 獲取 server_url 和 access_token
        const profileResponse = await request.get(`${SERVER_URL}/private/property/ecommerce/profile`);
        const serverUrl = profileResponse.body.server_url;
        setServerUrl(serverUrl);

        const tokenResponse = await request.get(`${SERVER_URL}/private/property/ecommerce/jwt`);
        const accessToken = tokenResponse.access_token;
        setAccessToken(accessToken);

        if (serverUrl) {
          // 2. 獲取分類列表
          const categoriesResponse = await axios.get(
            `${serverUrl}/public/category/`
          );
          setCategories(categoriesResponse.data);

          // 3. 獲取商家列表
          const organizationsResponse = await axios.get(
            `${serverUrl}/public/organization/`
          );
          setOrganizations(organizationsResponse.data);
        }
      } catch (error) {
        console.error('初始化數據失敗:', error);
        message.error('獲取初始數據失敗');
      }
    };

    initializeData();
  }, []);

  // Handle save
  const handleSave = async () => {
    try {
      const values = await form.validateFields();
      setSaveLoading(true);

      // 直接使用選擇的 provider 作為組織 ID
      const organizationId = values.provider;
      
      if (!organizationId) {
        throw new Error('請選擇商品提供者');
      }

      // 構建 API 請求數據
      const apiPayload = {
        name: values.productTitle,
        description: values.productDescription,
        notification: values.productNotice,
        category_id: values.categoryId,
        identity_ids: [organizationId], // 使用相同的 ID
        valid_from: moment().format('YYYY-MM-DD'),
        valid_until: values.validUntil || moment().add(1, 'year').format('YYYY-MM-DD')
      };

      // 發送創建商品請求
      const response = await axios.post(
        `${serverUrl}/private/sp/${organizationId}`, // 直接使用 organizationId
        apiPayload,
        {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.status === 200) {
        message.success('商品創建成功');
        form.resetFields();
        if (onSave) {
          onSave(response.data);
        }
      }
    } catch (error) {
      console.error('創建商品失敗:', error);
      message.error(error.response?.data?.message || '創建商品失敗');
    } finally {
      setSaveLoading(false);
    }
  };

  // handle tab change
  const handleTabChange = (activeKey) => {
    setTabLoading(true);
    setTimeout(() => {
      setTabLoading(false);
    }, 500);
  };

  // breadcrumb navigation
  const BreadcrumbNav = () => (
    <div className="flex flex-col space-y-2 mb-4 p-4">
      <div className="text-lg font-bold">商品管理</div>

      <div className="flex items-center text-sm">
        <Link to="/" className="text-green-600">
          <HomeOutlined className="mr-1" />
        </Link>
        <span className="mx-2 text-gray-500">/</span>
        <span
          className="text-green-600 cursor-pointer"
          onClick={() => window.location.href = '/product'}
        >
          商品管理
        </span>
        <span className="mx-2 text-gray-500">/</span>
        <span className="text-gray-500">新增商品</span>
      </div>
    </div>
  );

  const items = [
    {
      key: "1",
      label: "Basic Information",
      children: (
        <div className="bg-white p-6 rounded-lg">
          {tabLoading ? (
            <div className="flex justify-center items-center h-[400px]">
              <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
            </div>
          ) : (
            <Information form={form} organizations={organizations} categories={categories} />
          )}
        </div>
      ),
    },
    {
      key: "2",
      label: "商品規格",
      children: (
        <div className="bg-white p-6 rounded-lg">
          {tabLoading ? (
            <div className="flex justify-center items-center h-[400px]">
              <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
            </div>
          ) : (
            <ProductPricing form={form} />
          )}
        </div>
      ),
    },
    {
      key: "3",
      label: "Discounts and Offers",
      children: (
        <div className="bg-white p-6 rounded-lg">
          {tabLoading ? (
            <div className="flex justify-center items-center h-[400px]">
              <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
            </div>
          ) : (
            <DiscountManagement form={form} />
          )}
        </div>
      ),
    },
    {
      key: "4",
      label: "商品上架",
      children: (
        <div className="bg-white p-6 rounded-lg">
          {tabLoading ? (
            <div className="flex justify-center items-center h-[400px]">
              <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
            </div>
          ) : (
            <ProductPublish form={form} />
          )}
        </div>
      ),
    },
  ];

  // Handle product preview
  const ProductPreview = ({ data }) => {
    return (
      <>
        <div className="bg-[#F3F3F3] p-4" style={{ boxShadow: '0px -4px 8px 0px #00000012 inset' }}>
          <span className="text-lg font-semibold mb-4">前台商品預覽</span>
        </div>
        <div className="bg-white shadow-lg sticky top-4">
          {/* Top area */}
          <div className="p-4">
            <div className="relative mb-4">
              <img
                src={data.imageUrl}
                alt={data.name}
                className="w-full h-48 object-cover"
              />
            </div>
            <h3 className="text-lg font-bold mb-1">{data.name}</h3>
            <p className="text-lg font-bold mb-2">{data.provider}</p>
            {/* Product description area */}
            <div>
              <p className="text-sm text-gray-700">{data.description}</p>
            </div>
            <div className="text-[24px] mb-2 text-primary flex items-baseline justify-end pb-2">
              <span className="font-roboto text-2xl font-medium leading-[26px] tracking-[-0.9px] text-right">
                $ {Number(data.price).toLocaleString()}
              </span>
              <span className="text-xl text-black ml-1 align-baseline"></span>
            </div>
          </div>

          <Divider className="border-b-[5px] border-[#F0F0F0] mx-4 my-2" />

          {/* show duration selection according to pricingMode */}
          {formValues?.pricingMode === 'tiered' && data.pricingTable?.length > 0 && (
            <div className="p-4">
              <h4 className="text-base font-bold mb-3">Select Specification</h4>
              <h6 className="text-sm text-gray-600 mb-2">Model</h6>
              <div className="flex flex-wrap gap-2">
                {data.pricingTable.map((item) => (
                  <Button
                    key={item.actualDuration}
                    className="min-w-[80px] mb-2"
                    type={item.selected ? 'primary' : 'default'}
                    onClick={() => {
                      setPreviewData(prev => ({
                        ...prev,
                        price: item.price || 0,
                        pricingTable: prev.pricingTable.map(row => ({
                          ...row,
                          selected: row.actualDuration === item.actualDuration
                        }))
                      }));
                    }}
                  >
                    {item.actualDuration}
                  </Button>
                ))}
              </div>
            </div>
          )}

          {/* Reservation time selection area */}
          <div className="p-4">
            <h4 className="text-base font-bold mb-3">Select Time</h4>
            <div className="grid grid-cols-2 gap-4 mb-4">
              <div>
                <div className="text-sm mb-2">Date</div>
                <Select
                  className="w-full"
                  placeholder="請選擇日期"
                  disabled
                  value={moment().format('YYYY-MM-DD')}
                >
                  {[...Array(7)].map((_, index) => {
                    const date = moment().add(index, 'days');
                    return (
                      <Select.Option key={date.format('YYYY-MM-DD')} value={date.format('YYYY-MM-DD')}>
                        {date.format('MM/DD (ddd)')}
                      </Select.Option>
                    );
                  })}
                </Select>
              </div>
              <div>
                <div className="text-sm mb-2">Time</div>
                <Select
                  className="w-full"
                  placeholder="請選擇時間"
                  disabled
                  value="15:00"
                >
                  {['09:00', '10:00', '11:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00'].map((time) => (
                    <Select.Option key={time} value={time}>
                      {time}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>

            {/* 9-grid time selection */}
            <div className="grid grid-cols-3 gap-2">
              {[
                "09:00",
                "10:00",
                "11:00",
                "14:00",
                "15:00",
                "16:00",
                "17:00",
                "18:00",
                "19:00",
              ].map((time) => (
                <div
                  key={time}
                  className={`text-center py-2 ${time === "15:00" ? "bg-primary text-white" : "bg-gray-200"
                    } min-w-[80px] mb-2 rounded`}
                >
                  {time}
                </div>
              ))}
            </div>
          </div>
          <Divider className="border-b-[5px] border-[#F0F0F0] mx-4 my-2" />


          {/* Notice area */}
          <div className="p-4 border-b">
            <h4 className="text-base font-bold mb-2">注意事項</h4>
            <p className="text-sm text-gray-700">{data.productNotice}</p>
          </div>


        </div>
      </>
    );
  };

  // add loading effect
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center w-full h-screen">
        <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
      </div>
    );
  }

  return (
    <div className="flex w-full overflow-auto">

      {/* left form area */}
      <div className="flex-1 flex flex-col shadow min-w-0 overflow-auto h-full">
        {/* breadcrumb navigation */}
        <BreadcrumbNav />
        <Form
          form={form}
          layout="vertical"
        >
          <div className="bg-white rounded-lg mb-6">
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-6 p-4">
              <h1 className="text-[20px] font-semibold">新增商品</h1>
              <div className="flex gap-3">
                <Button type="default" className="w-full sm:w-auto">Save to Drafts</Button>
                <Button
                  type="primary"
                  onClick={handleSave}
                  className="w-full sm:w-auto"
                  loading={saveLoading} // add loading property
                  disabled={saveLoading} // disable button when loading
                >
                  {saveLoading ? '儲存中...' : 'Save and Publish'}
                </Button>
              </div>
            </div>
            <Tabs
              defaultActiveKey="1"
              size="large"
              items={items}
              onChange={handleTabChange}
              className="product-tabs"
            />
          </div>
        </Form>
      </div>

      {/* right preview area */}
      <div className="w-[440px] flex-shrink-0 border-l border-t border-gray-200 shadow overflow-auto">
        <div>
          {previewData && (
            <ProductPreview data={previewData} />
          )}
        </div>
      </div>
    </div>
  );
};

export default AddProductForm;
