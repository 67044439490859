import React, { useState, useEffect, useRef } from 'react';
import { Button, Divider, Tabs, Tag } from 'antd';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';


const ServiceDetail = ({ service }) => {
  const navigate = useNavigate();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [slideDirection, setSlideDirection] = useState('next');
  const timer = useRef(null);

  if (!service) {
    return (
      <div className="w-full flex items-center justify-center h-[calc(100vh-64px-32px)] bg-gray-50">
        <div className="flex flex-col items-center justify-center">
          <p className="text-gray-500 text-center">Please select a service to view details</p>
        </div>
      </div>
    );
  }

  const items = [
    {
      key: "1",
      label: "Service Information",
      children: (
        <div className="flex-1 pb-20">
          <div className="flex flex-col bg-white">
            {/* 圖片輪播區域 */}
            <div className="relative">
              <img 
                src={service.imageUrl} 
                alt={service.name} 
                className="w-full h-[240px] object-cover"
              />
              {service.recommendation && (
                <div className="absolute top-4 left-4 bg-[#FF7171] text-white px-3 py-1 rounded-md">
                  Popular
                </div>
              )}
              <div className="absolute bottom-4 right-4 flex gap-2">
                <span className="w-2 h-2 bg-white rounded-full opacity-50"></span>
                <span className="w-2 h-2 bg-white rounded-full"></span>
                <span className="w-2 h-2 bg-white rounded-full opacity-50"></span>
              </div>
            </div>

            <div className="p-6">
              {/* 標題區域 */}
              <h1 className="text-2xl font-bold mb-2">{service.name}</h1>
              <h2 className="text-gray-600 text-lg mb-6">{service.category?.name}</h2>

              {/* 功能標籤 */}
              <div className="flex gap-4 mb-8">
                <div className="bg-[#FFF5EA] text-[#B98B73] px-4 py-2 rounded-full">
                  Buy Now, Use Now
                </div>
                <div className="bg-[#FFF5EA] text-[#B98B73] px-4 py-2 rounded-full">
                  Free Cancellation
                </div>
              </div>

              {/* 使用期間 */}
              <div className="mb-8">
                <h3 className="text-gray-400 text-lg mb-2">Usage Period</h3>
                <p className="text-lg">{moment(service.startDate).format('YYYY.MM.DD')}~{moment(service.endDate).format('YYYY.MM.DD')}</p>
              </div>

              {/* 服務說明 */}
              <div className="mb-8">
                <h3 className="text-gray-400 text-lg mb-2">Service Description</h3>
                <p className="text-gray-700 leading-relaxed">{service.description}</p>
              </div>

              {/* 注意事項 */}
              <div className="mb-8">
                <h3 className="text-gray-400 text-lg mb-2">Notes</h3>
                <p className="text-gray-700">{service.notification}</p>
              </div>

              {/* 價格顯示 */}
              <div className="text-right mb-8">
                <p className="text-2xl font-bold">from $ {service.specs?.[0]?.price || 0} /hr</p>
              </div>

              {/* 規格選擇 */}
              <div className="mb-8">
                <h3 className="text-xl font-bold mb-4">Select Specification</h3>
                <h4 className="text-lg mb-3">Duration</h4>
                <div className="flex gap-4">
                  {service.specs?.map((spec, index) => (
                    <button key={index} className="bg-gray-100 px-6 py-3 rounded-lg">
                      {spec.duration} mins
                    </button>
                  ))}
                </div>
              </div>
            </div>

            {/* 編輯按鈕 */}
            <div className="fixed w-[440px] bottom-0 bg-white border shadow-3xl p-4">
              <Button
                type="primary"
                className="w-full h-10 bg-[#ADDEAC] hover:bg-[#45a049] text-black hover:!text-black"
                onClick={() => navigate(`/service/edit/${service.id}`)}
              >
                Editing Service
              </Button>
            </div>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="w-full flex flex-col h-full bg-white overflow-auto">
      <Tabs defaultActiveKey="1" items={items} className="flex-1 [&_.ant-tabs-content-holder]:overflow-auto [&_.ant-tabs-nav]:px-4 [&_.ant-tabs-nav]:pt-4" />
    </div>
  );
};

export default ServiceDetail;
