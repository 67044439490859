import React, { useState } from "react";
import axios from "axios";

import {
  Form,
  Input,
  Button,
  notification,
  Tooltip,
  Modal,
  Checkbox,
} from "antd";

import approveIcon from "./assets/approve.png";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const COMPANY_ID = process.env.REACT_APP_COMPANY_ID;

const openNotification = (title, description, type = "info") => {
  notification[type]({
    message: title,
    description: description,
    placement: "bottomRight",
  });
};

const Register = () => {
  const [success, setSuccess] = useState(false);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  // const [password, setPassword] = useState("")
  // const [passwordCheck, setPasswordCheck] = useState("")
  const [nickname, setNickname] = useState("");
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [hasScrolledToBottom, setHasScrolledToBottom] = useState(false);
  const [hasAgreed, setHasAgreed] = useState(false);
  const termsRef = React.useRef(null);

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    // 檢查是否滾動到底部或接近底部
    if (scrollHeight - scrollTop - clientHeight < 5) {
      setHasScrolledToBottom(true);
    }
  };

  const handleModalOk = () => {
    if (hasAgreed) {
      setIsModalOpen(false);
    }
  };

  const handleModalCancel = () => {
    // 點擊取消按鈕時，將 hasAgreed 設為 false
    setHasAgreed(false);
    setIsModalOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    // internal checking
    if (!email.includes("@")) {
      openNotification("Email 格式錯誤", "請輸入正確的 Email 格式", "error");
      setLoading(false);
      return;
    }

    // check username pattern
    if (!/^[A-Za-z\d._!#-]*$/.test(username)) {
      openNotification(
        "帳號格式錯誤",
        "特殊符號支援 . _ ! # - 五種。",
        "error"
      );
      setLoading(false);
      return;
    }

    try {
      await axios.get(
        SERVER_URL +
          "/public/auth/check/email/" +
          email +
          "?company_id=" +
          COMPANY_ID
      );
    } catch (error) {
      if (error.response.status === 409) {
        openNotification("Email 已被註冊", "請輸入其他 Email", "error");
        setLoading(false);
        return;
      }
    }

    // check username
    try {
      await axios.get(SERVER_URL + "/public/auth/check/username/" + username);
    } catch (error) {
      if (error.response.status === 409) {
        openNotification("帳號已被註冊", "請輸入其他帳號", "error");
        setLoading(false);
        return;
      }
    }

    // subimit
    try {
      await axios.post(SERVER_URL + "/public/auth/register/" + COMPANY_ID, {
        username: username,
        nickname: nickname,
        email: email,
      });
      setSuccess(true);
    } catch (error) {
      console.log(error);
      openNotification("註冊失敗", "請洽系統管理員", "error");
    }

    setLoading(false);
  };

  return (
    <div className="flex flex-col items-center justify-center w-full h-screen">
      <Modal
        title="TeamSync 服務使用及條款宣告"
        open={isModalOpen}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        closable={hasAgreed}
        maskClosable={false}
        footer={[
          <Button
            key="cancel"
            onClick={handleModalCancel}
            disabled={!hasAgreed}
          >
            取消
          </Button>,
          <Button
            key="ok"
            type="primary"
            onClick={handleModalOk}
            disabled={!hasAgreed}
          >
            同意
          </Button>,
        ]}
        width={800}
      >
        <div
          ref={termsRef}
          style={{
            maxHeight: "400px",
            overflowY: "auto",
            marginBottom: "20px",
            padding: "10px",
          }}
          onScroll={handleScroll}
        >
          <div className="w-full mx-auto  font-sans leading-relaxed h-fit">
            <section className="mb-8">
              <h2 className="text-lg mb-4 font-medium">一、適用範圍</h2>
              <p className="mb-4">
                本服務條款適用於 TeamSync
                企業智能協作平台（以下簡稱「本系統」），涵蓋 AI 助理、專屬 AI
                多人聊天室、模組化企業管理工具等功能。本系統旨在提升企業內部溝通效率與數據管理能力，僅供採購本系統服務的公司內登記之正式員工使
                用，非員工（包括但不限於合作夥伴、承包商、外包人員等）之使用需另行經本公司書面同意，否則將視同違約使用。
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-lg mb-4 font-medium">二、功能限制</h2>
              <p className="mb-4">
                本系統所提供的 AI
                服務，包括但不限於自動化問答、數據標記與企業知識管理，皆依據使用者輸入的內容生成回應。然而
                AI
                模型的輸出可能受限於資料來源、演算法特性及技術發展狀況，無法保證其結果完全正確或符合所有應用場景。本系統
                的 AI
                服務僅作為輔助工具，輸出內容僅供參考性質，無法取代專業人員的判斷，使用者應自行驗證
                AI 生成內容的準確性。
                使用者不得將本系統用於醫療診斷、法律建議、財務決策或其他涉及人身安全與重大經濟風險的用途。如使用者違反本規
                定，本公司蓋不負責，亦保留終止服務的權利。
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-lg mb-4 font-medium">
                三、潛在風險與使用者責任
              </h2>
              <p className="mb-4">
                本系統的 AI
                生成內容僅供參考，最終決策應由使用者自行判斷並承擔相應責任。使用者應了解
                AI 內容可能存在不準確、片
                面或不符合特定需求的情況，並應對輸出內容進行必要的審核與修正。使用者應審慎評估
                AI 輸出內容的適用性，並自行承
                擔使用風險。除故意或重大過失情形外，因使用者之誤用、錯誤認知、未經審慎評估或資料輸入錯誤造成任何損失，本公
                司將不承擔責任；本公司之責任上限最多不得超出使用者已支付之系統服務費用總額。
              </p>
              <p className="mb-4">
                使用者同意並承諾將對自身使用本系統之行為負責，並有義務積極且審慎地檢視、驗證及修正
                AI
                提供之任何資訊，尤其是在作出可能影響公司決策或具有重要影響力的情境下，更應仔細評估、審核
                AI 生成內容之正確性與適用性。
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-lg mb-4 font-medium">四、免責聲明</h2>
              <p className="mb-4">
                若本系統整合或依賴第三方服務（如
                API、外部數據來源等），本公司不對其可用性、準確性或安全性負責，亦不承擔因第三方服務中斷或變更所造成的任何影響或損失。本公司因系統技術升級、維護或法規調整等需求，保留於合理期間事先通知下對本系統之服務變更、調整之權利。但若出現緊急或不可抗力因素，本公司可免除事前通知義務。
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-lg mb-4 font-medium">五、使用規範與終止</h2>
              <p className="mb-4">
                使用者不得利用本系統從事違法、侵害第三方權益或違反公共利益的行為。若使用者因違反本條款而導致帳戶被限制、暫停或終止，將不獲得任何形式的退款或賠償。使用者不得透過本系統進行濫用
                AI 之相關行為，包括但不限於自動化濫發訊息、利用 AI
                生成非法或不當內容、試圖破解或逆向工程 AI
                模型。若本公司認定使用者違反規範，本公司有權立即終止服務，並保留追究法律責任的權利，且無須承擔任何責任。
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-lg mb-4 font-medium">六、條款修訂</h2>
              <p className="mb-4">
                本公司修訂服務條款時，將提前七日公告於官方網站或以電子郵件通知使用者。使用者在修訂條款生效後繼續使用服務時，即視為同意遵守變更後之條款內容。
              </p>
            </section>

            <section className="mb-8">
              <h2 className="text-lg mb-4 font-medium">七、聯繫方式</h2>
              <p className="mb-4">
                若對本條款或服務有疑問，請透過電子郵件（scxai@shuchenai.com）、客服電話（02-7748-0805）聯絡本公司客服團隊，我們將於收到後
                五 個工作日內回覆處理。
              </p>
            </section>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <Checkbox
            checked={hasAgreed}
            onChange={(e) => setHasAgreed(e.target.checked)}
            disabled={!hasScrolledToBottom}
          >
            我已閱讀並同意以上條款
          </Checkbox>
        </div>
      </Modal>

      <div className="flex flex-col items-center justify-center w-80 gap-4">
        <a
          href="/login"
          className="flex flex-row juystify-start w-full hover:underline cursor-pointer"
        >
          返回
        </a>
        {success ? (
          <div className="flex flex-col items-center gap-8">
            <img src={approveIcon} className="h-36 w-36" alt="approve" />
            <div>恭喜您註冊完成！請進入Email中進行驗證</div>
          </div>
        ) : (
          <div className="flex flex-col items-center">
            <div className="text-2xl font-bold mb-4">註冊</div>
            <Form
              layout="vertical"
              style={{
                width: "320px",
              }}
            >
              <Form.Item label="Email">
                <Input
                  onChange={(e) => setEmail(e.target.value)}
                  disabled={loading}
                />
              </Form.Item>
              <Tooltip title="特殊符號僅支援 . _ ! # - 五種。">
                <Form.Item label="帳號">
                  <Input
                    onChange={(e) => setUsername(e.target.value)}
                    disabled={loading}
                  />
                </Form.Item>
              </Tooltip>
              {/* <Form.Item label="密碼">
                  <Input.Password onChange={e => setPassword(e.target.value)} disabled={loading} />
                </Form.Item>
                <Form.Item label="再次輸入密碼">
                  <Input.Password onChange={e => setPasswordCheck(e.target.value)} disabled={loading} />
                </Form.Item> */}
              <Form.Item label="暱稱">
                <Input
                  onChange={(e) => setNickname(e.target.value)}
                  disabled={loading}
                />
              </Form.Item>
              <Form.Item>
                <Checkbox
                  checked={hasAgreed}
                  onChange={(e) => {
                    if (e.target.checked) {
                      // 如果要勾選為 true，則開啟 modal
                      setIsModalOpen(true);
                    } else {
                      // 如果要取消勾選，直接設為 false
                      setHasAgreed(false);
                    }
                  }}
                  disabled={loading}
                >
                  我已閱讀並同意服務條款
                </Checkbox>
              </Form.Item>
              <Form.Item>
                <Button
                  onClick={handleSubmit}
                  disabled={!email || !username || !nickname || !hasAgreed}
                  type="primary"
                  className="w-full"
                  loading={loading}
                >
                  註冊
                </Button>
              </Form.Item>
            </Form>
          </div>
        )}
      </div>
    </div>
  );
};

export default Register;
