import { AirbnbApi } from '../api/AirbnbApi';
import { 
  AirbnbProfile, 
  AirbnbBooking, 
} from '../../domain/entities/airbnb/AirbnbEntity';
import { createBoundClientEntity, createBoundMessageEntity, createBoundSourceEntity } from '../../domain/entities/bound/boundEntity';
export const AirbnbRepository = {
  _transformClientData: (rawData) => {
    if (!rawData) return null;
    return createBoundClientEntity(rawData);
  },

  _transformMessageData: (rawData) => {
    if (!rawData) return null;
    return createBoundMessageEntity(rawData);
  },

  _transformSourceData: (rawData) => {
    if (!rawData) return null;
    return createBoundSourceEntity(rawData);
  },




  getProfile: async (chatroomId) => {
      const data = await AirbnbApi.getProfile(chatroomId);
      return new AirbnbProfile(data);
  },


  getUserInfo: async (profileId) => {
    return await AirbnbApi.getUserInfo(profileId);
    // console.log('data', data);
    
    // return data.map(user => new AirbnbUser(user));
  },


  getSourceInfo: async (userId) => {
    const data = await AirbnbApi.getSourceInfo(userId);
    const dataWithPlatform = data.map(source => ({
      ...source,
      platform: 'airbnb'
    }));
    
    const res = dataWithPlatform.map(source => AirbnbRepository._transformSourceData(source));
    return res;
  },


  getConversationId: async (sourceId) => {
    const data = await AirbnbApi.getConversationId(sourceId);
    const dataWithPlatform = data.map(conversation => ({
      ...conversation,
      platform: 'airbnb'
    }));
    const res = dataWithPlatform.map(conversation => AirbnbRepository._transformClientData(conversation));
    return res;
},

  getBookingInfo: async (conversationId) => {
      const data = await AirbnbApi.getBookingInfo(conversationId);
      return data.map(booking => new AirbnbBooking(booking));
    
  },

  getConversationMessages: async (conversationId, displayName) => {
      const data = await AirbnbApi.getConversationMessages(conversationId, displayName);
      const dataWithPlatform = data.map(message => ({
        ...message,
        platform: 'airbnb',
        user: displayName

      }));      
      const res = dataWithPlatform.map(message => AirbnbRepository._transformMessageData(message));
      return res;
  },

  sendMessage: async (profileId, sourceId, conversationId, messageType, message, file) => {
    const data = await AirbnbApi.sendMessage(profileId, sourceId, conversationId, messageType, message, file);
    return data;
  }
}; 