import React, { useState, useEffect, createRef } from "react";
import axios from "axios";
import mqtt from "mqtt";
import dayjs from "dayjs";

import {
  Button,
  Tooltip,
  message,
  Dropdown,
  Modal,
  DatePicker,
} from "antd";

import { useAuth } from "../../../../contexts/AuthProvider";
import { ReactComponent as AIRoomIcon } from "../../assets/ai_room.svg";
import { ReactComponent as NormalRoomIcon } from "../../assets/normal_room.svg";
import { ReactComponent as ExportIcon } from "../../assets/more-export.svg";

import Message from "../Message";
import InputLine from "./InputLine";

import { LoadingOutlined, MoreOutlined } from "@ant-design/icons";
import { Spin } from "antd";

// import { ChatHistoriesApi } from "../../../../infrastructure/api/ChatHistoriesApi";
// import { TaskWatchdogApi } from "../../../../infrastructure/api/TaskWatchdogApi";
import exportChatHistoriesFacade from "../../../../application/facades/ExportChatHistoriesFacade";
const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const MQTT_URL = process.env.REACT_APP_MQTT_URL;
const MQTT_PORT = process.env.REACT_APP_MQTT_PORT;

// 定義匯出檔案類型常數
// const EXPORT_FILE_TYPES = ["ooo分類"];

// 定義日期範圍預設值
const RANGE_PRESETS = [
  {
    label: "最近7天",
    value: [dayjs().subtract(7, "day"), dayjs()],
  },
  {
    label: "最近30天",
    value: [dayjs().subtract(30, "day"), dayjs()],
  },
  {
    label: "最近3個月",
    value: [dayjs().subtract(3, "month"), dayjs()],
  },
  {
    label: "最近半年",
    value: [dayjs().subtract(6, "month"), dayjs()],
  },
  {
    label: "最近一年",
    value: [dayjs().subtract(1, "year"), dayjs()],
  },
];

// 自定義日期格式
const customDateFormat = (value) => {
  if (!value) return "";
  const weekDays = ["日", "一", "二", "三", "四", "五", "六"];
  const weekDay = weekDays[value.day()];
  return `${value.year()}年${
    value.month() + 1
  }月${value.date()}日 週${weekDay}`;
};

function formatInputForMarkdown(input) {
  const lines = input.split("\n"); // Split the input by new lines
  const formattedLines = lines
    .map((line) => (line.trim() !== "" ? line + " \\" : ""))
    .filter((line) => line !== ""); // Add backslash to each line and filter out empty lines
  const submitLines = formattedLines.join("\n"); // Join the lines back together
  return submitLines.substring(0, submitLines.length - 2); // Return the formatted input
}

function convertUrlsToLinks(text) {
  if (!text) return text;
  if (text.includes("[") && text.includes("]") && text.includes("(")) {
    return text;
  }

  // regex for url
  const urlRegex =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/g;

  return text.replace(urlRegex, (url) => {
    // remove punctuation at the end of url
    const cleanUrl = url.replace(/[.,;:!?)"'\]}]+$/, "");

    // remove unpaired parentheses
    let finalUrl = cleanUrl;
    const openParens = (finalUrl.match(/\(/g) || []).length;
    const closeParens = (finalUrl.match(/\)/g) || []).length;
    if (openParens > closeParens) {
      finalUrl = finalUrl.replace(/\([^)]*$/, "");
    }

    return `[${finalUrl}](${finalUrl})`;
  });
}

const Room = ({ isAI, room, messages, setMessages, onClear }) => {
  const target = isAI ? "ai" : "normal";
  const { token, userId } = useAuth();
  const [loading, setLoading] = useState(false);
  const [submittedMessage, setSubmittedMessage] = useState("");
  const [initLoading, setInitLoading] = useState(true);
  const [oldestMessageId, setOldestMessageId] = useState(null);
  const [triggerScroll, setTriggerScroll] = useState(true);
  const messagesStartRef = createRef();
  const messagesEndRef = createRef();
  const [mqttConnection, setMqttConnection] = useState(false);
  const [stickyDate, setStickyDate] = useState(null);
  const messagesContainerRef = createRef();

  // 添加匯出聊天記錄相關狀態和函數
  const [exportModalVisible, setExportModalVisible] = useState(false);
  const [exportDateRange, setExportDateRange] = useState([]);

  useEffect(() => {
    setMqttConnection(false);
    setSubmittedMessage("");
    setMessages([]);
    setOldestMessageId(null);
    fetchNewMessages(true).then(() => {
      setInitLoading(false);
    });
  }, [room]);

  useEffect(() => {
    if (triggerScroll) {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
      setTriggerScroll(false); // Reset scroll trigger
    }
  }, [triggerScroll]); // Depend only on the triggerScroll flag

  // MQTT connect
  useEffect(() => {
    // Establish connection with the MQTT broker
    const mqttClient = mqtt.connect(MQTT_URL, {
      clientId: "mqttx_" + Math.random().toString(16).substring(2, 10),
      port: MQTT_PORT,
      path: "/",
      connectTimeout: 10000,
      keepAlive: 30000,
      autoReconnect: true,
      reconnectPeriod: 1000,
      cleanStart: false,
    });

    mqttClient.on("error", (error) => {
      console.error("Connection error:", error);
      setMqttConnection(false);
    });

    mqttClient.on("connect", () => {
      if (isAI) {
        // mqttClient.subscribe(`chatroom/${room.id}/ai`);
        mqttClient.subscribe(`chatroom/${room?.id}/ai/response`, (err) => {
          if (err) {
            console.error("[AI] Subscription error:", err);
            setMqttConnection(false);
          } else {
            // console.log('[AI] Subscription successful');
            setMqttConnection(true);
          }
        });
      } else {
        mqttClient.subscribe(`chatroom/${room?.id}/normal`, (err) => {
          if (err) {
            console.error("[normal] Subscription error:", err);
            setMqttConnection(false);
          } else {
            // console.log('[normal] Subscription successful');
            setMqttConnection(true);
          }
        });
      }
    });

    mqttClient.on("message", (topic, payload) => {
      const newMessage = JSON.parse(payload.toString());

      setMessages((prevMessages) => [...prevMessages, { ...newMessage }]);

      setTriggerScroll(true);
    });

    // Clean up the connection when the component unmounts
    return () => mqttClient.end();
  }, [room]);

  const fetchOldMessages = async () => {
    const url = `${SERVER_URL}/private/chatrooms/chat/${target}/${room.id}${
      oldestMessageId ? `?from_id=${oldestMessageId}` : ""
    }`;
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Combine the current messages with the new response data
      const combinedMessages = [...messages, ...response.data];

      // Remove duplicates
      const uniqueMessages = Array.from(
        new Map(
          combinedMessages.map((message) => [message.id, message])
        ).values()
      );

      // Sort by 'created_at'
      uniqueMessages.sort(
        (a, b) => new Date(a.created_at) - new Date(b.created_at)
      );

      setMessages(uniqueMessages);

      if (uniqueMessages.length > 0) {
        setOldestMessageId(uniqueMessages[0].id);
      }
    } catch (error) {
      console.log(error);
      // message.error(JSON.stringify(error.response?.data?.detail));
    }
  };

  const fetchNewMessages = async (init = false) => {
    const url = `${SERVER_URL}/private/chatrooms/chat/${target}/${room?.id}`;
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data.length > 0) {
        // Combine the current messages with the new response data
        const combinedMessages = init
          ? response.data
          : [...messages, ...response.data];

        // Remove duplicates
        const uniqueMessages = Array.from(
          new Map(
            combinedMessages.map((message) => [message.id, message])
          ).values()
        );

        // Sort by 'created_at'
        uniqueMessages.sort(
          (a, b) => new Date(a.created_at) - new Date(b.created_at)
        );

        setOldestMessageId(uniqueMessages[0].id);
        setMessages(uniqueMessages);
      }
      setTriggerScroll(true); // Trigger scroll to bottom
    } catch (error) {
      console.log(error);
      // message.error(JSON.stringify(error.response?.data?.detail));
    }
  };

  // TODO: file with too long name needs to be blocked
  const submitFiles = async (blobs) => {
    let ticketId = null;
    try {
      const formData = new FormData();
      blobs.forEach((blob) => {
        formData.append("files", blob);
      });

      const response = await axios.post(
        SERVER_URL + "/private/chatrooms/chat/blob/" + room.id,
        formData,
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      ticketId = response.data.ticket_id;

      // Call the function to fetch tickets
      return fetchTickets(ticketId);
    } catch (error) {
      message.error(JSON.stringify(error.response?.data?.detail));
      return null; // Return null or handle error as needed
    }
  };

  const fetchTickets = async (ticketId) => {
    try {
      const ticketResponse = await Promise.race([
        axios.get(SERVER_URL + "/public/task/" + ticketId, {
          headers: {
            accept: "application/json",
          },
        }),
        new Promise((_, reject) =>
          setTimeout(() => reject(new Error("Request timeout")), 60000)
        ),
      ]);

      const ttl = ticketResponse.data.ttl; // Update this path according to your API response

      // TODO drop this if MQTT is workable
      if (ttl === -1) {
        // console.log("TTL is -1, refetching tickets...");
        return fetchTickets(ticketId); // Recursively refetch
      }

      const blobIds = ticketResponse.data.body.data.map((blob) => blob.id);
      return blobIds;
    } catch (error) {
      // console.log("Error fetching tickets:", error);
      throw error; // Rethrow the error or handle it appropriately
    }
  };

  const submitAIMessage = async (msg, blobs) => {
    setLoading(true);
    setSubmittedMessage(msg);
    let blobIds = [];
    if (blobs.length > 0) {
      blobIds = await submitFiles(blobs);
    }

    try {
      await axios.post(
        SERVER_URL + "/private/chatrooms/chat/ai/" + room.id,
        {
          chat_type: "ai",
          message: formatInputForMarkdown(msg),
          blob_ids: blobIds,
        },
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      message.error(JSON.stringify(error.response?.data?.detail));
    } finally {
      setLoading(false);
    }
  };

  const submitNormalMessage = async (msg, blobs) => {
    setLoading(true);
    setSubmittedMessage(msg);
    let blobIds = [];
    if (blobs.length > 0) {
      blobIds = await submitFiles(blobs);

      // check if there's a null object in blobIds and pop notification
      if (blobIds.some((blobId) => blobId === null)) {
        message.error("部分檔案不支援上傳");
      }

      // clear null object
      blobIds = blobIds.filter(Boolean);
    }

    try {
      await axios.post(
        SERVER_URL + "/private/chatrooms/chat/normal/" + room.id,
        {
          chat_type: "normal",
          message: formatInputForMarkdown(msg),
          blob_ids: blobIds,
        },
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      console.log(error);
      // message.error(JSON.stringify(error.response?.data?.detail));
    } finally {
      setLoading(false);
    }
  };

  const submitMessage = async (message, blobs) => {
    if (isAI) {
      await submitAIMessage(message, blobs);
    } else {
      await submitNormalMessage(message, blobs);
    }
    await fetchNewMessages();
    setTriggerScroll(true); // Trigger scroll to bottom
  };

  // 添加日期格式化函數
  const formatMessageDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    const weekDay = ["日", "一", "二", "三", "四", "五", "六"][d.getDay()];
    return `${year}-${month}-${day} (${weekDay})`;
  };

  // 添加滾動監聽處理函數
  useEffect(() => {
    const handleScroll = () => {
      if (!messagesContainerRef.current) return;

      const messageGroups =
        messagesContainerRef.current.querySelectorAll("[data-date]");
      let currentStickyDate = null;

      messageGroups.forEach((group) => {
        const rect = group.getBoundingClientRect();
        if (rect.top <= 100) {
          // 100px 是頂部預留空間
          currentStickyDate = group.getAttribute("data-date");
        }
      });

      setStickyDate(currentStickyDate);
    };

    const container = messagesContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [messages]);

  // 將消息按日期分組
  const groupMessagesByDate = (messages) => {
    const groups = {};
    messages.forEach((message) => {
      const date = formatMessageDate(
        message.created_at || message.question?.created_at
      );
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(message);
    });
    return groups;
  };

  // 定義下拉選單項目
  const dropdownItems = [
    {
      key: "export",
      label: "匯出聊天記錄",
      icon: <ExportIcon />,
      className: "!text-textLight gap-1 !my-0.5",
    },
    // {
    //   key: "gallery",
    //   label: "圖庫",
    //   icon: <GalleryIcon />,
    //   className: "!text-textLight gap-1 !my-0.5",
    // },
    // {
    //   key: "link",
    //   label: "連結",
    //   icon: <LinkIcon />,
    //   className: "!text-textLight gap-1 !my-0.5",
    // },
  ];

  // 處理選單項目點擊
  const handleMenuClick = ({ key }) => {
    switch (key) {
      case "export":
        // 顯示匯出聊天記錄模態框
        showExportModal();
        break;
      case "gallery":
        // 處理圖庫
        message.info("圖庫功能即將推出");
        break;
      case "link":
        // 處理連結
        message.info("連結功能即將推出");
        break;
      default:
        break;
    }
  };

  // 顯示匯出模態框
  const showExportModal = () => {
    // 初始化日期範圍為空，讓用戶自行選擇
    setExportDateRange([]);
    setExportModalVisible(true);
  };

  // 關閉匯出模態框
  const handleCancelExport = () => {
    setExportModalVisible(false);
    setExportDateRange([]);
  };

  // 處理日期範圍變更
  const onDateRangeChange = (dates, dateStrings) => {
    setExportDateRange(dates);
  };

  // 處理匯出操作
  const handleExport = async () => {
    if (
      !exportDateRange ||
      exportDateRange.length !== 2 ||
      !exportDateRange[0] ||
      !exportDateRange[1]
    ) {
      message.warning("請選擇匯出記錄區間");
      return;
    }

    // 顯示載入中提示
    const loadingMessage = message.loading("正在匯出聊天記錄...", 0);

    // 使用 Facade 進行匯出操作
    exportChatHistoriesFacade.exportChatroomHistories(
      room.id,
      exportDateRange[0].format("YYYY-MM-DD"),
      exportDateRange[1].format("YYYY-MM-DD"),
      // 成功回調
      (downloadUrl) => {
        loadingMessage();
        message.success("聊天記錄匯出成功");
        window.open(downloadUrl, "_blank");

        // 匯出成功後重置所有匯出相關資料
        setExportModalVisible(false);
        setExportDateRange([]);
      },
      // 錯誤回調
      (errorMessage) => {
        loadingMessage();
        message.error(errorMessage);
      },
      // 進度回調
      (progressMessage) => {
        // 可以在這裡更新進度提示，但由於 message.loading 已經在顯示，所以這裡不需要額外操作
      }
    );
  };

  if (initLoading) {
    return (
      <div className="flex justify-center items-center w-full h-full">
        <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
      </div>
    );
  }

  return (
    <div className="flex flex-col h-full w-full bg-bgLight">
      <div className="flex flex-row justify-between items-center h-8 px-3 py-5 border-b">
        <div className="flex flex-row justify-center items-center gap-2">
          {isAI ? (
            <AIRoomIcon style={{ width: "17px", height: "17px" }} />
          ) : (
            <NormalRoomIcon style={{ width: "17px", height: "17px" }} />
          )}
          {isAI ? "AI " : "一般"}聊天室
        </div>
        {mqttConnection ? (
          <Tooltip title="連線穩定">
            <div className="flex flex-row gap-1 ml-2 bg-green-600 w-2 h-2 rounded-full mr-auto" />
          </Tooltip>
        ) : (
          <Tooltip title="重新連線中...點擊以重整">
            <div
              className="flex flex-row gap-1 ml-2 bg-yellow-600 w-2 h-2 rounded-full animate-ping mr-auto"
              onClick={fetchNewMessages}
            />
          </Tooltip>
        )}
        {/* 更多按鈕 */}
        <div className="flex items-center gap-1">
          {isAI && (
            <Dropdown
              menu={{ items: dropdownItems, onClick: handleMenuClick }}
              placement="bottomRight"
              trigger={["click"]}
            >
              <Button
                type="text"
                icon={<MoreOutlined style={{ fontSize: "20px" }} />}
                className="flex items-center justify-center rounded-full "
              />
            </Dropdown>
          )}
        </div>

        {/* isAI &&
          <div className="flex flex-row justify-between items-center gap-1">
            <Tooltip title="AI 圖片生成">
              <Button className="border-0 bg-bgLight shadow-none min-w-9 min-h-9" icon={<AIPictureIcon style={{ width: "36px", height: "36px" }} />} />
            </Tooltip>
            <Tooltip title="連結">
              <Button className="border-0 bg-bgLight shadow-none min-w-9 min-h-9" icon={<LinkIcon style={{ width: "36px", height: "36px" }} />} />
            </Tooltip>
            <Tooltip title="筆記">
              <Button className="border-0 bg-bgLight shadow-none min-w-9 min-h-9" icon={<MemoryIcon style={{ width: "36px", height: "36px" }} />} />
            </Tooltip>
            <Tooltip title="搜尋">
              <Button disabled className="border-0 bg-bgLight shadow-none min-w-9 min-h-9" icon={<SearchIcon style={{ width: "36px", height: "36px" }} />} />
            </Tooltip>
          </div> */}
      </div>
      <div
        className="flex flex-col items-center justify-end px-6 overflow-x-hidden"
        style={{
          height: "calc(100dvh - 40px)",
          maxHeight: "calc(100dvh - 40px)",
        }}
      >
        <div
          ref={messagesContainerRef}
          className="relative overflow-y-auto overflow-x-hidden py-2 w-full"
          style={{
            height: "calc(100dvh - 40px)",
            maxHeight: "calc(100dvh - 40px)",
          }}
        >
          {stickyDate && (
            <div className="sticky top-0 z-50 bg-white/80 backdrop-blur-sm py-2 px-4 text-center text-sm text-gray-500 shadow-sm">
              {stickyDate}
            </div>
          )}
          <Button
            ref={messagesStartRef}
            className="w-full"
            type="text"
            onClick={fetchOldMessages}
          >
            讀取更多訊息
          </Button>
          {Object.entries(groupMessagesByDate(messages)).map(
            ([date, dateMessages]) => (
              <div key={date} data-date={date}>
                <div className="text-center my-4">
                  <span className="text-[#989898] text-xs bg-[#E8E8E8] px-2 py-1 rounded-[11px]">
                    {date}
                  </span>
                </div>
                {dateMessages.map((message) => {
                  if (message.question) {
                    return (
                      <div key={message.id}>
                        <Message
                          roomId={room.id}
                          type={
                            message.question.user_id === userId
                              ? "self"
                              : "other"
                          }
                          message={{
                            text: message.question.message,
                            time: message.question.created_at,
                            name:
                              message.question.user_id !== userId &&
                              message.question.user_nickname
                                ? message.question.user_nickname
                                : null,
                            avatar:
                              message.question.user_id !== userId &&
                              message.question.user_image_url
                                ? message.question.user_image_url
                                : null,
                          }}
                        />
                        {message.question.blobs?.length > 0 &&
                          message.question.blobs.map((blob, index) => (
                            <Message
                              roomId={room.id}
                              key={`${message.id}-question-blob-${index}`}
                              type={
                                message.question.user_id === userId
                                  ? "self"
                                  : "other"
                              }
                              message={{
                                blob: blob.url,
                                blobType: blob.content_type,
                                time: message.question.created_at,
                                name:
                                  message.question.user_id !== userId &&
                                  message.question.user_nickname
                                    ? message.question.user_nickname
                                    : null,
                                avatar:
                                  message.question.user_id !== userId &&
                                  message.question.user_image_url
                                    ? message.question.user_image_url
                                    : null,
                              }}
                            />
                          ))}
                        <Message
                          roomId={room.id}
                          type={"other"}
                          message={{
                            name: room.bot_name,
                            text: convertUrlsToLinks(message.message),
                            time: message.created_at,
                            question: message.question.message,
                            avatar: room.photo_url ? room.photo_url : null,
                          }}
                        />
                        {message.blobs?.length > 0 &&
                          message.blobs.map((blob, index) => (
                            <Message
                              roomId={room.id}
                              key={`${message.id}-answer-blob-${index}`}
                              type={"other"}
                              message={{
                                blob: blob.url,
                                blobType: blob.content_type,
                                time: message.created_at,
                                avatar: room.photo_url ? room.photo_url : null,
                              }}
                            />
                          ))}
                      </div>
                    );
                  }
                  if (message.blobs?.length > 0) {
                    return (
                      <div key={message.id}>
                        <Message
                          roomId={room.id}
                          type={message.user_id === userId ? "self" : "other"}
                          message={{
                            name: message.user_nickname,
                            text: message.message,
                            time: message.created_at,
                            avatar: message.user_image_url
                              ? message.user_image_url
                              : null,
                          }}
                        />
                        {message.blobs.map((blob, index) => (
                          <Message
                            roomId={room.id}
                            key={`${message.id}-blob-${index}`}
                            type={message.user_id === userId ? "self" : "other"}
                            message={{
                              name: message.user_nickname,
                              blob: blob.url,
                              blobType: blob.content_type,
                              time: message.created_at,
                              avatar: message.user_image_url
                                ? message.user_image_url
                                : null,
                            }}
                          />
                        ))}
                      </div>
                    );
                  }
                  return (
                    <Message
                      roomId={room.id}
                      key={message.id}
                      type={message.user_id === userId ? "self" : "other"}
                      message={{
                        name: message.user_nickname,
                        text: message.message,
                        time: message.created_at,
                        avatar: message.user_image_url
                          ? message.user_image_url
                          : null,
                      }}
                    />
                  );
                })}
              </div>
            )
          )}
          {isAI && loading && (
            <div>
              <Message
                roomId={room.id}
                type={"self"}
                message={{
                  text: submittedMessage,
                  time: "...",
                }}
              />
              <Message
                roomId={room.id}
                type={"skeleton"}
                ref={messagesEndRef}
              />
            </div>
          )}
          <div ref={messagesEndRef} />
        </div>
      </div>
      <InputLine
        isAI={isAI}
        submit={submitMessage}
        loading={loading}
        id={room?.id}
      />

      {/* 匯出聊天記錄模態框 */}
      <Modal
        title={
          <p className="text-center text-xl font-semibold leading-[130%] ">
            匯出AI聊天記錄
          </p>
        }
        open={exportModalVisible}
        onCancel={handleCancelExport}
        footer={[
          <Button key="cancel" onClick={handleCancelExport}>
            取消
          </Button>,
          <Button
            key="export"
            type="primary"
            onClick={handleExport}
            style={{ backgroundColor: "#52c41a" }}
          >
            匯出
          </Button>,
        ]}
        width={400}
      >
        <div className="flex flex-col gap-4 mb-10">
          <div className="mb-4">
            <div className="mb-2">匯出記錄區間</div>
            <DatePicker.RangePicker
              className="w-full"
              value={exportDateRange}
              onChange={onDateRangeChange}
              format={customDateFormat}
              allowClear={true}
              placeholder={["起始日期", "結束日期"]}
              presets={RANGE_PRESETS}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Room;
