import React from 'react';
import { Table, Input, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { shopColumns } from '@/app/cms/data/orgColumns';

const OrgTable = ({ 
  tableLoading,
  filteredShops,
  onRowClick,
  selectedService,
  handleSearch,
  handleStatusChange,
  selectedStatus 
}) => {
  // 狀態選項
  const statusOptions = [
    { value: "all", label: "狀態" },
    { value: "On Sale", label: "On Sale" },
    { value: "下架中", label: "下架中" },
  ];

  return (
    <>
      <div className="flex space-x-4 mb-4">
        <Input
          className="w-80"
          placeholder="搜尋"
          prefix={<SearchOutlined />}
          onChange={(e) => handleSearch(e.target.value)}
        />
        <Select
          className="w-28"
          placeholder="前台狀態"
          options={statusOptions}
          onChange={handleStatusChange}
          allowClear
          value={selectedStatus}
        />
      </div>

      <div className="flex-1 overflow-auto">
        <Table
          loading={tableLoading}
          style={{
            height: "100%",
          }}
          columns={shopColumns}
          dataSource={filteredShops}
          pagination={{
            pageSize: 10,
            className: "sticky bottom-0 pb-2"
          }}
          onRow={(record) => ({
            onClick: () => onRowClick(record),
            className: record.key === selectedService?.key ? "bg-gray-100" : "",
          })}
          rowClassName={(record) =>
            `cursor-pointer ${record.key === selectedService?.key ? "bg-gray-100" : ""}`
          }
          scroll={{ x: "max-content" }}
          className="[&_.ant-table-thead>tr>th]:bg-[#E0F0E0] h-full"
          size="small"
        />
      </div>
    </>
  );
};

export default OrgTable;
