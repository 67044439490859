
import React, { useState, useRef, useEffect } from "react";
import { Button, Tooltip, Input, message, notification } from "antd";
import { useAuth } from "../../../../contexts/AuthProvider";
import axios from "axios";
import { request } from "../../../../utils";


import {
  LoadingOutlined,
  CloseOutlined,
  HighlightOutlined,
} from "@ant-design/icons";
import { ReactComponent as AddFileIcon } from "../../assets/add_file.svg";
import { ReactComponent as Convert } from "../../assets/convert.svg";
import { ReactComponent as AiIcon } from "../../assets/ai-icon.svg";
import { ReactComponent as Produce } from "../../assets/produce.svg";
import { ReactComponent as Producing } from "../../assets/producing.svg";
import { ReactComponent as Refresh } from "../../assets/refresh.svg";
import { ReactComponent as Play } from "../../assets/play.svg";
import { ReactComponent as Pause } from "../../assets/pause.svg";
import { ReactComponent as Enter } from "../../assets/enter.svg";
import { ReactComponent as EnterGreen } from "../../assets/enter_green.svg";

const { TextArea } = Input;
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const openNotification = (title, description, type = 'info') => {
  notification[type]({
    message: title,
    description: description,
    placement: "top",
    duration: 2,
  });
};


const InputLine = ({
  disabled = false,
  isAI,
  submit,
  loading,
  id,
  message_id,
  isBound = false,
  isTextToSpeech = false,
  isAiResponse = false,
  channelType,
  conversationId,
  chatroomId,
  language,
}) => {
  const { token } = useAuth();
  const [input, setInput] = useState("");
  const [uploading, setUploading] = useState(false);
  const [blobs, setBlobs] = useState([]);
  const fileInputRef = useRef(null);
  const outerWidth = isBound ? `calc((100vw - 962px))` : `calc(50vw - 310px)`;
  // const innerWidth = isBound
  //   ? `calc((100vw - 978px))`
  //   : `calc((100vw - 618px)/2)`;
  const [memoryMode, setMemoryMode] = useState(false);
  // 文字轉語音功能
  const [textToSpeechEnable, setTextToSpeechEnable] = useState(false);
  // 文字轉語音功能的文字
  const [textToSpeechInput, setTextToSpeechInput] = useState("");
  // 文字轉語音功能的語音檔案
  const [textToSpeechAudio, setTextToSpeechAudio] = useState("");
  // 文字轉語音功能的語音檔案id
  const [textToSpeechAudioId, setTextToSpeechAudioId] = useState("");
  // 文字轉語音功能的語音ref
  const textToSpeechAudioRef = useRef(null);
  // 語音生成中
  const [textToSpeechLoading, setTextToSpeechLoading] = useState(false);
  // 語音播放中
  const [textToSpeechPlaying, setTextToSpeechPlaying] = useState(false);
  const [aiLoading, setAiLoading] = useState(false);

  // 添加一个 AbortController 的 ref
  const abortControllerRef = useRef(null);

  // 在 id 或 message_id 改变时重置状态
  useEffect(() => {
    setInput("");
    setBlobs([]);
    setAiLoading(false);
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      abortControllerRef.current = null;
    }
  }, [id, message_id]);

  useEffect(() => {
    if (!isAI && input.length > 0 && input[0] === "@") {
      setMemoryMode(true);
    } else {
      setMemoryMode(false);
    }
  }, [input]);

  const removeFile = (index) => {
    setBlobs(blobs.filter((_, i) => i !== index));
  };

  const handleClickUpload = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    let invalidFiles = [];
    Array.from(e.target.files).forEach((file) => {
      if (!checkFileSize(file)) {
        invalidFiles.push(file.name);
      }
      if (isAI) {
        if (!checkAiImageBlobType(file)) {
          invalidFiles.push(file.name);
        }
      }
    });
    if (invalidFiles.length > 0) {
      if (isAI) {
        message.error(
          `若於 AI 聊天室上傳圖片，請上傳小於 20MB 之 JPG 或 PNG 檔案，以下檔案無法上傳: ${invalidFiles.join(
            ", "
          )}`
        );
      } else {
        message.error(
          `檔案大小不可超過 20MB，以下檔案無法上傳: ${invalidFiles.join(", ")}`
        );
      }
      return;
    }
    setBlobs([...blobs, ...Array.from(e.target.files)]);
  };

  const formatFileSize = (size) => {
    if (size < 1024) return size + " bytes";
    else if (size >= 1024 && size < 1048576)
      return (size / 1024).toFixed(1) + " KB";
    else return (size / 1048576).toFixed(1) + " MB";
  };

  const checkFileSize = (file) => {
    if (file.size < 1024 * 1000 * 20) return true;
    return false;
  };

  const checkAiImageBlobType = (file) => {
    if (file.type.includes("image/")) {
      if (file.type === "image/jpeg" || file.type === "image/png") return true;
      return false;
    } else {
      return true;
    }
  };

  const handleEnter = (e) => {
    if (memoryMode) {
      handleQuickMemorize(input.substring(1, input.length));
      setMemoryMode(false);
      isTextToSpeech
        ? submit({ msg: input.substring(1, input.length), imgBlobs: blobs })
        : submit(input.substring(1, input.length), blobs);
    } else {
      isTextToSpeech
        ? submit({ msg: input, imgBlobs: blobs })
        : submit(input, blobs);
    }
    setInput("");
    setBlobs([]);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (!e.shiftKey && !e.nativeEvent.isComposing) {
        e.preventDefault();
        if (input.length < 1) {
          return;
        }
        handleEnter(); // Function to handle the sending of message
      } else if (e.shiftKey) {
        // Allow the default behavior, which should be inserting a new line
      }
    }
  };

  const handleQuickMemorize = async (text) => {
    openNotification("記憶中", "請保持網路連線。", "info");
    if (text) {
      await handlePostText(text);
      return;
    }
  };

  const handlePostText = async (text) => {
    setUploading(true);
    try {
      await axios.post(
        SERVER_URL + "/private/chatrooms/vector_store/text/" + id,
        {
          text: text,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      openNotification("文字檔案記憶成功送出！", "稍後即可在記憶模式中查看。", "success");
    } catch (error) {
      openNotification(
        "發生錯誤",
        JSON.stringify(error.response?.data?.detail)
      , "error");
    } finally {
      setUploading(false);
    }
  };

  // 文字轉語音功能
  const createTextToSpeech = async () => {
    if (textToSpeechLoading) return;
    setTextToSpeechLoading(true);
    try {
      const response = await axios.post(
        SERVER_URL + "/private/property/chatroom/voices/" + id + "/tts",
        {
          text: textToSpeechInput,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setTextToSpeechAudio(response.data.url);
      setTextToSpeechAudioId(response.data.id);
    } catch (error) {
      openNotification(
        "發生錯誤",
        JSON.stringify(error.response?.data?.detail)
      , "error");
    } finally {
      setTextToSpeechLoading(false);
    }
  };
  // 重新生成語音
  const recreateTextToSpeech = () => {
    setTextToSpeechAudio("");
    createTextToSpeech();
  };
  // 設定音檔
  useEffect(() => {
    if (textToSpeechAudio) {
      textToSpeechAudioRef.current = new Audio(textToSpeechAudio);

      // 設置播放和暫停的事件處理器
      textToSpeechAudioRef.current.onplaying = () => {
        setTextToSpeechPlaying(true);
      };
      textToSpeechAudioRef.current.onpause = () => {
        setTextToSpeechPlaying(false);
      };
    }

    return () => {
      // 清除之前的音頻實例
      if (textToSpeechAudioRef.current) {
        textToSpeechAudioRef.current.pause();
        textToSpeechAudioRef.current = null;
      }
    };
  }, [textToSpeechAudio]);
  // 音檔控制
  const handleTogglePlay = () => {
    if (textToSpeechAudioRef.current) {
      if (textToSpeechPlaying) {
        textToSpeechAudioRef.current.pause();
      } else {
        textToSpeechAudioRef.current.play();
      }
    }
  };

  // 送出語音訊息
  const handleSendAudioMsg = () => {
    submit({
      audioMsgId: textToSpeechAudioId,
    });
    resetAudioMsg();
  };

  const handleAiResponse = async () => {
    setAiLoading(true);
    // 创建新的 AbortController
    abortControllerRef.current = new AbortController();
    
    try {
      let ai_response;
      if (channelType === "airbnb") {
        ai_response = await axios.post(
          `https://rpa.scfg.io/airbnb/ai_response/kuhedr4?conversation_id=${conversationId}&chatroom_id=${chatroomId}&language=${language}&token=${token}`,
          {},
          {
            headers: {
              'X-API-KEY': 'sc90699098',
            }
          }
        );
      } else if (channelType === "agoda") {
        ai_response = await axios.post(
          `https://rpa.scfg.io/airbnb/ai_response/kuhedr4?conversation_id=${conversationId}&chatroom_id=${chatroomId}&language=${language}&token=${token}`,
          {},
          {
            headers: {
              'X-API-KEY': 'sc90699098',
            }
          }
        );
      } else if (channelType === "line") {
        console.log("Line AI生成回復");
        return;
      } else {
        console.log("AI生成回復");
        return;
      }

      if (ai_response && ai_response.data) {
        console.log('ai_response', ai_response);
        setInput(ai_response?.data);
        openNotification("AI 回應已載入", "您可以編輯或直接傳送 AI 的回應。", "success");
      } else {
        openNotification("回應錯誤", "未能獲取有效的 AI 回應。", "warning");
      }
    } catch (error) {
      if (error.name !== 'AbortError') {
        console.error("AI 回應錯誤:", error);
      }
    } finally {
      if (abortControllerRef.current) {
        setAiLoading(false);
        abortControllerRef.current = null;
      }
    }
  };
  
  useEffect(() => {
    if (textToSpeechAudio) {
      setTextToSpeechAudio("");
      setTextToSpeechAudioId("");
      textToSpeechAudioRef.current = null;
    }
  }, [textToSpeechInput]);

  // 清除語音相關資料
  const resetAudioMsg = () => {
    setTextToSpeechInput("");
    setTextToSpeechAudio("");
    setTextToSpeechAudioId("");
    textToSpeechAudioRef.current = null;
  };
  return (
    <div
      className="flex flex-col w-full gap-1 bg-bgLight relative bottom-0 py-2 shadow-up"
      style={{ width: outerWidth }}
    >
      <div className="flex flex-row w-full px-2 gap-1 overflow-auto">
        {blobs.length > 0 &&
          blobs.map((blob, index) => (
            <div key={index}>
              <Tooltip title={blob.name}>
                <div className="flex flex-row min-w-48 max-w-48 min-h-7 items-center border border-borderLight px-4 py-2 rounded-lg justify-between">
                  <div className="w-full truncate text-xs">{blob.name}</div>
                  <div className="min-w-fit text-textLight text-xs">
                    {formatFileSize(blob.size)}
                  </div>
                  <CloseOutlined
                    disabled={uploading}
                    onClick={() => removeFile(index)}
                    className="text-textDark hover:text-red-500 cursor-pointer ml-2"
                  />
                </div>
              </Tooltip>
            </div>
          ))}
      </div>
      {textToSpeechEnable && (
        <div
          className={`flex items-end w-full px-2   gap-2  ${
            !isBound ? "pl-14" : ""
          }`}
        >
          <Tooltip title="送出的訊息會被記憶" open={memoryMode}>
            <div
              className="relative min-h-6 flex-1"
              // style={{ width: innerWidth, maxWidth: innerWidth }}
            >
              <TextArea
                value={textToSpeechInput}
                onChange={(e) => setTextToSpeechInput(e.target.value)}
                autoSize
                style={{
                  paddingLeft: "8px",
                  paddingTop: "8px",
                  paddingBottom: "8px",
                  paddingRight: "48px",
                  height: "40px",
                  minHeight: "40px",
                  maxHeight: "calc(100dvh - 124px)",
                  overflowY: "auto",
                  // position: "absolute",
                  bottom: 0,
                  // width: "calc(100vw - 40px)",
                  borderRadius: "10px",
                }}
                placeholder="輸入文字，語音輸出"
              />
              <Button
                icon={
                  textToSpeechAudio ? (
                    <EnterGreen style={{ width: "16px", height: "16px" }} />
                  ) : (
                    <Enter style={{ width: "16px", height: "16px" }} />
                  )
                }
                className="absolute bottom-0 right-0 h-10 w-10 rounded-[10px] min-w-10 border-none outline-none !bg-transparent shadow-none"
                onClick={handleSendAudioMsg}
                disabled={!textToSpeechAudio || /^\s*$/.test(textToSpeechInput)}
              />
            </div>
          </Tooltip>
          {textToSpeechAudio && (
            <Tooltip title="重新生成">
              <Button
                className={`border-0 bg-primaryLight shadow-none min-w-10 min-h-10 ${
                  /^\s*$/.test(textToSpeechInput) ? "" : "!bg-primaryMedium"
                }`}
                disabled={/^\s*$/.test(textToSpeechInput)}
                icon={<Refresh />}
                onClick={() => {
                  recreateTextToSpeech();
                }}
              />
            </Tooltip>
          )}
          <Tooltip
            title={
              textToSpeechAudio
                ? textToSpeechPlaying
                  ? "暫停"
                  : "試聽"
                : textToSpeechLoading
                ? "生成語音中"
                : "生成語音"
            }
          >
            <Button
              className={`border-0 bg-primaryLight shadow-none min-w-10 min-h-10 ${
                /^\s*$/.test(textToSpeechInput) ? "" : "!bg-primaryMedium"
              }`}
              disabled={/^\s*$/.test(textToSpeechInput)}
              icon={
                textToSpeechLoading ? (
                  <Producing />
                ) : textToSpeechAudio ? (
                  textToSpeechPlaying ? (
                    <Pause />
                  ) : (
                    <Play />
                  )
                ) : (
                  <Produce />
                )
              }
              onClick={() => {
                // 如果有音檔，則播放或暫停
                textToSpeechAudio ? handleTogglePlay() : createTextToSpeech();
              }}
            />
          </Tooltip>
        </div>
      )}
      <div className="flex flex-row w-full px-2 py-2 gap-2 h-fit">
 
          <Tooltip title={!disabled && "新增檔案"} onClick={handleClickUpload}>
            <div>
              <input
                type="file"
                multiple
                onChange={!disabled && handleFileChange}
                id="file-upload"
                style={{ display: "none" }}
                ref={fileInputRef}
              />
              <Button
                className="border-0 bg-primaryLight shadow-none min-w-10 min-h-10"
                icon={<AddFileIcon style={{ width: "20px", height: "22px" }} />}
                disabled={disabled}
              />
            </div>
          </Tooltip>
        
        <Tooltip title="送出的訊息會被記憶" open={memoryMode}>
          <div
            className="relative min-h-6 flex-1"
            // style={{ width: innerWidth, maxWidth: innerWidth }}
          >
            <TextArea
              value={input}
              onChange={(e) => setInput(e.target.value)}
              autoSize
              maxLength={isBound ? 1000 : 10000}
              // NOTE Bound chatroom set to 1000, inner chatroom set to 10000
              onKeyDown={(e) => handleKeyDown(e)}
              style={{
                paddingLeft: "8px",
                paddingTop: "8px",
                paddingBottom: "8px",
                paddingRight: "48px",
                height: "40px",
                minHeight: "40px",
                maxHeight: "calc(100dvh - 124px)",
                overflowY: "auto",
                // position: "absolute",
                bottom: 0,
                // width: "calc(100vw - 40px)",
                borderRadius: "10px",
              }}
              placeholder={
                disabled ? "權限不足" : aiLoading ? "AI生成回復生成中..." : "輸入訊息 (⇧↵換行 | ↵送出)"
              }
              disabled={disabled || aiLoading}
            />
            <Button
            icon={
              loading || aiLoading? (
                <LoadingOutlined style={{ width: "16px", height: "16px" }} />
              ) : memoryMode ? (
                <HighlightOutlined
                  style={{ width: "16px", height: "16px" }}
                />
              ) : disabled ? (
                true
              ) : loading || /^\s*$/.test(input) ? (
                <Enter style={{ width: "16px", height: "16px" }} />
              ) : (
                <EnterGreen style={{ width: "16px", height: "16px" }} />
              )
            }
            onClick={(e) => handleEnter(e)}
            className="absolute bottom-0 right-0 h-10 w-10 rounded-[10px] min-w-10 border-none outline-none !bg-transparent shadow-none"
            disabled={disabled ? true : loading || /^\s*$/.test(input)}
          />
          </div>
        </Tooltip>
        {isTextToSpeech && (
          <Tooltip title="文字轉語音">
            <Button
              className={`border-0 bg-primaryLight shadow-none min-w-10 min-h-10 self-end ${
                textToSpeechEnable ? "!bg-primaryMedium" : ""
              }`}
              icon={<Convert />}
              disabled={disabled}
              onClick={() => setTextToSpeechEnable(!textToSpeechEnable)}
            />
          </Tooltip>
        )}
        {isAiResponse && (
          <Tooltip title="AI生成回復">
            <Button
              className={`border-0 bg-primaryLight shadow-none min-w-10 min-h-10 self-end`}
              icon={<AiIcon />}
              disabled={aiLoading? true : false}
              onClick={() => handleAiResponse()}
            />
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default InputLine;
