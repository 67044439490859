import React, { useContext, useEffect, useState } from "react";
import { Spin, Button } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import ToolBar from "../components/ToolBar";
import ProductTable from "../components/Product/ProductTable";
import ProductDetail from "../components/Product/ProductDetail";
import ProductStatistics from "../components/Product/ProductStatistics";
import { ProductContext } from "../context/ProductContext";
import OrderManagement from "../components/Product/OrderManagement";
import AddProductForm from "../components/Product/AddProductForm";
const Product = () => {

    const {
        loading,
        products,
        selectedProduct,
        showOrderManagement,
        showForm,
        fetchProducts,
        setSelectedProduct,
        setShowForm,
    } = useContext(ProductContext);

    useEffect(() => {
        fetchProducts();
    }, []);

    useEffect(() => {
        if (products?.length > 0 && !selectedProduct) {
            setSelectedProduct(products[0]);
        }
    }, [products, selectedProduct]);

    const handleShowForm = () => {
        setShowForm(true);
        setSelectedProduct(null);
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center w-full h-screen">
                <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
            </div>
        );
    }

    if (showOrderManagement) {
        return (
            <OrderManagement
                product={selectedProduct}
            // onBack={() => setShowOrderManagement(false)}
            />
        );
    }

    return (
        <div className="flex flex-col items-center justify-start w-full h-screen overflow-auto">
            <ToolBar pure={true} />
            <div className="flex flex-col w-full h-[calc(100vh-64px)]">

                <div className="flex-1 flex overflow-auto">
                    {showForm ? (
                        <div className="flex w-full space-x-4 overflow-auto">
                            <div className="flex-1 overflow-auto">
                                <AddProductForm />
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className="flex w-full overflow-auto bg-gray-50">
                                <div className="flex-1 flex flex-col shadow min-w-0 overflow-auto h-full p-4">
                                    <div className="flex justify-between items-center mb-4">
                                        <div className="text-lg font-bold">商品管理</div>
                                        <Button
                                            type="primary"
                                            icon={<PlusOutlined />}
                                            onClick={handleShowForm}
                                            className="bg-[#ADDEAC] hover:bg-[#45a049] text-black hover:!text-black"
                                        >
                                            新增商品
                                        </Button>
                                    </div>
                                    <ProductStatistics />
                                    <ProductTable />
                                </div>
                                <div className="w-[440px] flex-shrink-0 border-l border-t border-gray-200 shadow overflow-auto">
                                    <ProductDetail product={selectedProduct} />
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Product;
