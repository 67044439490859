import React, { createContext, useState } from 'react';
import { fetchProductsAPI, getAccessToken, getServerUrl } from '../services/api';

export const ProductContext = createContext();

export const ProductProvider = ({ children }) => {
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [showOrderManagement, setShowOrderManagement] = useState(false);
  const [filteredProducts, setFilteredProducts] = useState([]);

  console.log('products', products);
  

  const fetchProducts = async () => {
    setLoading(true);
    try {
      const token = await getAccessToken();
      const url = await getServerUrl();
      const fetchedProducts = await fetchProductsAPI(token, url);
      setProducts(fetchedProducts);
    } catch (error) {
      console.error('Error fetching products:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleShowForm = () => setShowForm(!showForm);

  const filterProducts = (status) => {
    if (!status || status === "all") {
      setFilteredProducts(products);
    } else {
      const filtered = products.filter(product => product.status === status);
      setFilteredProducts(filtered);
    }
  };

  const value = {
    products,
    selectedProduct,
    loading,
    showForm,
    showOrderManagement,
    fetchProducts,
    setSelectedProduct,
    setShowForm,
    handleShowForm,
    setShowOrderManagement,
    filteredProducts,
    filterProducts,
  };

  return (
    <ProductContext.Provider value={value}>
      {children}
    </ProductContext.Provider>
  );
};
