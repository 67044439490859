import React, { useContext, useEffect, useState } from "react";
import { Spin, Button } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import ToolBar from "../components/ToolBar";
import ServiceTable from "../components/Service/ServiceTable";
import ServiceDetail from "../components/Service/ServiceDetail";
import ServiceStatistics from "../components/Service/ServiceStatistics";
import { ServiceContext } from "../context/ServiceContext";
import OrderManagement from "../components/Service/OrderManagement";
import AddServiceForm from "../components/Service/AddServiceForm";


const Service = () => {


  const {
    loading,
    services,
    selectedService,
    showOrderManagement,
    showForm,
    fetchServices,
    setSelectedService,
    setShowForm,
    setShowOrderManagement,
  } = useContext(ServiceContext);

  useEffect(() => {
    fetchServices();
  }, []);

  useEffect(() => {
    if (services?.length > 0 && !selectedService) {
      const serviceWithImage = {
        ...services[0],
        imageUrl: services[0]?.blobs?.[0]?.url || '/images/default-service.jpg'
      };
      setSelectedService(serviceWithImage);
    }
  }, [services, selectedService]);

  if (loading) {
    return (
      <div className="flex justify-center items-center w-full h-screen">
        <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
      </div>
    );
  }
  const handleShowForm = () => {
    setShowForm(true);
    setSelectedService(null);
  };

  if (showOrderManagement) {
    return (
      <OrderManagement
        service={selectedService}
        onBack={() => setShowOrderManagement(false)}
      />
    );
  }

  return (
    <div className="flex flex-col items-center justify-start w-full h-screen overflow-auto">
      <ToolBar pure={true} />
      <div className="flex flex-col w-full h-[calc(100vh-64px)]">

        <div className="flex-1 flex overflow-auto">
          {showForm ? (
            <div className="flex w-full space-x-4 overflow-auto">
              <div className="flex-1 overflow-auto">
                <AddServiceForm />
              </div>
            </div>
          ) : (
            <>
              <div className="flex w-full overflow-auto bg-gray-50">
                <div className="flex-1 flex flex-col shadow min-w-0 overflow-auto h-full p-4">
                  <div className="flex justify-between items-center mb-4">
                    <div className="text-lg font-bold">Service Management</div>
                    <Button
                      type="primary"
                      icon={<PlusOutlined />}
                      onClick={handleShowForm}
                      className="bg-[#ADDEAC] hover:bg-[#45a049] text-black hover:!text-black"
                    >
                      Add Service
                    </Button>
                  </div>
                  <ServiceStatistics />
                  <ServiceTable />
                </div>
                <div className="w-[440px] flex-shrink-0 border-l border-t border-gray-200 shadow overflow-auto">
                  <ServiceDetail service={selectedService} />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Service;
