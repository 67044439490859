import React, { useState, useEffect, createRef } from 'react'
import mqtt from 'mqtt';

import { Button, message, Tooltip } from 'antd';

import { ReactComponent as LineIcon } from '../../assets/line.svg'
import { ReactComponent as FBIcon } from '../../assets/fb.svg'


import { useChat } from '../../../../hooks/use-chat';

import { AirbnbApi } from '../../../../infrastructure/api/AirbnbApi';

import Message from '../Message';
import InputLine from '../room/InputLine';

import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const MQTT_URL = "wss://api-broker.scfg.io" // process.env.REACT_APP_MQTT_URL;
const MQTT_PORT = process.env.REACT_APP_MQTT_PORT;

const BoundChatRoom = ({ userId, room, client, agoda, airbnb }) => {
  const [loading, setLoading] = useState(false)
  const [messages, setMessages] = useState([])
  const [initLoading, setInitLoading] = useState(true)
  const [oldestMessageId, setOldestMessageId] = useState(null)
  const [triggerScroll, setTriggerScroll] = useState(false);
  const [mqttConnection, setMqttConnection] = useState(false);
  const messagesStartRef = createRef();
  const messagesEndRef = createRef();
  const { fetchLineMessages, fetchFacebookMessages, fetchAgentMessages, sendLineMessage, sendFacebookMessage, sendAgentMessage, getAgodaConversationMessages, getAirbnbConversationMessages, sendAgodaMessage, sendAirbnbMessage } = useChat(room.id); 

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     fetchNewMessages();
  //   }, 20000);
    
  //   // 在組件卸載時清理定時器
  //   return () => {
  //     clearInterval(intervalId);
  //   };
  // }, []);


  useEffect(() => {
    setMqttConnection(false)
    setInitLoading(true)
    setMessages([])
    setOldestMessageId(null)
    fetchNewMessages(true).then(() => {
      setInitLoading(false);
    }).catch(error => {
      console.error('初始化訊息載入失敗：', error);
      setInitLoading(false);
    });
  }, [room, client])

  useEffect(() => {
    if (triggerScroll) {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
      setTriggerScroll(false); // Reset scroll trigger
    }
  }, [triggerScroll]); // Depend only on the triggerScroll flag

  // MQTT connect
  useEffect(() => {
    // Establish connection with the MQTT broker
    const mqttClient = mqtt.connect(MQTT_URL, {
      clientId: 'mqttx_' + Math.random().toString(16).substring(2, 10),
      port: MQTT_PORT,
      path: '/',
      connectTimeout: 10000,
      keepAlive: 30000,
      autoReconnect: true,
      reconnectPeriod: 1000,
      cleanStart: false,
    });

    mqttClient.on('error', (error) => {
      console.error('Connection error:', error);
      setMqttConnection(false);
    });

    mqttClient.on('connect', () => {
      // 同時訂閱 line 和 messenger 頻道，確保不會遺漏訊息
      // 訂閱 line 頻道
      mqttClient.subscribe(`chatroom/${room.id}/line/${client.userId}/#`, (error) => {
        if (error) {
          setMqttConnection(false);
        } else {
          setMqttConnection(true);
        }
      });
      
      // 訂閱 messenger 頻道
      mqttClient.subscribe(`chatroom/${room.id}/messenger/${client.userId}/#`, (error) => {
        if (error) {
          setMqttConnection(false);
        } else {
          setMqttConnection(true);
        }
      });

      // 訂閱 agent 頻道
      mqttClient.subscribe(`chatroom/${room.id}/agent/${client.userId}/#`, (error) => {
        if (error) {
          setMqttConnection(false);
        } else {
          setMqttConnection(true);
        }
      });
    });

    mqttClient.on('message', (topic, payload) => {
      console.log('topic', topic, 'payload', payload);
      
      const receivedClientId = topic.split('/')[3].toString();
      const messageType = topic.split('/')[4]?.toString() || '';
      console.log('ROOM | Message received:', {
        topic,
        clientId: receivedClientId,
        messageType,
        content: payload.toString()
      });

      try {
        const newMessage = JSON.parse(payload.toString());
        console.log('解析後的訊息:', newMessage);
        
        // 檢查消息是否與當前聊天室相符
        // const messageChannelType = newMessage.channel_type?.split(':')[0] || '';
        const isMatchingClient = client.userId === receivedClientId;
        
        if (isMatchingClient) {
          // 處理不同類型的訊息
          let ModifiedNewMessage = newMessage;
          
          // 處理問答類型的訊息
          if (newMessage.question) {
            ModifiedNewMessage = { 
              ...newMessage.question, 
              message: '#.aFaKeMeSsAgE', 
              response: { ...newMessage, question: null } 
            };
          }

          setMessages(prevMessages => [...prevMessages, { ...ModifiedNewMessage }]);
          setTriggerScroll(true);
        } else {
        }
      } catch (error) {
      }
    });

    // Clean up the connection when the component unmounts
    return () => mqttClient.end();
  }, [client, room.id]);


  const fetchOldMessages = async () => {
    try {
      let response;
      if (client.channel === 'line') {
        response = await fetchLineMessages(room.id, client.id, 10, oldestMessageId);
      } else if (client.channel === 'messenger') {
        response = await fetchFacebookMessages(room.id, client.id, 10, oldestMessageId);
      } else if (client.channel === 'teamsync') {
        response = await fetchAgentMessages(room.id, client.id, 10, oldestMessageId);
      }

      // 確保 response 存在且是陣列，如果不是則直接返回
      if (!Array.isArray(response)) {
        return;
      }

      // Combine the current messages with the new response data
      const combinedMessages = [...messages, ...response];

      // Remove duplicates
      const uniqueMessages = Array.from(new Map(combinedMessages.map(message => [message.id, message])).values());

      // Sort by 'created_at'
      uniqueMessages.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));

      setMessages(uniqueMessages)

      if (uniqueMessages.length > 0) {        
        setOldestMessageId(uniqueMessages[0].id)
      }
    } catch (error) {
      console.error(error)
      // message.error(JSON.stringify(error.response?.data?.detail));
    }
  }

  const fetchNewMessages = async (init = false, limit = 10 ) => {
    try {
      let response;
      if (client?.channel === 'line') {
        response = await fetchLineMessages(room.id, client?.id, limit);
      } else if (client?.channel === 'messenger') {
        response = await fetchFacebookMessages(room.id, client?.id, limit); 
      } else if (client?.channel === 'agoda') {
        response = await getAgodaConversationMessages(client?.id);
      } else if (client?.channel === 'airbnb') {
        response = await getAirbnbConversationMessages(client?.id, client?.displayName);
      } else if (client?.channel === 'agent') {      
        response = await fetchAgentMessages(room.id, client?.id, limit);
      }
      
      // 確保 response 存在且是陣列，如果不是則初始化為空陣列
      if (Array.isArray(response) && response.length > 0) {
        // Combine the current messages with the new response data
        const combinedMessages = init ? response : [...messages, ...response];

        // Remove duplicates
        const uniqueMessages = Array.from(new Map(combinedMessages.map(message => [message?.id, message])).values());

        // Sort by 'created_at'
        uniqueMessages.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));

        setOldestMessageId(uniqueMessages[0].id)
        setMessages(uniqueMessages)
      }
      setTriggerScroll(true); // Trigger scroll to bottom
    } catch (error) {
      console.error(error)
      // message.error(JSON.stringify(error.response??.detail));
    }
  }

  const submit = async (msg, blobs) => {
    setLoading(true)
    try {
      console.log('client channel', client.channel);
      
      if (client.channel === 'line') {
        await sendLineMessage(room.id, client.id, msg);
      } else if (client.channel === 'messenger') {
        await sendFacebookMessage(room.id, client.id, msg);
      } else if (client.channel === 'agent') {
        await sendAgentMessage(room.id, client.id, msg);
      } else if (client.channel === 'agoda') {
        console.log('agoda', agoda);
        console.log('client', client);

        
        const profileId = agoda?.profileId;
        const sourceId = client?.roomId;
        const conversationId = client?.id;
        
        if (blobs && blobs.length > 0) {
          // 如果有檔案，則以圖片方式發送
          const file = blobs[0]; // Agoda API 只支援一次發送一個檔案
          const messageType = 'image';
          await sendAgodaMessage(profileId, sourceId, conversationId, messageType, '', file);
          
          // 如果同時有文字和檔案，則文字需要單獨發送
          if (msg && msg.length > 0) {
            await sendAgodaMessage(profileId, sourceId, conversationId, 'text', msg, null);
          }
        } else {
          // 只有文字訊息
          const messageType = 'text';
          const message = msg;
          await sendAgodaMessage(profileId, sourceId, conversationId, messageType, message, null);
        }
      } else if (client.channel === 'airbnb') {
        const profileId = airbnb?.profileId;
        const sourceId = client?.roomId;
        const conversationId = client?.id;
        
        if (blobs && blobs.length > 0) {
          // 如果有檔案，則以圖片方式發送
          const file = blobs[0]; // Agoda API 只支援一次發送一個檔案
          const messageType = 'image';
          await sendAirbnbMessage(profileId, sourceId, conversationId, messageType, '', file);
          
          // 如果同時有文字和檔案，則文字需要單獨發送
          if (msg && msg.length > 0) {
            await sendAirbnbMessage(profileId, sourceId, conversationId, 'text', msg, null);
          }
        } else {
          // 只有文字訊息
          const messageType = 'text';
          const message = msg;
          await sendAirbnbMessage(profileId, sourceId, conversationId, messageType, message, null);
        }
      }
    } catch (error) {
      console.error(error)
      // if 404 not show
      if (error.response?.status !== 404) {
        if (error.response?.data?.detail === "Client has not been active for 7 days") {
          message.error("無法主動傳送訊息給逾 7 日未活動之客戶 (Line 系統限制)");
        } else {
          message.error(JSON.stringify(error.response?.data?.detail));
        }
      }
    } finally {
      setLoading(false)
      await fetchNewMessages()
      setTriggerScroll(true)
    }
  }

  if (!client || !room) {
    return (
      <div className="flex justify-center items-center w-full h-full">
        啊！發生了一點錯誤，請重新選擇或重新整理。
      </div>
    )
  }

  if (initLoading) {
    return (
      <div className="flex justify-center items-center w-full h-full">
        <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
      </div>
    )
  }

  return (
    <div className="w-full h-full bg-bgLight border-l border-r flex flex-col">
      {/* 標題區域 - 固定在頂部 */}
      <div className="flex flex-row items-center justify-start w-full h-10 py-2 border-b flex-shrink-0 mx-2">
        { /* TODO: more platform
          client.platform === 'line' && <LineIcon style={{ width: "32px", height: "32px" }} />
        */ }
        {client.channel === 'line' && <LineIcon style={{ width: "32px", height: "32px" }} />}
        {client.channel === 'messenger' && <FBIcon style={{ width: "18px", height: "18px" }} className="mx-2"/>}
        <p className="font-bold">
          {client.nickname ? client.nickname : client.displayName}
        </p>
        {/* <ReloadOutlined
          className="hover:text-primary mr-2"
          style={{ width: "12px", height: "12px" }}
          onClick={fetchNewMessages}
        /> */}
        {
          mqttConnection ?
            <Tooltip title="連線穩定">
              <div className="flex flex-row gap-1 ml-2 bg-green-600 w-2 h-2 rounded-full" />
            </Tooltip>
            :
            <Tooltip title="重新連線中...點擊以重整">
              <div className="flex flex-row gap-1 ml-2 bg-yellow-600 w-2 h-2 rounded-full animate-ping" onClick={fetchNewMessages} />
            </Tooltip>
        }
      </div>
      
      {/* 聊天訊息區域 - 填滿中間空間 */}
      <div className="flex-grow overflow-auto px-6">
        <div className='w-full h-full flex flex-col'>
          <Button ref={messagesStartRef} className="w-full" type="text" onClick={fetchOldMessages}>讀取更多訊息</Button>
          {messages.sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
            .map((message) => {
              {/* message sent from real human using Teamsync (use AI's role) */ }
              if (message.message === '#.aFaKeMeSsAgE') {
                return (
                  <Message
                    roomId={room.id}
                    key={message.id + Math.random().toString(16).substring(2, 6)}
                    type={'self'}
                    message={{
                      text: message.response.message,
                      time: message.response.created_at,
                    }} />
                )
              }
              {/* AI chat */ }
              return (
                <div key={message.id + Math.random().toString(16).substring(2, 6)}>
                  {/* client sent blobs */}
                  {
                    message.blobs?.length > 0 &&
                    message.blobs.map((blob, index) => (
                      <Message
                        roomId={room.id}
                        key={blob.id + Math.random().toString(16).substring(2, 6)}
                        type={'other'}
                        message={{
                          blob: blob.url,
                          blobType: blob.content_type,
                          time: message.created_at,
                          name: client.displayName,
                          avatar: client.pictureUrl
                        }} />
                    ))
                  }
                  {/* client sent text */}
                  <Message
                    roomId={room.id}
                    type={'other'}
                    message={{
                      text: message.message,
                      time: message.created_at,
                      name: client.displayName,
                      avatar: client.pictureUrl
                    }}
                  />
                  {/* ai auto reply's blobs */}
                  {
                    message.response && message.response.blobs?.length > 0 &&
                    message.response.blobs.map((blob, index) => (
                      <Message
                        roomId={room.id}
                        key={blob.id + Math.random().toString(16).substring(2, 6)}
                        type={'self'}
                        message={{
                          blob: blob.url,
                          blobType: blob.content_type,
                          time: message.response.created_at,
                        }} />
                    ))
                  }
                  {/* ai auto reply (vanilla fetch) */}
                  {
                    message.response && message.response.message !== '#.aFaKeMeSsAgE' &&
                    <Message
                      roomId={room.id}
                      type={'self'}
                      message={{
                        text: message.response.message,
                        time: message.response.created_at,
                        // question: message.message,
                      }} />
                  }
                </div>
              )
            })
          }
          <div ref={messagesEndRef} />
        </div>
      </div>
      
      {/* 輸入框區域 - 固定在底部 */}
      <div className="flex-shrink-0">
        <InputLine
          isAI={false}
          submit={submit}
          loading={loading}
          id={room.id}
          isBound={true}
          disabled={userId !== room.creator_id}
          isAiResponse={true}
          channelType={client.channel}
          conversationId={client?.id}
          chatroomId={room.id}
          language={'中文'}
        />
      </div>
    </div>
  )
}

export default BoundChatRoom