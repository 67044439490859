import React, { useState, useEffect } from "react";
import { Select, Input, DatePicker, Form, Tag, Upload, Modal, Carousel } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import "tailwindcss/tailwind.css";
import ImgCrop from 'antd-img-crop';
import moment from 'moment';

const { RangePicker } = DatePicker;
const { TextArea } = Input;

const Information = ({ form, organizations, categories, serviceData, setPreviewData }) => {
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newTagInput, setNewTagInput] = useState('');

  // 初始化 fileList
  useEffect(() => {
    if (serviceData?.blobs?.length > 0) {
      const initialFileList = serviceData.blobs.map((blob, index) => ({
        uid: `-${index}`,
        name: `image-${index}`,
        status: 'done',
        url: blob.url
      }));
      setFileList(initialFileList);
    }
  }, [serviceData]);

  // Get base64 of image
  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  // Handle image change
  const onChange = async ({ fileList: newFileList }) => {
    try {
      const processedFileList = await Promise.all(
        (newFileList || []).map(async (file) => {
          if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
          }
          return file;
        })
      );

      // 保留原有的圖片，只添加新圖片
      const existingImages = fileList.filter(file => file.url);
      const newImages = processedFileList.filter(file => !file.url);
      const combinedFileList = [...existingImages, ...newImages];
      
      setFileList(combinedFileList);

      // 更新表單值，使用最新上傳的圖片
      if (newImages.length > 0) {
        const imageUrl = newImages[0].preview;
        form.setFieldsValue({ serviceImage: imageUrl });
        // 更新預覽數據
        setPreviewData(prev => ({
          ...prev,
          images: [
            ...existingImages.map(img => img.url || img.preview),
            ...newImages.map(img => img.preview)
          ]
        }));
      } else if (combinedFileList.length > 0) {
        const imageUrl = combinedFileList[0].url || combinedFileList[0].preview;
        form.setFieldsValue({ serviceImage: imageUrl });
        setPreviewData(prev => ({
          ...prev,
          images: combinedFileList.map(img => img.url || img.preview)
        }));
      } else {
        form.setFieldsValue({ serviceImage: '' });
        setPreviewData(prev => ({
          ...prev,
          images: []
        }));
      }
    } catch (error) {
      console.error('Error processing image:', error);
    }
  };

  // Handle image preview
  const onPreview = async (file) => {
    const imageStyle = { 
      width: '100%', 
      maxHeight: '500px', 
      objectFit: 'contain' 
    };
    
    let src = file.url || file.preview;
    if (!src) {
      src = await getBase64(file.originFileObj);
      file.preview = src;
    }
    
    // 獲取所有圖片來源，包括 blobs
    const allImages = [
      ...fileList.map(item => item.url || item.preview),
      ...(serviceData?.blobs?.map(blob => blob.url) || [])
    ].filter(Boolean);
    
    Modal.info({
      title: 'Image Preview',
      width: 800,
      content: (
        <Carousel autoplay>
          {allImages.map((imgSrc, index) => (
            <div key={index}>
              <img 
                src={imgSrc}
                alt={`preview-${index}`}
                style={imageStyle}
              />
            </div>
          ))}
        </Carousel>
      ),
      footer: null,
    });
  };

  // Handle tag close
  const handleTagClose = (removedTag) => {
    const newTags = selectedTags.filter(tag => tag !== removedTag);
    setSelectedTags(newTags);
    form.setFieldsValue({ tags: newTags });
  };

  // Handle tag add
  const addTag = (newTag) => {
    if (newTag && !selectedTags.includes(newTag)) {
      const newTags = [...selectedTags, newTag];
      setSelectedTags(newTags);
      setTags(newTags);
      form.setFieldsValue({ tags: newTags });
    }
  };

  // add new tag
  const handleModalOk = () => {
    if (newTagInput) {
      addTag(newTagInput);
      setNewTagInput('');
    }
    setIsModalVisible(false);
  };

  const handleModalCancel = () => {
    setNewTagInput('');
    setIsModalVisible(false);
  };

  return (
    <>
      <Form.Item
        name="provider"
        label="Select Merchant"
        rules={[{ required: true, message: 'Please Select Merchant' }]}
        className="w-1/5"
      >
        <Select placeholder="Please Select Merchant">
          {organizations?.map((org) => (
            <Select.Option 
              key={org.id}
              value={org.id}
            >
              {org.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="categoryId"
        label="Select Category"
        rules={[{ required: true, message: 'Please Select a Category' }]}
        className="w-1/5"
      >
        <Select placeholder="Please Select a Category">
          {categories?.map((category) => (
            <Select.Option 
              key={category.id}
              value={category.id}
            >
              {category.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      {/* use different field names to store selected shop information */}
      <Form.Item name="selectedShopName" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="selectedShopId" hidden>
        <Input />
      </Form.Item>

      <div className="flex space-x-6">
        <Form.Item 
          label="Service Name" 
          name="serviceTitle" 
          className="w-1/3"
          rules={[
            { required: true, message: 'Please Enter Service Name' },
            { max: 128, message: 'Service name cannot exceed 128 characters' }
          ]}
        >
          <Input placeholder="Please Enter Service Name" />
        </Form.Item>
        <Form.Item 
          label="Service Code" 
          name="serviceNumber" 
          className="w-1/3"
        >
          <Input disabled placeholder="Please Enter Service Name" />
        </Form.Item>
      </div>
      <div className="font-medium mb-2">
        Main Service Image
        <span className="text-red-500 ml-1">*</span>
      </div>
      <Form.Item
        name="uploadImage"
        className="mb-4"
      >
        <div className="flex space-x-4">
          <ImgCrop rotationSlider>
            <Upload
              action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
              listType="picture-card"
              fileList={fileList}
              onChange={onChange}
              onPreview={onPreview}
              maxCount={5}
              beforeUpload={() => false}
            >
              {fileList.length < 5 && (
                <div>
                  <PlusOutlined />
                  <div className="mt-2">Upload Photo</div>
                </div>
              )}
            </Upload>
          </ImgCrop>
          

        </div>
      </Form.Item>
      <Form.Item 
        label="Service Description" 
        name="serviceDescription"
        rules={[
          { required: true, message: 'Please enter service description' },
          { max: 1024, message: 'Service description cannot exceed 1024 characters' }
        ]}
      >
        <TextArea rows={4} placeholder="Please Enter Service Description" />
      </Form.Item>

      <Form.Item 
        label="Instructions and Notes" 
        name="serviceNotice"
        rules={[
          { required: true, message: 'Please enter instructions and notes' },
          { max: 1024, message: 'Instructions and notes cannot exceed 1024 characters' }
        ]}
      >
        <TextArea rows={4} placeholder="Please Enter Instructions and Rules of Use" />
      </Form.Item>

      {/* Reservation and Tags */}
      <div className="flex space-x-4">
        <Form.Item 
          label="Reservation" 
          name="reservation"
        >
          <Select style={{ width: 200 }}>
            <Select.Option value="No Reservation Required">No Reservation Required</Select.Option>
            <Select.Option value="Reservation Date Required">Reservation Date Required</Select.Option>
            <Select.Option value="Reservation Date and Time Slot Required">Reservation Date and Time Slot Required</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item label="Reservation Available Period" name="bookingPeriod" >
          <RangePicker 
            allowEmpty={[true, true]}
          />
        </Form.Item>
      </div>

      <Form.Item
        label="Usage Period"
        name="validPeriod"
      >
        <RangePicker
          allowEmpty={[false, false]}
          format="YYYY-MM-DD"
          disabledDate={(current) => current && current < moment().startOf('day')}
          placeholder={['Start Date', 'End Date']}
        />
      </Form.Item>

      {/* tags */}
      <Form.Item 
          label={`Tags (${selectedTags.length}/5)`}
          name="tags"
        >
          <div className="flex flex-wrap gap-2 border border-gray-300 rounded-lg p-2 min-w-[400px] w-fit">
            {selectedTags.map((tag) => (
              <Tag
                key={tag}
                closable
                onClose={() => handleTagClose(tag)}
                className="bg-green-50 text-green-600 border-green-200"
              >
                {tag}
              </Tag>
            ))}
            {selectedTags.length < 5 && (
              <Tag
                className="border-dashed border-gray-300 cursor-pointer hover:border-green-500"
                onClick={() => setIsModalVisible(true)}
              >
                <PlusOutlined /> Add Tags
              </Tag>
            )}
          </div>
        </Form.Item>

      <Form.Item label="Service Notes (Displayed as Subtitle)" name="copyright">
        <Input placeholder="Please Enter Notes (within 25 characters)" maxLength={25} disabled/>
      </Form.Item>

      {/* Image URL */}
      <Form.Item 
        name="serviceImage" 
        label="圖片 URL" 
        hidden
      >
        <Input disabled />
      </Form.Item>

      {/* add Modal component */}
      <Modal
        title="新增標籤"
        open={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        okText="確定"
        cancelText="取消"
      >
        <Input
          placeholder="請輸入標籤名稱"
          value={newTagInput}
          onChange={(e) => setNewTagInput(e.target.value)}
          onPressEnter={handleModalOk}
        />
      </Modal>
    </>
  );
};

export default Information;
