import React, { useState, useEffect } from 'react';
import { Input, Select, Button, message } from 'antd';
import axios from 'axios';
import { useAuth } from '../../../contexts/AuthProvider';

const BatchAddAcc = () => {
    const [accounts, setAccounts] = useState(Array(10).fill().map((_, index) => ({
        id: index + 1,
        nickname: '',
        account: '',
        password: '',
        department_id: undefined,
        room_count: 0
    })));
    const [departments, setDepartments] = useState([]);
    const [totalRooms, setTotalRooms] = useState(200);
    const [assignedRooms, setAssignedRooms] = useState(180);
    const { token } = useAuth();
    const SERVER_URL = process.env.REACT_APP_SERVER_URL;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        setTotalRooms(200);
        fetchDepartments();
    }, []);

    useEffect(() => {
        // 計算未分配房間數
        const totalAssigned = accounts.reduce((sum, acc) => sum + (parseInt(acc.room_count) || 0), 0);
        setAssignedRooms(180 + totalAssigned);
    }, [accounts]);

    const fetchDepartments = async () => {
        try {
            const response = await axios.get(`${SERVER_URL}/private/company/departments`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json'
                }
            });
            setDepartments(response.data);
        } catch (error) {
            message.error('獲取部門列表失敗');
        }
    };

    const handleInputChange = (id, field, value) => {
        setAccounts(accounts.map(acc =>
            acc.id === id ? { ...acc, [field]: value } : acc
        ));
    };

    const handleRoomCountChange = (id, value) => {
        const numValue = parseInt(value) || 0;
        if (numValue < 0) return;

        setAccounts(accounts.map(acc =>
            acc.id === id ? { ...acc, room_count: numValue } : acc
        ));
    };

    const handleSubmit = async () => {
        const validAccounts = accounts.filter(acc =>
            acc.nickname && acc.account && acc.password && acc.department_id
        );

        if (validAccounts.length === 0) {
            message.error('請至少填寫一組完整的帳號資料');
            return;
        }

        try {
            await axios.post(`${SERVER_URL}/private/admin/batch-create-accounts`, {
                accounts: validAccounts
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json'
                }
            });
            message.success('批量新增帳號成功');
            // 重置表單
            setAccounts(Array(10).fill().map((_, index) => ({
                id: index + 1,
                nickname: '',
                account: '',
                password: '',
                department_id: undefined,
                room_count: 0
            })));
        } catch (error) {
            message.error('批量新增帳號失敗');
        }
    };

    const handleReturn = () => {
        window.location.reload();
    };

    return (
        <div className="p-6">
            <div className="flex gap-8 mb-4">
                <div>總房間數：{totalRooms}</div>
                <div>已分配房間：{assignedRooms}</div>
                <div>尚未分配房間：{totalRooms - assignedRooms}</div>
            </div>
            <div className="overflow-x-auto">
                <table className="w-full">
                    <thead>
                        <tr className="bg-[#E0F0E0] text-left">
                            <th className="p-3">序號</th>
                            <th className="p-3">暱稱</th>
                            <th className="p-3">帳號</th>
                            <th className="p-3">密碼</th>
                            <th className="p-3">部門</th>
                            <th className="p-3">分配房間數</th>
                        </tr>
                    </thead>
                    <tbody>
                        {accounts.map((account) => (
                            <tr key={account.id} className="border-b">
                                <td className="p-3">{account.id}</td>
                                <td className="p-3">
                                    <Input
                                        value={account.nickname}
                                        onChange={(e) => handleInputChange(account.id, 'nickname', e.target.value)}
                                    />
                                </td>
                                <td className="p-3">
                                    <Input
                                        value={account.account}
                                        onChange={(e) => handleInputChange(account.id, 'account', e.target.value)}
                                    />
                                </td>
                                <td className="p-3">
                                    <Input
                                        value={account.password}
                                        onChange={(e) => handleInputChange(account.id, 'password', e.target.value)}
                                        type="password"
                                    />
                                </td>
                                <td className="p-3">
                                    <Select
                                        className="w-full"
                                        value={account.department_id}
                                        onChange={(value) => handleInputChange(account.id, 'department_id', value)}
                                        placeholder="選擇部門"
                                    >
                                        {departments.map(dep => (
                                            <Select.Option key={dep.id} value={dep.id}>
                                                {dep.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </td>
                                <td className="p-3 flex items-center gap-2">
                                    <Button
                                        onClick={() => handleRoomCountChange(account.id, (account.room_count || 0) - 1)}
                                        disabled={!account.room_count}
                                    >
                                        -
                                    </Button>
                                    <Input
                                        className="w-20 text-center"
                                        value={account.room_count || 0}
                                        onChange={(e) => handleRoomCountChange(account.id, e.target.value)}
                                    />
                                    <Button
                                        onClick={() => handleRoomCountChange(account.id, (account.room_count || 0) + 1)}
                                    >
                                        +
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="mt-4 flex justify-end items-center gap-6">
                <Button
                    onClick={handleReturn}
                    className="!bg-white !hover:bg-red-400 !text-red-500 !border-red-500 !border-[1px] !hover:text-white !hover:border-red-200"
                >
                    退出
                </Button>
                <Button
                    type="primary"
                    onClick={handleSubmit}
                    className="bg-[#7AB679] hover:bg-[#7AB679]/80"
                >
                    儲存
                </Button>
            </div>
        </div>
    );
};

export default BatchAddAcc; 