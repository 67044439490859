import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
import { Button, Tabs, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

import ChatListItem from './ChatListItem'

const ChatList = (props) => {
  const [chatList, setChatList] = useState(props.chatList)

  useEffect(() => {
    setChatList(props.chatList);
  }, [props.chatList]);

  const onChangeTab = (key) => {
    if (parseInt(key) === 0) {
      setChatList(props.chatList)
    } else if (parseInt(key) === 1) {
      setChatList(props.chatList.filter(room => room.creator_id === props.userId))
    } else if (parseInt(key) === 2) {
      setChatList(props.chatList.filter(room => room.creator_id !== props.userId))
    }
  };

  const tabs = [
    {
      key: '0',
      label: '全部',
    },
    {
      key: '1',
      label: '房主',
    },
    {
      key: '2',
      label: '其他',
    },
  ];

  const groupedChatRooms = chatList.reduce((groups, room) => {
    groups[room.group] = groups[room.group] || [];
    groups[room.group].push(room);
    return groups;
  }, {});

  return (
    <div className="flex flex-col min-w-72 max-w-72 bg-bgMedium px-5 pt-4">
      <div className="flex flex-row justify-between gap-2">
        <Button type="primary" onClick={props.openCreateModal} className="mt-2 w-1/2">
          創建聊天室
        </Button>
        <Button type="primary" onClick={props.openJoinModal} className="mt-2 w-1/2">
          加入聊天室  
        </Button>
      </div>
      <div className="text-2xl font-bold mb-2 mt-6 text-textDark">
        聊天室
      </div>
      <Tabs defaultActiveKey="0" items={tabs} onChange={onChangeTab} />
      {
        props.loading ? <div className='flex items-center justify-center w-full h-full'>
          <Spin
            indicator={
              <LoadingOutlined
                style={{
                  fontSize: 48,
                }}
                spin
              />
            }
          />
        </div> :
        chatList.length === 0 ?
        <div className="w-full flex flex-col gap-4 items-center justify-center pt-12">
          這裡沒有任何聊天室。
          <a href="/explore">
            <Button>
              探索聊天室
            </Button>
          </a>
        </div> :
          <>
            <div className="flex flex-col gap-4 overflow-y-scroll pb-12 overflow-x-hidden">
              {
                Object.keys(groupedChatRooms).map((group, index) => {
                  const isGroupActive = groupedChatRooms[group].some(room => room.id === props.activeRoomId);

                  return (
                    <div
                      key={index}
                      className={`flex flex-col gap-2 p-2 rounded-xl ${isGroupActive ? "bg-primaryLight drop-shadow-md" : ""}`}
                    >
                      <div className="font-bold text-textDark">
                        {group}
                      </div>
                      {
                        groupedChatRooms[group].map((room, index) => (
                          <div
                            key={index}
                            onClick={() => props.changeActiveRoom(room.id)}
                          >
                            <div className="flex flex-row items-center">
                              {
                                room.id === props.activeRoomId &&
                                <div className={classnames("w-[3px] h-[64px] bg-primary rounded-r-lg absolute z-10 left-0", {
                                  "h-[88px]": room.creator_id === props.userId
                                })} />
                              }
                              <ChatListItem
                                isActive={room.id === props.activeRoomId}
                                isOwner={room.creator_id === props.userId}
                                roomName={room.name}
                                onlineCount={room.num_of_members}
                              />
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  )
                })}
            </div>
          </>
      }
    </div>
  )
}

export default ChatList