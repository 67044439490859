import { Tag, Button } from 'antd';
import moment from 'moment';

export const shopColumns = [
  {
    title: <span className="whitespace-nowrap">前台狀態</span>,
    dataIndex: "status",
    width: "10%",
    fixed: "left",
    render: (status) => (
      <span
        className={`badge rounded-full px-2 py-1 text-white whitespace-nowrap ${
          status === "On Sale" ? "bg-[#67BE5F]" : "bg-[#B6B3A7]"
        }`}
      >
        {status}
      </span>
    ),
  },
  {
    title: <span className="whitespace-nowrap">審核狀態</span>,
    dataIndex: "provider",
    width: "10%",
    fixed: "left",
    render: (provider) => (
      <span className="whitespace-nowrap">{provider}</span>
    ),
  },
  {
    title: <span className="whitespace-nowrap">商家序號</span>,
    dataIndex: "shopId",
    width: "8%",
    fixed: "left",
    render: (shopId) => (
      <span className="whitespace-nowrap">{shopId}</span>
    ),
  },
  {
    title: <span className="whitespace-nowrap">商家名稱</span>,
    dataIndex: "name",
    width: "15%",
    render: (text, record) => (
      <div className="flex items-center">
        <img
          src={record.imageUrl}
          alt="服務縮圖"
          className="w-6 h-6 object-cover rounded mr-2"
        />
        <span className="truncate whitespace-nowrap" style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {text}
        </span>
      </div>
    ),
  },
  {
    title: <span className="whitespace-nowrap">註冊申請人</span>,
    dataIndex: "price",
    width: "8%",
    render: (price) => (
      <span className="whitespace-nowrap">{price}</span>
    ),
  },
  {
    title: <span className="whitespace-nowrap">美容師數量</span>,
    dataIndex: "order",
    width: "8%",
    render: (order) => (
      <Button type="link" className="whitespace-nowrap">{order}</Button>
    ),
  },
  {
    title: <span className="whitespace-nowrap">服務數量</span>,
    dataIndex: "remaining",
    width: "7%",
    render: (remaining) => (
      <span className="whitespace-nowrap">{remaining === "0" ? "無限" : remaining}</span>
    ),
  },
  {
    title: <span className="whitespace-nowrap">商品數</span>,
    dataIndex: "memberDiscount",
    width: "7%",
    render: (memberDiscount) => (
      <span className="whitespace-nowrap">{memberDiscount}</span>
    ),
  },
  {
    title: <span className="whitespace-nowrap">累積營收</span>,
    dataIndex: "revenue",
    width: "10%",
    ellipsis: true,
    render: (revenue) => (
      <span className="whitespace-nowrap">
        {revenue ? ` ${revenue}` : "Unset"}
      </span>
    ),
  },
  {
    title: <span className="whitespace-nowrap">標籤</span>,
    dataIndex: "tags",
    width: "15%",
    render: (tags) => (
      <div className="flex gap-1 whitespace-nowrap" style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {tags?.length > 0 ? (
          tags.map((tag) => (
            <Tag key={tag}>{tag}</Tag>
          ))
        ) : (
          <Tag>Unset</Tag>
        )}
      </div>
    ),
  },
  {
    title: <span className="whitespace-nowrap">加入日期</span>,
    dataIndex: "publishDate",
    width: "10%",
    render: (date) => (
      <span className="whitespace-nowrap">{moment(date).format("YYYY-MM-DD")}</span>
    ),
  },
  {
    title: <span className="whitespace-nowrap">商家電話</span>,
    dataIndex: "phone",
    width: "10%",
    ellipsis: true,
    render: (phone) => (
      <span className="whitespace-nowrap">{phone}</span>
    ),
  },
  {
    title: <span className="whitespace-nowrap">商家Email</span>,
    dataIndex: "email",
    width: "10%",
    ellipsis: true,
    render: (email) => (
      <span className="whitespace-nowrap">{email}</span>
    ),
  },
  {
    title: <span className="whitespace-nowrap">所在縣市</span>,
    dataIndex: "city",
    width: "10%",
    ellipsis: true,
    render: (city) => (
      <span className="whitespace-nowrap">{city}</span>
    ),
  },
  {
    title: <span className="whitespace-nowrap">所在區域</span>,
    dataIndex: "district",
    width: "10%",
    ellipsis: true,
    render: (district) => (
      <span className="whitespace-nowrap">{district || "Unset"}</span>
    ),
  },
]; 