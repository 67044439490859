import { AgodaUseCases } from '../../application/useCases/AgodaUseCases';
import { AgodaRepository } from '../../infrastructure/repositories/AgodaRepository';

export const AgodaFacade = {
  // Get complete chat flow
  getAgodaChatFlow: async (chatroomId) => {
    return await AgodaUseCases.getAgodaChatFlow(AgodaRepository, chatroomId);
  },

  // Get conversation data
  getConversationData: async (conversationId) => {
    return await AgodaUseCases.getConversationData(AgodaRepository, conversationId);
  },

  // Get conversation ID
  getAgodaConversationId: async (sourceId) => {
    return await AgodaUseCases.getConversationId(AgodaRepository, sourceId);
  },

  // Get conversation messages
  getAgodaConversationMessages: async (conversationId) => {
    try {
      return await AgodaUseCases.getConversationMessages(AgodaRepository, conversationId);
    } catch (error) {
      console.error('Error getting conversation messages:', error);
      return [];
    }
  },

  // Get booking info
  getAgodaBookingInfo: async (conversationId) => {
    return await AgodaUseCases.getBookingInfo(AgodaRepository, conversationId);
  },

  // Get profile info
  getProfile: async (roomId) => {
    return await AgodaUseCases.getProfile(AgodaRepository, roomId);
  },

  // Get source info
  getSourceInfo: async (roomId) => {
    try {
      const sourceInfo = await AgodaUseCases.getSourceInfo(AgodaRepository, roomId);
      return sourceInfo;
    } catch (error) {
      console.error('Error getting source info:', error);

    }
  },

  // Send message
  sendAgodaMessage: async (profileId, sourceId, conversationId, messageType, message, file) => {
    return await AgodaUseCases.sendMessage(AgodaRepository, profileId, sourceId, conversationId, messageType, message, file);
  }
}; 