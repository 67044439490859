import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Input, Button, Select, Tag, Tabs, Radio } from "antd";
import { SearchOutlined, PlusOutlined } from "@ant-design/icons";
import "tailwindcss/tailwind.css";
import { Spin } from "antd";
import AddShopForm from "../components/Org/AddShopForm";
import { LoadingOutlined } from "@ant-design/icons";
import OrderManagement from "../components/Org/OrderManagement";
import moment from "moment";
import ToolBar from "../components/ToolBar";
import request from "@/utils/request";
import OrgDetail from "../components/Org/OrgDetail";
import OrgTable from '../components/Org/OrgTable';

const Org = () => {
  const [shops, setShops] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [activeTab, setActiveTab] = useState("2");
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const [tableLoading, setTableLoading] = useState(true);
  const [serverUrl, setServerUrl] = useState("");
  const [accessToken, setAccessToken] = useState("");

  const getServerUrl = async () => {
    try {
      const response = await request.get(`${SERVER_URL}/private/property/ecommerce/profile`);
      setServerUrl(response.body.server_url);
    } catch (error) {
      console.error('獲取伺服器 URL 失敗:', error);
      // 可以在這裡添加錯誤處理邏輯，例如顯示錯誤提示
    }
  }

  const getAccessToken = async () => {
    try {
      const response = await request.get(`${SERVER_URL}/private/property/ecommerce/jwt`);
      setAccessToken(response.access_token);
    } catch (error) {
      console.error('獲取存取令牌失敗:', error);
      // 可以在這裡添加錯誤處理邏輯，例如顯示錯誤提示
    }
  }

  useEffect(() => {
    getServerUrl();
    getAccessToken();

    const tokenInterval = setInterval(() => {
      getAccessToken();
    }, 10 * 60 * 1000);

    return () => clearInterval(tokenInterval);
  }, []);

  useEffect(() => {
    console.log(serverUrl);
    console.log(accessToken);
    console.log('shops', shops);
    
  }, [serverUrl, accessToken, shops]);

  
  // fetch member data
  useEffect(() => {
    if (serverUrl && accessToken) {
      fetchShops();
    }
  }, [serverUrl, accessToken]);

  // fetch shop data
  const fetchShops = async () => {
    if (!serverUrl || !accessToken) return;
    setTableLoading(true);
    try {
      const response = await axios.get(`${serverUrl}/public/organization`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      console.log('response', response);

      const formattedData = response.data.map(user => ({
        ...user,
        key: user.id
      }));

      setShops(formattedData);
      if (formattedData.length > 0 && !selectedShop) {
        setSelectedShop(formattedData[0]);
      }
    } catch (error) {
      console.error('獲取會員數據失敗:', error);
    } finally {
      setTableLoading(false);
    }
  };

  const [selectedService, setSelectedService] = useState(null);
  const [selectedShop, setSelectedShop] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [showOrderManagement, setShowOrderManagement] = useState(false);
  const totalShops = shops.length;
  const availableShops = shops.filter(
    (shop) => shop.status === "On Sale"
  ).length;
  const lowStockWarnings = shops.filter(
    (shop) => parseInt(shop.remaining) < 20 && shop.remaining !== "0"
  ).length;
  const onPromotion = shops.filter(
    (shop) => parseFloat(shop.memberDiscount) > 0
  ).length;
  const drafts = shops.filter((shop) => shop.status === "草稿夾").length;

  // Handle row click
  const onRowClick = (record) => {
    setSelectedShop(record);
  };

  // Handle add shop
  const handleAddShop = (newShop) => {
    const currentShops = JSON.parse(JSON.stringify(shops));
    const shopToAdd = {
      key: Date.now().toString(),
      status: "On Sale",
      provider: "審核通過",
      shopId: `SP${String(currentShops.length + 1).padStart(3, "0")}`,
      name: newShop.selectedShopName || newShop.name || "Unset",
      price: newShop.price || "Unset",
      order: newShop.order || "0",
      remaining: newShop.remaining || "0",
      memberDiscount: newShop.memberDiscount || "0",
      description: newShop.description || "Unset",
      tags: newShop.tags?.length ? newShop.tags : ["Unset"],
      publishDate: newShop.publishDate || new Date().toISOString(),
      imageUrl: newShop.imageUrl || "/images/default-shop.jpg",
      phone: newShop.phone || "Unset",
      email: newShop.email || "Unset",
      city: newShop.city || "Unset",
      district: newShop.district || "Unset",
      revenue: newShop.revenue || "0",
      businessHours: newShop.businessHours || "Unset",
      rating: newShop.rating || "Unset",
      establishDate: newShop.establishDate || "Unset",
      ownerName: newShop.ownerName || "Unset",
      ownerPhone: newShop.ownerPhone || "Unset"
    };
    const updatedShops = [shopToAdd, ...currentShops];

    setShops(updatedShops);
    setShowForm(false);
  };

  // Handle search
  const handleSearch = (value) => {
    setSearchText(value);
  };

  // Handle provider change
  const handleProviderChange = (value) => {
    setSelectedProvider(value);
  };

  // Handle status change
  const handleStatusChange = (value) => {
    setSelectedStatus(value);
  };

  // Handle filtered shops
  const filteredShops = shops.filter((shop) => {
    return (
      (searchText === "" ||
        shop.name.toLowerCase().includes(searchText.toLowerCase())) &&
      (selectedProvider === "all" ||
        selectedProvider === null ||
        shop.provider === selectedProvider) &&
      (selectedStatus === "all" ||
        selectedStatus === null ||
        shop.status === selectedStatus)
    );
  });

  // // Handle provider options
  //   const providerOptions = [
  //   { value: 'all', label: '全部商家' },
  //   ...[...new Set(shops.map(shop => shop.provider))].map(provider => ({
  //     value: provider,
  //     label: provider
  //   }))
  // ];

  // Handle status options
  const statusOptions = [
    { value: "all", label: "狀態" },
    { value: "On Sale", label: "On Sale" },
    { value: "下架中", label: "下架中" },
  ];

  // handle show form
  const handleShowForm = () => {
    setShowForm(true);
    setSelectedService(null);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center w-full h-screen">
        <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
      </div>
    );
  }

  if (showOrderManagement) {
    return <OrderManagement />;
  }

  return (
    <>
      <div className="flex flex-col items-center justify-start w-full h-screen overflow-auto">
        <ToolBar pure={true} />
        <div className="flex flex-col w-full h-[calc(100vh-64px)]">
          <div className="flex-1 flex overflow-auto">
            {showForm ? (
              <div className="flex w-full space-x-4 overflow-auto">
                <div className="flex-1 overflow-auto">
                  <AddShopForm
                    onSave={handleAddShop}
                    shops={shops} />
                </div>
              </div>
            ) : (
              <div className="flex w-full overflow-auto bg-gray-50">

                {/* left table area */}
                <div className="flex-1 flex flex-col shadow min-w-0 overflow-auto h-full p-4">

                  <div className="flex justify-between items-center">
                    <div className="text-lg font-bold">商家管理</div>
                    <Button
                      type="primary"
                      icon={<PlusOutlined />}
                      onClick={handleShowForm}
                      className="bg-[#ADDEAC] hover:bg-[#45a049] text-black hover:!text-black"
                    >
                      新增商家
                    </Button>
                  </div>

                  {/* Statistics */}
                  <div className="grid grid-cols-5 gap-4 mb-4">
                    <div className="col-span-5 bg-[#F3F3F3] rounded-lg shadow">
                      <div className="h-[36px] pt-2 pl-2 ">
                        <img
                          src="/images/folder.png"
                          alt="folder"
                          className="w-[20px] h-[20px]"
                        />
                      </div>
                      <div className="grid grid-cols-2 gap-2">
                        <div className="p-2 text-start rounded-lg">
                          <div className="text-sm">總商家數</div>
                          <div className="text-2xl font-bold">
                            {totalShops}
                          </div>
                        </div>
                        <div className="p-2 text-start rounded-lg">
                          <div className="text-sm">營業中</div>
                          <div className="text-2xl font-bold">
                            {availableShops}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <OrgTable 
                    tableLoading={tableLoading}
                    filteredShops={filteredShops}
                    onRowClick={onRowClick}
                    selectedService={selectedService}
                    handleSearch={handleSearch}
                    handleStatusChange={handleStatusChange}
                    selectedStatus={selectedStatus}
                  />
                </div>


                {/* right preview area */}
                <div className="w-[440px] flex-shrink-0 border-l border-t border-gray-200 shadow overflow-auto">
                  <div>
                    {selectedShop && (
                      <OrgDetail shop={selectedShop} className="pl-6" />
                    )}
                  </div>
                  <div className="fixed w-[440px]  bottom-0 bg-white border shadow-3xl p-4">
                    <Button
                      type="primary"
                      className="w-full h-10 bg-[#4CAF50] hover:bg-[#45a049]"
                    >
                      Editing Service
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Org;