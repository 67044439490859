
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Modal, Input, Button, Select, message } from 'antd'
import MgntAvatar from '../../../systemSettings/components/MgntAvatar';

import { ReactComponent as PublicIcon } from '../../assets/privacy_public.svg';
import { ReactComponent as PrivateIcon } from '../../assets/privacy_private.svg';

import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const Footer = ({ loading, disabled, onClick, text = "儲存" }) => {
  return (
    // <div className="flex flex-row justify-end items-center gap-1 mt-4 px-4">
    <Button type="primary" onClick={onClick} disabled={disabled} loading={loading}>
      {text}
    </Button>
    // </div>
  )
}

const NameModal = ({ id, open, close, token, reload, oldName }) => {
  const [loading, setLoading] = useState(false)
  const [newName, setNewName] = useState(oldName)
  const [error, setError] = useState(false)

  useEffect(() => {
    setNewName(oldName)
  }, [open])

  const handleChangeName = async () => {
    if (newName.length < 1) {
      setError(true)
      return
    }

    setLoading(true)
    try {
      await axios.patch(SERVER_URL + '/private/chatrooms/setting/name/' + id, {
        text: newName
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
    } catch (error) {
      message.error(JSON.stringify(error.response?.data?.detail));
    } finally {
      setLoading(false)
      setNewName("")
      close()
      reload()
    }
  }

  return (
    <Modal
      open={open}
      onCancel={close}
      width={400}
      onOk={handleChangeName}
      footer={<Footer loading={loading} disabled={newName === ""} onClick={handleChangeName} />}
    >
      <div className="flex flex-col items-center pt-8 py-4 min-h-20">
        <div className="items-start">
          <div className="text-textDark mb-2">變更名稱</div>
          <Input status={error && "error"} className="w-80" value={newName} onChange={e => setNewName(e.target.value)} disabled={loading} />
        </div>
      </div>
    </Modal>
  )
}

const BotNameModal = ({ id, open, close, token, reload, oldBotName }) => {
  const [loading, setLoading] = useState(false)
  const [newBotName, setNewBotName] = useState(oldBotName)
  const [error, setError] = useState(false)

  useEffect(() => {
    setNewBotName(oldBotName)
  }, [open])

  const handleChangeName = async () => {
    if (newBotName.length < 1) {
      setError(true)
      return
    }

    setLoading(true)
    try {
      await axios.patch(SERVER_URL + '/private/chatrooms/setting/botname/' + id, {
        text: newBotName
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
    } catch (error) {
      message.error(JSON.stringify(error.response?.data?.detail));
    } finally {
      setLoading(false)
      setNewBotName("")
      close()
      reload()
    }
  }

  return (
    <Modal
      open={open}
      onCancel={close}
      width={400}
      onOk={handleChangeName}
      footer={<Footer loading={loading} disabled={newBotName === ""} onClick={handleChangeName} />}
    >
      <div className="flex flex-col items-center pt-8 py-4 min-h-20">
        <div className="items-start">
          <div className="text-textDark mb-2">變更 AI 暱稱</div>
          <Input status={error && "error"} className="w-80" value={newBotName} onChange={e => setNewBotName(e.target.value)} disabled={loading} />
        </div>
      </div>
    </Modal>
  )
}

const AdminModal = ({ id, open, close, token, oldAdmin, reload }) => {
  const [loading, setLoading] = useState(false)
  const [newAdmin, setNewAdmin] = useState("")
  const [member, setMember] = useState([])

  useEffect(() => {
    setNewAdmin("")
    fetchMembers()
  }, [open])

  const fetchMembers = async () => {
    setLoading(true)

    try {
      const response = await axios.get(SERVER_URL + '/private/chatrooms/' + id + '/members', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      setMember(response.data)
    } catch (error) {
      message.error(JSON.stringify(error.response?.data?.detail));
    } finally {
      setLoading(false)
    }
  }

  const handleChangeAdmin = async () => {
    setLoading(true)
    try {
      await axios.patch(SERVER_URL + '/private/chatrooms/setting/ownership/' + id + '?user_id=' + newAdmin, {}, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
    } catch (error) {
      message.error(JSON.stringify(error.response?.data?.detail));
    } finally {
      setLoading(false)
      setNewAdmin("")
      close()
      reload()
    }
  }

  return (
    <Modal
      open={open}
      onCancel={close}
      width={400}
      onOk={handleChangeAdmin}
      footer={<Footer loading={loading} disabled={newAdmin === ""} onClick={handleChangeAdmin} />}
    >
      <div className="flex flex-col items-center pt-8 py-4 min-h-20">
        <div className="items-start">
          <div className="text-textDark">變更房主</div>
          <div className="text-textLight mb-2 text-xs">原房主: {oldAdmin} (變更後立即失去管理權)</div>
          <Select
            showSearch
            placeholder="選擇新的房主"
            value={newAdmin}
            optionFilterProp="children"
            onChange={setNewAdmin}
            options={member.map(m => ({ value: m.id, label: m.nickname }))}
            className="w-80"
          />
        </div>
      </div>
    </Modal>
  )
}

const PrivacyModal = ({ id, open, close, privacy, token, reload }) => {
  const [loading, setLoading] = useState(false)
  const [newPrivacy, setNewPrivacy] = useState(privacy)
  const [newPassword, setNewPassword] = useState("")

  useEffect(() => {
    setNewPrivacy(privacy)
  }, [open])

  const handleChangePrivacy = async () => {
    setLoading(true)
    if (privacy === false && newPrivacy === true) {
      if (newPassword.length < 4) {
        return
      }
    }

    const privacyVar = newPrivacy ? "false" : "true"

    try {
      await axios.patch(SERVER_URL + "/private/chatrooms/setting/visibility/" + id + "?is_public=" + privacyVar, {}, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
    } catch (error) {
      message.error(JSON.stringify(error.response?.data?.detail));
    }

    if (privacy === false && newPrivacy === true) {
      try {
        await axios.patch(`${SERVER_URL}/private/chatrooms/setting/passphrase/${id}`, {
          text: newPassword
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
      } catch (error) {
        message.error(JSON.stringify(error.response?.data?.detail));
      }
    }

    setLoading(false)
    setNewPrivacy(null)
    close()
    reload()
  }

  return (
    <Modal
      open={open}
      onCancel={close}
      width={400}
      onOk={handleChangePrivacy}
      footer={<Footer loading={loading} disabled={newPrivacy === null || ((privacy === false && newPrivacy === true) && newPassword.length < 4)} onClick={handleChangePrivacy} />}
    >
      <div className="flex flex-col items-center pt-8 py-4 min-h-20">
        <div className="flex flex-row justify-between items-center w-80">
          <div onClick={() => setNewPrivacy(false)} className={`w-38 h-38 cursor-pointer flex flex-col items-center p-6 bg-white rounded-lg border hover:bg-bgLight ${newPrivacy === false ? "border-primary text-primary font-bold" : "border-borderDark"}`}>
            <PublicIcon className="w-24 h-24 mb-2" />
            <div>公開</div>
          </div>
          <div onClick={() => setNewPrivacy(true)} className={`w-38 h-38 cursor-pointer flex flex-col items-center p-6 bg-white rounded-lg hover:bg-bgLight border ${newPrivacy === true ? "border-primary text-primary font-bold" : "border-borderDark"}`}>
            <PrivateIcon className="w-24 h-24 mb-2" />
            <div>私人</div>
          </div>
        </div>
        {
          privacy === false && newPrivacy === true &&
          <div className="items-start">
            <div className="text-textDark mb-2 mt-4">新增密碼 (至少四位數)</div>
            <Input.Password className="w-80" onChange={e => setNewPassword(e.target.value)} />
          </div>
        }
      </div>
    </Modal>
  )
}

const PasswordModal = ({ id, open, close, token, reload }) => {
  const [loading, setLoading] = useState(false)
  const [newPassword, setNewPassword] = useState("")

  useEffect(() => {
    setNewPassword("")
  }, [open])

  const handleChangePassword = async () => {
    setLoading(true)
    try {
      await axios.patch(`${SERVER_URL}/private/chatrooms/setting/passphrase/${id}`, {
        text: newPassword
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
    } catch (error) {
      message.error(JSON.stringify(error.response?.data?.detail));
    } finally {
      setLoading(false)
      setNewPassword("")
      close()
      reload()
    }
  }

  const onSubmit = () => {
    if (newPassword.length < 4) {
      return
    }
    handleChangePassword()
  }

  return (
    <Modal
      open={open}
      onCancel={close}
      width={400}
      onOk={onSubmit}
      footer={<Footer loading={loading} disabled={newPassword.length < 4} onClick={onSubmit} />}
    >
      <div className="flex flex-col items-center pt-8 py-4 min-h-20">
        <div className="items-start">
          <div className="text-textDark mb-2">變更密碼 (至少四位數)</div>
          <Input.Password value={newPassword} className="w-80" onChange={e => setNewPassword(e.target.value)} />
        </div>
      </div>
    </Modal>

  )
}

const PermissionSetting = ({ room, token }) => {
  const [loading, setLoading] = useState(false)

  const [openName, setOpenName] = useState(false)
  const [openAdmin, setOpenAdmin] = useState(false)
  const [openPrivacy, setOpenPrivacy] = useState(false)
  const [openPassword, setOpenPassword] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [openBotName, setOpenBotName] = useState(false)
  const [openNormalChatDelete, setOpenNormalChatDelete] = useState(false)
  const [openAIChatDelete, setOpenAIChatDelete] = useState(false)

  const [currentRoom, setCurrentRoom] = useState(null)

  useEffect(() => {
    fetchRoom()
  }, [room])

  const fetchRoom = async () => {
    setLoading(true)
    try {
      const response = await axios.get(SERVER_URL + '/private/chatrooms/' + room.id, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setCurrentRoom(response.data)
    } catch (error) {
      message.error(JSON.stringify(error.response?.data?.detail));
    } finally {
      setLoading(false)
    }
  }

  const handleDeleteRoom = async () => {
    setLoading(true)
    try {
      await axios.delete(`${SERVER_URL}/private/property/chatroom/line_channel/${room.id}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      message.success("Line@ 已解除串接");
    } catch (error) {
      message.error(JSON.stringify(error.response?.data?.detail));
    } finally {
      try {
        axios.delete(SERVER_URL + '/private/chatrooms/' + room.id, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
      } catch (error) {
        message.error(JSON.stringify(error.response?.data?.detail));
      } finally {
        setLoading(false)
        window.location.href = "/chat"
      }
    }
  }

  if (loading) {
    return (
      <div className="flex justify-center items-center w-full h-full">
        <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
      </div>
    )
  }


  return (
    <>
      <NameModal id={room.id} open={openName} close={() => setOpenName(false)} token={token} reload={fetchRoom} oldName={currentRoom?.name} />
      <BotNameModal id={room.id} open={openBotName} close={() => setOpenBotName(false)} token={token} reload={fetchRoom} oldBotName={currentRoom?.bot_name} />
      <AdminModal id={room.id} open={openAdmin} close={() => setOpenAdmin(false)} token={token} reload={fetchRoom} oldAdmin={currentRoom?.creator_nickname} />
      <PrivacyModal id={room.id} open={openPrivacy} close={() => setOpenPrivacy(false)} privacy={!currentRoom?.is_public} token={token} reload={fetchRoom} />
      <PasswordModal id={room.id} open={openPassword} close={() => setOpenPassword(false)} token={token} reload={fetchRoom} />
      <div className="flex flex-col justify-start items-start w-full px-4 py-6 gap-1">
        <div className="text-lg text-textDark font-bold w-full">
          房間管理
          {/* <span className="text-xs text-textLight underline font-normal ml-2 cursor-pointer" onClick={fetchRoom}>重新整理</span> */}
        </div>
        <MgntAvatar
          photoUrl={currentRoom?.photo_url}
          uploadUrl={`${SERVER_URL}/private/chatrooms/setting/image/${room.id}`}
          title=""
          name={currentRoom?.name}
          refresh={fetchRoom}
        />
        <div className="flex flex-col border border-borderLight rounded bg-bgMedium w-full">
          <div className="flex flex-col items-start w-full h-22 p-4">
            <div className="font-bold">聊天室名稱</div>
            <div className="flex flex-row justify-between w-full items-center">
              <div>
                {currentRoom?.name}
              </div>
              <Button onClick={() => setOpenName(true)}>
                變更
              </Button>
            </div>
          </div>
          <div className="flex flex-col items-start w-full h-22 p-4">
            <div className="font-bold">聊天室ID</div>
            <div className="flex flex-row justify-between w-full items-center">
              <div>
                {currentRoom?.id}
              </div>
              <Button onClick={() => {
                navigator.clipboard.writeText(currentRoom?.id);
                message.success("已複製聊天室ID");
              }}>
                複製
              </Button>
            </div>
          </div>
          <div className="flex flex-col items-start w-full h-22 p-4 gap-1 border-t border-t-borderLight">
            <div className="font-bold">AI 暱稱</div>
            <div className="flex flex-row justify-between w-full items-center">
              <div>
                {currentRoom?.bot_name}
              </div>
              <Button onClick={() => setOpenBotName(true)}>
                變更
              </Button>
            </div>
          </div>
          <div className="flex flex-col items-start w-full h-22 p-4 gap-1 border-t border-t-borderLight">
            <div className="font-bold">房主</div>
            <div className="flex flex-row justify-between w-full items-center">
              <div>
                {currentRoom?.creator_nickname}
              </div>
              <Button onClick={() => setOpenAdmin(true)}>
                變更
              </Button>
            </div>
          </div>
          <div className="flex flex-col items-start w-full h-22 p-4 gap-1 border-t border-t-borderLight">
            <div className="font-bold">目前狀態</div>
            <div className="flex flex-row justify-between w-full items-center">
              <div>
                {currentRoom?.is_public ? "公開" : "私人"}
              </div>
              <Button onClick={() => setOpenPrivacy(true)}>
                變更
              </Button>
            </div>
          </div>
          {
            currentRoom?.is_public === false &&
            <div className="flex flex-col items-start w-full h-22 p-4 gap-1 border-t border-t-borderLight">
              <div className="font-bold">密碼</div>
              <div className="flex flex-row justify-between w-full items-center">
                <div>
                  ****
                </div>
                <Button onClick={() => setOpenPassword(true)}>
                  變更
                </Button>
              </div>
            </div>
          }
          <div className="flex flex-col items-start w-full h-22 p-4 gap-1 border-t border-t-borderLight">
            <div className="font-bold text-red-600">紀錄清空</div>
            <div className="flex flex-row justify-between w-full items-center">
              <div className="text-red-600">
                一般聊天室
              </div>
              {
                openNormalChatDelete ?
                  <div className="flex flex-row gap-1">
                    <Button danger 
                      // onClick={handleDeleteNormalChat}
                    >
                      確認刪除
                    </Button>
                    <Button onClick={() => setOpenNormalChatDelete(false)}>
                      取消
                    </Button>
                  </div>
                  :
                  <Button
                    onClick={() => setOpenNormalChatDelete(true)}
                    danger>
                    刪除
                  </Button>
              }
            </div>
          </div>
          <div className="flex flex-col items-start w-full h-22 p-4 gap-1 border-t border-t-borderLight">
            <div className="font-bold text-red-600">紀錄清空</div>
            <div className="flex flex-row justify-between w-full items-center">
              <div className="text-red-600">
                AI聊天室
              </div>
              {
                openAIChatDelete ?
                  <div className="flex flex-row gap-1">
                    <Button danger 
                      // onClick={handleDeleteAIChat}
                    >
                      確認刪除
                    </Button>
                    <Button onClick={() => setOpenAIChatDelete(false)}>
                      取消
                    </Button>
                  </div>
                  :
                  <Button
                    onClick={() => setOpenAIChatDelete(true)}
                    danger>
                    刪除
                  </Button>
              }
            </div>
          </div>
          <div className="flex flex-col items-start w-full h-22 p-4 gap-1 border-t border-t-borderLight">
            <div className="font-bold text-red-600">刪除房間</div>
            <div className="flex flex-row justify-between w-full items-center">
              <div className="text-red-600">
                刪除房間
              </div>
              {
                openDelete ?
                  <div className="flex flex-row gap-1">
                    <Button danger onClick={handleDeleteRoom}>
                      確認刪除
                    </Button>
                    <Button onClick={() => setOpenDelete(false)}>
                      取消
                    </Button>
                  </div>
                  :
                  <Button onClick={() => setOpenDelete(true)} danger>
                    刪除
                  </Button>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PermissionSetting