import { BoundRepository } from '../../../infrastructure/repositories/BoundRepository';
export const BoundUseCase = {
  // Line
  fetchLineClients: async (roomId) => { 
    return await BoundRepository.fetchLineClients(roomId);
  },
  fetchLineMessages: async (roomId, socialMediaClientId, limit = 10, fromId = null) => {
    return await BoundRepository.fetchLineMessages(roomId, socialMediaClientId, limit, fromId);
  },
  sendLineMessage: async (roomId, socialMediaClientId, message) => {
    return await BoundRepository.sendLineMessage(roomId, socialMediaClientId, message);
  },
  // Messenger
  fetchMessengerClients: async (roomId) => {
    return await BoundRepository.fetchMessengerClients(roomId);
  },
  fetchMessengerMessages: async (roomId, socialMediaClientId, limit = 10, fromId = null) => {
    return await BoundRepository.fetchMessengerMessages(roomId, socialMediaClientId, limit, fromId);
  },
  sendFacebookMessage: async (roomId, socialMediaClientId, message) => {
    return await BoundRepository.sendFacebookMessage(roomId, socialMediaClientId, message);
  },
  // Agent
  fetchAgentClients: async (roomId) => {
    return await BoundRepository.fetchAgentClients(roomId);
  },
  fetchAgentMessages: async (roomId, socialMediaClientId, limit = 10, fromId = null) => {
    return await BoundRepository.fetchAgentMessages(roomId, socialMediaClientId, limit, fromId);
  },
  sendAgentMessage: async (roomId, socialMediaClientId, message) => {
    return await BoundRepository.sendAgentMessage(roomId, socialMediaClientId, message);
  },
}; 