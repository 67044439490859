import { AirbnbUseCases } from '../useCases/AirbnbUseCases';
import { AirbnbRepository } from '../../infrastructure/repositories/AirbnbRepository';

export const AirbnbFacade = {
  // Get complete chat flow
  getAirbnbChatFlow: async (chatroomId) => {
    try {
      return await AirbnbUseCases.getAirbnbChatFlow(AirbnbRepository, chatroomId);
    } catch (error) {
      // console.error('Facade Error in getAgodaChatFlow:', error);
      // Return user-friendly error messages

      // throw new Error('系統發生錯誤，請稍後再試');
    }
  },

  // Get conversation data
  getConversationData: async (conversationId) => {
    return await AirbnbUseCases.getConversationData(AirbnbRepository, conversationId);
  },

  // Get conversation ID
  getAirbnbConversationId: async (sourceId) => {
    return await AirbnbUseCases.getConversationId(AirbnbRepository, sourceId);
  },

  // Get conversation messages
  getAirbnbConversationMessages: async (conversationId, displayName) => {
    try {
      return await AirbnbUseCases.getConversationMessages(AirbnbRepository, conversationId, displayName);
    } catch (error) {
      console.error('Error getting Airbnb conversation messages:', error);
      return []; // 當發生錯誤時，返回空陣列而不是拋出錯誤
    }
  },

  // Get source info
  getAirbnbSourceInfo: async (roomId) => {
    try {
      const sourceInfo = await AirbnbUseCases.getSourceInfo(AirbnbRepository, roomId);
      console.log('111111111', sourceInfo);
      return sourceInfo;
    } catch (error) {
      console.error('Error getting source info:', error);

    }
  },

  // Get booking info
  getAirbnbBookingInfo: async (conversationId) => {
    return await AirbnbUseCases.getBookingInfo(AirbnbRepository, conversationId);
  },
  // Send message
  sendAirbnbMessage: async (profileId, sourceId, conversationId, messageType, message, file) => {
    return await AirbnbUseCases.sendMessage(AirbnbRepository, profileId, sourceId, conversationId, messageType, message, file);
  }
};  