import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-tw';
import { LineChannelFacade } from '../../../../application/facades/LineChannelFacade';

import userAvatar from '../../assets/userAvatar.png';
import { useBound } from '../../../../contexts/BoundContext';

// 格式化時間的工具函數
const formatMessageTime = (timestamp) => {
  if (!timestamp) return '';

  const messageTime = dayjs(timestamp);
  const now = dayjs();

  // 如果是今天
  if (messageTime.isSame(now, 'day')) {
    return messageTime.format('A hh:mm').replace('AM', '上午').replace('PM', '下午');
  }

  // 如果是昨天
  if (messageTime.isSame(now.subtract(1, 'day'), 'day')) {
    return '昨天';
  }

  // 如果是更早的日期
  return messageTime.format('M/D');
};

const BoundListItem = ({
  client,
  activeId,
  setActiveId,
  newMessage = false,
  num_of_unseen_messages,
  room,
  setClients
}) => {
  const { setRoomId, setMessageId, orderAgodaMessage } = useBound();
  const hasFakeMessage =
    client.last_two_messages[0]?.response &&
    client.last_two_messages[0]?.response?.message === '#.aFaKeMeSsAgE';
  const [guestName, setGuestName] = useState('');

  useEffect(() => {
    // filter data by message id
    const data = orderAgodaMessage?.filter(
      (message) => message.message_id === client.messageId
    );

    // set guest name
    if (
      data.length > 0 &&
      data[0].result &&
      Array.isArray(data[0].result.guest_details) &&
      data[0].result.guest_details.length > 0 &&
      data[0].result.guest_details[0].length > 1
    ) {
      setGuestName(data[0].result.guest_details[0][1]);
    } else {
      setGuestName('Unknown Guest');
    }
  }, [orderAgodaMessage, client.messageId, client]);

  return (
    <div
      className={classnames(
        "flex flex-row gap-2 w-full cursor-pointer px-4 py-3 items-center rounded bg-bgLight shrink-0",
        { "hover:bg-bgDark": activeId !== client.messageId },
        { "bg-primaryMedium hover:bg-primaryMedium": activeId === client.userId }
      )}
      onClick={async () => {
        setActiveId(client.messageId ? client.messageId : client.userId);
        setRoomId(client.roomId);
        setMessageId(client.messageId);

        // 如果有未讀訊息，重新獲取資料
        if (num_of_unseen_messages > 0) {
          try {
            await LineChannelFacade.setLastSeenMessageCache(client);   
            const res = await LineChannelFacade.fetchLineClients(room.id);
            if (res) {
              setClients(res);
            }
                     
          } catch (error) {
            console.error('Error fetching Line channel after click:', error);
          }
        }
      }}
    >
      <img
        src={client.pictureUrl ? client.pictureUrl : userAvatar}
        alt={client.displayName || guestName}
        className="w-12 h-12 rounded-full shrink-0"
      />
      <div className="flex flex-col flex-1 min-w-0">
        <div className="flex flex-row justify-between items-center w-full">
          <div className="font-medium truncate max-w-[120px]">{client.displayName || guestName}</div>
          <div className="text-xs text-textLight shrink-0">
            {formatMessageTime(
              hasFakeMessage || !client.last_two_messages[0]?.response
                ? client.last_two_messages[0]?.created_at
                : client.last_two_messages[0]?.response?.created_at
            )}
          </div>
        </div>
        <div className="flex flex-row items-center gap-1 w-full">
          <div className={`text-xs text-textDark truncate flex-1 ${newMessage ? '!font-bold' : ''}`}>
            {
              hasFakeMessage || !client.last_two_messages[0]?.response
                ? (client.last_two_messages[0]?.message || "...")
                : (client.last_two_messages[0]?.response?.message || "...")
            }
          </div>
          {
            num_of_unseen_messages > 0 && (
              <>
                {/* <div className="bg-red-600 rounded-full w-2 h-2 shrink-0" /> */}
                {num_of_unseen_messages === 0 ? '' : num_of_unseen_messages > 99 ?
                  (
                    <div className="shrink-0 text-xs text-white bg-primary rounded-full px-2 h-5 flex items-center justify-center">
                      99+
                    </div>
                  ) : (
                    <div className="shrink-0 text-xs text-white bg-primary rounded-full px-2 h-5 flex items-center justify-center">
                      {num_of_unseen_messages}
                    </div>
                  )
                }
              </>
            )
          }


        </div>
      </div>
    </div>
  );
};

export default BoundListItem;
