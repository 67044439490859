import React, { useState, useEffect } from "react";
import { request } from "@/utils";
import axios from "axios";
import { Table, Input, Button, Tag, Tabs, Spin } from "antd";
import { SearchOutlined, LoadingOutlined } from "@ant-design/icons";
import moment from "moment";
import { userColumns } from '@/app/cms/data/userColumns';
import ToolBar from "../components/ToolBar";
import "tailwindcss/tailwind.css";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

// define UserDetail component
const UserDetail = ({ user }) => {
  const items = [
    {
      key: "1",
      label: "會員資訊",
      children: (
        <div className="flex-1 pb-20">
          <div className="flex flex-col bg-white rounded-lg gap-1">
            <div className="p-4">
              {/* 頭像和基本資訊區 */}
              <div className="flex flex-col items-center mb-6">
                <img
                  src={user.picture || "/images/user.png"}
                  alt="會員頭像"
                  className="w-20 h-20 rounded-full object-cover mb-2"
                />
                <div className="text-center">
                  <div className="flex items-center gap-2 justify-center">
                    <span className="text-xl font-bold">
                      {user.nickname || `${user.first_name || ''} ${user.last_name || ''}`.trim() || 'Unset'}
                    </span>
                    <span className="text-gray-500">Miss</span>
                  </div>
                  <div className="flex gap-2 mt-2 justify-center">
                    <Tag color="green">Top VIP Customer</Tag>
                    <Tag color="green">Level {user.level}</Tag>
                  </div>
                </div>
              </div>

              {/* 會員標籤區 */}
              <div className="flex flex-wrap gap-2 mb-6">
                <Tag className="px-4 py-2 rounded-full bg-[#E8F5E9] border-none text-black">
                  Regular Customer
                </Tag>
                <Tag className="px-4 py-2 rounded-full bg-[#E8F5E9] border-none text-black">
                  Elegant Beauty
                </Tag>
                <Tag className="px-4 py-2 rounded-full bg-[#E8F5E9] border-none text-black">
                  Makeup Artist
                </Tag>
              </div>

              {/* 社群平台區 */}
              <div className="mb-6">
                <h3 className="text-xl font-bold mb-4">Social Platform</h3>
                <div className="space-y-3">
                  {['Facebook', 'Instagram', 'Thread', 'TikTok'].map((platform) => (
                    <div key={platform} className="flex items-center justify-between">
                      <div className="flex items-center gap-2">
                        <img
                          src={`/images/${platform.toLowerCase()}.png`}
                          alt={platform}
                          className="w-6 h-6"
                        />
                        <span>OOO</span>
                      </div>
                      <Button type="primary" className="bg-[#E8F5E9] text-black border-none hover:bg-[#C8E6C9]">
                        Go to
                      </Button>
                    </div>
                  ))}
                </div>
              </div>

              {/* 基本資料區 */}
              <div>
                <h3 className="text-xl font-bold mb-4">Basic Information</h3>
                <div className="space-y-3">
                  <div className="flex items-center gap-3">
                    <img src="/images/date.png" alt="生日" className="w-6 h-6" />
                    <span>{moment(user.birthday).format("MMMM DD, YYYY")}</span>
                  </div>
                  <div className="flex items-center gap-3">
                    <img src="/images/phone.png" alt="電話" className="w-6 h-6" />
                    <span>{user.phone || '09xx-xxx-xxx'}</span>
                  </div>
                  <div className="flex items-center gap-3">
                    <img src="/images/email.png" alt="信箱" className="w-6 h-6" />
                    <span>{user.email || 'OOOOOO@gmail.com'}</span>
                  </div>
                  <div className="flex items-center gap-3">
                    <img src="/images/hat.png" alt="學歷" className="w-6 h-6" />
                    <span>OO University OO Department</span>
                  </div>
                </div>
              </div>

              {/* 興趣區 */}
              <div className="mt-6">
                <div className="flex items-center justify-between mb-4">
                  <h3 className="text-xl font-bold">Interests</h3>
                  {/* <Button type="text" icon={<img src="/images/edit.png" alt="edit" className="w-5 h-5" />} /> */}
                </div>
                <div className="bg-[#E8F5E9] p-3 rounded-lg">
                  Photography, Camping
                </div>
              </div>
            </div>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="flex flex-col h-full relative">
      <div className="flex-1">
        <Tabs
          defaultActiveKey="1"
          items={items}
          className="flex-1 [&_.ant-tabs-content-holder]:overflow-auto [&_.ant-tabs-nav]:px-4 [&_.ant-tabs-nav]:pt-4"
        />
      </div>
    </div>
  );
};

const User = () => {

  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [tableLoading, setTableLoading] = useState(false);
  const [serverUrl, setServerUrl] = useState("");
  const [accessToken, setAccessToken] = useState("");

  const getServerUrl = async () => {
    try {
      const response = await request.get(`${SERVER_URL}/private/property/ecommerce/profile`);
      setServerUrl(response.body.server_url);
    } catch (error) {
      console.error('獲取伺服器 URL 失敗:', error);
      // 可以在這裡添加錯誤處理邏輯，例如顯示錯誤提示
    }
  }

  const getAccessToken = async () => {
    try {
      const response = await request.get(`${SERVER_URL}/private/property/ecommerce/jwt`);
      setAccessToken(response.access_token);
    } catch (error) {
      console.error('獲取存取令牌失敗:', error);
      // 可以在這裡添加錯誤處理邏輯，例如顯示錯誤提示
    }
  }

  useEffect(() => {
    getServerUrl();
    getAccessToken();

    const tokenInterval = setInterval(() => {
      getAccessToken();
    }, 10 * 60 * 1000);

    return () => clearInterval(tokenInterval);
  }, []);

  useEffect(() => {
    console.log(serverUrl);
    console.log(accessToken);
  }, [serverUrl, accessToken]);



  // fetch user data
  useEffect(() => {
    if (serverUrl && accessToken) {
      fetchUsers();
    }
  }, [serverUrl, accessToken]);

  // fetch user data
  const fetchUsers = async () => {
    if (!serverUrl || !accessToken) return;

    setTableLoading(true);
    try {
      const response = await axios.get(`${serverUrl}/system/user/all`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });

      const formattedData = response.data.users.map(user => ({
        ...user,
        key: user.id
      }));

      setUsers(formattedData);
    } catch (error) {
      console.error('獲取會員數據失敗:', error);
    } finally {
      setTableLoading(false);
    }
  };

  // handle search
  const handleSearch = (value) => {
    setSearchText(value);
  };

  // handle filtered users
  const filteredUsers = users.filter((user) => {
    const searchValue = searchText.toLowerCase();
    const nickname = user.nickname || '';
    const firstName = user.first_name || '';
    const lastName = user.last_name || '';
    const fullName = `${firstName} ${lastName}`.trim();
    const displayName = nickname || fullName || '';
    
    return searchText === "" || displayName.toLowerCase().includes(searchValue);
  });

  // set default selected user
  useEffect(() => {
    if (!loading && users.length > 0 && !selectedUser) {
      setSelectedUser(users[0]);
    }
  }, [loading, users, selectedUser]);

  // handle loading effect
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  // handle row click
  const onRowClick = (record) => {
    setSelectedUser(record);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center w-full h-screen">
        <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-col items-center justify-start w-full h-screen overflow-auto">
        <ToolBar pure={true} />
        <div className="flex flex-col w-full h-[calc(100vh-64px)]">
          <div className="flex-1 flex overflow-auto">
            <div className="flex w-full overflow-auto bg-gray-50">
              {/* left table area  */}
              <div className="flex-1 flex flex-col shadow min-w-0 overflow-auto h-full p-4">
                <div className="flex justify-between items-center">
                  <div className="text-lg font-bold">Member Management</div>
                </div>

                {/* Statistics information */}
                <div className="grid grid-cols-5 gap-4 mb-4">
                  <div className="col-span-2 bg-[#F3F3F3] rounded-lg shadow">
                    <div className="h-[36px] pt-2 pl-2 ">
                      <img
                        src="/images/folder.png"
                        alt="folder"
                        className="w-[20px] h-[20px]"
                      />
                    </div>
                    <div className="grid grid-cols-2 gap-2">
                      <div className="p-2 text-start rounded-lg">
                        <div className="text-sm">Total Membership</div>
                        <div className="text-2xl font-bold">{users.length}</div>
                      </div>
                      <div className="p-2 text-start rounded-lg">
                        <div className="text-sm">Consumed Members</div>
                        <div className="text-2xl font-bold">0</div>
                      </div>
                    </div>
                  </div>

                  <div className="col-span-3 p-1 rounded-lg ">
                    <div className="bg-[#FFCC9129] rounded-lg w-[36px] h-[36px] inline-flex items-center justify-center">
                      <img
                        src="/images/2user.png"
                        alt="folder"
                        className="w-[20px] h-[20px]"
                      />
                    </div>
                    <div className="grid grid-cols-3 gap-2">
                      <div className="p-2 text-start rounded-lg">
                        <div className="text-sm">New Members in the Last 7 Days</div>
                        <div className="text-2xl font-bold">0</div>
                      </div>
                      <div className="p-2 text-start rounded-lg">
                        <div className="text-sm">Consumed Members</div>
                        <div className="text-2xl font-bold">0</div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* search area  */}
                <div className="flex space-x-4 mb-4 mt-4">
                  <Input
                    className="w-80"
                    placeholder="搜尋"
                    prefix={<SearchOutlined />}
                    onChange={(e) => handleSearch(e.target.value)}
                  />
                </div>

                {/* table  */}
                <div className="flex-1 overflow-auto">
                  <Table
                    loading={tableLoading}
                    style={{
                      height: "100%",
                    }}
                    columns={userColumns}
                    dataSource={filteredUsers}
                    pagination={{
                      pageSize: 10,
                      className: "sticky bottom-0 pb-2",
                    }}
                    onRow={(record) => ({
                      onClick: () => onRowClick(record),
                      className:
                        record.key === selectedUser?.key ? "bg-gray-100" : "",
                    })}
                    rowClassName={(record) =>
                      `cursor-pointer ${record.key === selectedUser?.key ? "bg-gray-100" : ""
                      }`
                    }
                    scroll={{ x: "max-content" }}
                    className="[&_.ant-table-thead>tr>th]:bg-[#E0F0E0] h-full"
                    size="small"
                  />
                </div>
              </div>

              {/* right preview area  */}
              <div className="w-[440px] flex-shrink-0 border-l border-t border-gray-200 shadow overflow-auto">
                <div>
                  {selectedUser ? (
                    <UserDetail user={selectedUser} className="pl-6" />
                  ) : (
                    <div className="flex items-center justify-center h-[calc(100vh-64px)] text-gray-400">
                      Please select a member
                    </div>
                  )}
                </div>
                <div className="fixed w-[440px] bottom-0 bg-white border shadow-3xl p-4">
                  <Button
                    type="primary"
                    className="w-full h-10 bg-[#4CAF50] hover:bg-[#45a049]"
                    disabled={!selectedUser}
                  >
                    View Member
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default User;
