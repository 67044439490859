import { BoundUseCase } from '../../application/useCases/bound/BoundUseCase';

export const BoundFacade = {
  // Line
  fetchLineClients: async (roomId) => {
    return await BoundUseCase.fetchLineClients(roomId);
  },
  fetchLineMessages: async (roomId, socialMediaClientId, limit = 10, fromId = null) => {
    return await BoundUseCase.fetchLineMessages(roomId, socialMediaClientId, limit, fromId);
  },
  sendLineMessage: async (roomId, socialMediaClientId, message) => {
    return await BoundUseCase.sendLineMessage(roomId, socialMediaClientId, message);
  },
  // Messenger
  fetchMessengerClients: async (roomId) => {
    return await BoundUseCase.fetchMessengerClients(roomId);
  },
  fetchMessengerMessages: async (roomId, socialMediaClientId, limit = 10, fromId = null) => {
    return await BoundUseCase.fetchMessengerMessages(roomId, socialMediaClientId, limit, fromId);
  },
  sendFacebookMessage: async (roomId, socialMediaClientId, message) => {
    return await BoundUseCase.sendFacebookMessage(roomId, socialMediaClientId, message);
  },
  // Agent
  fetchAgentClients: async (roomId) => {
    return await BoundUseCase.fetchAgentClients(roomId);
  },
  fetchAgentMessages: async (roomId, socialMediaClientId, limit = 10, fromId = null) => {
    return await BoundUseCase.fetchAgentMessages(roomId, socialMediaClientId, limit, fromId);
  },
  sendAgentMessage: async (roomId, socialMediaClientId, message) => {
    return await BoundUseCase.sendAgentMessage(roomId, socialMediaClientId, message);
  },
};
