import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Avatar, Input, Spin, message } from 'antd';
import { UserOutlined, LoadingOutlined } from '@ant-design/icons';
import { useUser } from '@/contexts/UserProvider';
import { useAuth } from '@/contexts/AuthProvider';
import { ReactComponent as Edit } from '../assets/edit_icon.svg';
import UploadPhoto from './UploadPhoto';
import ChangePassword from './ChangePassword';

const UserData = () => {
  const { user, isLoading, error } = useUser();
  const { token } = useAuth();
  const [isEdit, setIsEdit] = useState(false);
  const [nickname, setNickname] = useState(null);
  const [userPhoto, setUserPhoto] = useState(null);
  const [popUploadWindow, setPopUploadWindow] = useState(false);

  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  const handleEdit = () => {
    setIsEdit(!isEdit)
  };
  // const handleCancel = () => {
  //   setIsEdit(false)
  // };

  const handleUploadWindow = () => {
    setPopUploadWindow(!popUploadWindow)
  };

  useEffect(() => {
    if (user) {
      setNickname(user.nickname);
      setUserPhoto(user.photo_url);
    }
  }, [user]);

  if (isLoading) {
    return <div className='flex items-center justify-center w-full'>
      <Spin
        indicator={
          <LoadingOutlined
            style={{
              fontSize: 48,
            }}
            spin
          />
        }
      />
    </div>
  };

  const handleSetNickname = async () => {
    try {
      await axios.patch(`${SERVER_URL}/private/user/nickname`,
        { text: nickname }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      });
      setIsEdit(false);
      window.location.reload();
    } catch (error) {
      message.error(error.response.data.detail)
    }
  };


  return (
    <div className='w-[100%] h-full overflow-y-auto text-[#3E3D3D]'>
      <h3 className='text-[17px] font-semibold px-[12px]'>
      Basic Information
      </h3>
      <div className='p-[12px] flex flex-col '>
        <div className='flex w-full justify-between items-center p-[20px] border-[1px] border-[#E0E0E0] bg-bgMedium rounded-[4px]'>
          <Avatar size={52} icon={<UserOutlined />} src={userPhoto ? userPhoto : undefined} />
          <button className='bg-primaryMedium w-[58px] p-1 rounded-[3px] text-[16px]'
            onClick={handleUploadWindow}>
            變更
          </button>
          {popUploadWindow &&
            <div className='fixed top-[30%] left-[35%] z-30'>
              <UploadPhoto
                handleUploadWindow={handleUploadWindow} />
            </div>}
        </div>
        <div className='flex justify-end pt-[24px] pb-[10px]'>
          <div >
            <Edit />
          </div>
        </div>
        <div className='flex flex-col w-full h-[420px] justify-around px-[16px] border-[1px] border-[#E0E0E0] bg-bgMedium rounded-[4px] text-[16px] font-normal'>
          {/* <div className='flex flex-col h-1/6 justify-center border-b-[1px] border-[#E0E0E0]'>
            <p>ID</p>
            <p>{user.id}</p>
          </div> */}
          <div className='flex flex-col h-1/5 justify-center border-b-[1px] border-[#E0E0E0]'>
            <p>帳號</p>
            <p>{user.username}</p>
          </div>
          <div className='flex h-1/5 justify-between items-center border-b-[1px] border-[#E0E0E0]'>
            <div>
              <p>密碼</p>
              <p>********</p>
            </div>
            <button className='bg-primaryMedium w-[58px]  p-1 rounded-[3px] text-[16px]'
              onClick={handleEdit}>
              變更</button>
          </div>
          <div className='flex h-1/5 items-center justify-between border-b-[1px] border-[#E0E0E0]'>
            <div>
              <p>暱稱</p>
              {/* {isEdit ? */}
              <Input
                value={nickname}
                placeholder='Edite'
                onChange={(e) => { setNickname(e.target.value) }}
                className='h-[28px]'
              >
              </Input>
            </div>
            <button className='bg-primaryMedium w-[58px] h-[32px] p-1 rounded-[3px] text-[16px]'
              onClick={handleSetNickname}>
              變更</button>
            {/* :
              <p>{nickname}</p>
            } */}
          </div>
          <div className='flex flex-col h-1/5  justify-center border-b-[1px] border-[#E0E0E0]'>
            <p>Email</p>
            <p>{user.email}</p>
          </div>
          <div className='flex flex-col h-1/5 justify-center '>
            <p>部門</p>
            <p>{user.department_name}</p>
          </div>
          {/* <div className='flex flex-col h-1/4 justify-center'>
            <p>房主身分</p>
            <p>{user.created_rooms.length === 0 ? "否" : "是"}</p>
          </div> */}
        </div>
      </div>
      {/* {
        isEdit ?
          <div className='flex  p-5'>
            <button className='text-[14px] bg-primaryMedium p-2 rounded-[3px] w-[58px] h-[32px] flex items-center justify-center mr-[10px]'
              onClick={handleSetNickname}>
              確認</button>
            <button className='text-[14px] bg-[#DEACAC] p-2 rounded-[3px] w-[58px] h-[32px] flex items-center justify-center'
              onClick={handleCancel}>
              取消</button>
          </div>
          :
          null
      } */}
      {
        isEdit &&
        <ChangePassword
          handlePopup={handleEdit}
        />}

    </div >
  )
}

export default UserData