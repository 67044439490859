import { FacebookApi } from '../api/FacebookApi';
import { createBoundClientEntity, createBoundMessageEntity } from '../../domain/entities/bound/boundEntity';

export const FacebookRepository = {
  transformChatData: (rawData) => {
    if (!rawData) return null;
    return createBoundMessageEntity(rawData);
  },

  transformClientData: (rawData) => {
    if (!rawData) return null;
    return createBoundClientEntity(rawData);
  },

  fetchFacebookClients: async (roomId) => {
      const response = await FacebookApi.getFacebookClients(roomId);
      
      // Handle array of clients
      if (Array.isArray(response)) {
        return response.map(client => FacebookRepository.transformClientData(client));
      }
      
      // Handle single client
      return FacebookRepository.transformClientData(response);
  },

  fetchFacebookMessages: async (roomId, socialMediaClientId, limit = 10, oldestMessageId = null) => {
    const response = await FacebookApi.getFacebookMessages(roomId, socialMediaClientId, limit, oldestMessageId);
    return response.map(chat => FacebookRepository.transformChatData(chat));
  },

  sendFacebookMessage: async (roomId, socialMediaClientId, message) => {
    return await FacebookApi.sendFacebookMessage(roomId, socialMediaClientId, message);
  },

  // get
  getFacebookChannel: async (roomId) => {
    return await FacebookApi.getFacebookChannel(roomId);
  },

  // create
  createFacebookProperty: async (roomId, facebookProperty) => {
    return await FacebookApi.createFacebookProperty(roomId, facebookProperty);
  },
  
  // update
  updateFacebookWebhookStatus: async (roomId, socialMediaClientId, useWebhook) => {
    return await FacebookApi.updateFacebookWebhookStatus(roomId, socialMediaClientId, useWebhook);
  },
  updateFacebookChatSettings: async (roomId, chatSettings) => {
    return await FacebookApi.updateFacebookChatSettings(roomId, chatSettings);
  },

  updateFacebookBaseSettings: async (roomId, baseSettings) => {
    return await FacebookApi.updateFacebookBaseSettings(roomId, baseSettings);
  },
  updateFacebookNickname: async (roomId, socialMediaClientId, nickname) => {
    return await FacebookApi.updateFacebookNickname(roomId, socialMediaClientId, nickname);
  },

  // delete
  deleteFacebookProperty: async (roomId) => {
    return await FacebookApi.deleteFacebookProperty(roomId);
  },

  addFacebookNote: async (roomId, clientId, noteName, noteContent) => {
    return await FacebookApi.addFacebookNote(roomId, clientId, noteName, noteContent);
  },
  updateFacebookNote: async (roomId, clientId, noteId, noteName, noteContent) => {
    return await FacebookApi.updateFacebookNote(roomId, clientId, noteId, noteName, noteContent);
  },
  deleteFacebookNote: async (roomId, clientId, noteId) => {
    return await FacebookApi.deleteFacebookNote(roomId, clientId, noteId);
  },
  addFacebookTag: async (roomId, clientId, tagName) => {
    return await FacebookApi.addFacebookTag(roomId, clientId, tagName);
  },
  deleteFacebookTag: async (roomId, clientId, tagId) => {
    return await FacebookApi.deleteFacebookTag(roomId, clientId, tagId);
  },
  fetchFacebookTagGroup: async (roomId) => {
    return await FacebookApi.fetchFacebookTagGroup(roomId);
  }
  
  
  
}; 