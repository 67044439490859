import request from "../../utils/request";

export const TaskWatchdogApi = {
  /**
   * 獲取任務狀態
   * @param {string} ticketId - 任務票證ID
   * @param {number} [db=0] - 數據庫索引，默認為0
   * @returns {Promise<Object>} - 返回任務狀態信息
   */
  getTask: async (ticketId, db = 0) => {
    try {
      const response = await request.get(`/public/task/${ticketId}`, {
        params: { db },
      });
      return response;
    } catch (error) {
      console.error("TaskWatchdogApi.getTaskStatus Error:", error);
      throw error;
    }
  },

  /**
   * 刪除任務
   * @param {string} ticketId - 任務票證ID
   * @returns {Promise<Object>} - 返回刪除操作結果
   */
  deleteTask: async (ticketId) => {
    try {
      const response = await request.delete(`/root/task/${ticketId}`);
      return response;
    } catch (error) {
      console.error("TaskWatchdogApi.deleteTask Error:", error);
      throw error;
    }
  },
};
